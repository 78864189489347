import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { Trash as TrashIcon } from '../../icons/trash';
import { ImagesUploader } from '../assets/images-uploader';

export const ImageComponent = ({ value, properties, callback, setSelectedImages, selectedImages, setImages, images }) => (
  <>
    <Grid
      item
      xs={12}
      sx={{ mb: 2 }}
    >
      <Typography
        color="textPrimary"
        sx={{ mb: 1.25 }}
        variant="subtitle2"
      >
        {properties.label}
      </Typography>
      {value
        ? (
          <Box
            sx={{
              borderRadius: 1,
              boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
              display: 'flex',
              position: 'relative',
              width: 'fit-content',
              '& img': {
                borderRadius: 1,
                display: 'block',
                maxWidth: 126
              },
              '&:hover': {
                boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                '& > button': {
                  display: 'block'
                },
                '& img': {
                  opacity: 0.3
                }
              }
            }}
          >
            <img
              alt="Uploaded"
              src={value}
            />
            <IconButton
              color="error"
              onClick={() => callback('', properties.fieldName)}
              sx={{
                display: 'none',
                left: 0,
                position: 'absolute',
                top: 0

              }}
            >
              <TrashIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <ImagesUploader
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            images={images}
            setImages={setImages}
            multiSelect={false}
          />
        )}
    </Grid>

  </>
);

ImageComponent.propTypes = {
  properties: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  setSelectedImages: PropTypes.func.isRequired,
  selectedImages: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  setImages: PropTypes.func.isRequired
};
