import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,

} from '@mui/material';
import toast from 'react-hot-toast';
import React from 'react';
import { useAuth } from '../../hooks/use-auth';

import { demoId } from '../../utils/demo';

export const OrganizationDeleteDialog = ({ onClose, open, handleApiReload, clickedEventId }) => {
  const { apiRequest, org, refreshOrgs } = useAuth();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          minWidth: '600px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Delete Data Connection
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ width: '95%' }}>Are you sure you want to delete this data source? All data associated with this data source will be removed from the app and cannot be recovered.</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (org.id !== demoId) {
                apiRequest('/api/connections/delete', {
                  id: clickedEventId
                }).then(async () => {
                  await refreshOrgs(org.id);
                  handleApiReload();
                  toast.success('Data Connection Deleted');
                  onClose?.();
                }).catch((err) => {
                  toast.error('Failed to Delete Data Connection');
                  console.log(err);
                });
              } else {
                toast.success('Data Connection Deleted');
                onClose?.();
              }
            }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

OrganizationDeleteDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleApiReload: PropTypes.func,
  clickedEventId: PropTypes.string,
};
