import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormGroup,
  Slider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { InputField } from '../input-field';

export const WizardSurvey = (props) => {
  const { values, setValues, onNextStep } = props;
  const handleValuesChange = (event) => {
    const otherUpdates = {};
    if (event.target.name === 'loyalty_points' && values.loyalty_points !== event.target.value) {
      if (event.target.value > 0) {
        otherUpdates.survey_closing_text = `We appreciate your feedback and have added ${event.target.value} loyalty points to your account. Enjoy!`;
        otherUpdates.email_html = `<p>Hi {{ first_name }},</p><p><br></p><p>We&rsquo;re unashamedly obsessed with customer service and depend on customer feedback to stay at the top of our game. </p><p><br></p><p>We&rsquo;d appreciate a few minutes of your time to tell us your feedback using the link below. Your feedback is valuable to us so we’d like to offer ${event.target.value} loyalty points in appreciation.</p><p><br></p><p>Thank you in advance,</p><p><br></p><p>{{ organization.name }}</p>`;
      } else {
        otherUpdates.survey_closing_text = 'We appreciate your feedback and look forward to seeing you in our shop soon.';
        otherUpdates.email_html = '<p>Hi {{ first_name }},</p><p><br></p><p>We&rsquo;re unashamedly obsessed with customer service and depend on customer feedback to stay at the top of our game. </p><p><br></p><p>We&rsquo;d appreciate a few minutes of your time to tell us your feedback using the link below.</p><p><br></p><p>Thank you in advance,</p><p><br></p><p>{{ organization.name }}</p>';
      }
    }
    if ((event.target.name === 'loyalty_points' || event.target.name === 'exclude_days' || event.target.name === 'wait_for') && event.target.value < 0) {
      return;
    }

    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: (!event.target.value || Number.isNaN(Number(event.target.value))) ? event.target.value : parseInt(event.target.value, 10),
      ...otherUpdates,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    onNextStep({
      survey_name: values.survey_name,
      trigger: values.trigger,
      wait: values.wait || 0,
      loyalty_points: values.loyalty_points || 0,
      from_email: values.from_email,
      preview_text: values.preview_text,
      from_name: values.from_name,
      subject: values.subject,
      exclude_days: values.exclude_days || 0,
      survey_frequency: values.survey_frequency,
      returns_question: values.returns_question,
      include_vocation: values.include_vocation,
      products_physical: values.products_physical,
      survey_opening_text: values.survey_opening_text,
      survey_closing_text: values.survey_closing_text,
      ...values.email_html && { email_html: values.email_html },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridAutoFlow: 'row'
          }}
        >
          <Typography
            color="textPrimary"
            sx={{ my: 2 }}
            variant="h6"
          >
            Survey Details
          </Typography>

          <InputField
            fullWidth
            label="Survey Name"
            name="survey_name"
            onChange={handleValuesChange}
            value={values.survey_name}
          />

          <InputField
            fullWidth
            disabled
            helperText="The event to trigger this survey on"
            label="Trigger"
            name="trigger"
            onChange={handleValuesChange}
            select
            sx={{ mt: 2, display: 'none' }}
            value={values.trigger}
          >
            {[
              {
                value: 'FINISHED_CHECKOUT',
                label: 'Finished Checkout',
              },
              {
                value: 'LOYALTY_REDEEMED',
                label: 'Redeemed Loyalty Reward',
              },
            ].map((op) => (
              <MenuItem
                key={op.value}
                value={op.value}
              >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{op.label}</ListItemText>
              </MenuItem>
            ))}
          </InputField>

          <InputField
            fullWidth
            label="Wait for"
            helperText="Set the number of days between the purchase event and sending the survey request. i.e, if it takes an average of 5 days for the customer to receive your product, we recommend waiting 6 or 7 days for this survey."
            name="wait"
            type="number"
            onChange={handleValuesChange}
            value={values.wait}
            textFieldProps={{
              min: 0,
              max: 9999,
              step: 1
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  days
                </InputAdornment>
              ),
            }}
          />

          <InputField
            fullWidth
            label="Loyalty Points"
            helperText="Give loyalty points for submitting this form"
            name="loyalty_points"
            type="number"
            textFieldProps={{
              min: 0,
              max: 9999,
              step: 1
            }}
            onChange={handleValuesChange}
            value={values.loyalty_points}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  points
                </InputAdornment>
              ),
            }}
          />

          <InputField
            fullWidth
            label="Exclusion Period"
            helperText="Exclude customers who have received marketing emails or survey requests within a set period"
            name="exclude_days"
            type="number"
            onChange={handleValuesChange}
            value={values.exclude_days}
            textFieldProps={{
              min: 0,
              max: 9999,
              step: 1
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  days
                </InputAdornment>
              ),
            }}
          />

          <Box>
            <Typography
              color="textPrimary"
              sx={{ mb: 3 }}
              variant="subtitle2"
            >
              Survey Frequency
            </Typography>
            <Slider
              name="survey_frequency"
              value={values.survey_frequency}
              onChange={handleValuesChange}
              valueLabelDisplay="auto"
              step={5}
              valueLabelFormat={(value) => `${value}%`}
            />
            <Typography
              sx={{ color: '#66788A',
                fontWeight: '400',
                lineHeight: '1.6',
                fontSize: '0.75rem',
                textAlign: 'left',
                marginTop: '3px',
                marginRight: '14px',
                marginBottom: '0',
                marginLeft: '14px'
              }}
            >
              It is better to spread survey requests over time. The frequency setting allows you to set the rate for sending a survey to a customer, based on their order count e.g. a setting of 10 means a survey will be sent for every 10th purchase made by the customer.
            </Typography>
          </Box>

          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  name="Include returns question?"
                  onChange={() => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      returns_question: !values.returns_question
                    }));
                  }}
                  checked={Boolean(values.returns_question)}
                />
            )}
              label="Include returns question?"
            />
            <Typography
              sx={{ color: '#66788A',
                fontWeight: '400',
                lineHeight: '1.6',
                fontSize: '0.75rem',
                textAlign: 'left',
                marginTop: '3px',
                marginRight: '14px',
                marginBottom: '0',
                marginLeft: '14px'
              }}
            >
              Tick this box if you want to include the returns question as part of your survey. This question can be valuable if returns are common for your business, but potentially detrimental if returns shouldn’t need to be considered.
            </Typography>
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  name="Ask for job title?"
                  onChange={() => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      include_vocation: !values.include_vocation
                    }));
                  }}
                  checked={Boolean(values.include_vocation)}
                />
            )}
              label="Ask for job title?"
            />
            <Typography
              sx={{ color: '#66788A',
                fontWeight: '400',
                lineHeight: '1.6',
                fontSize: '0.75rem',
                textAlign: 'left',
                marginTop: '3px',
                marginRight: '14px',
                marginBottom: '0',
                marginLeft: '14px'
              }}
            >
              Asking for your customers’ job title will strengthen the psychographic segmentation of your audience.
            </Typography>
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  name="Are your products physical?"
                  onChange={() => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      products_physical: !values.products_physical
                    }));
                  }}
                  checked={Boolean(values.products_physical)}
                />
            )}
              label="Are your products physical?"
            />
          </FormGroup>

          <InputField
            fullWidth
            label="Sender Name"
            name="from_name"
            onChange={handleValuesChange}
            value={values.from_name}
          />

          <InputField
            fullWidth
            label="Sender Email"
            disabled
            name="from_email"
            onChange={handleValuesChange}
            value={values.from_email}
          />

          <InputField
            fullWidth
            label="Email Preview Text"
            name="preview_text"
            onChange={handleValuesChange}
            value={values.preview_text}
          />

          <InputField
            fullWidth
            multiline
            rows={2}
            label="Survey Opening Paragraph"
            name="survey_opening_text"
            onChange={handleValuesChange}
            value={values.survey_opening_text}
          />

          <InputField
            fullWidth
            multiline
            rows={2}
            label="Survey Closing Paragraph"
            name="survey_closing_text"
            onChange={handleValuesChange}
            value={values.survey_closing_text}
          />

        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 3
          }}
        >
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Next Step
          </Button>
        </Box>
      </form>
    </div>
  );
};

WizardSurvey.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired
};
