import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonBase,
  Chip,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { usePopover } from '../hooks/use-popover';
import { Selector as SelectorIcon } from '../icons/selector';
import { useAuth } from '../hooks/use-auth';

export const OrganizationPopover = (props) => {
  const { sx, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { changeOrg, orgs, org } = useAuth();
  const navigate = useNavigate();

  // console.log(orgs);

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: 'flex',
          p: 1,
          minWidth: 180,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)'
          },
          ...sx
        }}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            color: 'primary.contrastText',
            mr: 3
          }}
          variant="subtitle2"
        >
          {org.name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { minWidth: 200 }
        }}
      >
        <List>
          {orgs.map((organization) => (
            <ListItem
              key={organization.id}
              button
              selected={organization.id === org.id}
              onClick={() => {
                localStorage.removeItem('sit');
                changeOrg(organization.id);
                navigate('/dashboard');
              }}
            >
              <ListItemText primary={organization.name} />
              {
                (organization?.accountType === 'Business') && (
                <Chip
                  label="Business"
                  color="secondary"
                  sx={{ ml: 1, cursor: 'pointer' }}
                />
                )
              }
            </ListItem>
          ))}
          <ListItem
            button
            component={RouterLink}
            to="/onboard"
          >
            <ListItemText primary="Create Organization" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

OrganizationPopover.propTypes = {
  sx: PropTypes.object
};
