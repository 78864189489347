export const EventTypes = [
  {
    value: 'VIEWED_PRODUCT',
    label: 'Viewed a Product',
  },
  {
    value: 'UNSUBSCRIBED',
    label: 'Unsubscribed',
  },
  {
    value: 'GROUP_CHANGED',
    label: 'Changed their Group',
    context: 'GROUP',
  },
  {
    value: 'LOYALTY_CHANGE',
    label: 'Loyalty Points Changed',
  },
  {
    value: 'LOYALTY_REDEEMED',
    label: 'Redeemed Loyalty Reward',
  },
  {
    value: 'FORM_SUBMIT',
    label: 'Submitted a form',
    context: 'FORM',
  },
  {
    value: 'MANUAL',
    label: 'Manual',
  },
  {
    value: 'FINISHED_CHECKOUT',
    label: 'Finished Checkout',
  },
  {
    value: 'ADD_TO_CART',
    label: 'Added to Cart',
  },
  {
    value: 'UPDATED_CART',
    label: 'Updated Cart',
  },
  {
    value: 'PAGE_VIEW',
    label: 'Page View',
  },
  {
    value: 'PAGE_EXIT',
    label: 'Exited Page',
  },
  {
    value: 'SEARCH',
    label: 'Searched',
  },
  {
    value: 'PRODUCT_CARD_CLICK',
    label: 'Clicked on a Product Card',
  },
  {
    value: 'PRODUCT_GALLERY_CLICK',
    label: 'Clicked on a Product Gallery',
  },
  {
    value: 'STARTED_CHECKOUT',
    label: 'Started a Checkout',
  },
  {
    value: 'VARIANT_SELECTED',
    label: 'Selected a Variant',
  },
  {
    value: 'CUSTOM',
    label: 'Custom',
  },
  {
    value: 'SUBSCRIBED',
    label: 'Subscribed',
  },
];

export const getEvent = (event) => EventTypes.find((x) => x.value === event)?.label;
