import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import DraftsIcon from '@mui/icons-material/Drafts';
import CodeIcon from '@mui/icons-material/Code';
import {
  Button,
  Dialog,
  Card,
  CardContent,
  CardActionArea,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
  Grid
} from '@mui/material';
import { InputField } from '../input-field';
import { LoadableButton } from '../loadable-button';
import { useAuth } from '../../hooks/use-auth';

export const ActionDialog = (props) => {
  const { org } = useAuth();
  const { action, open, onClose, onNewEmail, onEditEmail, ...other } = props;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sendEmail: action?.sendEmail || false,
      changePoints: action?.changePoints || false,
      changeCustomer: action?.changeCustomer || false,
      smart_send: action?.smart_send || false,
      from_email: action?.from_email || (org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`),
      from_name: action?.from_name || (org.name),
      subject: action?.subject || '',
      exclude_days: action?.exclude_days || null,
      survey_frequency: action?.survey_frequency || null,
      preview_text: action?.preview_text || '',
      loyalty_points_change: action?.loyalty_points_change || 0,
      customer_updates: action?.customer_updates || [],
      submit: null
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Group name is required.').max(255, 'Group name must be less than 255 characters').notOneOf(['All', 'Returning', 'Ordered recently'], 'Group name already exists.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        toast.success('Action Saved');
        onClose({ values });
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle>
        Update Action
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >

          <Grid
            item
            xs={12}
          >
            <FormGroup sx={{ mb: 0 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="sendEmail"
                    onChange={formik.handleChange}
                    checked={formik.values.sendEmail}
                  />
              )}
                label="Send email?"
              />
            </FormGroup>
          </Grid>

          {
            formik.values.sendEmail
            && (
            <>
              <Grid
                item
                xs={12}
              >
                <InputField
                  error={Boolean(formik.touched.subject && formik.errors.subject)}
                  fullWidth
                  helperText={formik.touched.subject && formik.errors.subject}
                  label="Subject line"
                  name="subject"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <InputField
                  error={Boolean(formik.touched.preview_text && formik.errors.preview_text)}
                  fullWidth
                  helperText={formik.touched.preview_text && formik.errors.preview_text}
                  label="Preview Text"
                  name="preview_text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.preview_text}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <InputField
                  error={Boolean(formik.touched.from_name && formik.errors.from_name)}
                  fullWidth
                  helperText={formik.touched.from_name && formik.errors.from_name}
                  label="Sender Name"
                  name="from_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.from_name}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <InputField
                  error={Boolean(formik.touched.from_email && formik.errors.from_email)}
                  fullWidth
                  helperText={formik.touched.from_email && formik.errors.from_email}
                  label="Sender Email"
                  name="from_email"
                  disabled
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.from_email}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormGroup sx={{ mb: 0 }}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="smart_send"
                        onChange={formik.handleChange}
                        checked={Boolean(formik.values.smart_send)}
                      />
                  )}
                    label="Skip recently emailed customers?"
                  />
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Typography
                  sx={{ pb: 1 }}
                  variant="h6"
                >
                  Design your email
                </Typography>
                {
                  (action && action.emailType && action.email_html)
                    ? (
                      <Card
                        variant="outlined"
                        sx={{ mb: 1 }}
                      >
                        <CardActionArea
                          onClick={() => { onEditEmail(formik.values); }}
                        >
                          <CardContent sx={{ p: 1 }}>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              justifyContent="center"
                            >

                              <Grid
                                xs={2}
                                item
                              >
                                {
                              action.emailType === 'DESIGN' && <ViewQuiltIcon sx={{ fontSize: '5em' }} />
                            }
                                {
                              action.emailType === 'TEXT' && <DraftsIcon sx={{ fontSize: '5em' }} />
                            }
                                {
                              action.emailType === 'CODE' && <CodeIcon sx={{ fontSize: '5em' }} />
                            }
                              </Grid>

                              <Grid
                                xs={10}
                                item
                              >
                                <Typography
                                  variant="h5"
                                  color="primary"
                                >
                                  Edit your email
                                </Typography>
                              </Grid>

                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )
                    : (
                      <>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { onNewEmail('DESIGN', formik.values); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >

                                <Grid
                                  xs={2}
                                  item
                                >
                                  <ViewQuiltIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  xs={10}
                                  item
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                  >
                                    Drag and Drop
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    Create an email using our drag-and-drop editor.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { onNewEmail('TEXT', formik.values); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >

                                <Grid
                                  xs={2}
                                  item
                                >
                                  <DraftsIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  xs={10}
                                  item
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                  >
                                    Text Only
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    Send a plain text email with basic formatting.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { onNewEmail('CODE', formik.values); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >

                                <Grid
                                  xs={2}
                                  item
                                >
                                  <CodeIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  xs={10}
                                  item
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                  >
                                    HTML
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    Supply your own email code for complete control.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </>
                    )
                }

              </Grid>
            </>
            )
          }

          <Grid
            item
            xs={12}
          >
            <Divider />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormGroup sx={{ mb: 0 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="changePoints"
                    onChange={formik.handleChange}
                    checked={formik.values.changePoints}
                  />
              )}
                label="Change Loyalty Points?"
              />
            </FormGroup>
          </Grid>

          {
            formik.values.changePoints
            && (
            <>
              <Grid
                item
                xs={12}
              >
                <InputField
                  error={Boolean(formik.touched.loyalty_points_change && formik.errors.loyalty_points_change)}
                  fullWidth
                  helperText={formik.touched.loyalty_points_change && formik.errors.loyalty_points_change}
                  label="Loyalty Points Change"
                  type="number"
                  name="loyalty_points_change"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  textFieldProps={{
                    step: 1
                  }}
                  value={(formik.values.loyalty_points_change < 0 ? 0 : parseInt(formik.values.loyalty_points_change, 10)) || 0}
                />
              </Grid>
            </>
            )
          }

          <Grid
            item
            xs={12}
          >
            <Divider />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormGroup sx={{ mb: 0 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="changeCustomer"
                    onChange={formik.handleChange}
                    checked={formik.values.changeCustomer}
                  />
              )}
                label="Change Customer Properties?"
              />
            </FormGroup>
          </Grid>

          {
            formik.values.changeCustomer
            && (
            <>
              {/* repeatable fields */}
              {formik.values.customer_updates.map((detail, y) => {
                const isTouched = (formik.touched.customer_updates?.length && formik.touched.customer_updates[y]) || {};
                const isErrored = (formik.errors.customer_updates?.length && formik.errors.customer_updates[y]) || {};

                return (
                  <>
                    <Grid
                      item
                      key={y}
                      xs={12}
                      sx={{ mb: '1em' }}
                    >
                      <InputField
                        error={Boolean(isTouched.key && isErrored.key)}
                        fullWidth
                        helperText={isTouched.key && isErrored.key}
                        label="Property"
                        name={`customer_updates.${y}.key`}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={detail.key}
                        sx={{ mb: '1em' }}
                      />
                      <InputField
                        error={Boolean(isTouched.value && isErrored.value)}
                        fullWidth
                        helperText={isTouched.value && isErrored.value}
                        label="Value"
                        name={`customer_updates.${y}.value`}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={detail.value}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ mb: '1em' }}
                    >
                      <Button onClick={() => {
                        const newIndex = formik.values.customer_updates.length;
                        formik.setFieldValue(`customer_updates.${newIndex}.key`, '');
                        formik.setFieldValue(`customer_updates.${newIndex}.value`, '');
                        const newDetailsTouched = formik.touched.customer_updates?.length ? [...formik.touched.customer_updates] : [];
                        newDetailsTouched[newIndex] = false;
                        formik.setTouched({ ...formik.touched, customer_updates: newDetailsTouched });
                      }}
                      >
                        Add
                      </Button>
                      <Button onClick={() => {
                        formik.setFieldValue(`${formik.values.customer_updates.splice(y, 1)}`);
                      }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                );
              })}

              { formik.values.customer_updates.length === 0
                ? (
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        formik.setFieldValue('customer_updates.[0].key', '');
                        formik.setFieldValue('customer_updates.[0].value', '');
                        formik.setTouched({ ...formik.touched, customer_updates: false });
                      }}
                    >
                      Add Custom Properties
                    </Button>
                  </Grid>
                )
                : null}
            </>
            )
          }

          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadableButton
          loading={formik.isSubmitting}
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => { formik.handleSubmit(); }}
          variant="contained"
        >
          Update
        </LoadableButton>
      </DialogActions>
    </Dialog>
  );
};

ActionDialog.defaultProps = {
  open: false
};

ActionDialog.propTypes = {
  action: PropTypes.object,
  onNewEmail: PropTypes.func,
  onEditEmail: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func
};
