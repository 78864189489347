import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Skeleton
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { format, parseISO } from 'date-fns';
import toast from 'react-hot-toast';
import { InputField } from '../components/input-field';
import gtm from '../lib/gtm';
import { useMounted } from '../hooks/use-mounted';
import { useAuth } from '../hooks/use-auth';
import { PropertyList } from '../components/property-list';
import { PropertyListItem } from '../components/property-list-item';
import { Status } from '../components/status';
import { OrganizationSourceDialog } from '../components/organization/organization-source-dialog';
import { OrganizationDeleteDialog } from '../components/organization/organization-delete-dialog';
import { OrganizationAddNewDialog } from '../components/organization/organization-add-new-dialog';
import { OrganizationShopifyConnectionDialog } from '../components/organization/organization-shopify-connection-dialog';
import { SelectOrganizationConnectionDialog } from '../components/organization/select-organization-connection-dialog';

import { demoId } from '../utils/demo';
import demoConnections from '../demo/rawConnections.json';

const statusVariants = [
  {
    color: 'green',
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    color: 'red',
    label: 'Error',
    value: 'ERROR'
  },
  {
    color: 'Blue',
    label: 'New',
    value: 'NEW'
  },
];

function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
}

export const Integrations = () => {
  const mounted = useMounted();
  const { apiRequest, org, orgs, changeOrg } = useAuth();
  const [connectionState, setConnectionState] = useState();
  const [openSourceDialog, setOpenSourceDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddNewDialog, setOpenAddNewDialog] = useState(false);
  const [openShopifyConnectionDialog, setOpenShopifyConnectionDialog] = useState(false);
  const [openSelectOrganizationDialog, setOpenSelectOrganizationDialog] = useState(false);
  const [connectionExists, setConnectionExists] = useState(false);
  const [shopifyConnectionExists, setShopifyConnectionExists] = useState(false);
  const [vendConnectionExists, setVendConnectionExists] = useState(false);
  const [createType, setCreateType] = useState();
  const [reloadApi, setReloadApi] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();
  const [requestStatus, setRequestStatus] = useState({ isLoading: true });
  const [vendId, setVendId] = useState(null);
  const [sitState, setSitState] = useState(null);
  let { sit } = useQueryParams();
  // const [webhookSecret, setWebhookSecret] = useState(null);

  const handleVendId = (e) => {
    setVendId(e.target.value);
  };

  // const handleWebhookSecret = (e) => {
  //   setWebhookSecret(e.target.value);
  // };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        custom: 500,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1536,
      },
    },
  });
  const custom = useMediaQuery(theme.breakpoints.down('custom'));
  const getLoadingStatus = useCallback(async () => {
    setRequestStatus(() => (true));
    try {
      if (mounted.current) {
        setRequestStatus(() => ({ isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setRequestStatus(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  // Updates the data connection webhook secret
  // async function handleShopifySave() {
  //   try {
  //     await apiRequest('/api/connections/update', {
  //       id: connectionState?.find((x) => x.source === 'SHOPIFY').id,
  //       webhookSecret
  //     }).then(() => {
  //       toast.success('Webhook secret updated');
  //       setReloadApi(!reloadApi);
  //     }).catch((err) => {
  //       console.log(err);
  //       toast.error('Webhook secret failed to update');
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     toast.error('Webhook secret failed to update');
  //   }
  // }

  async function handleVendSave() {
    try {
      if (org.id !== demoId) {
        await apiRequest('/api/connections/update', {
          id: connectionState?.find((x) => x.source === 'VEND').id,
          vendDiscountId: vendId
        }).then(() => {
          toast.success('Vend ID updated');
          setReloadApi(!reloadApi);
        }).catch((err) => {
          console.log(err);
          toast.error('Vend ID failed to update');
        });
      }
    } catch (err) {
      console.error(err);
      toast.error('Vend ID failed to update');
    }
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });

    // get data connection
    if (org.id !== demoId) {
      apiRequest('/api/connections').then((result) => {
        setConnectionState(result);
        setVendId(result?.find((x) => x.source === 'VEND') ? result?.find((x) => x.source === 'VEND').vend_discount_id : null);
        setShopifyConnectionExists(result?.find((x) => x.source === 'SHOPIFY'));
        setVendConnectionExists(result?.find((x) => x.source === 'VEND'));
        setConnectionExists(result.length > 0);
        // setWebhookSecret(result?.find((x) => x.source === 'SHOPIFY') ? result?.find((x) => x.source === 'SHOPIFY').webhook_secret : null);
        getLoadingStatus().catch(console.error);
      }).catch((err) => console.log(err));
    } else {
      setConnectionState(demoConnections);
      setVendId(demoConnections?.find((x) => x.source === 'VEND') ? demoConnections?.find((x) => x.source === 'VEND').vend_discount_id : null);
      setShopifyConnectionExists(demoConnections?.find((x) => x.source === 'SHOPIFY'));
      setVendConnectionExists(demoConnections?.find((x) => x.source === 'VEND'));
      setConnectionExists(demoConnections.length > 0);
      getLoadingStatus().catch(console.error);
    }
  }, [org, reloadApi]);

  useEffect(() => {
    // check if integration valid
    if (sit && demoId !== org.id) {
      setSitState(sit);
      apiRequest('/public/validate-storefront-integration', { token: sit }).then(async (result) => {
        if (result && result.domain !== org.data_connection_domain) {
          const domainOrg = orgs.find((x) => x.data_connection_domain === result.domain);
          if (domainOrg && domainOrg.id) {
            await changeOrg(domainOrg.id);
          }
        }
        if (result && result.valid) {
          setOpenShopifyConnectionDialog(true);
        } else if (result && org.data_connection_domain !== result.domain) {
          setOpenSelectOrganizationDialog(true);
        }
      }).catch((err) => {
        sit = null;
        setSitState(null);
        console.log(err);
      });
    } else if (localStorage.getItem('sit') && demoId !== org.id) {
      setSitState(localStorage.getItem('sit'));
      apiRequest('/public/validate-storefront-integration', { token: localStorage.getItem('sit') }).then(async (result) => {
        console.log(result.domain, org.data_connection_domain);
        if (result && result.domain !== org.data_connection_domain) {
          const domainOrg = orgs.find((x) => x.data_connection_domain === result.domain);
          if (domainOrg && domainOrg.id) {
            await changeOrg(domainOrg.id);
          }
        }
        if (result && result.valid) {
          setOpenShopifyConnectionDialog(true);
        } else if (result && org.data_connection_domain !== result.domain) {
          setOpenSelectOrganizationDialog(true);
        } else {
          localStorage.removeItem('sit');
        }
      }).catch((err) => {
        localStorage.removeItem('sit');
        setSitState(null);
        console.log(err);
      });
    }
  }, [sit]);

  const renderLoading = () => {
    if (requestStatus.isLoading) {
      return (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      );
    }

    if (requestStatus.error) {
      return (
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              display: 'flex',
              flexDirection: 'column',
              p: 3
            }}
          >
            <ErrorOutlineIcon />
            <Typography
              color="textSecondary"
              sx={{ mt: 2 }}
              variant="body2"
            >
              {requestStatus.error}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      {!requestStatus.isLoading
        ? (
          <Box>

            <Grid sx={{ mb: '2em' }}>
              <Button
                disabled={Boolean(connectionExists || shopifyConnectionExists || sitState)}
                color="primary"
                size="large"
                fullWidth={custom}
                sx={{ mt: 2, mr: '1em', }}
                variant="contained"
                onClick={() => {
                  setOpenAddNewDialog(true);
                  setCreateType('SHOPIFY');
                }}
              >
                Shopify Connection
              </Button>

              <Button
                disabled={Boolean(connectionExists || vendConnectionExists || sitState)}
                color="primary"
                size="large"
                fullWidth={custom}
                sx={{ mt: 2 }}
                variant="outlined"
                onClick={() => {
                  setOpenAddNewDialog(true);
                  setCreateType('VEND');
                }}
              >
                Vend Connection
              </Button>
            </Grid>
            {connectionState.map((connection, i) => {
              const statusVariant = statusVariants.find((variant) => variant.value === connection.status);
              if (connection.source === 'SHOPIFY') {
                return (
                  <Card
                    variant="outlined"
                    sx={{ mb: '1em' }}
                    key={i}
                  >
                    <CardContent>
                      <Grid
                        container
                        spacing={4}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <PropertyList>
                            <PropertyListItem
                              divider
                              label=""
                            >
                              <Typography
                                variant="h5"
                                sx={{ display: 'inline' }}
                                key="123"
                              >
                                {connection.source === null ? <i>Not Set</i> : connection.source}
                              </Typography>
                              {/* {connection.primary === null ? <i>Not Set</i> : connection?.primary === 1
                                ? (
                                  <Typography
                                    variant="h5"
                                    sx={{ display: 'inline' }}
                                  >
                                    {' (Primary)'}
                                  </Typography>
                                )
                                : null} */}
                            </PropertyListItem>
                            <PropertyListItem
                              divider
                              label="Created at"
                        // DATE FNS formatting is different with newer version
                              value={connection.created_at === null ? <i>Not Set</i> : format(parseISO(connection.created_at), 'dd/MM/yyyy hh:mm a')}
                            />
                            <PropertyListItem
                              divider
                              label="Last Synced"
                              value={connection.last_synced === null ? <i>Not Set</i> : connection.last_synced}
                            />
                            <PropertyListItem
                              label="Status"
                            >
                              <Status
                                color={statusVariant.color}
                                label={statusVariant.label}
                              />
                            </PropertyListItem>
                          </PropertyList>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Grid>
                            {/* <InputField
                              disabled
                              fullWidth
                              value={connection.secret}
                              label="Shopify Secret Key"
                              type="password"
                              name="secret"
                              sx={{ mb: 2 }}
                            /> */}
                            <InputField
                              disabled
                              fullWidth
                              value={connection.domain}
                              label="Shopify Domain"
                              name="domain"
                              sx={{ mb: 2 }}
                            />
                            {/* <InputField
                              fullWidth
                              value={webhookSecret || ''}
                              label="Webhook Secret"
                              name="webhook_secret"
                              onChange={handleWebhookSecret}
                              sx={{ mb: 2 }}
                            /> */}
                            {connection.primary === 1
                              ? null
                              : (
                                <Button
                                  color="primary"
                                  size="large"
                                  fullWidth={custom}
                                  sx={{ mt: 2, mr: '1em', }}
                                  variant="contained"
                                  onClick={() => {
                                    setOpenSourceDialog(true);
                                    setClickedEventId(connection.id);
                                  }}
                                >
                                  Set as Primary Data Source
                                </Button>
                              )}
                            <Button
                              color="primary"
                              size="large"
                              fullWidth={custom}
                              sx={{ mt: 2 }}
                              variant="outlined"
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setClickedEventId(connection.id);
                              }}
                            >
                              Delete
                            </Button>
                            {/* {connection?.webhook_secret !== webhookSecret
                              ? (
                                <Button
                                  color="primary"
                                  size="large"
                                  fullWidth={custom}
                                  sx={{ mt: 2, ml: 2 }}
                                  onClick={() => {
                                    handleShopifySave();
                                  }}
                                >
                                  Save
                                </Button>
                              )
                              : null} */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              }
              return (
                <Card
                  sx={{ mb: '1em' }}
                  variant="outlined"
                  key={`${connection.source} ${i} source`}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <PropertyList>
                          <PropertyListItem
                            divider
                            label=""
                          >
                            <Typography
                              variant="h5"
                              sx={{ display: 'inline' }}
                            >
                              {connection.source === null ? <i>Not Set</i> : connection.source}
                            </Typography>
                            {/* {connection.primary === null ? <i>Not Set</i> : connection?.primary === 1
                              ? (
                                <Typography
                                  variant="h5"
                                  sx={{ display: 'inline' }}
                                >
                                  {' (Primary)'}
                                </Typography>
                              )
                              : null} */}
                          </PropertyListItem>
                          <PropertyListItem
                            divider
                            label="Created at"
                            value={connection.created_at === null ? <i>Not Set</i> : format(parseISO(connection.created_at), 'dd/MM/yyyy hh:mm a')}
                          />
                          <PropertyListItem
                            divider
                            label="Last Synced"
                            value={connection.last_synced === null ? <i>Not Set</i> : connection.last_synced}
                          />
                          <PropertyListItem
                            label="Status"
                          >
                            <Status
                              color={statusVariant.color}
                              label={statusVariant.label}
                            />
                          </PropertyListItem>
                        </PropertyList>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid>
                          <InputField
                            disabled
                            fullWidth
                            label="Vend Secret Key"
                            name="secret"
                            type="password"
                            value={connection.secret === null ? <i>Not Set</i> : connection?.secret}
                            sx={{ mb: 2 }}
                          />
                          <InputField
                            fullWidth
                            disabled
                            label="Vend Domain"
                            name="domain"
                            value={connection.domain === null ? <i>Not Set</i> : connection?.domain}
                            sx={{ mb: 2 }}
                          />
                          <InputField
                            fullWidth
                            label="Vend Discount ID"
                            name="discountId"
                            value={vendId || ''}
                            onChange={handleVendId}
                            sx={{ mb: 2 }}
                          />
                          {connection.primary === 1
                            ? null
                            : (
                              <Button
                                color="primary"
                                size="large"
                                fullWidth={custom}
                                sx={{ mt: 2, mr: '1em', }}
                                variant="contained"
                                onClick={() => {
                                  setOpenSourceDialog(true);
                                  setClickedEventId(connection.id);
                                }}
                              >
                                Set as Primary Data Source
                              </Button>
                            )}
                          <Button
                            color="primary"
                            size="large"
                            fullWidth={custom}
                            sx={{ mt: 2 }}
                            variant="outlined"
                            onClick={() => {
                              setOpenDeleteDialog(true);
                              setClickedEventId(connection.id);
                            }}
                          >
                            Delete
                          </Button>
                          {connection?.vend_discount_id !== vendId
                            ? (
                              <Button
                                color="primary"
                                size="large"
                                sx={{ mt: 2, ml: 2 }}
                                onClick={() => {
                                  handleVendSave();
                                }}
                              >
                                Save
                              </Button>
                            )
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

              );
            })}

            <OrganizationSourceDialog
              onClose={() => {
                setOpenSourceDialog(false);
              }}
              open={openSourceDialog}
              handleApiReload={() => {
                setReloadApi(!reloadApi);
              }}
              connections={connectionState}
              clickedEventId={clickedEventId}
            />
            <OrganizationDeleteDialog
              onClose={() => {
                setOpenDeleteDialog(false);
              }}
              open={openDeleteDialog}
              handleApiReload={() => {
                setReloadApi(!reloadApi);
              }}
              clickedEventId={clickedEventId}
            />
            <OrganizationShopifyConnectionDialog
              onClose={() => {
                setOpenShopifyConnectionDialog(false);
                sit = null;
                localStorage.removeItem('sit');
                setSitState(null);
              }}
              open={openShopifyConnectionDialog}
              handleApiReload={() => {
                setReloadApi(!reloadApi);
              }}
              token={sitState}
              connections={connectionState}
            />
            <SelectOrganizationConnectionDialog
              onClose={() => {
                setOpenSelectOrganizationDialog(false);
                sit = null;
                localStorage.removeItem('sit');
                setSitState(null);
              }}
              open={openSelectOrganizationDialog}
              handleApiReload={() => {
                setReloadApi(!reloadApi);
              }}
              token={sitState}
              connections={connectionState}
            />
            <OrganizationAddNewDialog
              onClose={() => {
                setOpenAddNewDialog(false);
              }}
              open={openAddNewDialog}
              createType={createType}
              connections={connectionState}
              handleApiReload={() => {
                setReloadApi(!reloadApi);
              }}
            />
          </Box>
        )
        : renderLoading()}
    </>
  );
};
