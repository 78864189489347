import PropTypes from 'prop-types';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React from 'react';

export const TypologyCard = ({ guidance }) => {
  console.log(guidance);
  return (
    <Box sx={{ px: 3 }}>
      {guidance?.data?.groups?.map((list) => (
        <React.Fragment
          key={list.name}
        >
          <Typography
            sx={{ mb: 0 }}
            variant="overline"
            fontWeight="bold"
          >
            {list.name}
          </Typography>
          <List sx={{ pb: 1, pt: 0 }}>
            {list.items.map((item) => (
              <ListItem
                sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                disableGutters
                key={item.Importance}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 1,
                    mt: 0.8,
                  }}
                >
                  {item.StatementType === 'Dont' ? <CancelOutlinedIcon sx={{ color: 'error.main' }} /> : <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.main' }} /> }
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={item.AdviceText}
                />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Box>
  );
};

TypologyCard.propTypes = {
  guidance: PropTypes.object.isRequired
};
