import { useState, useEffect, useCallback } from 'react';
import { Box, Card, List, Skeleton } from '@mui/material';
import { CustomerActivityItem } from './customer-activity-item';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import { useMounted } from '../../hooks/use-mounted';
import { useAuth } from '../../hooks/use-auth';
import { demoId } from '../../utils/demo';
import demoEventsData from '../../demo/rawEventsData.json';

export const CustomerActivity = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();

  const [eventState, setEventState] = useState();

  const [requestStatus, setRequestStatus] = useState({ isLoading: true });
  const getLoadingStatus = useCallback(async () => {
    setRequestStatus(() => (true));
    try {
      const eventsData = org.id === demoId ? demoEventsData : await apiRequest('/api/events', {
        limit: 10,
        excludeProperties: true
      });
      if (mounted.current) {
        setEventState(eventsData);
        setRequestStatus(() => ({ isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setRequestStatus(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, [org]);

  useEffect(() => {
    getLoadingStatus().catch(console.error);
  }, [org]);

  const displayLoading = requestStatus.isLoading;
  const displayError = Boolean(!requestStatus.isLoading && requestStatus.error);
  const displayUnavailable = Boolean(!requestStatus.isLoading
    && !requestStatus.error);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
        >
          {!requestStatus.isLoading
            ? (
              <>
                { (eventState && eventState.length)
                  ? (
                    <List disablePadding>
                      {eventState?.map((event) => (
                        <CustomerActivityItem
                          key={event.id}
                          event={event}
                          divider
                        />
                      ))}
                    </List>
                  )
                  : <ResourceUnavailable sx={{ m: 2 }} />}
              </>

            )
            : (
              <>
                {displayLoading && (
                <Box sx={{ p: 2 }}>
                  <Skeleton height={42} />
                  <Skeleton height={42} />
                  <Skeleton height={42} />
                </Box>
                )}
                {displayError && (
                <ResourceError
                  error={requestStatus.error}
                  sx={{ m: 2 }}
                />
                )}
                {displayUnavailable && <ResourceUnavailable sx={{ m: 2 }} />}
              </>
            )}

        </Card>
      </Box>
    </>
  );
};
