import PropTypes from 'prop-types';
import { Grid, Card, Box, Typography, CircularProgress } from '@mui/material';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { format, subMonths, startOfMonth, endOfMonth, subYears, subDays, differenceInDays } from 'date-fns';
import { ActionsMenu } from '../actions-menu';
import { GroupBys } from '../../utils/group-by-date';
import { DateControls } from '../insights-root/date-controls';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { demoId } from '../../utils/demo';

export const CampaignAreaGraph = ({ selectedCampaignId }) => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();

  const [range, setRange] = useState('All Typologies');

  const [groupBy, setGroupBy] = useState(GroupBys[1].key); // 1 = DAY
  const groupByOptions = GroupBys.map((g) => ({ label: `Group by ${g.label}`, key: g.key, onClick: () => { setGroupBy(g.key); } }));

  const firstOfLastMonth = org.id === demoId ? startOfMonth(subMonths(new Date('2022-06-01'), 1)) : startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = org.id === demoId ? endOfMonth(subMonths(new Date('2022-06-01'), 1)) : endOfMonth(subMonths(new Date(), 1));

  const [selectedDateRange, setSelectedDateRange] = useState({ start: firstOfLastMonth, end: endOfLastMonth, compareStart: null, compareEnd: null });
  const selectedGroupBy = GroupBys.find((x) => x.key === groupBy);
  const xAxisRange = selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const [graphState, setGraphState] = useState({ isLoading: true, data: [] });

  const ranges = [
    {
      label: 'All Typologies',
      onClick: () => { setRange('All Typologies'); }
    },
    {
      label: 'Traditionalist',
      onClick: () => { setRange('Traditionalist'); }
    },
    {
      label: 'Conspicuous',
      onClick: () => { setRange('Conspicuous'); }
    },
    {
      label: 'Pioneer',
      onClick: () => { setRange('Pioneer'); }
    },
    {
      label: 'Conformist',
      onClick: () => { setRange('Conformist'); }
    },
    {
      label: 'Intellectual',
      onClick: () => { setRange('Intellectual'); }
    },
    {
      label: 'Subsistent',
      onClick: () => { setRange('Subsistent'); }
    },
    {
      label: 'Individual',
      onClick: () => { setRange('Individual'); }
    },
    {
      label: 'Analytical',
      onClick: () => { setRange('Analytical'); }
    },
    {
      label: 'Aspirant',
      onClick: () => { setRange('Aspirant'); }
    },
    {
      label: 'Affluential',
      onClick: () => { setRange('Affluential'); }
    },
    {
      label: 'Protectionist',
      onClick: () => { setRange('Protectionist'); }
    },
    {
      label: 'Eccentric',
      onClick: () => { setRange('Eccentric'); }
    },

  ];
  const theme = useTheme();
  const chartOptions = {
    chart: {
      type: 'area',
      zoom: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    colors: ['#CCCCCC', '#0058FF', '#21D59B'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    grid: {
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      curve: 'straight',
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: '#555',
        show: true,
      },
      axisTicks: {
        color: '#555',
        show: true,
      },
      categories: xAxisRange,
      labels: {
        style: {
          colors: '#555',
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: -12,
        style: {
          colors: '#555',
        },
      },
    },
  };
  // END CHART STUFF

  async function getGraphData() {
    try {
      setGraphState(() => ({ isLoading: true }));
      const result = await apiRequest('/email/campaign-graph', {
        id: selectedCampaignId,
        startDate: format(selectedDateRange.start, 'yyyy-MM-dd'),
        endDate: format(selectedDateRange.end, 'yyyy-MM-dd'),
        groupBy,
        context: range !== 'All Typologies' ? range : ''
      });

      const formattedCampaignData = [
        {
          name: 'Delivered',
          data: xAxisRange.map((singleDate) => {
            const matchResult = result.find((x) => x.formatted_date === singleDate);
            return matchResult ? matchResult.delivered : 0;
          })
        },
        {
          name: 'Opened',
          data: xAxisRange.map((singleDate) => {
            const matchResult = result.find((x) => x.formatted_date === singleDate);
            return matchResult ? matchResult.opened : 0;
          })
        },
        {
          name: 'Clicked',
          data: xAxisRange.map((singleDate) => {
            const matchResult = result.find((x) => x.formatted_date === singleDate);
            return matchResult ? matchResult.clicked : 0;
          })
        },
      ];
      if (mounted.current) {
        setGraphState(() => ({
          isLoading: false,
          data: formattedCampaignData
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setGraphState(() => ({
          isLoading: true,
          error: err.message
        }));
      }
    }
  }

  useEffect(() => {
    getGraphData();
  }, [groupBy, selectedDateRange, range, org]);

  return (
    <Grid
      item
      marginBottom="2em"
    >
      <Card variant="outlined">

        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: '2em',
            py: '2em'
          }}
        >
          <Typography variant="h5">Overview</Typography>
          <Grid
            item
            md={5}
            sm={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}
          >

            <ActionsMenu
              actions={groupByOptions}
              label={groupByOptions.find((x) => x.key === groupBy).label}
              size="small"
              variant="text"
              sx={{ mr: 1, }}
            />
            <DateControls
              onDateChange={(start, end, compare = null) => {
                let compareStart = null;
                let compareEnd = null;
                if (compare && compare === 'Last Year') {
                  compareStart = subYears(start, 1);
                  compareEnd = subYears(end, 1);
                }
                if (compare && compare === 'Last Period') {
                  const days = differenceInDays(end, start);
                  compareStart = subDays(start, days + 1);
                  compareEnd = subDays(start, 1);
                }
                setSelectedDateRange({ start, end, compareStart, compareEnd });
              }}
            />
            <ActionsMenu
              actions={ranges}
              label={range}
              size="small"
              variant="text"
              sx={{ mr: 1 }}
            />

          </Grid>
        </Grid>
        <Box sx={{ px: 1, height: '100%' }}>
          {!graphState.isLoading
            ? (
              <>
                <Chart
                  height="350"
                  options={chartOptions}
                  series={graphState.data}
                  type="area"
                />
              </>
            )
            : (
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              >
                <CircularProgress
                  color="primary"
                  size={100}
                />

              </Box>
            )}
        </Box>
      </Card>
    </Grid>
  );
};

CampaignAreaGraph.propTypes = {
  selectedCampaignId: PropTypes.string
};
