import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box
} from '@mui/material';
import React from 'react';
import { useAuth } from '../../hooks/use-auth';

export const SelectOrganizationConnectionDialog = ({ onClose, open }) => {
  const { orgs } = useAuth();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          minWidth: '600px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Shopify Integration
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ width: '95%' }}>An integration already exists for this organization. If you wish to add a new connection to this organization, please delete the existing Integration first.</Typography>
          {orgs.length > 1 && (
            <>
              <Typography sx={{ mt: 2, width: '95%' }}>To integrate to different organization, change your currently selected organization.</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onClose?.();
            }}
            variant="contained"
          >
            Okay
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

SelectOrganizationConnectionDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
