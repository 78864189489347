import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Container, Grid, Toolbar, Button } from '@mui/material';
import { Wizard } from '../components/wizard';
import gtm from '../lib/gtm';
import { useAuth } from '../hooks/use-auth';

export const Onboard = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Helmet>
        <title>Create a New Organisation | AudienceZen Dashboard</title>
      </Helmet>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'neutral.900' }}
      >
        <Container maxWidth="md">
          <Toolbar
            disableGutters
            sx={{ height: 64 }}
          >
            <RouterLink
              to="/"
              style={{ height: '41.8px' }}
            >
              <img
                src="/static/AudienceZen-logo-white-green.png"
                alt="AudienceZen Logo"
                style={{ height: '100%' }}
              />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Button
              color="primary"
              variant="contained"
              component="button"
              sx={{ maxWidth: 'fit-content', display: 'flex', mb: 2 }}
              onClick={() => {
                handleLogout();
              }}
            >
              Log Out
            </Button>
            <Grid
              container
              spacing={6}
              justifyContent="center"
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Wizard />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
