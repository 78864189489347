import PropTypes from 'prop-types';
import { Avatar, Box, Card, Typography, Skeleton, Grid, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Typologies } from '../../utils/typology';
import { ActionsMenu } from '../actions-menu';

export const GenericMetricCard = ({ data, typologyConfidence = 0, icon = 'send', title = 'Metric', subtitle = 'Subtitle', option = null, setOption = null, isLoading }) => {
  const typologyClone = [...Typologies];
  typologyClone.push({ label: 'All Typologies' });
  const options = typologyClone.map((x) => ({
    label: x.label,
    value: x.value || x.label,
    onClick: () => { setOption(x.label); }
  }));
  const determineIcon = (name) => {
    if (name.toLowerCase() === 'send') {
      return <SendIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
    } if (name.toLowerCase() === 'star') {
      return <StarBorderIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
    } if (name.toLowerCase() === 'cart') {
      return <AddShoppingCartIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
    } if (name.toLowerCase() === 'person') {
      return <GroupAddIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
    } if (name.toLowerCase() === 'money') {
      return <AttachMoneyIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
    }
    return <InsertEmoticonIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />;
  };
  return (
    <Card
      sx={{ height: '100%' }}
      variant="outlined"
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          pt: 2,
          px: 3,
          pb: 0,
        }}
      >
        <Typography
          color="textSecondary"
          variant="overline"
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          py: 0,
          px: 3
        }}
      >
        <Box
          sx={{
            flexGrow: 'grow',
          }}
        >
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {
              (isLoading)
                ? (
                  <Skeleton
                    height={42}
                    width={220}
                    sx={{ p: 0, m: 0 }}
                  />
                )
                : (data || 0)
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            ml: 2
          }}
        >
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              height: 56,
              width: 56
            }}
          >
            {determineIcon(icon)}
          </Avatar>
        </Box>
      </Box>
      <Box
        sx={{
          pt: 0,
          px: 3,
          pb: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start'
        }}
      >
        {
          (isLoading)
            ? (
              <Skeleton
                height={42}
                width={50}
                sx={{ p: 0, m: 0 }}
              />
            )
            : (
              option !== null

                ? (
                  <>
                    <ActionsMenu
                      actions={options}
                      label={option}
                      size="small"
                      variant="text"
                      sx={{ mr: 1, width: 'auto' }}
                    />
                    {icon.toLowerCase() === 'person' && option !== 'No Typology' && (
                    <Tooltip title="Typology Confidence Rating">
                      <Grid
                        container
                        spacing={0.1}
                        sx={{ ml: 1 }}
                      >
                        <Grid item>
                          <StarIcon style={{ color: '#f7bd19', fontSize: 26 }} />
                        </Grid>
                        <Grid item>
                          { typologyConfidence > 0.2 ? <StarIcon style={{ color: '#f7bd19', fontSize: 26 }} /> : <StarOutlineIcon style={{ color: '#ccc', fontSize: 26 }} /> }
                        </Grid>
                        <Grid item>
                          { typologyConfidence > 0.4 ? <StarIcon style={{ color: '#f7bd19', fontSize: 26 }} /> : <StarOutlineIcon style={{ color: '#ccc', fontSize: 26 }} /> }
                        </Grid>
                        <Grid item>
                          { typologyConfidence > 0.6 ? <StarIcon style={{ color: '#f7bd19', fontSize: 26 }} /> : <StarOutlineIcon style={{ color: '#ccc', fontSize: 26 }} /> }
                        </Grid>
                        <Grid item>
                          { typologyConfidence > 0.8 ? <StarIcon style={{ color: '#f7bd19', fontSize: 26 }} /> : <StarOutlineIcon style={{ color: '#ccc', fontSize: 26 }} /> }
                        </Grid>
                      </Grid>
                    </Tooltip>
                    )}
                  </>
                )
                : (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {subtitle}
                  </Typography>
                )
            )
        }

      </Box>
    </Card>
  );
};

GenericMetricCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  typologyConfidence: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  option: PropTypes.string,
  setOption: PropTypes.func,
  isLoading: PropTypes.bool,
};
