import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/ext-language_tools';

export const HtmlEditor = ({ onChange, width, height, name, value }) => (
  <AceEditor
    mode="html"
    theme="sqlserver"
    onChange={onChange}
    name={name}
    value={value}
    style={{ border: '1px solid #ccc' }}
    editorProps={{ $blockScrolling: false }}
    setOptions={{
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
      resize: true,
    }}
    width={width}
    height={height}
    showPrintMargin={false}
    wrapEnabled
  />
);

HtmlEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
