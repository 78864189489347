/* eslint-disable no-shadow, no-param-reassign,  no-self-assign, camelcase  */
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { GroupBys } from '../../../utils/group-by-date';
import { ActionsMenu } from '../../actions-menu';

const options = [
  'Product Selection', 'Ease of Use', 'Fulfillment', 'Product Quality', 'Competitive Price', 'Communication', 'Returns', 'Customer Support'
];

export const Trends = ({ title = '2. Trends in customer satisfaction', range, data, ...rest }) => {
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const [selectedOption, setSelectedOption] = useState(0);

  const ranges = options.map((x, i) => ({
    label: x,
    onClick: () => { setSelectedOption(i); }
  }));
  const parsed_data = [...data].map((x) => {
    const parsedProperties = Object.assign({}, ...JSON.parse(x.properties));
    return { ...parsedProperties, created_at: x.created_at };
  });

  const formattedData = [...parsed_data].map((obj) => ({
    created_at: obj.created_at,

    expectations_communication: obj.expectations_communication || 0,
    expectations_ease_of_use: obj.expectations_ease_of_use || 0,
    expectations_fulfillment: obj.expectations_fulfillment || 0,
    expectations_pricing: obj.expectations_pricing || 0,
    expectations_product_selection: obj.expectations_product_selection || 0,
    expectations_quality: obj.expectations_quality || 0,
    expectations_returns: obj.expectations_returns || 0,
    expectations_service: obj.expectations_service || 0,

    performance_communication: obj.performance_communication || 0,
    performance_ease_of_use: obj.performance_ease_of_use || 0,
    performance_fulfillment: obj.performance_fulfillment || 0,
    performance_pricing: obj.performance_pricing || 0,
    performance_product_selection: obj.performance_product_selection || 0,
    performance_quality: obj.performance_quality || 0,
    performance_returns: obj.performance_returns || 0,
    performance_service: obj.performance_service || 0
  }));
  const propertiesArr = [
    'expectations_product_selection', 'expectations_ease_of_use', 'expectations_fulfillment', 'expectations_quality', 'expectations_pricing', 'expectations_communication', 'expectations_returns', 'expectations_service',
    'performance_product_selection', 'performance_ease_of_use', 'performance_fulfillment', 'performance_quality', 'performance_pricing', 'performance_communication', 'performance_returns', 'performance_service'
  ];
  function getStandardDeviation(unformattedArray) {
    if (!unformattedArray || unformattedArray.length === 0) { return null; }

    const splitArr = propertiesArr.map((property) => ({ [property]: (unformattedArray.map((obj) => {
      if (obj[property]) {
        return +obj[property];
      }
      return null;
    }).filter(Number)
    ) }));

    const splitStdArr = splitArr.map((arr) => {
      const array = Object.values(arr)[0];
      const n = array.length;
      const mean = array.length > 0 ? array.reduce((a, b) => a + b) / n : 0;
      const stdDev = array.length > 0 ? Math.sqrt(array.map((x) => ((x - mean) ** 2)).reduce((a, b) => a + b) / n) : 0;
      return { [Object.keys(arr)]: [mean - stdDev, mean + stdDev] };
    });
    return splitStdArr;
  }
  const stdDevArr = getStandardDeviation(formattedData);

  const seriesRaw = [...range].map((x) => {
    // [0]['expectations_product_selection'][0]
    const fData = [...formattedData].filter((y) => {
      const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
      return (formattedDate === x);
    }).reduce((acc, curr) => {
      Object.keys(curr).forEach((key) => {
        // if curr's current key is inside our properties arr
        if (propertiesArr.includes(String(key))) {
          // filter if the value at current's, current value within our calculated std dev
          if (curr[key] > stdDevArr.find((x) => Object.keys(x)[0] === String(key))[String(key)][0] && curr[key] < stdDevArr.find((x) => Object.keys(x)[0] === String(key))[String(key)][1]) {
            curr[key] = curr[key];
          } else {
            curr[key] = null;
          }
        } else if (String(key) === 'created_at') {
          curr[key] = curr[key];
        } else {
          curr[key] = null;
        }
      });

      const properties = curr;
      acc = {
        expectations: [
          acc.expectations[0] + +properties.expectations_product_selection || 0,
          acc.expectations[1] + +properties.expectations_ease_of_use || 0,
          acc.expectations[2] + +properties.expectations_fulfillment || 0,
          acc.expectations[3] + +properties.expectations_quality || 0,
          acc.expectations[4] + +properties.expectations_pricing || 0,
          acc.expectations[5] + +properties.expectations_communication || 0,
          acc.expectations[6] + +properties.expectations_returns || 0,
          acc.expectations[7] + +properties.expectations_service || 0,
        ],
        expectations_freq: [
          acc.expectations_freq[0] + (properties.expectations_product_selection !== null ? 1 : 0),
          acc.expectations_freq[1] + (properties.expectations_ease_of_use !== null ? 1 : 0),
          acc.expectations_freq[2] + (properties.expectations_fulfillment !== null ? 1 : 0),
          acc.expectations_freq[3] + (properties.expectations_quality !== null ? 1 : 0),
          acc.expectations_freq[4] + (properties.expectations_pricing !== null ? 1 : 0),
          acc.expectations_freq[5] + (properties.expectations_communication !== null ? 1 : 0),
          acc.expectations_freq[6] + (properties.expectations_returns !== null ? 1 : 0),
          acc.expectations_freq[7] + (properties.expectations_service !== null ? 1 : 0),
        ],
        performance: [
          acc.performance[0] + +properties.performance_product_selection || 0,
          acc.performance[1] + +properties.performance_ease_of_use || 0,
          acc.performance[2] + +properties.performance_fulfillment || 0,
          acc.performance[3] + +properties.performance_quality || 0,
          acc.performance[4] + +properties.performance_pricing || 0,
          acc.performance[5] + +properties.performance_communication || 0,
          acc.performance[6] + +properties.performance_returns || 0,
          acc.performance[7] + +properties.performance_service || 0,
        ],
        performance_freq: [
          acc.performance_freq[0] + (properties.performance_product_selection !== null ? 1 : 0),
          acc.performance_freq[1] + (properties.performance_ease_of_use !== null ? 1 : 0),
          acc.performance_freq[2] + (properties.performance_fulfillment !== null ? 1 : 0),
          acc.performance_freq[3] + (properties.performance_quality !== null ? 1 : 0),
          acc.performance_freq[4] + (properties.performance_pricing !== null ? 1 : 0),
          acc.performance_freq[5] + (properties.performance_communication !== null ? 1 : 0),
          acc.performance_freq[6] + (properties.performance_returns !== null ? 1 : 0),
          acc.performance_freq[7] + (properties.performance_service !== null ? 1 : 0),
        ],
      };

      return acc;
    }, {
      expectations: [0, 0, 0, 0, 0, 0, 0, 0],
      expectations_freq: [0, 0, 0, 0, 0, 0, 0, 0],
      performance: [0, 0, 0, 0, 0, 0, 0, 0],
      performance_freq: [0, 0, 0, 0, 0, 0, 0, 0],
    });
    return {
      expectations: fData.expectations[selectedOption] ? (fData.expectations[selectedOption] / fData.expectations_freq[selectedOption]).toFixed(2) : 0,
      performance: fData.performance[selectedOption] ? (fData.performance[selectedOption] / fData.performance_freq[selectedOption]).toFixed(2) : 0,
    };
  });
  const series = [
    {
      name: 'Expectations',
      data: [
        ...seriesRaw.map((x) => x.expectations),
      ],
    },
    {
      name: 'Experience',
      data: [
        ...seriesRaw.map((x) => x.performance),
      ]
    }
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false,
      }
    },
    colors: ['#F68338', '#00B0F0'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      // padding: {
      //   left: 50,
      //   right: 50
      // },
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: range,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter(val) {
          return val.toFixed(0);
        },
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title={title}
        action={(
          <ActionsMenu
            actions={ranges}
            label={options[selectedOption]}
            size="small"
            variant="text"
          />
        )}
      />
      <Divider />
      <CardContent>
        <Chart
          options={chartOptions}
          series={series}
          type="area"
          height={400}
        />
      </CardContent>
    </Card>
  );
};

Trends.propTypes = {
  title: PropTypes.string,
  range: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.object),
};
