export const nps = (scores = []) => {
  if (scores.length === 0) {
    return 0;
  }
  let promoters = 0;
  let detractors = 0;

  for (let i = 0; i < scores.length; i++) {
    if (scores[i] >= 86) promoters++;
    if (scores[i] <= 65) detractors++;
  }

  return Math.round(((promoters / scores.length) - (detractors / scores.length)) * 100);
};
