import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { MenuItem, InputAdornment } from '@mui/material';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';

export const ProductComponent = ({ value, properties, callback }) => {
  const { apiRequest } = useAuth();
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    async function getFeeds() {
      const data = await apiRequest('/feeds/');
      if (data && data.feeds) {
        setFeeds(data?.feeds.map((x) => ({
          value: x.name,
          label: x.name,
          id: x.id,
        })));
      }
    }
    getFeeds();
  }, []);

  return (
    <>
      <InputField
        fullWidth
        helperText={properties.helperText}
        label={properties.label}
        name={properties.fieldName}
        onChange={(event) => callback(event.target.value, properties.fieldName)}
        select
        placeholder="Select a product feed"
        value={value}
        InputProps={(properties.prefix || properties.suffix) ? {
          startAdornment: properties.prefix ? (
            <InputAdornment position="start">
              {properties.prefix}
            </InputAdornment>
          ) : undefined,
          endAdornment: properties.suffix ? (
            <InputAdornment position="start">
              {properties.suffix}
            </InputAdornment>
          ) : undefined,
        } : undefined}
      >
        <MenuItem
          key="select-feed"
          value={null}
        >
          Select a Product Feed
        </MenuItem>
        {feeds.map((option) => {
          if (typeof option === 'object') {
            return (
              <MenuItem
                key={option.id}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          );
        })}
      </InputField>
    </>
  );
};

ProductComponent.propTypes = {
  properties: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
