import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';
import numeral from 'numeral';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';
import gtm from '../lib/gtm';
import { PreferredBrand } from '../components/summary-root/preferred-brand';
import { GenericMetricCard } from '../components/summary-root/generic-metric-card';
import { LifetimeSpend } from '../components/summary-root/lifetime-spend';
import { CustomerActivity } from '../components/summary-root/customer-activity';
import { StackedSales } from '../components/summary-root/stacked-sales';
import { DonutChart } from '../components/insights-root/donut-chart';
import { CustomersOverTime } from '../components/insights-root/customers-over-time';
import { Typologies } from '../utils/typology';
import { demoId } from '../utils/demo';
import demoGroups from '../demo/groups.json';

export const InsightsRoot = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [groups, setGroups] = useState(null);
  const [expand, setExpand] = useState(false);

  const [option, setOption] = useState(Typologies[0].label);
  const [salesController, setSalesController] = useState({
    loading: true,
    value: null,

  });
  const [customerController, setCustomerController] = useState({
    loading: false,
    value: null,
    typologyConfidence: 0
  });

  useEffect(() => {
    async function getCustomerData() {
      try {
        const selectedTypology = groups.find((x) => (option.toLowerCase() !== 'no typology' ? (x.name.toLowerCase() === option.toLowerCase()) : (x.name.toLowerCase() === 'default')));
        if (selectedTypology) {
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 999) + 100) } : await apiRequest('/api/customers/count', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setCustomerController({
              loading: false,
              typologyConfidence: selectedTypology.typology_confidence,
              value
            });
          }
        } else {
          console.log('Typology group missing?', option);
        }
      } catch (e) {
        console.log(e);
      }
    }
    async function getSalesData() {
      try {
        const selectedTypology = groups.find((x) => (option.toLowerCase() !== 'no typology' ? (x.name.toLowerCase() === option.toLowerCase()) : (x.name.toLowerCase() === 'default')));
        if (selectedTypology) {
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 99999) + 10000) } : await apiRequest('/api/sales/total', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setSalesController({
              loading: false,
              value
            });
          }
        } else {
          console.log('Typology group missing?', option, groups);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (groups && option) {
      getSalesData().catch(console.error);
      getCustomerData().catch(console.error);
    }
  }, [org, option, groups]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    async function getGroups() {
      const groupData = org.id === demoId ? demoGroups : await apiRequest('/api/groups');
      if (mounted.current) {
        const allTypologies = Typologies.map((typology) => groupData.find((x) => x.name.toLowerCase() === typology.value.toLowerCase()));
        allTypologies.push(groupData.find((x) => x.name.toLowerCase() === 'all typologies'));
        setGroups(allTypologies);
      }
    }
    getGroups();
  }, [org]);

  if (org?.accountType === 'Business') {
    return (
      <>
        <Helmet>
          <title>Insights: Summary | AudienceZen Dashboard</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default' }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pb: 2,
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Insights - Summary
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={9}
              md={9}
              xs={12}
            >
              <CustomersOverTime />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              xs={12}
            >
              <Box sx={{ width: '100%' }}>
                <GenericMetricCard
                  option={option}
                  setOption={setOption}
                  title="CUSTOMERS PER TYPOLOGY"
                  data={numeral(customerController.value).format('0,0')}
                  typologyConfidence={customerController.typologyConfidence}
                  subtitle={option}
                  icon="Person"
                  isLoading={customerController.loading}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Insights: Summary | AudienceZen Dashboard</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pb: 2,
          }}
        >
          <Typography
            color="textPrimary"
            variant="h4"
          >
            Insights - Summary
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xl={expand ? 12 : 6}
            lg={12}
            md={12}
            xs={12}
          >
            <StackedSales
              hideInfo
              expand={expand}
              setExpand={setExpand}
            />
          </Grid>
          {expand ? null
            : (
              <>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  xs={12}
                >
                  <DonutChart />
                </Grid>

                <Grid
                  container
                  item
                  xl={3}
                  spacing={3}
                  lg={6}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <GenericMetricCard
                      option={option}
                      setOption={setOption}
                      title="SALES PER TYPOLOGY"
                      data={numeral(salesController.value).format('$0,0.00')}
                      subtitle={option}
                      icon="Cart"
                      isLoading={salesController.loading}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <GenericMetricCard
                      option={option}
                      setOption={setOption}
                      title="CUSTOMERS PER TYPOLOGY"
                      data={numeral(customerController.value).format('0,0')}
                      typologyConfidence={customerController.typologyConfidence}
                      subtitle={option}
                      icon="Person"
                      isLoading={customerController.loading}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <PreferredBrand />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <LifetimeSpend />
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={9}
                  md={12}
                  xs={12}
                >
                  <CustomersOverTime />
                </Grid>

                <Grid
                  item
                  lg={3}
                  md={12}
                  xs={12}
                >
                  <CustomerActivity />
                </Grid>

              </>
            )}
        </Grid>
      </Box>
    </>
  );
};
