import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const RadarChart = ({ data, ...rest }) => {
  const theme = useTheme();
  const series = [
    {
      name: 'Expectations',
      data: [
        data.expectations_product_selection || 0,
        data.expectations_ease_of_use || 0,
        data.expectations_fulfillment || 0,
        data.expectations_quality || 0,
        data.expectations_pricing || 0,
        data.expectations_communication || 0,
        data.expectations_returns || 0,
        data.expectations_service || 0,
      ],
    },
    {
      name: 'Experience',
      data: [
        data.performance_product_selection || 0,
        data.performance_ease_of_use || 0,
        data.performance_fulfillment || 0,
        data.performance_quality || 0,
        data.performance_pricing || 0,
        data.performance_communication || 0,
        data.performance_returns || 0,
        data.performance_service || 0,
      ],
    }

  ];
  const chartOptions = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    legend: {
      position: 'top',
    },
    tooltip: {
      enabled: true,
    },
    colors: ['#F68338', '#00B0F0'],
    labels: {
      show: true,
      style: {
        fontSize: '11px'
      }
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: [0.1, 0.6]
    },
    markers: {
      size: 0
    },
    yaxis: {
      tickAmount: 5,
      max: 100,
      min: 0,
    },
    xaxis: {
      categories: ['Product Selection', 'Ease of Use', 'Fulfillment', 'Product Quality', 'Competitive Price', 'Communication', 'Returns', 'Customer Support'],
    },
    theme: {
      mode: theme.palette.mode
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColor: '#e8e8e8',
          fill: {
            colors: ['#f8f8f8', '#fff']
          }
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardContent>
        <Chart
          options={chartOptions}
          series={series}
          type="radar"
          height={400}
        />
      </CardContent>
    </Card>
  );
};

RadarChart.propTypes = {
  data: PropTypes.object,
};
