import { useTheme } from '@mui/material';

export const styles = () => {
  const theme = useTheme();
  return (
    {
      cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 400,
        },
      },

      sliderContainer: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',

      },
      sliderLabel: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 65,
        },
      },
      slider: {
        padding: '22px 0px',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0',
        },
      },
    }
  );
};
