import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent, Container, Grid, Box, CircularProgress } from '@mui/material';
import { Stepper } from './stepper';
import { WizardConfirmation } from './wizard-confirmation';
import { WizardProfile } from './wizard-profile';
import { useAuth } from '../hooks/use-auth';
import { WizardAccount } from './wizard-account';

const steps = [
  {
    title: 'Step 1',
    content: 'Account Type'
  },
  {
    title: 'Step 2',
    content: 'Organisation'
  },
  {
    title: 'Step 3',
    content: 'Confirmation'
  }
];

export const Wizard = (props) => {
  const navigate = useNavigate();
  const { apiRequest, refreshOrgs } = useAuth();
  const { orientation } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    accountType: 'Ecommerce',
    name: '',
    ownername: '',
    ownerjobtitle: '',
    address1: '',
    address2: '',
    country: 'United States',
    city: '',
    state: '',
    phone: '',
    currency: 'USD'
  });

  const handleNextStep = (newValues) => {
    setValues((prevValues) => ({ ...prevValues, ...newValues }));
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { id } = await apiRequest('/api/organisations/create', {
        accountType: values.accountType,
        name: values.name,
        ownerName: values.ownername,
        ownerJobTitle: values.ownerjobtitle,
        address1: values.address1,
        address2: values.address2,
        country: values.country,
        city: values.city,
        state: values.state,
        phone: values.phone,
        currency: values.currency
      }) || {};
      if (id) {
        await refreshOrgs(id);
        navigate('/dashboard');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getContent = () => {
    if (currentStep === 0) {
      return (
        <WizardAccount
          onNextStep={handleNextStep}
        />
      );
    }

    if (currentStep === 1) {
      return (
        <WizardProfile
          initialValues={{
            name: values.orgname,
            ownername: values.ownername,
            ownerjobtitle: values.ownerjobtitle,
            address1: values.address1,
            address2: values.address2,
            country: values.country,
            city: values.city,
            state: values.state,
            phone: values.phone,
            currency: values.currency
          }}
          onNextStep={handleNextStep}
        />
      );
    }

    return (
      <WizardConfirmation
        onPreviousStep={handlePreviousStep}
        onComplete={handleSubmit}
        values={values}
      />
    );
  };

  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <Card variant="outlined">
      {orientation === 'horizontal'
        ? (
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Stepper
                  steps={steps}
                  currentStep={currentStep}
                />
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
              >
                {getContent()}
              </Grid>
            </Grid>
          </CardContent>
        )
        : (
          <CardContent>
            <Stepper
              currentStep={currentStep}
              orientation="horizontal"
              steps={steps}
            />
            <Container
              maxWidth="sm"
              sx={{ mt: 6 }}
            >
              {getContent()}
            </Container>
          </CardContent>
        )}
    </Card>
  );
};

Wizard.defaultProps = {
  orientation: 'horizontal'
};

Wizard.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
};
