import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Button, Card, CardContent } from '@mui/material';
import toast from 'react-hot-toast';
import { ImagesUploader } from '../components/assets/images-uploader';
import gtm from '../lib/gtm';
import { Trash as TrashIcon } from '../icons/trash';
import { useDialog } from '../hooks/use-dialog';
import { ImagesUploaderDialog } from '../components/assets/images-uploader-dialog';
import ImageEditor from '../components/assets/image-editor';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

import { demoId } from '../utils/demo';

export const Assets = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [uploadDialogOpen, handleOpenUploadDialog, handleCloseUploadDialog] = useDialog();

  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);

  const [editAsset, setEditAsset] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  async function deleteAssets() {
    try {
      if (org.id !== demoId) {
        await apiRequest('/api/assets/delete', {
          assets: selectedImages || []
        });
      }
      if (mounted.current) {
        toast.success(selectedImages.length > 1 ? 'Assets Deleted' : 'Asset Deleted');
        setReload(!reload);
        setSelectedImages([]);
      }
    } catch (err) {
      console.error(err);
      toast.error(selectedImages.length > 1 ? 'Assets failed to delete' : 'Asset failed to delete');
    }
  }
  const handleMultiDelete = () => {
    deleteAssets();
  };
  const handleEdit = (imageID) => {
    setEditAsset(imageID ? images.find((x) => x.id === imageID) : '');
  };
  return (
    <>
      <Helmet>
        <title>Assets | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          py: 4
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {editAsset === null ? 'Assets' : 'Edit Image'}
              </Typography>
              {editAsset !== null
                ? (
                  <Box>
                    <Button
                      color="primary"
                      startIcon={<ArrowLeftIcon />}
                      onClick={() => setEditAsset(null)}
                      variant="text"
                    >
                      Assets
                    </Button>
                  </Box>
                )
                : null}
            </Box>
            {selectedImages.length > 0
              ? (
                <Button
                  color="primary"
                  onClick={() => handleOpenUploadDialog()}
                  size="large"
                  startIcon={<TrashIcon fontSize="small" />}
                  variant="contained"
                >
                  <Box display={{ xs: 'none', md: 'block' }}>{`Delete ${selectedImages.length} Selected Images`}</Box>
                </Button>
              )
              : null}
          </Box>

          <div>

            <Card variant="outlined">
              <CardContent sx={{ overflowY: 'scroll' }}>
                {!editAsset

                  ? (
                    <ImagesUploader
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      images={images}
                      setImages={setImages}
                      handleEdit={handleEdit}
                      multiSelect
                      reload={reload}
                    />
                  )

                  : (
                    <ImageEditor
                      asset={editAsset}
                      setAsset={setEditAsset}
                    />
                  )}
              </CardContent>
            </Card>
          </div>
        </Container>
      </Box>
      <ImagesUploaderDialog
        onCancel={handleCloseUploadDialog}
        open={uploadDialogOpen}
        onDelete={handleMultiDelete}
        selectedImagesCount={selectedImages.length}
      />
    </>
  );
};
