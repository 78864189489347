import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';

export const ProductsMenu = (props) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => {
          handleClose();
          // navigate to edit screen
          props.setFeedId(props.feedId);
          props.setNavState('Edit');
        }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          props.setFeedId(props.feedId);
          props.handleDelete(props.feedId);
        }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

ProductsMenu.propTypes = {
  handleDelete: PropTypes.func,
  setNavState: PropTypes.func,
  setFeedId: PropTypes.func,
  feedId: PropTypes.string,
};
