import { Avatar, Box, Card, Typography, Skeleton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import { subDays } from 'date-fns';
import { useMounted } from '../../hooks/use-mounted';
import { useAuth } from '../../hooks/use-auth';
import { Typologies } from '../../utils/typology';
import { ActionsMenu } from '../actions-menu';
import { demoId } from '../../utils/demo';
import demoTypologyPerformanceData from '../../demo/rawTypologyPerformance.json';

export const PreferredBrand = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [option, setOption] = useState(Typologies[0].label);

  const startDate = subDays(new Date(), 90);
  const endDate = subDays(new Date(), 1);
  const options = Typologies.map((x) => ({
    label: x.label,
    onClick: () => { setOption(x.label); }
  }));
  const [controller, setController] = useState({
    loading: true,
    data: null,
  });

  useEffect(() => {
    async function getData() {
      try {
        const data = org.id === demoId ? demoTypologyPerformanceData : await apiRequest('/api/sales/typology-performance', {
          startDate, endDate
        });
        console.log('typology performance', data);
        if (mounted.current) {
          setController({
            loading: false,
            data
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
    getData().catch(console.error);
  }, [org]);

  const selectedTypology = controller?.data?.find((x) => x.context.toLowerCase() === (option === 'No Typology' ? 'default' : option.toLowerCase()));
  return (
    <Card
      sx={{ height: '100%' }}
      variant="outlined"
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          pt: 2,
          px: 3,
          pb: 0,
        }}
      >
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Preferred Brand
        </Typography>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          py: 0,
          px: 3
        }}
      >
        <Box
          sx={{
            flexGrow: 'grow',
          }}
        >
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {
              (controller.loading)
                ? (
                  <Skeleton
                    height={42}
                    width={220}
                    sx={{ p: 0, m: 0 }}
                  />
                )
                : selectedTypology?.brand
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            ml: 2
          }}
        >
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              height: 56,
              width: 56
            }}
          >
            <ShoppingCartIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />
          </Avatar>
        </Box>
      </Box>
      <Box
        sx={{
          pt: 0,
          px: 3,
          pb: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start'
        }}
      >
        {
          (controller.loading)
            ? (
              <Skeleton
                height={42}
                width={50}
                sx={{ p: 0, m: 0 }}
              />
            )
            : (
              <ActionsMenu
                actions={options}
                label={option}
                size="small"
                variant="text"
                sx={{ mr: 1, width: 'auto' }}
              />
            )
        }
      </Box>
    </Card>
  );
};
