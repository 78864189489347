/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Typography, Grid, Tab, Tabs, Divider, CircularProgress } from '@mui/material';
import mjml2html from 'mjml-browser';
import styled from '@emotion/styled';

/* Components */
import { useLocation, useNavigate } from 'react-router-dom';
import { getComponentHtml } from '../utils/email-components';
import { EmailTemplates, VocationEmailTemplates } from '../utils/email-templates';

/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Demo */
import { demoId } from '../utils/demo';
import demoEmailTemplates from '../demo/rawEmailTemplateData.json';

export const EmailTemplatesCreate = () => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const { apiRequest, org } = useAuth();
  const [emailTemplatesState, setEmailTemplatesState] = useState({ isLoading: true });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    async function getEmailTemplates() {
      try {
        setEmailTemplatesState(() => ({ isLoading: true }));
        const result = org.id === demoId ? demoEmailTemplates : await apiRequest('/email/templates') || [];
        if (mounted.current) {
          setEmailTemplatesState(() => ({
            isLoading: false,
            data: result
          }));
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setEmailTemplatesState(() => ({
            isLoading: false,
            error: err.message,
            data: [],
          }));
        }
      }
    }

    getEmailTemplates().catch(console.error);
  }, [org]);

  if (emailTemplatesState?.loading) {
    return (
      <>
        <Helmet>
          <title>Email Template | AudienceZen Dashboard</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
            height: '100%'
          }}
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress
              color="primary"
              size={100}
            />

          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Email Template | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              p: '1em',

            }}
          >
            <Typography
              variant="h4"
              sx={{ display: 'inline' }}
            >
              Start with a template
            </Typography>
            <Tabs
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              variant="scrollable"
            >
              <Tab
                label="Basics"
              />
              <Tab
                label="Surveys"
              />
              <Tab
                label="My Templates"
              />
            </Tabs>

            <Divider />

            {
              (value === 0)
              && (
              <Grid
                container
              >
                {EmailTemplates.map((template) => (
                  <EmailTemplateCard
                    key={template.id}
                    navigate={navigate}
                    location={location}
                    template={template}
                  />
                ))}
              </Grid>
              )
            }

            {
              (value === 1)
              && (
              <Grid
                container
              >
                {VocationEmailTemplates.map((template) => (
                  <EmailTemplateCard
                    key={template.id}
                    navigate={navigate}
                    location={location}
                    template={template}
                  />
                ))}
              </Grid>
              )
            }

            {
              (value === 2)
              && (
              <Grid
                container
              >

                {Array.isArray(emailTemplatesState?.data?.emailTemplates) && emailTemplatesState?.data?.emailTemplates?.map((template) => (
                  <EmailTemplateCard
                    key={template.id}
                    location={location}
                    navigate={navigate}
                    template={template}
                  />
                ))}

              </Grid>
              )
            }
          </Card>
        </Container>
      </Box>

    </>
  );
};

const renderComponent = (item) => {
  const { html, errors } = mjml2html(getComponentHtml(item, 600, true), {
    validationLevel: 'soft'
  });

  if (errors && errors.length) {
    console.log('mjml errors:', errors);
    return { __html: errors.map(({ message }) => `<pre style="font-size: 0.8em; padding: 5px 15px; background: #fff; border: 1px solid rgba(255,0,0,0.4); white-space: pre-wrap;">${message}</pre>`).join('') };
  }

  return { __html: html };
};

const EmailPreview = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
  width: 600px;
  margin: 0 auto;
  background: ${({ emailProperties }) => (emailProperties?.background_color ? emailProperties.background_color : '#fff')};
  padding-left: ${({ emailProperties }) => (emailProperties?.padding_left ? emailProperties.padding_left : '0')}px;
  padding-right: ${({ emailProperties }) => (emailProperties?.padding_right ? emailProperties.padding_right : '0')}px;
  padding-top: ${({ emailProperties }) => (emailProperties?.padding_top ? emailProperties.padding_top : '0')}px;
  padding-bottom: ${({ emailProperties }) => (emailProperties?.padding_bottom ? emailProperties.padding_bottom : '0')}px;
  border-style: ${({ emailProperties }) => (emailProperties?.border_style ? emailProperties.border_style : 'none')};
  border-width: ${({ emailProperties }) => (emailProperties?.border_width ? emailProperties.border_width : '1')}px;
  border-color: ${({ emailProperties }) => (emailProperties?.border_color ? emailProperties.border_color : '#000000')};
  border-radius: ${({ emailProperties }) => (emailProperties?.border_radius ? emailProperties.border_radius : '0')}px;
  transform: scale(0.5) translate(-50%, -50%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  user-drag: none;
  ::-webkit-scrollbar {
    display: none;
  }
  & img {
    user-select: none;
    user-drag: none;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const EmailTemplateCard = ({ template, navigate, location }) => {
  const email_json = template?.email_json ? JSON.parse(template.email_json) : {};
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        cursor: 'pointer',
        mb: 2,
        maxWidth: '350px',
        '&:hover': {
          opacity: 0.8
        }
      }}
    >
      <Card
        sx={{ height: '350px', backgroundColor: '#354147', m: '1em', p: '1em', position: 'relative' }}
        onClick={() => {
          navigate('/email-editor', {
            state: {
              ...email_json,
              ...location?.state?.callback && { callback: location.state.callback }
            }
          });
        }}
      >
        <Grid
          justifyContent="center"
          container
          sx={{
            width: 300,
            overflow: 'hidden',
          }}
        >
          {
          (email_json?.content && email_json?.content?.length)
          && (
          <EmailPreview
            widthProps={600}
            emailProperties={email_json.properties}
          >
            { email_json.content.map((x) => (
              <div
                key={x.id}
                dangerouslySetInnerHTML={renderComponent(x)}
              />
            )) }
          </EmailPreview>
          )
        }
        </Grid>
      </Card>
      <Grid>
        <Typography sx={{ textAlign: 'center' }}>
          {template.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

EmailTemplateCard.propTypes = {
  template: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
