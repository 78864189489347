import { useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography, Grid, Chip, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import numeral from 'numeral';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFilters } from '../hooks/use-filters';
import { ProductFilterDialog } from '../components/product-feed/product-filters-dialog';
import image from '../components/product-feed/product-01.png';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator
} from '../utils/filter-operators';
import { InputField } from '../components/input-field';

const filterProperties = [
  {
    label: 'Product Tags',
    name: 'productTags',
    type: 'string'
  },
  {
    label: 'Product Type',
    name: 'productType',
    type: 'string'
  },
  {
    label: 'Product Vendor',
    name: 'productVendor',
    type: 'string'
  },
  {
    label: 'Product Title',
    name: 'productTitle',
    type: 'string'
  },
  {
    label: 'Product SKU',
    name: 'productSKU',
    type: 'string'
  },
  {
    label: 'Product Price',
    name: 'productPrice',
    type: 'number'
  },
  {
    label: 'Product Compare Price',
    name: 'productComparePrice',
    type: 'number'
  },
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator
];
function createData(name, type, vendor, price, comparePrice) {
  return { name, type, vendor, price, comparePrice };
}
const rows = [
  createData('Frozen yoghurt', 'Desert', 'Tip-Top', 4.99, 6.00),
  createData('Ice cream sandwich', 'Desert', 'Tip-Top', 2, 3.50),
  createData('Eclair', 'Desert', 'Tip-Top', 9.99, 15.20),
  createData('Cupcake', 'Desert', 'Pams', 2.99, 4),
  createData('Gingerbread', 'Desert', 'Arnots', 4.99, 5.50),
];

export const CreateNewFeed = () => {
  const {
    addFilter,
    filters,
    handleOperatorChange,
    handlePropertyChange,
    handleValueChange,
    removeFilter
  } = useFilters(filterProperties, filterOperators);

  const [dataSource, setDataSource] = useState('');

  const handleChange = (event) => {
    setDataSource(event.target.value);
  };

  const customBreak = useMediaQuery((theme) => theme.breakpoints.down(1536));
  // const theme = createTheme({
  //   breakpoints: {
  //     values: {

  //      customXL:1650
  //    }
  //  }
  //  });
  return (
    <>
      <Helmet>
        <title>New Product Feed | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >

        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ py: 4, alignItems: 'center', display: 'flex', justifyContent: customBreak ? 'center' : 'space-between' }}>

            <Typography
              color="textPrimary"
              variant="h4"
            >
              New Product Feed
            </Typography>
            <Grid>
              <Button
                color="primary"
                variant="text"
                sx={{ mr: '1em', }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Box>

          <Grid
            container
            justifyContent="space-between"
            rowGap={2}
            sx={{ direction: customBreak ? 'column' : 'row',
              justifyContent: customBreak ? 'center' : 'space-between',
            }}
          >
            <Grid sx={{ width: '24em' }}>
              <Card sx={{ p: '2em', mb: customBreak ? '2em' : 'inherit' }}>

                <Grid
                  container
                  direction="column"
                  sx={{ alignItems: 'center' }}
                >
                  <Typography
                    color="textPrimary"
                    sx={{ width: '100%', textAlign: 'left', marginBottom: '2em', lineHeight: '1.5', fontWeight: '600', fontFamily: 'inter, san-serif', fontSize: '16px' }}
                  >
                    Setup
                  </Typography>
                  <Grid>
                    <InputField
                      label="Feed Name"
                      sx={{ width: '20em' }}
                      id="feed name"
                    />
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="flex-end"
                  >
                    <FormControl sx={{ width: '20em', }}>

                      <Typography sx={{ color: '#212B36', fontSize: '14px', fontWeight: 500, mb: '4px', position: 'relative', height: '20px' }}>Data Source</Typography>
                      <Select
                        sx={{ height: '2.28em',
                          py: '6px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: '#d5d8e1',
                            borderRadius: '6px',
                            boxShadow: '0px 1px 2px 0px rgb(9 30 66 / 8%)',
                            overflow: 'hidden',
                            padding: '0px'
                          },
                        }}
                        variant="outlined"
                        labelId="data-source"
                        id="data source"
                        value={dataSource}
                        onChange={handleChange}
                      >
                        <MenuItem value="Shopify">Shopify</MenuItem>
                        <MenuItem value="Vend">Vend</MenuItem>
                      </Select>
                      <Divider sx={{ my: '2em' }} />
                      <Typography sx={{ mb: '1em' }}>Filters</Typography>
                      {filters.map((filter, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                          <ProductFilterDialog
                            filter={filter}
                            index={index}
                            onAddFilter={addFilter}
                            onRemoveFilter={removeFilter}
                            onOperatorChange={handleOperatorChange}
                            onPropertyChange={handlePropertyChange}
                            onValueChange={handleValueChange}
                            operators={filterOperators}
                            properties={filterProperties}
                            cantAdd={(index !== (filters.length - 1))}
                          />
                          {filters.length > index + 1 && (
                          <Divider
                            sx={{
                              my: 2,
                              '& .MuiDivider-wrapper': {
                                p: 0
                              }
                            }}
                            textAlign="left"
                          >
                            <Chip
                              color="primary"
                              label="AND"
                              size="small"
                            />
                          </Divider>
                          )}
                        </Fragment>
                      ))}
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid
              xl={8}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Card>
                <Grid
                  container
                  display="flex"
                  sx={{ justifyContent: 'center' }}
                >
                  <Typography
                    color="textPrimary"
                    sx={{ width: '100%', textAlign: 'left', mb: '2em', pl: 4, pt: 4, lineHeight: '1.5', fontWeight: '600', fontFamily: 'inter, san-serif', fontSize: '16px' }}
                  >

                    Total products
                    {' '}
                    {rows.length}
                  </Typography>

                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Vendor</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Compare Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                        >
                          <TableCell sx={{ width: '20em' }}>
                            <Grid
                              container
                              alignItems="center"
                            >
                              <img
                                src={image}
                                style={{ width: '20%' }}
                                alt="placeholder"
                              />
                              <Grid
                                item
                                flexDirection="column"
                                sx={{ ml: '1.5em' }}
                              >
                                <Typography variant="h6">{row.name}</Typography>

                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  BBAK01-A
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>{row.type}</TableCell>
                          <TableCell>{row.vendor}</TableCell>
                          <TableCell>{`${numeral(row.price).format('$0,0.00')}`}</TableCell>
                          <TableCell>{`${numeral(row.comparePrice).format('$0,0.00')}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>

              </Card>
            </Grid>
          </Grid>
        </Container>

      </Box>
    </>
  );
};
