/* eslint-disable */
import { Card, CardContent, CardHeader } from '@mui/material';
import propTypes from 'prop-types';
import Chart from 'react-apexcharts';

export const TypologyBreakdownChart = ({ data, chartref }) => {
  const total = data?.reduce?.((acc,curr) => acc + curr.count, 0);
  const chartOptions = {
    labels: data?.map((typology) => `${typology.typology} (${((typology.count / total) * 100).toFixed(0)}%)`),
    colors: data?.map((typology) => typology.color),
    series: data?.map((typology) => Math.round(typology.count * 100) / 100),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  return (

    data
      ? (
        <Card
          variant="outlined"
          sx={{ mb: 2, }}
        >
          <CardHeader
            title="Typology breakdown for audience"
          />
          <CardContent>
            <Chart
              ref={chartref ? chartref : undefined}
              options={chartOptions}
              series={chartOptions.series}
              type="pie"
              height={400}
            />
          </CardContent>
        </Card>
      )

      : null

  );
};

TypologyBreakdownChart.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired
};
