import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import mjml2html from 'mjml-browser';

import { getComponentHtml } from '../../utils/email-components';

const EmailPreview = styled.div`
  width: ${({ widthProps }) => `${widthProps}px;`}
  margin: 0 auto;
  background: ${({ emailProperties }) => (emailProperties?.background_color ? emailProperties.background_color : '#fff')};
  padding-left: ${({ emailProperties }) => (emailProperties?.padding_left ? emailProperties.padding_left : '0')}px;
  padding-right: ${({ emailProperties }) => (emailProperties?.padding_right ? emailProperties.padding_right : '0')}px;
  padding-top: ${({ emailProperties }) => (emailProperties?.padding_top ? emailProperties.padding_top : '0')}px;
  padding-bottom: ${({ emailProperties }) => (emailProperties?.padding_bottom ? emailProperties.padding_bottom : '0')}px;
  border-style: ${({ emailProperties }) => (emailProperties?.border_style ? emailProperties.border_style : 'none')};
  border-width: ${({ emailProperties }) => (emailProperties?.border_width ? emailProperties.border_width : '1')}px;
  border-color: ${({ emailProperties }) => (emailProperties?.border_color ? emailProperties.border_color : '#000000')};
  border-radius: ${({ emailProperties }) => (emailProperties?.border_radius ? emailProperties.border_radius : '0')}px;
  max-height: calc(100vh - 64px - 100px);
  margin-top: 50px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  & a {
    // text-decoration: none;
    // color: inherit;
  }
`;

const Selectable = styled.div`
  position: relative;
  width: ${({ widthProps }) => `${widthProps}px;`}
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0px 0px 0px 2px transparent;
    ${({ isSelected }) => isSelected && 'box-shadow: inset 0px 0px 0px 2px #2b98d3 !important;'}
    ${({ isDragging }) => isDragging && 'background: rgba(43,152,211, 0.2); !important;'}
  }
  &:hover::after {
    box-shadow: inset 0px 0px 0px 2px #354147;
  }
`;

const Empty = styled.div`
  position: relative;
  width: ${({ widthProps }) => `${widthProps}px;`}
  min-height: 100px;
  border: 1px dashed #354147;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.4);
`;

export const EmailEditor = ({ items, onSelect, selectedBlock, previewType, emailProperties }) => {
  const desktopWidth = emailProperties?.width ? emailProperties.width : 600;
  const renderComponent = (item) => {
    const { html, errors } = mjml2html(getComponentHtml(item, (previewType === 'desktop' ? desktopWidth : 320), (previewType === 'desktop')), {
      validationLevel: 'soft'
    });

    if (errors && errors.length) {
      console.log('mjml errors:', errors);
      return { __html: errors.map(({ message }) => `<pre style="font-size: 0.8em; padding: 5px 15px; background: #fff; border: 1px solid rgba(255,0,0,0.4); white-space: pre-wrap;">${message}</pre>`).join('') };
    }

    return { __html: html };
  };

  return (
    <Box
      sx={{
        backgroundColor: emailProperties?.email_background ? emailProperties.email_background : 'background.default',
        flexGrow: 1,
      }}
    >
      <Container
        maxWidth={false}
        disableGutters
      >
        <Box sx={{ px: 4 }}>
          <Droppable droppableId="emailView">
            {(provided) => (
              <EmailPreview
                {...provided.droppableProps}
                ref={provided.innerRef}
                widthProps={previewType === 'desktop' ? desktopWidth : 320}
                emailProperties={emailProperties}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(providedDraggable, snapshotDraggable) => (
                      <Selectable
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={providedDraggable.draggableProps.style}
                        isDragging={snapshotDraggable.isDragging}
                        isSelected={Boolean(selectedBlock && item.id === selectedBlock.id)}
                        onClick={() => { onSelect(item); }}
                        widthProps={previewType === 'desktop' ? desktopWidth : 320}
                      >
                        { }
                        <div dangerouslySetInnerHTML={renderComponent(item)} />
                      </Selectable>
                    )}
                  </Draggable>
                ))}
                {
                  (items.length === 0)
                    ? (
                      <Empty
                        widthProps={previewType === 'desktop' ? desktopWidth : 320}
                      >
                        <Typography
                          variant="overline"
                          sx={{
                            color: '#354147',
                            textAlign: 'center'
                          }}
                        >
                          DRAG BLOCKS HERE
                        </Typography>
                      </Empty>
                    )
                    : provided.placeholder
                }
              </EmailPreview>
            )}
          </Droppable>
          {
            /*
            <Box>
              <div dangerouslySetInnerHTML={renderComponent({
                type: "CODE",
                properties: {
                  content: emailProperties.unsubscribe_message,
                  padding_top: 10,
                  padding_bottom: 10,
                  padding_left: 0,
                  padding_right: 0,
                  background_color: "",
                  border_style: "none",
                  border_width: 3,
                  border_color: "#000000",
                  font_family: "Arial",
                  font_size: "12px",
                  line_height: "20px",
                  font_weight: "normal",
                  align: "center",
                  text_color: "#354147",
                }
              })} />
            </Box>
            */
          }
        </Box>
      </Container>
    </Box>
  );
};

EmailEditor.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedBlock: PropTypes.object,
  previewType: PropTypes.string.isRequired,
  emailProperties: PropTypes.object.isRequired
};
