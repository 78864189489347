import numeral from 'numeral';

export const CustomerProperties = [
  {
    label: 'Accepts Marketing',
    name: 'accepts_marketing',
    type: 'string'
  },
  {
    label: 'Typology',
    name: 'typology',
    type: 'string'
  },
  {
    label: 'Vocation',
    name: 'vocation',
    type: 'number'
  },
  {
    label: 'Loyalty Points',
    name: 'loyalty_points',
    hideBusiness: true,
    type: 'number'
  },
  {
    label: 'Orders Count',
    name: 'orders',
    hideBusiness: true,
    type: 'number'
  },
  {
    label: 'Total Spend',
    name: 'spent',
    hideBusiness: true,
    type: 'number'
  },
  {
    label: 'Last Order',
    name: 'last_order',
    hideBusiness: true,
    type: 'date'
  },
  {
    label: 'Average Order Value',
    name: 'average_order_value',
    hideBusiness: true,
    type: 'number'
  },
];

export const SalesDataProperties = [
  {
    label: 'Order Code',
    name: 'orders.code',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => `#${value}`,
  },
  {
    label: 'Order Status',
    name: 'orders.status',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  // {
  //   label: 'Payment Gateways',
  //   name: 'payment_gateways',
  //   type: 'string',
  //   sortable: true,
  //   filterable: true,
  //   format: (value) => value
  // },
  {
    label: 'Shipping Country',
    name: 'orders.shipping_country',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Shipping Region',
    name: 'orders.shipping_region',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Shipping Zip',
    name: 'orders.shipping_zip',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Line Item Status',
    name: 'line_items.status',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Product Name',
    name: 'line_items.name',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Product SKU',
    name: 'line_items.sku',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Product Brand',
    name: 'line_items.brand',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Product Type',
    name: 'line_items.type',
    type: 'string',
    sortable: true,
    filterable: true,
    format: (value) => value
  },
  {
    label: 'Quantity',
    name: 'line_items.quantity',
    type: 'number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('0,0')
  },
  {
    label: 'Unit Price',
    name: 'line_items.unit_price',
    type: 'number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('$0,0.00')
  },
  {
    label: 'Total Discount',
    name: 'line_items.total_discount',
    type: 'percent_number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('$0,0.00')
  },
  {
    label: 'Total Sales',
    name: 'line_items.total_price',
    type: 'number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('$0,0.00')
  },
  {
    label: 'Orders Total',
    name: 'orders_total',
    type: 'number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('0,0')
  },
  {
    label: 'Total Line Items',
    name: 'line_items_total',
    type: 'number',
    sortable: true,
    filterable: true,
    format: (value) => numeral(value).format('0,0')
  },
];
