import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react';
import { format, subMonths, startOfMonth, endOfMonth, subYears, subDays, differenceInDays } from 'date-fns';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { useMounted } from '../../hooks/use-mounted';
import { DateControls } from '../insights-root/date-controls';
import { ActionsMenu } from '../actions-menu';
import { useAuth } from '../../hooks/use-auth';
import { Typologies } from '../../utils/typology';
import { GroupBys } from '../../utils/group-by-date';
import { demoId } from '../../utils/demo';
import demoSalesData from '../../demo/rawSalesData.json';

export const StackedSales = ({ hideInfo, expand, setExpand, ...other }) => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const theme = useTheme();

  const firstOfLastMonth = org.id === demoId ? startOfMonth(subMonths(new Date('2022-08-01'), 1)) : startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = org.id === demoId ? endOfMonth(subMonths(new Date('2022-08-01'), 1)) : endOfMonth(subMonths(new Date(), 1));

  const [groupBy, setGroupBy] = useState(GroupBys[1].key); // 1 = DAY
  const groupByOptions = GroupBys.filter((x) => !/(HOUR)/.test(x.key)).map((g) => ({ label: `Group by ${g.label}`, key: g.key, onClick: () => { setGroupBy(g.key); } }));
  const [salesData, setSalesData] = useState({ isLoading: true, data: [] });
  const [selectedDateRange, setSelectedDateRange] = useState({ start: firstOfLastMonth, end: endOfLastMonth, compareStart: null, compareEnd: null });
  const selectedGroupBy = GroupBys.find((x) => x.key === groupBy);
  const xAxisRange = selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const xAxisRangeCompare = (selectedDateRange.compareStart && selectedDateRange.compareEnd) ? selectedGroupBy.getRange(selectedDateRange.compareStart, selectedDateRange.compareEnd) : null;

  const getSalesData = useCallback(async () => {
    setSalesData({ isLoading: true, data: [] });
    try {
      const rawSalesData = org.id === demoId ? demoSalesData : await apiRequest('/api/sales', {
        contextType: 'GROUP',
        startDate: format(selectedDateRange.start, 'yyyy-MM-dd'),
        endDate: format(selectedDateRange.end, 'yyyy-MM-dd'),
        filters: [],
        appliedDisjunctively: false,
        groupBy,
        dateField: 'orders.created_at',
        sortColumn: 'line_items.total_price',
        sortDirection: 'DESC',
        columns: ['line_items.total_price']
      }) || [];

      const formattedSalesData = [
        ...Typologies.map((typology) => ({
          name: typology.label,
          data: [...xAxisRange.map((formattedDate) => {
            const dataPoint = rawSalesData.find((x) => x.context.toUpperCase() === typology.value && x.formatted_date === formattedDate);
            return (dataPoint && dataPoint['line_items.total_price']) ? dataPoint['line_items.total_price'] : 0;
          })]
        }))
      ];
      // Get compare sales data if existss
      let formattedCompareData = null;
      if (xAxisRangeCompare?.length) {
        const compareRawSalesData = org.id === demoId ? demoSalesData : await apiRequest('/api/sales', {
          contextType: 'GROUP',
          startDate: format(selectedDateRange.compareStart, 'yyyy-MM-dd'),
          endDate: format(selectedDateRange.compareEnd, 'yyyy-MM-dd'),
          filters: [],
          appliedDisjunctively: false,
          groupBy,
          dateField: 'orders.created_at',
          sortColumn: 'line_items.total_price',
          sortDirection: 'DESC',
          columns: ['line_items.total_price']
        }) || [];

        formattedCompareData = [
          ...Typologies.map((typology) => ({
            name: typology.label,
            data: [...xAxisRangeCompare.map((formattedDate) => {
              const dataPoint = compareRawSalesData.find((x) => x.context.toUpperCase() === typology.value && x.formatted_date === formattedDate);
              return (dataPoint && dataPoint['line_items.total_price']) ? dataPoint['line_items.total_price'] : 0;
            })]
          }))
        ];
      }
      if (mounted.current) {
        setSalesData({ isLoading: false, data: formattedSalesData, compareData: formattedCompareData });
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setSalesData({
          isLoading: false,
          data: [],
        });
      }
    }
  }, [groupBy, selectedDateRange, org]);

  // we want to update sales data whenever selected date range changes
  useEffect(() => {
    getSalesData().catch(console.error);
  }, [groupBy, selectedDateRange, org]);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'straight',
    },
    colors: Typologies.map((x) => x.color),
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    title: {
      text: undefined
    },
    xaxis: {
      categories: xAxisRange,
    },
    yaxis: {
      title: {
        text: undefined
      },
      labels: {
        formatter(val) {
          return `${(val / 1000).toFixed(1)}K`;
        },
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      // offsetX: 40
    }
  };

  const compareChartOptions = {
    ...chartOptions,
    xaxis: {
      categories: xAxisRangeCompare,
    }
  };

  return (
    <Card
      variant="outlined"
      {...other}
      sx={{ p: '1em', height: '100%' }}
    >
      <Grid
        container
        alignItems="center"

        // justifyContent="space-between"
      >

        <Grid
          container
          alignItems="center"
          sx={{ display: 'flex', }}
          gap={1}
        >
          <CardHeader
            title="Sales over time"
          />
          <ActionsMenu
            actions={groupByOptions}
            label={groupByOptions.find((x) => x.key === groupBy).label}
            size="small"
            variant="text"
            sx={{ mr: 1, }}
          />
          <Grid
            item
            display="flex"
          >
            <DateControls
              showCompare
              onDateChange={(start, end, compare = null) => {
                let compareStart = null;
                let compareEnd = null;
                if (compare && compare === 'Last Year') {
                  compareStart = subYears(start, 1);
                  compareEnd = subYears(end, 1);
                }
                if (compare && compare === 'Last Period') {
                  const days = differenceInDays(end, start);
                  compareStart = subDays(start, days + 1);
                  compareEnd = subDays(start, 1);
                }
                setSelectedDateRange({ start, end, compareStart, compareEnd });
              }}
            />

          </Grid>
          <Grid
            display="flex"
            sx={{ flexGrow: '3', justifyContent: 'flex-end' }}
          >
            <Button
              onClick={() => {
                setExpand(!expand);
              }}
              display="flex"
            >
              {expand ? <ZoomInMapIcon /> : <ZoomOutMapIcon /> }
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <CardContent>
        <Grid
          container
          spacing={3}
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={12}
            xl={hideInfo ? 12 : 8}
            sx={{ pt: 0, minHeight: '400px' }}
          >
            {!salesData.isLoading
              ? (
                <>
                  <Chart
                    height={400}
                    type="bar"
                    options={chartOptions}
                    series={salesData.data}
                  />
                  {
                    salesData.compareData?.length
                    && (
                    <>
                      <CardHeader
                        title="Sales over time (Compared)"
                      />
                      <Chart
                        height={400}
                        type="bar"
                        options={compareChartOptions}
                        series={salesData.compareData}
                      />
                    </>
                    )
                  }
                </>
              )
              : (
                <Box
                  sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                >
                  <CircularProgress
                    color="primary"
                    size={100}
                  />

                </Box>
              )}
          </Grid>
          {
            !hideInfo && (
              <Grid
                item
                xl={4}
                lg={12}
                md={12}
              >
                <Card
                  variant="outlined"
                >
                  <CardHeader
                    title="AudienceZen and typologies"
                  />
                  <CardContent>
                    <Typography
                      color="inherit"
                      variant="body2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        height: '100%',
                      }}
                    >
                      AudienceZen will segment your audience into groups of customers who share a similar purchase decision mentality.
                      <br />
                      <br />
                      AudienceZen then provides expert, easy to use guidance for writing sales emails and in-store content to suit each audience segment.
                      AudienceZen’s segmentation model provides twelve psychographic purchase decision-mentality typologies,
                      each of which comes with specific communication insights.
                    </Typography>
                    <Divider sx={{ pt: 2, mb: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        color="primary"
                        size="large"
                        variant="text"
                        component={RouterLink}
                        to="/dashboard/insights"
                      >
                        View Insights
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )
          }
        </Grid>

      </CardContent>
    </Card>
  );
};

StackedSales.propTypes = {
  hideInfo: PropTypes.bool.isRequired,
  expand: PropTypes.bool.isRequired,
  setExpand: PropTypes.func.isRequired,
};
