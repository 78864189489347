import { Avatar, Box, Card, Typography, Skeleton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from 'react';
import numeral from 'numeral';
import { ActionsMenu } from '../actions-menu';
import { Typologies } from '../../utils/typology';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { demoId } from '../../utils/demo';
import demoGroups from '../../demo/groups.json';

export const LifetimeSpend = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [option, setOption] = useState(Typologies[0].label);
  const [groups, setGroups] = useState(null);
  const [salesController, setSalesController] = useState({
    loading: true,
    value: null,
  });
  const [customerController, setCustomerController] = useState({
    loading: true,
    value: null
  });
  const options = Typologies.filter((y) => y.value.toLowerCase() !== 'default').map((x) => ({
    label: x.label,
    onClick: () => { setOption(x.label); }
  }));
  useEffect(() => {
    async function getGroups() {
      const groupData = org.id === demoId ? demoGroups : await apiRequest('/api/groups');
      if (mounted.current) {
        setGroups(groupData);
      }
    }
    getGroups();
  }, [org]);

  useEffect(() => {
    async function getCustomerData() {
      try {
        const selectedTypology = groups.find((x) => x.name.toLowerCase() === option.toLowerCase());
        if (selectedTypology) {
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 999) + 100) } : await apiRequest('/api/customers/count', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setCustomerController({
              loading: false,
              value
            });
          }
        } else {
          console.log('Typology group missing?', option);
        }
      } catch (e) {
        console.log(e);
      }
    }
    async function getSalesData() {
      try {
        const selectedTypology = groups.find((x) => x.name.toLowerCase() === option.toLowerCase());
        if (selectedTypology) {
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 99999) + 10000) } : await apiRequest('/api/sales/total', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setSalesController({
              loading: false,
              value
            });
          }
        } else {
          console.log('Typology group missing?', option, groups);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (groups && option) {
      getSalesData().catch(console.error);
      getCustomerData().catch(console.error);
    }
  }, [org, option, groups]);

  return (
    <Card
      sx={{ height: '100%' }}
      variant="outlined"
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          pt: 2,
          px: 3,
          pb: 0,
        }}
      >
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Average lifetime spend per typology
        </Typography>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          py: 0,
          px: 3
        }}
      >
        <Box
          sx={{
            flexGrow: 'grow',
          }}
        >
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {
              (customerController.loading && salesController.loading)
                ? (
                  <Skeleton
                    height={42}
                    width={220}
                    sx={{ p: 0, m: 0 }}
                  />
                )
                : numeral(salesController.value / customerController.value).format('$0,0.00')
            }

          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            ml: 2
          }}
        >
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              height: 56,
              width: 56
            }}
          >
            <SendIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />
          </Avatar>
        </Box>
      </Box>
      <Box
        sx={{
          pt: 0,
          px: 3,
          pb: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start'
        }}
      >
        {
          (customerController.loading && salesController.loading)
            ? (
              <Skeleton
                height={42}
                width={50}
                sx={{ p: 0, m: 0 }}
              />
            )
            : (
              option !== null

                ? (
                  <ActionsMenu
                    actions={options}
                    label={option}
                    size="small"
                    variant="text"
                    sx={{ mr: 1, width: 'auto' }}
                  />
                )

                : (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {option}
                  </Typography>
                )
            )
        }

      </Box>
    </Card>
  );
};
