import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Popover,
  List,
  ListItem,
  Checkbox,
  Box,
} from '@mui/material';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { usePopover } from '../../hooks/use-popover';
import { SalesDataProperties } from '../../utils/properties';

export const TablePopover = (props) => {
  const {
    onClose,
    defaultValue,
  } = props;

  const [columns, setColumns] = useState([...defaultValue]);
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <>
      <Button
        color="primary"
        onClick={handleOpen}
        ref={anchorRef}
        startIcon={<PlaylistAddIcon />}
        size="large"
        sx={{ order: 3 }}
      >
        Columns
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={() => {
          handleClose();
          onClose?.(columns);
        }}
        open={open}
        PaperProps={{
          sx: {
            // width: 260,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <List disablePadding>
          {
            [...SalesDataProperties].map((option) => (
              <ListItem key={option.name}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <Checkbox
                    checked={Boolean(columns.includes(option.name))}
                    onChange={() => {
                      const newColumns = [...columns];
                      const cIndex = columns.findIndex((x) => x === option.name);
                      if (cIndex === -1) {
                        newColumns.push(option.name);
                      } else {
                        newColumns.splice(cIndex, 1);
                      }
                      setColumns(newColumns);
                    }}
                  />
                  <Typography
                    color="textPrimary"
                    variant="body2"
                  >
                    {option.label}
                  </Typography>
                </Box>
              </ListItem>
            ))
          }
        </List>
      </Popover>
    </>
  );
};

TablePopover.defaultProps = {
  defaultValue: [],
};

TablePopover.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
};
