import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, Toolbar, Typography, CircularProgress } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { PhoneIphone as PhoneIphoneIcon } from '../../icons/phone-iphone';
import { lightNeutral } from '../../colors';
import { InputField } from '../input-field';

export const EmailNavbar = ({ previewType, changePreviewType, onSave, onEmailNameChange, emailName, loading }) => (
  <AppBar
    elevation={0}
    sx={{ backgroundColor: lightNeutral[900] }}
  >
    <Toolbar
      disableGutters
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: 64,
        px: 3,
        py: 1
      }}
    >
      <RouterLink
        to="/"
        style={{ height: '41.8px' }}
      >
        <img
          src="/static/AudienceZen-logo-white-green.png"
          alt="AudienceZen Logo"
          style={{ height: '100%' }}
        />
      </RouterLink>
      <Divider
        flexItem
        orientation="vertical"
        sx={{
          borderColor: 'rgba(255,255,255,0.1)',
          mx: 3
        }}
      />
      <Typography
        color="white"
        variant="subtitle1"
        sx={{
          mr: 1
        }}
      >
        Editing:
      </Typography>
      <InputField
        name="email"
        variant="standard"
        sx={{
          mr: 1
        }}
        inputProps={{
          color: 'white',
          sx: {
            color: 'white',
            fontStyle: 'italic',
          }
        }}
        onChange={(ev) => onEmailNameChange(ev.target.value)}
        value={emailName}
      />

      <Box
        onClick={() => changePreviewType('desktop')}
        sx={{
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          borderRadius: 2,
          transition: '0.2s background',
          ...(previewType === 'desktop') && { background: 'rgba(255,255,255,0.2)' },
          ...(previewType !== 'desktop') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
          mr: 1,
        }}
      >
        <DesktopMacIcon
          fontSize="large"
          style={{
            color: '#fff',
          }}
        />
      </Box>
      <Box
        onClick={() => changePreviewType('mobile')}
        sx={{
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          borderRadius: 2,
          transition: '0.2s background',
          ...(previewType === 'mobile') && { background: 'rgba(255,255,255,0.2)' },
          ...(previewType !== 'mobile') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
        }}
      >
        <PhoneIphoneIcon
          fontSize="large"
          sx={{
            color: '#fff',
          }}
        />
      </Box>

      <Box sx={{ flexGrow: 1, height: '100%' }} />
      {
        loading
          ? (
            <CircularProgress
              sx={{ color: 'white' }}
              size={24}
            />
          )
          : (
            <Button
              endIcon={(
                <ArrowRightAltIcon
                  fontSize="small"
                  sx={{
                    ml: 2
                  }}
                />
          )}
              onClick={() => {
                onSave?.();
              }}
              variant="contained"
            >
              Save & Return
            </Button>
          )
      }
    </Toolbar>
  </AppBar>
);

EmailNavbar.propTypes = {
  previewType: PropTypes.string,
  changePreviewType: PropTypes.func,
  onSave: PropTypes.func,
  onEmailNameChange: PropTypes.func,
  emailName: PropTypes.string,
  loading: PropTypes.bool,
};
