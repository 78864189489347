import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from '../campaign/editor-toolbar';

export const WizardTemplate = (props) => {
  const { values, setValues, onNextStep, onPreviousStep } = props;
  const handleSubmit = (event) => {
    event.preventDefault();
    onNextStep({
      email_html: values.email_html,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridAutoFlow: 'row'
          }}
        >
          <Typography
            color="textPrimary"
            sx={{ mt: 2, mb: 0 }}
            variant="h6"
          >
            Customize your messaging
          </Typography>

          <Box
            sx={{ maxHeight: '600px', overflowY: 'auto' }}
            data-text-editor="editorBox"
          >
            <EditorToolbar />
            <ReactQuill
              value={values.email_html}
              onChange={(html) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  email_html: html
                }));
              }}
              placeholder="Start customising your email template now!"
              modules={modules}
              formats={formats}
              bounds={'[data-text-editor="editorBox"]'}
            />
          </Box>
          <Alert
            severity="info"
          >
            Your survey link will be appended to the end of your email message.
          </Alert>

        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 3
          }}
        >
          <Button
            color="primary"
            onClick={onPreviousStep}
            size="large"
            sx={{ mr: 2 }}
            type="button"
            variant="text"
          >
            Back
          </Button>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Next Step
          </Button>
        </Box>
      </form>
    </div>
  );
};

WizardTemplate.propTypes = {
  values: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired
};
