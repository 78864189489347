import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography, Card, LinearProgress, CircularProgress } from '@mui/material';
import numeral from 'numeral';
import { parseISO, format } from 'date-fns';
import gtm from '../lib/gtm';
import { MetricItem } from '../components/campaign/metric-item';
import { InfoTable } from '../components/campaign/info-table';
import { CampaignAreaGraph } from '../components/campaign/campaign-area-graph';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';
import { Typologies } from '../utils/typology';
import { demoId } from '../utils/demo';
import demoCampaign from '../demo/rawCampaignSummary.json';

export const CampaignSummary = () => {
  const selectedCampaign = useParams();
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [campaignsState, setCampaignsState] = useState({ isLoading: true, data: [] });

  async function getCampaigns() {
    try {
      setCampaignsState(() => ({ isLoading: true }));
      const result = org.id === demoId ? demoCampaign : await apiRequest('/email/campaign', {
        id: selectedCampaign.campaignId
      });

      if (mounted.current) {
        setCampaignsState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setCampaignsState(() => ({
          isLoading: true,
          error: err.message
        }));
      }
    }
  }

  useEffect(() => {
    getCampaigns();
  }, [org]);

  useEffect(() => {
    gtm.push({
      event: 'page_view',
    });
  }, []);

  const metrics = [
    // need getattributedrevenue endpoint
    {
      value: numeral(campaignsState?.data?.revenue && campaignsState?.data?.revenue !== null && campaignsState?.data?.revenue !== undefined ? campaignsState?.data?.revenue : 0).format('$0,0.00'),
      caption: `revenue generated across ${campaignsState?.data?.placed_order && campaignsState?.data?.placed_order !== null && campaignsState?.data?.placed_order !== undefined ? campaignsState?.data?.placed_order : 0} ${campaignsState?.data?.placed_order === 1 ? 'order' : 'orders'}`,
    },
    // currently not retrieving this from endpoint as it breaks request
    {
      value: campaignsState?.data?.total_sent && campaignsState?.data?.total_sent !== null && campaignsState?.data?.total_sent !== undefined ? numeral(campaignsState?.data?.total_sent).format('0,0') : 0,
      caption: 'Sent'
    },
    {
      value: campaignsState?.data?.total_opened && campaignsState?.data?.total_opened !== null && campaignsState?.data?.total_opened !== undefined ? numeral(campaignsState?.data?.total_opened).format('0,0') : 0,
      caption: 'opened'
    },
    {
      value: campaignsState?.data?.total_clicked && campaignsState?.data?.total_clicked !== null && campaignsState?.data?.total_clicked !== undefined ? numeral(campaignsState?.data?.total_clicked).format('0,0') : 0,
      caption: 'clicked'
    },
    // UNSURE
    {
      value: campaignsState?.data?.placed_order && campaignsState?.data?.placed_order !== null && campaignsState?.data?.placed_order !== undefined ? numeral(campaignsState?.data?.placed_order).format('0,0') : 0,
      caption: 'Placed order'
    },
    {
      value: campaignsState?.data?.open_rate_per_typology && campaignsState?.data?.open_rate_per_typology[0] && campaignsState?.data?.open_rate_per_typology[0].typology ? campaignsState?.data?.open_rate_per_typology[0]?.typology.charAt(0).toUpperCase() + campaignsState?.data?.open_rate_per_typology[0]?.typology.toLowerCase().slice(1) : 'Not Set',
      caption: 'Typology with most opens'
    },
    {
      value: campaignsState?.data?.unsubscribed && campaignsState?.data?.unsubscribed !== null && campaignsState?.data?.unsubscribed !== undefined ? campaignsState?.data?.unsubscribed : 0,
      caption: 'Unsubscribes'
    },
    {
      value: campaignsState?.data?.spam_complaints && campaignsState?.data?.spam_complaints !== null && campaignsState?.data?.spam_complaints !== undefined ? campaignsState?.data?.spam_complaints : 0,
      caption: 'Spam complaints'
    },
  ];

  const clickRatePerTypology = Typologies.map((typology) => ({
    typology: typology.label,
    count: campaignsState?.data?.click_rate_per_typology?.find((x) => x.typology !== null && x.typology.toUpperCase() === typology.label.toUpperCase())
      ? campaignsState?.data?.click_rate_per_typology?.find((x) => x.typology !== null && x.typology.toUpperCase() === typology.label.toUpperCase()).count
      : 0
  })).sort((a, b) => (a.count < b.count ? 1 : -1));

  const openRatePerTypology = Typologies.map((typology) => ({
    typology: typology.label,
    count: campaignsState?.data?.open_rate_per_typology?.find((x) => x.typology !== null && x.typology.toUpperCase() === typology.label.toUpperCase())
      ? campaignsState?.data?.open_rate_per_typology?.find((x) => x.typology !== null && x.typology.toUpperCase() === typology.label.toUpperCase()).count
      : 0
  })).sort((a, b) => (a.count < b.count ? 1 : -1));
  return (
    <>
      <Helmet>
        <title>Campaign Summary | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          height: '100%'
        }}
      >
        <>
          {/* wrapper */}
          {!campaignsState.isLoading

            ? (
              <Grid
                container
                direction="column"
                sx={{
                  pb: 2,
                }}
              >
                {/* top bar  header */}

                <Grid
                  container
                  direction="column"
                  style={{ width: '100%' }}
                >
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      {`Email reporting: ${campaignsState.data.name ? campaignsState.data.name : 'No name set'}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {`Created: ${campaignsState.data.created_at ? format(parseISO(campaignsState.data.created_at), 'dd MMM, yyyy, hh:mm a') : 'Not Set'}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {`Sent: ${campaignsState.data.sent_at ? format(parseISO(campaignsState.data.sent_at), 'dd MMM, yyyy, hh:mm a') : 'Not Set'}`}
                    </Typography>
                  </Grid>
                </Grid>

                {/* top bar  header end */}
                {/* Start 4x2 */}
                <Grid
                  item
                  container
                  direction="row"
                  sx={{ marginTop: '2em' }}
                >
                  <Grid
                    container
                    spacing={4}
                    sx={{ marginBottom: '2em' }}
                  >
                    {
                metrics.map((metric) => (
                  <Grid
                    item
                    key={metric.caption}
                    xl={3}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <MetricItem
                      value={metric.value.toString()}
                      caption={metric.caption}
                    />
                  </Grid>
                ))
              }
                  </Grid>
                </Grid>
                {/* end 4x2 cards */}

                {/* Start 2x1 cards */}
                <Grid
                  container
                  spacing={4}
                  marginBottom="1.5em"
                >
                  {/*  card 1 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card
                      variant="outlined"
                      sx={{ padding: '1em' }}
                    >
                      <InfoTable
                        caption="Open Rate"
                        value={campaignsState.data.total_opened && campaignsState.data.total_sent ? numeral((campaignsState.data.total_opened / campaignsState.data.total_sent)).format('0.00%') : 0}
                        divider={false}
                        headerStyle="h5"
                      />

                      <LinearProgress
                        variant="determinate"
                        value={campaignsState.data.total_opened && campaignsState.data.total_sent ? (campaignsState.data.total_opened / campaignsState.data.total_sent) * 100 : 0}
                        color="inherit"
                      />
                      <InfoTable
                        caption="Successful deliveries"
                        value={campaignsState.data.total_sent ? numeral(((campaignsState.data.total_sent - (campaignsState.data.bounced ? campaignsState.data.bounced : 0)) / campaignsState.data.total_sent)).format('0.00%') : 0}
                        divider
                        headerStyle="body2"
                      />
                      <InfoTable
                        caption="Total unique opens"
                        value={campaignsState.data.unique_opened ? campaignsState.data.unique_opened : 0}
                        divider
                        headerStyle="body2"
                      />
                      <InfoTable
                        caption="Bounced/Not Delivered"
                        value={campaignsState.data.bounced ? campaignsState.data.bounced : 0}
                        headerStyle="body2"
                      />
                    </Card>
                  </Grid>
                  {/*  card 2 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card
                      variant="outlined"
                      sx={{ padding: '1em' }}
                    >
                      {/* numeral(value / 100).format('0%') */}
                      <InfoTable
                        caption="Click Rate"
                        value={campaignsState.data.total_clicked && campaignsState.data.total_sent ? numeral((campaignsState.data.total_clicked / campaignsState.data.total_sent)).format('0.00%') : 0}
                        divider={false}
                        headerStyle="h5"
                      />

                      <LinearProgress
                        variant="determinate"
                        value={campaignsState.data.total_clicked && campaignsState.data.total_sent ? (campaignsState.data.total_clicked / campaignsState.data.total_sent) * 100 : 0}
                        color="inherit"
                      />
                      <InfoTable
                        caption="Clicks per unique open"
                        value={campaignsState.data.unique_clicked && campaignsState.data.unique_opened ? numeral((campaignsState.data.unique_clicked / campaignsState.data.unique_opened)).format('0.00%') : 0}
                        divider
                        headerStyle="body2"
                      />
                      <InfoTable
                        caption="Total unique clicks"
                        value={campaignsState.data.unique_clicked ? campaignsState.data.unique_clicked : 0}
                        divider
                        headerStyle="body2"
                      />
                      <InfoTable
                        caption="Unsubscribed"
                        value={campaignsState.data.unsubscribed ? campaignsState.data.unsubscribed : 0}
                        headerStyle="body2"
                      />
                    </Card>
                  </Grid>
                </Grid>
                {/* end of 2x1 card */}
                {/* start of graph */}
                <CampaignAreaGraph selectedCampaignId={selectedCampaign.campaignId} />
                {/* end of graph */}
                {/* start of  2x2 */}
                <Grid
                  container
                  spacing={4}
                  marginBottom="1.5em"
                >
                  {/*  card 1 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card variant="outlined">
                      <InfoTable
                        caption="Click rate per Typology"
                        divider
                        headerStyle="h5"
                      />
                      {clickRatePerTypology.map((item, i) => (
                        <InfoTable
                          caption={item.typology}
                          value={item.count}
                          divider={false}
                          headerStyle="body2"
                          highlightCell={i % 2 !== 0}
                        />
                      ))}
                    </Card>
                  </Grid>
                  {/*  card 2 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card variant="outlined">
                      <InfoTable
                        caption="Open rate per Typology"
                        divider
                        headerStyle="h5"
                      />
                      {openRatePerTypology.map((item, i) => (
                        <InfoTable
                          caption={item.typology}
                          value={item.count}
                          divider={false}
                          headerStyle="body2"
                          highlightCell={i % 2 !== 0}
                        />
                      ))}
                    </Card>
                  </Grid>
                  {/* card 3 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card variant="outlined">
                      <InfoTable
                        caption="Customers with most opens"
                        divider
                        headerStyle="h5"
                      />
                      {campaignsState.data.customer_most_opens.map((item, i) => (
                        <InfoTable
                          caption={item.email}
                          value={item.count}
                          divider={false}
                          headerStyle="body2"
                          highlightCell={i % 2 !== 0}
                        />
                      ))}

                    </Card>
                  </Grid>
                  {/* card 4 */}
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Card variant="outlined">
                      <InfoTable
                        caption="Top links clicked"
                        divider
                        headerStyle="h5"
                      />
                      {campaignsState.data.top_links.map((item, i) => (
                        <InfoTable
                          caption={item.link}
                          value={item.count}
                          divider={false}
                          headerStyle="body2"
                          highlightCell={i % 2 !== 0}
                        />
                      ))}
                    </Card>
                  </Grid>
                </Grid>
                {/* End of 2x2 */}
              </Grid>
            )
            : (
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              >
                <CircularProgress
                  color="primary"
                  size={100}
                />

              </Box>
            )}
        </>
      </Box>
    </>
  );
};
