import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,

} from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import React from 'react';
import { useAuth } from '../../hooks/use-auth';

export const OrganizationSourceDialog = ({ onClose, open, handleApiReload, clickedEventId, }) => {
  const { apiRequest } = useAuth();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          minWidth: '600px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Set as Primary Data Source
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ width: '95%' }}>
            {`Are you sure you want to change your primary data source? Editing your data source will affect your synced data.
           If you change the primary data source, all historic data associated with your previous primary data source will be deleted from this app.`}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              apiRequest('/api/connections/primary', {
                id: clickedEventId
              }).then(() => {
                handleApiReload();
                toast.success('Set as to Primary Data Source');
                onClose?.();
              }).catch((err) => {
                toast.error('Failed to set as Primary Data Source');
                console.log(err);
              });
            }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

OrganizationSourceDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleApiReload: PropTypes.func,
  clickedEventId: PropTypes.string,
};
