import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,

} from '@mui/material';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { format } from 'date-fns';
import { Scrollbar } from '../scrollbar';
import { ResourceUnavailable } from '../resource-unavailable';
import { ResourceError } from '../resource-error';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { applyPagination } from '../../utils/apply-pagination';
import { Pagination } from '../pagination';

export const AutomationsTable = (props) => {
  const {
    flows: flowsProp,
    error,
    isLoading,
    onPageChange,
    archiveFlow,
    page,
  } = props;

  const navigate = useNavigate();

  const [flows, setFlows] = useState(flowsProp);
  const [archiveDialogOpen, handleOpenArchiveDialog, handleCloseArchiveDialog] = useDialog();

  useEffect(() => {
    setFlows(flowsProp);
  }, [flowsProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !flows?.length);

  const [anchorEL, setAnchorEL] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e, flow) => {
    setSelectedFlow(flow);
    setAnchorEL(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEL(null);
    setOpen(false);
    // setSelectedFlow(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table>

          <TableBody>
            {applyPagination(flows?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)), page, 20).map((flow) => (
              <TableRow
                hover
                key={flow.id}
              >
                <TableCell
                  width="50px"
                  sx={{ paddingRight: '10px' }}
                >
                  <Grid
                    container
                    direction="column"
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: '#ccc',
                      ...(flow.status === 'PUBLISHED' || flow.status === 'SCHEDULED') && { backgroundColor: 'success.main' },
                      ...(flow.status === 'DRAFT') && { backgroundColor: 'info.main' },
                      ...(flow.status === 'ARCHIVED') && { backgroundColor: '#ccc' },
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                    }}
                  >
                    <FlashOnIcon sx={{ fontSize: 36, color: 'white' }} />
                  </Grid>

                </TableCell>

                <TableCell>
                  <Grid
                    display="flex"
                    direction="column"
                    container
                  >
                    {
                        (flow.status === 'PUBLISHED' || flow.status === 'DRAFT')
                          ? (
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/dashboard/automations/edit/${flow.id}`}
                              underline="none"
                              variant="subtitle2"
                            >
                              <Typography
                                variant="h5"
                                sx={{ textDecoration: 'underline' }}
                              >
                                {flow.name}
                              </Typography>
                            </Link>
                          )
                          : (
                            <Typography
                              variant="h5"
                              sx={{ textDecoration: 'underline' }}
                            >
                              {flow.name}
                            </Typography>
                          )
                      }

                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        ...(flow.status === 'PUBLISHED') && { color: 'success.main' },
                        ...(flow.status === 'DRAFT') && { color: 'info.main' },
                        ...(flow.status === 'ARCHIVED') && { color: '#ccc' },
                      }}
                    >
                      { flow.status }
                    </Typography>

                    {
                        flow.status === 'DRAFT' && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ display: 'block', width: '100%' }}
                        >
                          {'Edited On: '}
                          {format(new Date(flow.updated_at), 'dd/MM/yyyy p')}
                        </Typography>
                        )
  }
                    {
                        flow.status === 'ARCHIVED' && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ display: 'block', width: '100%' }}
                        >
                          {'Edited On: '}
                          {format(new Date(flow.updated_at), 'dd/MM/yyyy p')}
                        </Typography>
                        )
  }

                    {
                        flow.status === 'PUBLISHED' && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ display: 'block', width: '100%' }}
                        >
                          {'Edited On: '}
                          {format(new Date(flow.updated_at), 'dd/MM/yyyy p')}
                        </Typography>
                        )
  }

                  </Grid>
                </TableCell>

                {
                    (flow.status !== 'ARCHIVED' && flow.status !== 'PUBLISHED')
                      ? (
                        <TableCell sx={{ paddingLeft: '8em', cursor: 'pointer' }}>
                          <Grid
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <MoreVertIcon
                              aria-owns={anchorEL ? 'simple-menu' : undefined}
                              aria-haspopup={anchorEL ? 'true' : undefined}
                              onClick={(event) => handleClick(event, flow)}
                            />
                          </Grid>
                        </TableCell>
                      )
                      : <TableCell sx={{ paddingLeft: '8em', cursor: 'pointer' }} />
                  }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      {/* <PagelessPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        isLastPage={(flows.length / 10) <= page}
        //isLastPage={Boolean(flows?.length < 50)}
      /> */}
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page + 1}
        rowsCount={flows?.length}
        pageSize={20}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEL}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        sx={{ marginTop: '-2em' }}
      >
        <MenuItem onClick={() => {
          navigate(`/dashboard/automations/edit/${selectedFlow.id}`);
        }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenArchiveDialog}>
          Archive
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        message="Are you sure you want to archive this automation?"
        onCancel={handleCloseArchiveDialog}
        onConfirm={() => {
          archiveFlow(selectedFlow.id);
          setSelectedFlow(null);
          handleCloseArchiveDialog();
        }}
        open={archiveDialogOpen}
        title="Archive Automation"
        variant="error"

      />
    </Box>
  );
};

AutomationsTable.defaultProps = {
  flows: [],
  page: 1,
};

AutomationsTable.propTypes = {
  flows: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  archiveFlow: Proptypes.func,
  page: Proptypes.number,
};
