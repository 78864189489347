/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Container, Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format, subMonths, startOfMonth } from 'date-fns';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { useAuth } from '../hooks/use-auth';
import { GroupBys } from '../utils/group-by-date';
import { DateControls } from '../components/insights-root/date-controls';

/* Utils */
import gtm from '../lib/gtm';

import { RadarChart } from '../components/survey/wallet/radar-chart';
import { Submissions } from '../components/survey/submissions';
import { Trends } from '../components/survey/wallet/trends';
import { PreferredLineChart } from '../components/survey/wallet/preferred-line-chart';

import { demoId } from '../utils/demo';
import demoEventsData from '../demo/rawSurveyEventData.json';

export const SurveyWallet = () => {
  const { apiRequest, org } = useAuth();
  const selectedGroupBy = GroupBys[2];
  const [loading, setLoading] = useState(true);
  const [controller, setController] = useState({
    data: null
  });

  const [selectedDateRange, setSelectedDateRange] = useState({ start: startOfMonth(subMonths(new Date(), 11)), end: new Date() });
  const xAxisRange = org.id === demoId ? selectedGroupBy.getRange(new Date('2021-09-01'), new Date('2022-08-31')) : selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));
  async function getData() {
    setLoading(true);

    const raw_data = org.id === demoId ? demoEventsData : await apiRequest('/api/events', {
      eventType: 'FORM_SUBMIT',
      startDate: format(selectedDateRange.start, 'yyyy-MM-dd'),
      endDate: format(selectedDateRange.end, 'yyyy-MM-dd'),
    });
    setController({
      ...controller,
      raw_data
    });

    setLoading(false);
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getData();
  }, [selectedDateRange]);

  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: md ? 1 : 2,
                flexDirection: md ? 'column' : 'row',
                textAlign: md ? 'center' : 'unset',
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/surveys"
                variant="text"
              >
                Return to surveys
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="subtitle2">Themes:</Typography>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/referrals"
                variant="text"
              >
                Growing customer referrals
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/inactive"
                variant="text"
              >
                Why do customers stop buying?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/value"
                variant="text"
              >
                Am I satisfying my high value customers?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/wallet"
                variant="text"
              >
                How do I increase my share of wallet?
              </Button>
            </Box>
          </Box>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: md ? '0' : 2,
                      flexDirection: md ? 'column' : 'row',
                      mb: 1,
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{ mb: 1 }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      How do I increase my share of wallet?
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="subtitle2">Select Date Range:</Typography>
                    <DateControls
                      defaultStart={selectedDateRange.start}
                      defaultEnd={selectedDateRange.end}
                      showCompare={false}
                      onDateChange={(start, end = null) => {
                        setSelectedDateRange({ start, end });
                      }}
                    />
                  </Box>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                  >
                    Share of wallet is your percentage of a customer&#34;s spending in your category. Naturally, you want customers to spend more with you and less with your competitors. The key to achieving this is to become your customers’ preferred vendor. Loyalty is not a good indicator of share of wallet because, sadly, your customers might be just as loyal to your competitors as they are to you. Instead, you need to measure your customers’ preference to purchase from you compared to your competitors. The analysis steps below reveal that preference and how to increase it, resulting in more share of wallet.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <PreferredLineChart
                range={xAxisRange}
                data={controller.raw_data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    This chart shows trends in the strength (or otherwise) of your customers’ preference to buy from you rather than competitors. The greater their preference to purchase from you, the greater your share of wallet.
                    <br />
                    <br />
                    Clearly, you want to score at the top of the range or show a definite trend in that direction. If there is room for improvement, work through the analysis steps below to understand which aspects of customer experience are compromising your share of wallet.

                    <br />
                    <br />
                    The preferred vendor score is an average based on data collected from answers to this question in the customer satisfaction survey:
                    <br />
                    <br />
                    <i>{'To what extent is {merchant name} your preferred vendor in this category?'}</i>

                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <RadarChart data={controller.raw_data} />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    Priority for improving the customer experience attributes that increase you share of wallet should go to those which will favourably impact on the most customers. Chart 2 plots the customer satisfaction of the largest cluster of customers within the spectrum of responses to the preferred vendor question in the survey.
                    <br />
                    <br />
                    Look for any gaps between customers’ general expectations for service attributes (orange line) to their experience of these attributes from your business (blue area). A gap between the orange line and the blue area indicates a service attribute with poor customer satisfaction and this may be dragging down your preferred vendor score and share of wallet.
                    <br />
                    <br />
                    For greater insight, look into customer satisfaction trends in chart 3 below.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Trends
                title="3. Trends in customer satisfaction scores"
                range={xAxisRange}
                data={controller.raw_data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    {'This chart allows you to analyse trends in your high value customers’ expectations and experience. Filter by customer experience attribute to identify any attributes for which there is a consistent and significant decline in experience compared to expectation. Any such attribute needs urgent attention.  '}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    Conversely, attributes for which the gap is closing are already improving and can be deprioritized.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Submissions
                title="4. Review survey comments for further insight"
                data={controller.raw_data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                  >
                    Pinpoint what should be improved
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    {'To pinpoint what needs to be improved, search for clues in the comments provided by survey respondents. '}
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    Also, look for recurring issues raised in:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your customer support communications.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your social media presence.
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    And, ideally, talk to some customers who have had unsatisfactory experiences.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
};
