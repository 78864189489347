import { Box, Card, Divider, Typography, Grid, FormControl } from '@mui/material';
import * as React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import StarIcon from '@mui/icons-material/Star';
import { AutomationTemplates } from '../utils/automation-templates';
import { useAuth } from '../hooks/use-auth';
import { demoId } from '../utils/demo';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const AutomationsCreate = () => {
  const { apiRequest, org } = useAuth();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const newAutomation = async (data = {}) => {
    try {
      const request = org.id === demoId ? { id: '652c5063-eb7e-11ec-982a-0a6cc190ea84' } : await apiRequest('/flows/create', {
        name: 'My new automation',
        eventTrigger: 'FINISHED_CHECKOUT',
        wait: 0,
        eventFilters: [],
        eventAppliedDisjunctively: false,
        customerFilters: [],
        customerAppliedDisjunctively: false,
        ...data,
      });
      if (request?.id) {
        if (data) {
          if (org.id !== demoId) {
            await apiRequest('/flows/update', {
              id: request?.id,
              ...data,
            });
          }
        }

        navigate(`/dashboard/automations/edit/${request.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Grid>
            <Typography
              color="textPrimary"
              variant="h4"
              component={RouterLink}
              to="/dashboard/automations"
              sx={{ textDecoration: 'none', '&:hover': { color: '#008944' }, }}
            >
              Automations
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              sx={{ display: 'inline' }}
            >
              {' > '}
            </Typography>
            <Typography
              variant="h4"
              sx={{ display: 'inline' }}
            >
              Browse Ideas
            </Typography>
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Box>
      <Divider sx={{ mb: '2em' }} />
      {/* CONTENT SECTION */}
      <Grid
        container
        display="grid"
        sx={{ gridTemplateColumns: '2fr 8fr' }}
      >
        <Grid
          container
          direction="column"
          sx={{ width: '100%' }}
        >
          {/* SINGLE  FILTER CARD */}
          <Card sx={{ maxWidth: 345, backgroundColor: '#F2F4F5', mb: '1em', }}>
            <CardContent sx={{ px: '1em', height: '60px', pt: '10px' }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    display="inline"
                  >
                    Goal
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    display="Grid"
                  >
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                      sx={{ p: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>

                  </Grid>

                </Grid>
              </Grid>
            </CardContent>
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              <CardContent sx={{ p: '0.5em', pt: '0', px: '1.5em' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="Goal options"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Any Goal"
                    />
                    <FormControlLabel
                      value="Ecommerce"
                      control={<Radio />}
                      label="Ecommerce"
                    />
                    <FormControlLabel
                      value="Surveys"
                      control={<Radio />}
                      label="Surveys"
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Collapse>
          </Card>
          {/* END SINGLE  FILTER CARD */}
        </Grid>
        <Grid
          sx={{ ml: 2 }}
          container
          spacing={2}
        >
          {/* CREATE NEW FROM SCRATCH */}
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Card
              onClick={() => {
                newAutomation();
              }}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'white',
                px: '1em',
                minHeight: '150px'
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ height: '100%' }}
              >
                <Grid item>
                  <Grid
                    item
                    sx={{ pb: '1em' }}
                  >
                    <Grid
                      container
                      display="flex"
                      justifyContent="center"
                    >
                      <AddCircleIcon sx={{ mr: '0.5em' }} />
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ mb: 0 }}
                      >
                        Create from Scratch
                      </Typography>

                    </Grid>
                  </Grid>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                  >
                    Create a new automation from a blank slate
                  </Typography>

                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* CREATE NEW FROM SCRATCH */}
          {AutomationTemplates.filter((x) => (!value) || value.toLowerCase() === x.category?.toLowerCase()).map((x) => (
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Card
                sx={{ cursor: 'pointer', backgroundColor: 'white', px: '1em', minHeight: '150px' }}
                onClick={() => {
                  newAutomation(x.data);
                }}
              >
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ height: '100%', width: '100%' }}
                >
                  <Grid item>
                    <Grid
                      item
                      sx={{ pb: '1em', width: '100%' }}
                    >
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{ mb: 0, mr: '0.5em' }}
                        >
                          {x.name}
                        </Typography>
                        <StarIcon />
                      </Grid>
                      <Typography
                        variant="Caption"
                        color="text.secondary"
                        sx={{ width: '100%' }}
                      >
                        {x.category}
                      </Typography>
                    </Grid>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ width: '100%' }}
                    >
                      {x.description}
                    </Typography>

                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}

        </Grid>
      </Grid>
    </Box>
  );
};
