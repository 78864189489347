import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import propTypes from 'prop-types';
import 'react-syntax-highlighter/dist/esm/styles/prism';
import toast from 'react-hot-toast';

export const CodeBlock = ({ code, ...rest }) => (
  <SyntaxHighlighter
    onClick={() => {
      navigator.clipboard.writeText(code);
      toast.success('Code snippet copied!');
    }}
    showLineNumbers
    language="liquid"
    customStyle={{ marginBottom: '40px', maxHeight: '300px' }}
    {...rest}
  >
    {code}
  </SyntaxHighlighter>
);

CodeBlock.propTypes = {
  code: propTypes.string.isRequired
};
