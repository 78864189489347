/* eslint-disable */ 
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Card, Button, Grid, List, ListItem, ListItemIcon, ListItemText, LinearProgress, CircularProgress, Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { InputField } from '../components/input-field';
/* Utils */
import gtm from '../lib/gtm';
import toast from "react-hot-toast";
import { useAuth } from '../hooks/use-auth';
import { TypologyGuidance } from '../utils/typologies-sanity-api';

const traitKeys = [
  'Altruism',
  'Calmness',
  'Cooperation',
  'Empathy',
  'Hostility',
  'Trust',
  'Achievement',
  'Cautiousness',
  'Orderliness',
  'Impulsiveness',
  'Competence',
  'Anger',
  'Anxiety',
  'Dwelling',
  'Positivity',
  'Self-Consciousness',
  'Stability',
  'Vulnerability',
  'Activity',
  'Assertiveness',
  'Excitement Seeking',
  'Friendliness',
  'Sociability',
  'Adventurousness',
  'Creativity',
  'Emotionality',
  'Intellect',
];

export const Analysis = () => {
  const { apiRequest, org } = useAuth();

  console.log(org);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  const initialController = { text: '',
    dataLoading: true,
    recommendations: [],
    results: [] };
  const [controller, setController] = useState(initialController);
  const [showResults, setShowResults] = useState(false);
  const max650 = useMediaQuery('(min-width:650px)');
  const max1200 = useMediaQuery('(max-width:1200px)');

  const getData = async () => {
    try {
      const sentences = controller?.text?.split(/[.,]+/).map((x) => x.trim()).filter((x) => Boolean(x));
      let nlpResults = [];

      for (let sentenceIndex = 0; sentenceIndex < sentences.length; sentenceIndex++) {
        const sentence = sentences[sentenceIndex];
        const { response } = await apiRequest('/analytics/nlpString', { string: sentence }) || {};
        
        if(response) {
          nlpResults.push(response);
        }
      }
      
      console.log(nlpResults);
      const payload = traitKeys.map((x) => nlpResults.includes(x) ? 7 : 0).join(",") + `,${org?.need_level || "Desired"}`;
      console.log(payload);

      const { result:data } = await apiRequest('/analytics/nlpCalc', { payload }) || {};

      if(!data) {
        toast.error("Something went wrong");
        setShowResults(false);
        return null;
      }

      console.log(data);
      const typology = data?.split(",")?.[0]?.replaceAll(/[^a-zA-Z ]/g, "");
      let typologyContent = null;

      const { result } = await apiRequest('/analytics/guidanceByTypology', {
        rd: org?.need_level || "Desired",
        typology,
      }) || { result: null };

      if(result && result?.length) {
        const intro = result?.find((x) => x.StatementType === 'Intro');
        const groups = result?.reduce((acc, curr) => {
          if(curr.StatementType === 'Intro') {
            return acc;
          }

          let sIndex = acc.findIndex((x) => x.name === curr.Section);
          if(sIndex === -1) {
            acc.push({
              name: curr.Section,
              items: [curr]
            });
          } else {
            acc[sIndex].items = [...acc[sIndex].items, curr];
          }

          return acc;
        }, []);

        typologyContent = { intro, groups };
      }

      // setShowResults(false);
      setController({
        ...controller,
        dataLoading: false,
        typology,
        typologyContent,
        recommendations: typologyContent?.groups || null,
        results: nlpResults?.map((x,i) => ({
          text: sentences[i],
          result: x,
        })),
        // results: resultsFake
      });
    } catch(e) {
      console.log(e);
      setShowResults(false);
      toast.error("Something went wrong");
    }

  };

  return (
    <div>
      <Helmet>
        <title>Analyze text | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Analyze Text
              </Typography>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginBottom: '2em',
              gap: 1,
              padding: 2,
              mb: 2,
            }}
          >
            {!showResults
              ? (
                <Box
                  sx={{ mt: 2 }}
                >
                  <Tooltip title="Natural Language Processing combines linguistics and computer science to create intelligent systems capable of understanding, analysing, and extracting meaning from text or speech.">
                    <Typography sx={{ mb: 2 }}>
                      Let our NLP algorithm provide deeper customer analysis. Enter written communications below to view individualised consumer type and personality insights. <em>Really</em> understand how to communicate with your customers!
                    </Typography>
                  </Tooltip>
                  
                  <InputField
                    fullWidth
                    multiline
                    rows={5}
                    name="analyze_text"
                    placeholder="Enter text here..."
                    onChange={(ev) => setController({ ...controller, text: ev.target.value })}
                    value={controller.text}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="button"
                    sx={{ maxWidth: 'fit-content', ml: 'auto', display: 'flex', mt: 1 }}
                    onClick={() => {
                      setShowResults(true);
                      getData();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              )
              : null}

            {showResults
              ? (!controller.dataLoading

                ? (
                  <Grid
                    container
                    gap={3}
                  >
                    <Grid
                      item
                      xs={max1200 ? 12 : 7}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          marginBottom: '2em',
                          gap: 1,
                          padding: 2,
                          mb: 2,
                        }}
                      >

                        <Box
                          sx={{ display: 'flex' }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="h4"
                          >
                            Results
                          </Typography>
                          <Button
                            color="primary"
                            variant="contained"
                            component="button"
                            sx={{ maxWidth: 'fit-content', ml: 'auto', display: 'flex', height: 'fit-content' }}
                            onClick={() => {
                              setShowResults(false);
                              setController(initialController);
                            }}
                          >
                            Reset Form
                          </Button>
                        </Box>

                        <Typography
                          sx={{ fontWeight: 'bold' }}
                        >
                          Your typology is {controller?.typology || "Unknown"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                        >
                          {controller?.typologyContent?.intro?.AdviceText}
                        </Typography>
                        <p style={{ marginBottom: 0 }}>We found the following traits:</p>
                        <ul style={{ listStyle: 'disc', marginTop: 10 }}>
                          {controller?.results?.map((x) => (
                            <li key={x.text}><strong>{x?.result}:</strong> <em>{x?.text}</em></li>
                          ))}
                        </ul>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={max1200 ? 12 : 4.5}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          marginBottom: '2em',
                          gap: 1,
                          padding: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                        >
                          Recommendations for {controller?.typology || "Unknown"}
                        </Typography>

                        {controller?.recommendations?.map((list) => (
                          <React.Fragment
                            key={list.name}
                          >
                            <Typography
                              sx={{ mb: 0 }}
                              variant="overline"
                              fontWeight="bold"
                            >
                              {list.name}
                            </Typography>
                            <List sx={{ pb: 1, pt: 0 }}>
                              {list.items.map((item) => (
                                <ListItem
                                  sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                                  disableGutters
                                  key={item?.Importance}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 'auto',
                                      mr: 1,
                                      mt: 0.8,
                                    }}
                                  >
                                    {item.StatementType === 'Dont' ? <CancelOutlinedIcon sx={{ color: 'error.main' }} /> : <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.main' }} /> }
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{ fontSize: 14 }}
                                    primary={item.AdviceText}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </React.Fragment>
                        ))}
                      </Card>
                    </Grid>
                  </Grid>
                )
                : (
                  <Box
                    sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                  >
                    <CircularProgress
                      color="primary"
                      size={100}
                    />

                  </Box>
                )
              )
              : null}

          </Card>
        </Container>
      </Box>

    </div>
  );
};
