import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  AlertTitle,
  Link,
  Box,
  FormHelperText,
  Alert,
  Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';

export const OrganizationAddNewDialog = ({ onClose, open, createType, connections, handleApiReload }) => {
  const { apiRequest } = useAuth();

  const shopifyForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      domain: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      domain: Yup.string().max(255).required('A domain must be supplied to create a new connection')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const match = shopifyForm.values.domain.match(/^(?:https?:)?(?:\/\/)?([^/?]+)/i);
        const hostname = match && match[1];
        const authUrl = await apiRequest('/public/storefront-auth-url', { shopUrl: hostname });
        // apiRequest('/api/connections/create', {
        //   source: 'SHOPIFY', domain: shopifyForm.values.domain, secret: shopifyForm.values.secret, webhook_secret: shopifyForm.values.webhook_secret, primary: connections.length === 0,
        // }).then(() => {
        //   handleApiReload();
        //   toast.success('New Data Connection Created');
        //   onClose?.();
        // }).catch((err) => {
        //   toast.error('Failed to Create new Data Connection');
        //   helpers.setErrors({ submit: 'Please check the above fields are correct and your permissions are correct. Please refer to our guide linked above for step by step instructions.' });
        //   console.log(err);
        // });

        if (authUrl && authUrl !== '') {
          window.location.href = authUrl;
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: 'Domain invalid or already in use.' });
        helpers.setSubmitting(false);
      }
    }
  });

  const vendForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      secret: '',
      domain: '',
      discountId: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      secret: Yup.string().max(255).required('A secret must be supplied to create a new connection'),
      domain: Yup.string().max(255).required('A domain must be supplied to create a new connection'),
      discountId: Yup.string().max(255).max(255).required('A discount ID must be supplied to create a new connection'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        apiRequest('/api/connections/create', {
          source: 'VEND', secret: vendForm.values.secret, domain: vendForm.values.domain, vendDiscountId: vendForm.values.discountId, primary: connections.length === 0,
        }).then(() => {
          handleApiReload();
          toast.success('New Data Connection Created');
          onClose?.();
        }).catch((err) => {
          toast.error('Failed to Create new Data Connection');
          helpers.setErrors({ submit: 'Please check the above fields are correct and your permissions are correct. Please refer to our guide linked above for step by step instructions.' });
          console.log(err);
        });

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const newVend = () => (
    <Dialog
      open={open}
      onClose={() => {
        vendForm.resetForm();
        onClose();
      }}
      PaperProps={{
        sx: {
          maxWidth: '600px',
          width: '100%'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Connect Your Vend Account
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Alert
            severity="info"
            sx={{ width: '100%', mb: 2 }}
            action={(
              <Button
                color="primary"
                variant="contained"
                endIcon={<OpenInNewIcon />}
                sx={{ minWidth: 150 }}
                component={Link}
                href="https://audiencezen.zendesk.com/hc/en-us/articles/4621593510297"
                target="_blank"
              >
                Open Guide
              </Button>
            )}
          >
            <AlertTitle>Where do I get these?</AlertTitle>
            Read our guide for step by step instructions on how to get these fields.
          </Alert>
          <form autoComplete="off">
            <InputField
              fullWidth
              error={Boolean(vendForm.touched.domain
              && vendForm.errors.domain)}
              helperText={vendForm.touched.domain ? vendForm.errors.domain : 'e.g. example.vendhq.com'}
              onBlur={vendForm.handleBlur}
              onChange={vendForm.handleChange}
              value={vendForm.values.domain}
              label="Vend Domain"
              name="domain"
              sx={{ mb: 2 }}
            />
            <InputField
              fullWidth
              error={Boolean(vendForm.touched.secret
              && vendForm.errors.secret)}
              helperText={vendForm.touched.secret && vendForm.errors.secret}
              onBlur={vendForm.handleBlur}
              onChange={vendForm.handleChange}
              value={vendForm.values.secret}
              label="Vend Secret Key"
              name="secret"
              type="text"
              sx={{ mb: 2 }}
            />
            <InputField
              fullWidth
              error={Boolean(vendForm.touched.discountId
              && vendForm.errors.discountId)}
              helperText={vendForm.touched.discountId && vendForm.errors.discountId}
              onBlur={vendForm.handleBlur}
              onChange={vendForm.handleChange}
              value={vendForm.values.discountId}
              label="Vend Discount ID"
              name="discountId"
              sx={{ mb: 2 }}
            />
            {vendForm.errors.submit && (
              <Grid
                item
                xs={12}
              >
                <FormHelperText error>
                  {shopifyForm.errors.submit}
                </FormHelperText>
              </Grid>
            )}
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 2,
                width: '100%'
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  vendForm.resetForm();
                  onClose();
                }}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  vendForm.handleSubmit();
                }}
                variant="contained"
              >
                Connect
              </Button>
            </Grid>
          </form>
        </DialogContent>

      </Box>
    </Dialog>
  );
  const newShopify = () => (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        shopifyForm.resetForm();
      }}
      PaperProps={{
        sx: {
          maxWidth: '600px',
          // minWidth: '300px',
          width: '100%'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Connect Your Shopify Account
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* <Alert
            severity="info"
            sx={{ width: '100%', mb: 2 }}
            action={(
              <Button
                color="primary"
                variant="contained"
                endIcon={<OpenInNewIcon />}
                sx={{ minWidth: 150 }}
                component={Link}
                href="https://audiencezen.zendesk.com/hc/en-us/articles/4620717175193"
                target="_blank"
              >
                Open Guide
              </Button>
            )}
          >
            <AlertTitle>Where do I get these?</AlertTitle>
            Read our guide for step by step instructions on how to get these fields.
          </Alert> */}
          <form
            style={{ width: '100%' }}
            autoComplete="off"
          >
            <InputField
              fullWidth
              error={Boolean(shopifyForm.touched.domain
                        && shopifyForm.errors.domain)}
              helperText={shopifyForm.touched.domain ? shopifyForm.errors.domain : 'e.g. example.myshopify.com'}
              onBlur={shopifyForm.handleBlur}
              onChange={shopifyForm.handleChange}
              value={shopifyForm.values.domain}
              label="Shopify Domain"
              type="text"
              name="domain"
              sx={{ mb: 2, width: '100%' }}
            />
            {/* <InputField
              fullWidth
              error={Boolean(shopifyForm.touched.secret
                           && shopifyForm.errors.secret)}
              helperText={shopifyForm.touched.secret ? shopifyForm.errors.secret : 'e.g. shpat_••••••••••••••••••••••••••••••••'}
              onBlur={shopifyForm.handleBlur}
              onChange={shopifyForm.handleChange}
              value={shopifyForm.values.secret}
              label="Shopify Secret Key"
              type="text"
              name="secret"
              sx={{ mb: 2, }}
            />
            <InputField
              fullWidth
              error={Boolean(shopifyForm.touched.webhook_secret
                           && shopifyForm.errors.webhook_secret)}
              helperText={shopifyForm.touched.webhook_secret ? shopifyForm.errors.webhook_secret : 'e.g. shpss_••••••••••••••••••••••••••••••••'}
              onBlur={shopifyForm.handleBlur}
              onChange={shopifyForm.handleChange}
              value={shopifyForm.values.webhook_secret}
              label="Webhook Secret"
              type="text"
              name="webhook_secret"
              sx={{ mb: 2, }}
            /> */}

            {shopifyForm.errors.submit && (
              <Grid
                item
                xs={12}
              >
                <FormHelperText error>
                  {shopifyForm.errors.submit}
                </FormHelperText>
              </Grid>
            )}

            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 2,
                width: '100%'
              }}
            >
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  onClose();
                  shopifyForm.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  shopifyForm.handleSubmit();
                }}
              >
                Connect
              </Button>
            </Grid>
          </form>
        </DialogContent>

      </Box>
    </Dialog>
  );

  return createType === 'SHOPIFY' ? newShopify() : newVend();
};
