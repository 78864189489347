import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, Typography, Grid, Button, Alert, Autocomplete, TextField } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';

import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { Scrollbar } from '../scrollbar';
import { InputField } from '../input-field';

import { Cube as CubeIcon } from '../../icons/cube';
import { ColorSwatch as ColorSwatchIcon } from '../../icons/color-swatch';
import { Eye as EyeIcon } from '../../icons/eye';

import { renderField, EmailSettings } from '../../utils/email-components';
import { useAuth } from '../../hooks/use-auth';

const EmailComponent = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0  0.5rem 0;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
`;

export const EmailSidebar = ({ emailProperties, updateEmailSettings, onDelete, view, setView, emailComponents, selectedBlock, blocks, updateBlock, unsetBlock, resetBlock, handlePreviewSend, previewStatus, setPreviewStatus }) => {
  const { apiRequest, org } = useAuth();

  const [previewEmail, setPreviewEmail] = useState('');
  const [previewJson, setPreviewJson] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [values, setValues] = useState([]);
  const [customerList, setCustomerList] = useState({ data: [], loading: true });

  const getCustomers = async () => {
    try {
      const data = await apiRequest('/api/customers', {
        customerSort: 'NAME', sortDirection: 'asc', query: searchQuery, limit: '300', page: 0, customerFilters: [], appliedDisjunctively: false
      });
      setCustomerList({
        data: data.customers,
        loading: false
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [org]);

  useEffect(() => {
    getCustomers();
  }, [searchQuery]);
  console.log(previewStatus);
  const renderSettings = () => {
    switch (view) {
      case 'block_properties':
        if (blocks.length && selectedBlock !== null) {
          const block = blocks.find((x) => x.id === selectedBlock.id);
          const blockTemplate = emailComponents.find((x) => x.id === block.type);
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
            >
              <div>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box
                    onClick={onDelete}
                    sx={{
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      borderRadius: 1,
                      transition: '0.2s background',
                      '&:hover': {
                        background: 'rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <DeleteIcon
                      fontSize="medium"
                      sx={{
                        color: '#A9AFB4',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    color="contrastText"
                    variant="body1"
                    fontWeight="bold"
                  >
                    { blockTemplate.blockName ? blockTemplate.blockName : 'Block' }
                  </Typography>
                  <Button
                    color="primary"
                    onClick={resetBlock}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={unsetBlock}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 0, pb: 5 }}
                >
                  {
                  blockTemplate.fields.map((x, i) => (
                    <Grid
                      item
                      xs={x.size ? x.size : 12}
                      key={`${x.fieldName}-${i}`}
                    >
                      {renderField(x, block.properties[x.fieldName], updateBlock)}
                    </Grid>
                  ))
                }
                </Grid>
              </div>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          );
        }
        return (<></>);

      case 'blocks':
        return (
          <>
            <Box
              sx={{
                width: '100%',
                backgroundColor: '#A9AFB4',
                p: 2
              }}
            >
              <Typography variant="h5">Drag blocks to add them:</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
            >
              <Droppable
                droppableId="emailComponents"
                isDropDisabled
              >
                {(droppableProvided) => (
                  <div
                    ref={droppableProvided.innerRef}
                    style={{
                    // display: 'grid',
                    // gridTemplateColumns: '1fr 1fr',
                    // gridGap: '10px',
                    }}
                  >
                    {emailComponents.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <EmailComponent
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            isDragging={snapshot.isDragging}
                          >
                            {item.icon()}
                            <Typography
                              color="contrastText"
                              variant="body1"
                              fontWeight="bold"
                            >
                              {item.id}
                            </Typography>
                          </EmailComponent>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </>
        );
      case 'styles':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2
            }}
          >
            <div>
              <Typography
                color="contrastText"
                variant="body1"
                fontWeight="bold"
              >
                Email Styles
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ mt: 0, pb: 5 }}
              >
                {
              [...EmailSettings.fields].map((x, i) => (
                <Grid
                  item
                  xs={x.size ? x.size : 12}
                  key={`${x.fieldName}-${i}`}
                >
                  {renderField(x, emailProperties[x.fieldName], updateEmailSettings)}
                </Grid>
              ))
            }
              </Grid>
            </div>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        );
      case 'preview':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2
            }}
          >

            <div>
              <Typography
                color="contrastText"
                variant="subtitle1"
                fontWeight="bold"
              >
                Preview Email
              </Typography>
              <Typography
                color="contrastText"
                variant="body2"
              >
                Send a copy of this email template to a designated email address.
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ mt: 0, pb: 5 }}
              >
                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    fullWidth
                    helperText="Email address to receive the email"
                    label="Email"
                    name="email"
                    onChange={(event) => {
                      setPreviewEmail(event.target.value);
                      setPreviewStatus('');
                    }}
                    value={previewEmail}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  {customerList.loading
                    ? (
                      <TextField
                        sx={{ width: '100%' }}
                        label="Choose a customer"
                        name="password"
                        disabled
                        autoComplete="off"
                      />
                    )
                    : (
                      <Autocomplete
                        id="customer-select"
                        freeSolo
                        disableClearable
                        sx={{ width: '100%', alignItems: 'flex-start' }}
                        options={customerList?.data}
                        autoHighlight
                        getOptionLabel={(option) => option.email}
                        inputValue={searchQuery}
                        multiple
                        value={values}
                        onChange={(event, newInputValue, reason) => {
                          if (reason === 'selectOption') {
                            if (values.length < 1) {
                              setValues(newInputValue);
                              setPreviewJson(JSON.stringify(newInputValue[0]));
                              setPreviewStatus('');
                            } else if (values.length === 1) {
                              const temp = values;
                              temp.splice(0, 1, newInputValue[1]);
                              setValues(temp);
                              setPreviewJson(JSON.stringify(newInputValue[1]));
                              setPreviewStatus('');
                            }
                          }
                          if (reason === 'removeOption') {
                            setPreviewStatus('');
                            setPreviewJson('');
                            setValues([]);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSearchQuery(newInputValue);
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start !important', alignContent: 'flex-start !important' }}
                          >
                            <Typography>
                              {option.first_name}
                              {' '}
                              (
                              {option.typology === undefined || option.typology === null ? 'Not Set' : option.typology}
                              )
                            </Typography>
                            <Typography>{option.email}</Typography>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a customer"
                            inputProps={{
                              ...params.inputProps,
                              name: 'password',
                              autoComplete: 'off'
                            }}
                          />
                        )}
                      />
                    )}
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    fullWidth
                    helperText="Test placeholders by specifying their values with JSON. Must be valid JSON to send the email."
                    label="Email Placeholders"
                    name="json"
                    multiline
                    rows={4}
                    onChange={(event) => {
                      setPreviewJson(event.target.value);
                      setPreviewStatus('');
                    }}
                    value={previewJson}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  {
          previewStatus !== ''
            ? <Alert severity={previewStatus === 'Email Sent' ? 'success' : 'error'}>{previewStatus}</Alert>

            : null
        }
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <Button
                    color="primary"
                    onClick={() => { handlePreviewSend(previewEmail, previewJson); }}
                    variant="contained"
                  >
                    Send Email
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        );
      default:
        return (
          <></>
        );
    }
  };

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          width: 500,
          '& .simplebar-content': {
            height: '100%'
          },
        }
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '91px 1fr',
          height: '100%',
        }}
      >

        {/* Leftmost Sidebar */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: 'text.primary',
            py: 2,
            px: 1,
            gap: 1,
          }}
        >
          <Box
            onClick={() => setView('blocks')}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              py: 1,
              cursor: 'pointer',
              borderRadius: 2,
              transition: '0.2s background',
              ...(view === 'blocks') && { background: 'rgba(255,255,255,0.2)' },
              ...(view !== 'blocks') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
            }}
          >
            <CubeIcon
              fontSize="large"
              sx={{
                color: '#A9AFB4',
                mb: 0.3,
              }}
            />
            <Typography
              variant="overline"
              sx={{
                color: '#fff',
                textAlign: 'center'
              }}
            >
              BLOCKS
            </Typography>
          </Box>

          <Box
            onClick={() => setView('styles')}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              py: 1,
              cursor: 'pointer',
              borderRadius: 2,
              transition: '0.2s background',
              ...(view === 'styles') && { background: 'rgba(255,255,255,0.2)' },
              ...(view !== 'styles') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
            }}
          >
            <ColorSwatchIcon
              fontSize="large"
              sx={{ color: '#A9AFB4' }}
            />
            <Typography
              variant="overline"
              sx={{
                color: '#fff',
                textAlign: 'center'
              }}
            >
              STYLES
            </Typography>
          </Box>
          <Box
            onClick={() => setView('preview')}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              py: 1,
              cursor: 'pointer',
              borderRadius: 2,
              transition: '0.2s background',
              ...(view === 'preview') && { background: 'rgba(255,255,255,0.2)' },
              ...(view !== 'preview') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
            }}
          >
            <EyeIcon
              fontSize="large"
              sx={{ color: '#A9AFB4' }}
            />
            <Typography
              variant="overline"
              sx={{
                color: '#fff',
                textAlign: 'center'
              }}
            >
              PREVIEW
            </Typography>
          </Box>
          {
            (selectedBlock !== null)
            && (
            <Box
              onClick={() => setView('block_properties')}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                py: 1,
                cursor: 'pointer',
                borderRadius: 2,
                transition: '0.2s background',
                ...(view === 'block_properties') && { background: 'rgba(255,255,255,0.2)' },
                ...(view !== 'block_properties') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
              }}
            >
              <SettingsIcon
                fontSize="large"
                sx={{ color: '#A9AFB4' }}
              />
              <Typography
                variant="overline"
                sx={{
                  color: '#fff',
                  textAlign: 'center'
                }}
              >
                BLOCK
              </Typography>
            </Box>
            )
          }
        </Box>

        {/* Settings Sidebar */}

        <Scrollbar
          style={{
            display: 'flex',
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'auto'
          }}
        >
          { renderSettings() }
        </Scrollbar>

      </Box>
    </Drawer>
  );
};

EmailSidebar.propTypes = {
  emailProperties: PropTypes.object.isRequired,
  updateEmailSettings: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  emailComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedBlock: PropTypes.object,
  blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateBlock: PropTypes.func.isRequired,
  unsetBlock: PropTypes.func.isRequired,
  resetBlock: PropTypes.func.isRequired,
  handlePreviewSend: PropTypes.func.isRequired,
  previewStatus: PropTypes.string.isRequired,
  setPreviewStatus: PropTypes.func.isRequired
};
