import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  MenuItem,
  Grid,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableRow,
  Card,
  CardHeader

} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useAuth } from '../../hooks/use-auth';
import { InputField } from '../input-field';
import { useMounted } from '../../hooks/use-mounted';

import { demoId } from '../../utils/demo';

const discountType = [
  {
    value: 'FREE_SHIPPING',
    label: 'FREE SHIPPING',
  },
  {
    value: 'FIXED',
    label: 'FIXED',
  },
  {
    value: 'PERCENTAGE',
    label: 'PERCENTAGE',
  },
  {
    value: 'PRODUCT_FIXED',
    label: 'PRODUCT FIXED',
  },
  {
    value: 'PRODUCT_PERCENTAGE',
    label: 'PRODUCT PERCENTAGE',
  },

];

export const DiscountDialog = ({ open, onClose, handleApiReload, connections, discounts }) => {
  const [productSearchInput, setProductSearchInput] = React.useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [productSearchClick, setProductSearchClick] = useState(false);
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();

  const handleChange = (event) => {
    setProductSearchInput(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      type: '',
      value: '',
      product: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      type: Yup
        .string()
        .oneOf(discountType.map((option) => option.value))
        .required('Discount type is required'),
      product: Yup
        .string()
        .when('type', {
          is: (type) => type === 'PRODUCT_FIXED' || type === 'PRODUCT_PERCENTAGE',
          then: Yup.string().required('Product is required'),
        }),

      name: Yup
        .string()
        .required('Discount name is required')
        .test(
          'Discount code not unique',
          'Discount code not unique',
          (value) => {
            const isUnique = !discounts.data.discounts.some((x) => x.name.toLowerCase() === value?.toLowerCase());

            return isUnique;
          }
        )
        .max(255),
      value: Yup
        .number()
        .when('type', {
          is: (type) => type !== 'FREE_SHIPPING',
          then: Yup.number().required('Value is required'),
        }),

    }),
    onSubmit: async (values, helpers) => {
      try {
        if (org.id !== demoId) {
          apiRequest('/loyalty/create-discount', {
            discountType: values.type,
            value: values.value,
            productId: values.product ? values.product : null,
            name: values.name,
          }).then(() => {
            handleApiReload();
            toast.success('Discount saved');
          }).catch((err) => {
            toast.error('Discount not saved');
            console.log(err);
          });
        } else {
          toast.success('Discount saved');
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        formik.resetForm();
        onClose?.();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const selectedItem = productOptions.filter((x) => x.id === formik.values.product);
  // used to check if hasShopifyConnection.length if true then there is atleast 1 shopify connection
  const hasShopifyConnection = connections.some((x) => x.source === 'SHOPIFY');

  const percentage = (inputObj) => {
    if (inputObj.value <= 100) return true;
    return false;
  };
  const nonPercentage = (inputObj) => {
    if (inputObj.value >= 0) return true;
    return false;
  };

  async function handleProductSearch() {
    try {
      const products = await apiRequest('/loyalty/search-products', {
        page: 1, limit: 50, query: productSearchInput
      });
      if (mounted.current) {
        setProductOptions(products.products);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const productSearch = () => {
    if (formik.values.type === 'PRODUCT_FIXED' || formik.values.type === 'PRODUCT_PERCENTAGE') {
      return (
        <>
          <Grid
            container
            display="grid"
            gap="20px"
            sx={{ alignItems: 'flex-end', gridTemplateColumns: '1fr 120px', mb: '1em' }}
          >

            <InputField
              className="ProductInput"
              label="Product List"
              onChange={(e) => {
                handleChange(e);
                setProductSearchClick(false);
              }}
              value={productSearchInput}
              sx={{ flexBasis: '70%',
                '&.ProductInput>.MuiInputBase-root': {
                  borderColor: productSearchClick ? 'red' : 'default'

                }, }}
              fullWidth
              id="product-list"
              placeholder={productSearchClick ? 'Please enter your search parameters first.' : null}
            />

            <Button
              variant="outlined"
              fullWidth
              sx={{ flexGrow: '2' }}
              onClick={() => {
                if (productSearchInput.trim().length > 0) {
                  handleProductSearch();
                  setProductSearchClick(false);
                } else {
                  setProductSearchClick(true);
                }
              }}
            >
              Search
            </Button>
          </Grid>

        </>

      );
    }
    return null;
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        formik.resetForm();
        setProductOptions([]);
        setProductSearchInput('');
      }}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: '1000px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"

      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            sx={{ ml: 2 }}
            variant="inherit"
          >
            Discounts
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',

            display: 'flex',
            width: '100%',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"

          >
            <InputField
              name="name"
              label="Name"
              fullWidth
              sx={{ mb: '1em' }}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <InputField
              name="type"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('value', '', false);
              }}
              value={formik.values.type}
              error={Boolean(formik.touched.type && formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              sx={{ mb: '1em' }}
              label="Discount type"
              select
              fullWidth
            >
              {discountType.filter((x) => x.value === 'FIXED' || hasShopifyConnection).map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}

            </InputField>
            {formik.values.type !== 'FREE_SHIPPING'
              ? (
                <NumberFormat
                  name="value"
                  label="Value"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (!formik.values.type.includes('PERCENTAGE')) {
                      formik.setFieldValue('value', e.target.value);
                    } else {
                      formik.setFieldValue('value', parseInt(e.target.value.toString().slice(0, -1), 10));
                    }
                  }}
                  value={formik.values.value}
                  error={Boolean(formik.touched.value && formik.errors.value)}
                  helperText={formik.touched.value && formik.errors.value}
                  customInput={InputField}
                  fullWidth
                  sx={{ mb: '1.4em' }}
                  isAllowed={formik.values.type === 'PRODUCT_PERCENTAGE' || formik.values.type === 'PERCENTAGE' ? percentage : nonPercentage}
                  suffix={formik.values.type === 'PRODUCT_PERCENTAGE' || formik.values.type === 'PERCENTAGE' ? '%' : undefined}
                  decimalScale={2}
                />
              )
              : null}

            {formik.values.product && selectedItem[0] !== undefined
              ? (
                <Card sx={{ margin: 'auto' }}>
                  <CardHeader
                    action={(
                      <IconButton
                        aria-label="settings"
                        onClick={() => { formik.setFieldValue('product', ''); }}
                      >
                        <CancelIcon />
                      </IconButton>
            )}
                    title={`${selectedItem[0].name}`}
                  />
                  <Grid
                    container
                    display="flex"
                    direction="column"
                    sx={{ p: '1em' }}
                  >

                    <Grid
                      item
                      sx={{ width: '300px', height: '300px', mb: '2em' }}
                    >

                      {selectedItem[0].image_src
                        ? (
                          <img
                            src={selectedItem[0].image_src}
                            alt={selectedItem[0].name}
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          />
                        )
                        : <CardGiftcardIcon sx={{ height: '100%', width: '100%' }} />}
                    </Grid>

                    <Typography>{selectedItem[0].brand}</Typography>
                    <Typography>{selectedItem[0].type}</Typography>

                  </Grid>

                </Card>
              )
              : (
                <>
                  {productSearch()}
                  {productOptions
                    ? (
                      <Grid container>
                        <Table sx={{ cursor: 'pointer' }}>
                          <TableBody>
                            {productOptions.map((item) => (
                              <TableRow
                                key={item.name}
                                hover
                                onClick={() => {
                                  formik.setFieldValue('product', item.id);
                                }}
                              >
                                <TableCell>
                                  <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    gap="5px"
                                  >
                                    <Grid
                                      item
                                      sx={{ width: '100px', height: '100px' }}
                                    >
                                      {item.image_src
                                        ? (
                                          <img
                                            src={item.image_src}
                                            alt={item.name}
                                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                          />
                                        )
                                        : <CardGiftcardIcon sx={{ height: '100%', width: '100%', }} />}

                                    </Grid>
                                    <Grid
                                      item
                                      display="flex"
                                      sx={{ flexWrap: 'wrap', width: '200px' }}
                                    >
                                      <Typography>{item.name}</Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell>
                                  {item.brand}
                                </TableCell>
                                <TableCell>
                                  {item.type}
                                </TableCell>
                                <TableCell align="right">
                                  <AddCircleOutlineIcon sx={{ fontSize: '30px' }} />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    )
                    : null}
                </>
              )}

          </Box>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
              formik.resetForm();
              setProductSearchClick(false);
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              formik.handleSubmit();
              setProductOptions([]);
              setProductSearchInput('');
              setProductSearchClick(false);
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DiscountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleApiReload: PropTypes.func,
  connections: PropTypes.arrayOf(PropTypes.object),
  discounts: PropTypes.object,
};
