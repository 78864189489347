import { useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Divider,
  Button,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { format, parseISO } from 'date-fns';
import { Parser } from 'json2csv';
import DownloadIcon from '@mui/icons-material/Download';
import { Scrollbar } from '../scrollbar';
import { ActionsMenu } from '../actions-menu';
import { Pagination } from '../pagination';
import { applyPagination } from '../../utils/apply-pagination';
import { useAuth } from '../../hooks/use-auth';

const columnHeadings = ['Event Type', 'Customer', 'Date', 'Code', 'Points Change', 'Event Details'];

export const Submissions = ({ loyaltyFlat }) => {
  const { apiRequest } = useAuth();
  const [range, setRange] = useState({ label: 'All Events', value: 'ALL' });

  const [controller, setController] = useState({ sort: 'asc', sortBy: 'Event Type', page: 0 });

  const ResponsiveTableCell = styled(TableCell)({
    width: range.label === 'All Events' ? '16.6%' : range.label === 'Joined' ? '33.33%' : range.label === 'Redeemed' ? '20%' : '25%'
  });
  const ranges = [
    {
      label: 'All Events',
      onClick: () => { setRange({ label: 'All Events', value: 'ALL' }); }
    },
    {
      label: 'Earnt',
      onClick: () => { setRange({ label: 'Earnt', value: 'LOYALTY_CHANGE' }); }
    },
    {
      label: 'Redeemed',
      onClick: () => { setRange({ label: 'Redeemed', value: 'LOYALTY_REDEEMED' }); }
    },
    {
      label: 'Joined',
      onClick: () => { setRange({ label: 'Joined', value: 'LOYALTY_SIGNUP' }); }
    }
  ];
  const handleSortChange = (property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1
    });
  };
  const filteredData = [...loyaltyFlat.all];
  if (loyaltyFlat) {
    switch (controller.sort) {
      case 'asc':
        switch (controller.sortBy) {
          case 'Event Type':
            filteredData.sort((a, b) => b.event_type.localeCompare(a.event_type));
            break;

          case 'Customer':
            filteredData.sort((a, b) => b.email.localeCompare(a.email));
            break;

          case 'Date':
            filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            break;

          case 'Points Change':
            filteredData.sort((a, b) => ((b.properties.points !== undefined ? b.properties.points : -9999) - (a.properties.points !== undefined ? a.properties.points : -9999)));
            break;

          default:
            break;
        }
        break;

      case 'desc':
        switch (controller.sortBy) {
          case 'Event Type':
            filteredData.sort((a, b) => a.event_type.localeCompare(b.event_type));
            break;

          case 'Customer':
            filteredData.sort((a, b) => a.email.localeCompare(b.email));
            break;

          case 'Date':
            filteredData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            break;

          case 'Points Change':
            filteredData.sort((a, b) => ((a.properties.points !== undefined ? a.properties.points : -9999) - (b.properties.points !== undefined ? b.properties.points : -9999)));
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }
  const exportData = () => {
    const parser = new Parser({ fields: ['id', 'email', 'event_type', 'properties.code', 'properties.points', 'properties.reason', 'properties.rewardId', 'first_name', 'last_name', 'typology', 'created_at'] });
    const csv = parser.parse(filteredData);
    const linkSource = `data:text/csv;charset=utf-8,${csv}`;
    const downloadLink = document.createElement('a');
    const fileName = `export-${(new Date()).toISOString()}.csv`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportLoyaltyPoints = async () => {
    const loyaltyPointsData = await apiRequest('/loyalty/get-customer-loyalty-points');
    const parser = new Parser({ fields: ['email', 'loyalty_points'] });
    const csv = parser.parse(loyaltyPointsData);
    const linkSource = `data:text/csv;charset=utf-8,${csv}`;
    const downloadLink = document.createElement('a');
    const fileName = `customer-loyalty-points-${(new Date()).toISOString()}.csv`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Card
      variant="outlined"
    >
      <CardHeader
        action={(
          <>
            <ActionsMenu
              actions={ranges}
              label={range.label}
              size="small"
              variant="text"
            />
            <Button
              color="primary"
              onClick={() => exportData()}
              size="small"
              startIcon={<DownloadIcon fontSize="small" />}
              variant="contained"
            >
              <Box display={{ xs: 'none', md: 'block' }}>Download Loyalty Events</Box>
            </Button>
            <Button
              color="primary"
              onClick={() => exportLoyaltyPoints()}
              size="small"
              startIcon={<DownloadIcon fontSize="small" />}
              variant="contained"
              sx={{ ml: 1 }}
            >
              <Box display={{ xs: 'none', md: 'block' }}>Export Customers&apos; Loyalty Points</Box>
            </Button>
          </>
        )}
        title="Recent Loyalty Events"
      />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {columnHeadings.map((column) => (
                ((range.label !== 'All Events' && column === 'Event Type') || (range.label === 'Joined' && (column === 'Points Change' || column === 'Code')) || (range.label === 'Earnt' && column === 'Code'))
                  ? null
                  : (
                    <TableCell key={column}>

                      {column !== 'Event Details' && column !== 'Code'
                        ? (
                          <TableSortLabel
                            active={controller.sortBy === column}
                            direction={controller.sortBy === column ? controller.sort : 'asc'}
                            disabled={loyaltyFlat.isLoading}
                            onClick={() => handleSortChange(column)}
                          >
                            {column}
                          </TableSortLabel>
                        )
                        : column}

                    </TableCell>
                  )

              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {applyPagination(filteredData.filter((x) => (range.value !== 'ALL' ? x.event_type === range.value : x)), controller.page, 10).map((item, i) => (
              <TableRow
                key={item.email + i}
                hover
              >
                {range.label === 'All Events'
                  ? <ResponsiveTableCell>{item.event_type.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}</ResponsiveTableCell>
                  : null}

                <ResponsiveTableCell>
                  {item.email}
                </ResponsiveTableCell>

                <ResponsiveTableCell>
                  {format(parseISO(item.created_at), "dd/MM/yy 'At' p")}
                </ResponsiveTableCell>

                {range.label === 'All Events' || range.label === 'Redeemed'
                  ? (
                    <ResponsiveTableCell>
                      {item.properties.code ? item.properties.code : 'N/A'}
                    </ResponsiveTableCell>
                  )
                  : null}

                {range.label !== 'Joined'
                  ? (
                    <ResponsiveTableCell>
                      {' '}
                      {(item.properties && item.properties.points !== undefined) ? item.properties.points : 'N/A'}
                    </ResponsiveTableCell>
                  )
                  : null}

                <ResponsiveTableCell>
                  {(item.properties && item.properties.reason !== undefined) ? item.properties.reason : 'Not Set'}
                </ResponsiveTableCell>

              </TableRow>
            ))}
          </TableBody>

        </Table>
        <Divider sx={{ mt: 'auto' }} />
        <Pagination
          disabled={!filteredData}
          onPageChange={handlePageChange}
          page={controller.page + 1}
          rowsCount={filteredData.length}
          pageSize={10}
        />
      </Scrollbar>
    </Card>
  );
};

Submissions.propTypes = {
  loyaltyFlat: PropTypes.object,
};
