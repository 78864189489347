import { Box, Container, Link, Typography } from '@mui/material';

const links = [
  // {
  //   label: 'About Us',
  //   href: '#'
  // },
  {
    label: 'Terms',
    href: 'https://audiencezen.com/terms-of-service/'
  }
];

export const Footer = () => (
  <Box sx={{ backgroundColor: 'background.default' }}>
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: {
          sm: 'row',
          xs: 'column'
        },
        py: 3,
        '& a': {
          mt: {
            sm: 0,
            xs: 1
          },
          '&:not(:last-child)': {
            mr: {
              sm: 5,
              xs: 0
            }
          }
        }
      }}
    >
      <Typography
        color="textSecondary"
        variant="caption"
      >
        ©
        {` ${(new Date().getFullYear())} `}
        AudienceZen
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {links.map((link) => (
        <Link
          color="textSecondary"
          href={link.href}
          key={link.label}
          target="_blank"
          underline="none"
          variant="body2"
        >
          {link.label}
        </Link>
      ))}
    </Container>
  </Box>
);
