import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { demoId } from '../utils/demo';
import demoCreateCampaign from '../demo/rawCreateCampaign.json';
import demoCampaigns from '../demo/rawCampaigns.json';

/* Components */
import { CampaignTable } from '../components/campaign/campaign-table';
import { Plus as PlusIcon } from '../icons/plus';

/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Utils */
import gtm from '../lib/gtm';

export const CampaignScreen = () => {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [controller, setController] = useState({
    page: 0,
  });
  const [campaignsState, setCampaignsState] = useState({ isLoading: true });

  const newCampaign = async (data = {}) => {
    try {
      const request = org.id === demoId ? demoCreateCampaign : await apiRequest('/email/create-campaign', {
        name: 'My new campaign',
        smartSend: false,
        fromName: org.name,
        needLevel: org.need_level || 'Desired',
        fromEmail: org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`,
        ...data,
      });
      if (request?.id) {
        navigate(`/dashboard/campaigns/edit/${request.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function getCampaigns() {
    try {
      setCampaignsState(() => ({ isLoading: true }));
      const result = org.id === demoId ? demoCampaigns : await apiRequest('/email/campaigns', {
        limit: 50,
        page: controller.page
      });

      if (mounted.current) {
        setCampaignsState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setCampaignsState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }

  const cancelCampaignSend = async (id) => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/email/cancel-campaign', {
          id
        });
      }
      getCampaigns();
      toast.success('Campaign cancelled');
    } catch (e) {
      console.log(e);
      toast.error('Error');
    }
  };

  const archiveCampaign = async (id) => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/email/archive-campaign', {
          id
        });
      }
      getCampaigns();
      toast.success('Campaign archived');
    } catch (e) {
      console.log(e);
      toast.error('Error');
    }
  };

  useEffect(() => {
    getCampaigns();
  }, [controller, org]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1
    });
  };

  return (
    <>
      <Helmet>
        <title>Campaigns | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Campaigns
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  newCampaign();
                }}
              >
                <Box display={{ xs: 'none', md: 'block' }}>New Campaign</Box>
              </Button>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
          >
            <CampaignTable
              campaigns={campaignsState.data?.campaigns}
              error={campaignsState.error}
              isLoading={campaignsState.isLoading}
              onPageChange={handlePageChange}
              archiveCampaign={archiveCampaign}
              cancelCampaignSend={cancelCampaignSend}
              page={controller.page}
            />
          </Card>
        </Container>
      </Box>

    </>
  );
};
