/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { demoId } from '../utils/demo';
import demoEmailTemplates from '../demo/rawEmailTemplateData.json';

/* Components */
import { EmailTable } from '../components/email/email-editor-table';
import { Plus as PlusIcon } from '../icons/plus';

/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Utils */
import gtm from '../lib/gtm';

export const EmailTemplates = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [controller, setController] = useState({
    page: 0,
  });
  const [emailsState, setEmailsState] = useState({ isLoading: true });

  async function getEmails() {
    try {
      setEmailsState(() => ({ isLoading: true }));
      const result = org.id === demoId ? demoEmailTemplates : await apiRequest('/email/templates', {
        limit: 50,
        page: controller.page
      }) || [];

      if (mounted.current) {
        setEmailsState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setEmailsState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }

  const deleteEmailTemplate = async (id) => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/email/delete-template', {
          id
        });
      }
      getEmails();
      toast.success('Email archived');
    } catch (e) {
      console.log(e);
      toast.error('Error');
    }
  };

  useEffect(() => {
    getEmails();
  }, [controller, org]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1
    });
  };

  return (
    <>
      <Helmet>
        <title>Email Templates | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Emails
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                component={RouterLink}
                to="/dashboard/email-templates/create"
              >
                <Box display={{ xs: 'none', md: 'block' }}>New Email Template</Box>
              </Button>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
          >
            <EmailTable
              emails={emailsState.data?.emailTemplates}
              error={emailsState.error}
              isLoading={emailsState.isLoading}
              onPageChange={handlePageChange}
              archiveEmail={deleteEmailTemplate}
              page={controller.page + 1}
            />
          </Card>
        </Container>
      </Box>

    </>
  );
};
