import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Box, Typography, Button, RadioGroup, FormControlLabel, Radio, Card, CardHeader, Divider } from '@mui/material';

export const WizardAccount = (props) => {
  const { onNextStep } = props;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountType: 'Ecommerce',
    },
    validationSchema: Yup.object().shape({
      accountType: Yup.string().max(255).required('Please select an account type'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        onNextStep({
          accountType: values.accountType,
        });
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridAutoFlow: 'row'
          }}
        >
          <Typography
            color="textPrimary"
            sx={{ my: 2 }}
            variant="h6"
          >
            Step 1. Account Type
          </Typography>

          <Card variant="outlined">
            <CardHeader title="Select your account type" />
            <RadioGroup
              name="accountType"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.accountType}
            >
              <Divider />
              <FormControlLabel
                disableTypography
                control={<Radio color="primary" />}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <div>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Ecommerce
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                      >
                        For organisations selling on Shopify.
                      </Typography>
                    </div>
                  </Box>
              )}
                sx={{
                  m: 0,
                  px: 1.5,
                  py: 1.5
                }}
                value="Ecommerce"
              />
              <Divider />
              <FormControlLabel
                disableTypography
                control={<Radio color="primary" />}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <div>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Business
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                      >
                        For all non-ecommerce organisations.
                      </Typography>
                    </div>
                  </Box>
              )}
                sx={{
                  m: 0,
                  px: 1.5,
                  py: 1.5
                }}
                value="Business"
              />
            </RadioGroup>
          </Card>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 3
          }}
        >
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Next Step
          </Button>
        </Box>
      </form>
    </div>
  );
};

WizardAccount.propTypes = {
  onNextStep: PropTypes.func.isRequired
};
