/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, CircularProgress, Container, Typography, Grid, CardContent, Divider } from '@mui/material';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import mjml2html from 'mjml-browser';
import { Plus as PlusIcon } from '../icons/plus';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { Stepper } from '../components/stepper';
import { WizardConfirmation } from '../components/survey/wizard-confirmation';
import { WizardSurvey } from '../components/survey/wizard-survey';
import { WizardTemplate } from '../components/survey/wizard-template';
// import { WizardQuestions } from '../components/survey/wizard-questions';
import { useAuth } from '../hooks/use-auth';
import { isDev } from '../utils/is-dev';
import { useDialog } from '../hooks/use-dialog';
import { useMounted } from '../hooks/use-mounted';
import { PreviewEmailDialog } from '../components/email/preview-email-dialog';
/* Utils */
import gtm from '../lib/gtm';
import { AutomationTemplates } from '../utils/automation-templates';

import { demoId } from '../utils/demo';
import demoSurveyData from '../demo/rawSurveyData.json';

const steps = [
  {
    title: 'Step 1',
    content: 'Configure Survey'
  },
  {
    title: 'Step 2',
    content: 'Customize message'
  },
  {
    title: 'Step 3',
    content: 'Review'
  },
];

const generateTemplate = (content, id) => {
  const mjmlTemplate = `
  <mjml>
    <mj-body background-color="#F9FAFB">
      <mj-wrapper padding="40px" background-color="#FFFFFF">
        <mj-section>
          <mj-column>
            <mj-text font-size="14px">
            ${content}
            </mj-text>
              <mj-button href="https://survey.audiencezen.com/form/${id}?c={{ customer_id }}" width="100%" height="48px" background-color="#000">Take the Survey</mj-button>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    </mj-body>
  </mjml>
  `;

  const { html, errors } = mjml2html(mjmlTemplate, {
    validationLevel: 'soft'
  });

  if (errors && errors.length) {
    console.log('mjml errors:', errors);
    return { __html: errors.map(({ message }) => `<pre style="font-size: 0.8em; padding: 5px 15px; background: #fff; border: 1px solid rgba(255,0,0,0.4); white-space: pre-wrap;">${message}</pre>`).join('') };
  }

  return html;
};

export const SurveyEditor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { apiRequest, org } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const mounted = useMounted();
  const [previewDialogOpen, handleOpenPreviewDialog, handleClosePreviewDialog] = useDialog();
  const [values, setValues] = useState({
    survey_name: 'My new survey',
    trigger: 'FINISHED_CHECKOUT',
    wait: 5,
    loyalty_points: 0,
    from_email: org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`,
    from_name: org.name,
    subject: 'Tell us what you think!',
    preview_text: "We'd appreciate a few minutes of your time to tell us your feedback",
    // show_nps: true,
    id: null,
    email_html: '<p>Hi {{ first_name }},</p><p><br></p><p>We&rsquo;re unashamedly obsessed with customer service and depend on customer feedback to stay at the top of our game. </p><p><br></p><p>We&rsquo;d appreciate a few minutes of your time to tell us your feedback using the link below.</p><p><br></p><p>Thank you in advance,</p><p><br></p><p>{{ organization.name }}</p>',
    // form_questions: [...QuestionTypes.map((x) => ({ ...x, enabled: true }))],
    exclude_days: 90,
    survey_frequency: 25,
    returns_question: true,
    include_vocation: true,
    survey_opening_text: 'Thank you for agreeing to complete our survey.',
    survey_closing_text: 'We appreciate your feedback and look forward to seeing you in our shop soon.',
  });
  const vocationTemplateEmailJSON = JSON.parse(AutomationTemplates.find((x) => x.name.toLowerCase() === 'vocation survey').data.flowSteps)[0].email_json;
  vocationTemplateEmailJSON.content.find((x) => x.type === 'TEXT').properties.content = values.email_html;
  vocationTemplateEmailJSON.content.find((x) => x.type === 'BUTTON').properties.link = `https://survey.audiencezen.com/form/${values.id}?c={{ customer_id }}`;
  vocationTemplateEmailJSON.name = `Email for: ${values.survey_name}`;

  useEffect(() => {
    vocationTemplateEmailJSON.content.find((x) => x.type === 'TEXT').properties.content = values.email_html;
    vocationTemplateEmailJSON.content.find((x) => x.type === 'BUTTON').properties.link = `https://survey.audiencezen.com/form/${values.id}?c={{ customer_id }}`;
    vocationTemplateEmailJSON.name = `Email for: ${values.survey_name}`;
  }, [values.survey_name, values.id, values.email_html]);

  useEffect(() => {
    async function getForm() {
      setLoading(true);
      try {
        const data = org.id === demoId ? demoSurveyData : await apiRequest('/api/form', {
          id: params.surveyId,
        });
        if (mounted.current) {
          const formJSON = data.form_json ? JSON.parse(data.form_json) : {};
          if (data) {
            const newState = {
              ...values,
              id: data.id,
              survey_name: data.name,
              ...formJSON.trigger && { trigger: formJSON.trigger },
              ...formJSON.wait && { wait: formJSON.wait },
              ...formJSON.loyalty_points && { loyalty_points: formJSON.loyalty_points },
              ...formJSON.preview_text && { preview_text: formJSON.preview_text },
              ...formJSON.from_email && { from_email: formJSON.from_email },
              ...formJSON.from_name && { from_name: formJSON.from_name },
              ...formJSON.subject && { subject: formJSON.subject },
              // ...formJSON.show_nps && { show_nps: formJSON.show_nps },
              ...formJSON.email_html && { email_html: formJSON.email_html },
              // ...formJSON.form_questions && { form_questions: formJSON.form_questions },
              ...formJSON.exclude_days && { exclude_days: formJSON.exclude_days },
              ...formJSON.survey_frequency && { survey_frequency: formJSON.survey_frequency },
              // ...formJSON.returns_question && { returns_question: formJSON.returns_question }
              // ...formJSON.include_vocation && { include_vocation: formJSON.include_vocation },
              returns_question: formJSON.returns_question,
              include_vocation: formJSON.include_vocation,
              ...formJSON.survey_opening_text && { survey_opening_text: formJSON.survey_opening_text },
              ...formJSON.survey_closing_text && { survey_closing_text: formJSON.survey_closing_text },
            };

            setValues(newState);
            setLoading(false);
          }
        }
      } catch (err) {
        if (mounted.current) {
          console.log(err);
        }
      }
    }

    gtm.push({ event: 'page_view' });
    getForm();
  }, []);

  const saveForm = async () => {
    try {
      const { id, survey_name, ...data } = values;
      setValues((prevValues) => ({ ...prevValues, ...values }));
      if (org.id !== demoId) {
        await apiRequest('/api/form/update', {
          id,
          name: survey_name,
          formJson: JSON.stringify(data),
        });
      }
      toast.success('Survey saved');
    } catch (e) {
      console.log(e);
    }
  };
  const handleNextStep = (newValues) => {
    setValues((prevValues) => ({ ...prevValues, ...newValues }));
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      try {
        // unpublish other survey
        // await location.state.formsContext.forEach((survey)=>{
        //    apiRequest('/flows/unpublish', {
        //     id:survey.id
        //   })
        //    apiRequest('/api/form/update', {
        //     id: survey.id,
        //     name: survey.name,
        //     formJson:null
        //   });
        // })

        // Create the automation
        const request = org.id === demoId ? {} : await apiRequest('/flows/create', {
          name: `Automation for: ${values.survey_name}`,
          eventTrigger: values.trigger,
          wait: values.wait,
          eventFilters: [],
          eventAppliedDisjunctively: false,
          customerFilters: [],
          customerAppliedDisjunctively: false,
        });
        if (request?.id) {
          const finalHtml = generateTemplate(values.email_html, values.id);

          if (isDev) {
            console.log(finalHtml);
          }

          // Add our email action to the automation
          await apiRequest('/flows/update', {
            id: request.id,
            name: `Automation for: ${values.survey_name}`,
            eventTrigger: values.trigger,
            wait: values.wait,
            eventFilters: [],
            eventAppliedDisjunctively: false,
            customerFilters: [],
            customerAppliedDisjunctively: false,
            flowSteps: JSON.stringify([
              {
                title: 'Action',
                subtitle: 'Edit to configure',
                type: 'ACTION',
                smart_send: false,
                loyalty_points_change: 0,
                customer_updates: [
                  {
                    type: 'TEXT',
                    key: 'Survey Sent',
                    value: 'Yes'
                  }
                ],
                from_email: values.from_email,
                from_name: values.from_name,
                subject: values.subject,
                preview_text: values.preview_text,
                email_json: vocationTemplateEmailJSON,
                emailType: 'DESIGN',
                email_html: finalHtml,
                sendEmail: true,
                changePoints: false,
                changeCustomer: true,
                survey_frequency: values.survey_frequency,
                exclude_days: values.exclude_days,
                formId: values.id
              }
            ]),
          });

          // Publish it
          await apiRequest('/flows/publish', {
            id: request.id,
          });

          // and finally update our form with the automation ID.
          const { id, survey_name, ...data } = values;
          await apiRequest('/api/form/update', {
            id,
            name: survey_name,
            formJson: JSON.stringify({
              ...data,
              automationId: request.id,
            }),
          });
          toast.success('Survey published');
          navigate('/dashboard/surveys');
        }
        if (org.id === demoId) {
          toast.success('Survey published');
          navigate('/dashboard/surveys');
        }
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getContent = () => {
    if (currentStep === 0) {
      return (
        <WizardSurvey
          onNextStep={handleNextStep}
          values={values}
          setValues={setValues}
        />
      );
    }

    if (currentStep === 1) {
      return (
        <WizardTemplate
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
          values={{
            email_html: values.email_html,
          }}
          setValues={setValues}
        />
      );
    }

    // if (currentStep === 2) {
    //   return (
    //     <WizardQuestions
    //       initialValues={{
    //         form_questions: values.form_questions,
    //         show_nps: values.show_nps,
    //       }}
    //       onPreviousStep={handlePreviousStep}
    //       onNextStep={handleNextStep}
    //     />
    //   );
    // }

    return (
      <WizardConfirmation
        onPreviousStep={handlePreviousStep}
        onComplete={handleSubmit}
        values={values}
        preview={generateTemplate(values.email_html, values.id)}
      />
    );
  };

  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  const queryObject = { from_name: values.from_name, returns_question: values.returns_question, survey_opening_text: values.survey_opening_text, include_vocation: values.include_vocation, survey_closing_text: values.survey_closing_text };
  const query = encodeURIComponent(JSON.stringify(queryObject));

  return (
    <>
      <Helmet>
        <title>New Survey | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/surveys"
                variant="text"
              >
                Return to surveys
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                size="large"
                variant="contained"
                onClick={handleOpenPreviewDialog}
              >
                Send Preview Email
              </Button>
              <Button
                size="large"
                variant="contained"
                href={`https://survey.audiencezen.com/form/0?${query}`}
                target="_blank"
              >
                Preview Form
              </Button>
              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  saveForm();
                }}
              >
                Save as draft
              </Button>
            </Box>
          </Box>
          <Card
            variant="outlined"
          >
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Stepper
                    steps={steps}
                    currentStep={currentStep}
                  />
                  {
                    currentStep === 0
                    && (
                    <Card
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      <CardContent>
                        <Typography variant="h5">FAQ</Typography>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <Typography variant="body2">Exclusion: we recommend a minimum of 90 days.</Typography>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Typography variant="body2">Survey frequency: It is better to spread survey requests over time. The frequency setting allows you to set the sample rate for requesting surveys for any touchpoint event e.g. a setting of 10 means a survey request will be sent for every 10th event for the selected touchpoint.</Typography>
                      </CardContent>
                    </Card>
                    )
                  }
                  {/*
                    currentStep === 2
                    && (
                    <Card
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      <CardContent>
                        <Typography variant="subtitle1">Top Tip</Typography>
                        <Typography variant="body2">The Net Promoter Score (NPS) is a powerful indicator of growth and loyalty. Your ‘promoters’ recommend you to their friends, helping you to acquire new customers through referral.</Typography>
                      </CardContent>
                    </Card>
                    )
                  */}
                </Grid>
                <Grid
                  item
                  md={8}
                  xs={12}
                >
                  {getContent()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <PreviewEmailDialog
        onCancel={() => {
          handleClosePreviewDialog();
        }}
        open={previewDialogOpen}
        selectedEmail={{ email_json: JSON.stringify(vocationTemplateEmailJSON) }}
      />
    </>
  );
};
