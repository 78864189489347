import { Card, Grid, Button, Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/ext-language_tools';
import { useLocation, useNavigate } from 'react-router-dom';

export const CodeEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editor, setEditor] = useState(location?.state?.html ? location.state.html : '');

  const handleChange = (html) => {
    setEditor(html);
  };

  return (
    <>
      <Helmet>
        <title>HTML Editor | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          py: 4
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="flex-end"
            sx={{ pb: '3em', pt: '1em' }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (location?.state?.callback) {
                  const paramsData = location?.state?.callback?.data || {};
                  navigate(location?.state?.callback?.url, {
                    state: {
                      ...paramsData,
                      html: editor,
                    }
                  });
                }
              }}
            >
              Save and Exit
            </Button>
          </Grid>
          {/* button nav bar */}

          {/* END HEADER COMPONENT */}
          <Card>

            <AceEditor
              mode="html"
              theme="sqlserver"
              onChange={handleChange}
              value={editor}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: false }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                resize: true,

              }}
              width="1500px"
              height="700px"
              showPrintMargin={false}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};
