import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Box, Divider, Drawer, List } from '@mui/material';
// import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
// import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SendIcon from '@mui/icons-material/Send';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ForumIcon from '@mui/icons-material/Forum';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import InsightsIcon from '@mui/icons-material/Insights';
import ImageIcon from '@mui/icons-material/Image';
import MoneyIcon from '@mui/icons-material/Money';
import HelpIcon from '@mui/icons-material/Help';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FeedIcon from '@mui/icons-material/Feed';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AbcIcon from '@mui/icons-material/Abc';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { typologyCheck } from '../utils/checks';
import { useAuth } from '../hooks/use-auth';
import { Scrollbar } from './scrollbar';
import { DashboardSidebarItem } from './dashboard-sidebar-item';

export const DashboardSidebar = (props) => {
  const { pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);
  const auth = useAuth();

  const items = [
    {
      icon: DashboardIcon,
      href: '/dashboard/summary',
      title: 'Summary',
      showBusiness: true,
    },
    ...typologyCheck(auth.org) ? [{
      icon: InsightsIcon,
      href: '/dashboard/insights',
      title: 'Insights',
      showBusiness: true,
      items: [
        {
          href: '/dashboard/insights',
          title: 'Summary'
        },
        {
          href: '/dashboard/insights/reports',
          title: 'Reports'
        },
        {
          href: '/dashboard/insights/scheduled',
          title: 'Scheduled'
        },
      ]
    }] : [],
    {
      icon: PeopleIcon,
      href: '/dashboard/customers',
      title: 'Customers',
      showBusiness: true,
    },
    {
      icon: AnalyticsIcon,
      href: '/dashboard/analysis',
      title: 'Analysis',
      showBusiness: true,
    },
    ...typologyCheck(auth.org) ? [{
      icon: AbcIcon,
      href: '/dashboard/typologies',
      title: 'Typologies',
      showBusiness: true,
    }] : [],
    {
      icon: SendIcon,
      href: '/dashboard/campaigns/',
      title: 'Campaigns',
    },
    {
      icon: FeedIcon,
      href: '/dashboard/email-templates',
      title: 'Email Templates',
    },
    {
      icon: FlashOnIcon,
      href: '/dashboard/automations',
      title: 'Automations',
    },
    {
      icon: ForumIcon,
      href: '/dashboard/surveys',
      title: 'Surveys',
      showBusiness: true,
      items: [
        {
          href: '/dashboard/surveys',
          title: 'Summary',
        },
        {
          href: '/dashboard/surveys/referrals',
          title: 'Reports',
        },
        {
          href: '/dashboard/surveys/drilldown',
          title: 'Drilldown',
        },
      ]
    },
    {
      icon: LoyaltyIcon,
      href: '/dashboard/loyalty',
      title: 'Loyalty',
      divider: true
    },
    {
      icon: MoneyIcon,
      href: '/dashboard/discounts',
      title: 'Discounts'
    },
    {
      icon: ImageIcon,
      href: '/dashboard/assets',
      title: 'Assets'
    },
    {
      icon: AddShoppingCartIcon,
      href: '/dashboard/product-feed',
      title: 'Product Feed'
    },
    {
      icon: DashboardIcon,
      href: '/dashboard/getting-started',
      title: 'Getting Started',
      hideOnboard: true,
      showOnboard: true,
    },
    {
      icon: StorefrontIcon,
      href: '/dashboard/organization',
      title: 'Organization',
      adminOnly: true,
      showOnboard: true,
      showBusiness: true,
      items: [
        {
          href: '/dashboard/organization',
          title: 'General Settings'
        },
        {
          href: '/dashboard/organization/team',
          title: 'Team'
        },
        {
          href: '/dashboard/organization/billing',
          title: 'Billing'
        },
        {
          href: '/dashboard/organization/integrations',
          title: 'Integrations'
        },
        {
          href: '/dashboard/organization/scripts',
          title: 'Tracking & Website Scripts'
        }
      ]
    },
    {
      icon: HelpIcon,
      href: 'https://audiencezen.zendesk.com/hc/en-us',
      external: true,
      showOnboard: true,
      showBusiness: true,
      title: 'Help'
    },
  ];

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = typeof item.items[index].href !== 'object' && matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = typeof item.href !== 'object' && !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <List disablePadding>
            {items.filter((item) => {
              if (!item?.showBusiness && auth?.org?.accountType === 'Business') {
                return false;
              }
              if (item?.adminOnly && !auth?.org?.access?.includes('admin')) {
                return false;
              }
              if (auth?.org?.data_connection_status !== 'ACTIVE' && auth?.org?.accountType !== 'Business' && !item?.showOnboard) {
                return false;
              }
              if ((auth?.org?.data_connection_status === 'ACTIVE' || auth?.org?.accountType === 'Business') && item?.hideOnboard) {
                return false;
              }

              return true;
            }).map((item) => (
              <React.Fragment key={item.title}>
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  key={item.title}
                  onOpen={() => handleOpenItem(item)}
                  open={openedItem?.title === item.title && (hovered || pinned)}
                  pinned={pinned}
                  {...item}
                />
                { item.divider ? <Divider sx={{ pt: 2, mb: 2 }} /> : <></> }
              </React.Fragment>
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          {
            /*
            <Divider />
            <Box sx={{ pt: 1 }}>
              <IconButton onClick={onPin}>
                {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Box>
            */
          }
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
