const sanityClient = require('@sanity/client');

const client = sanityClient({
  projectId: 'c9wjyiqc',
  dataset: 'production',
  apiVersion: '2022-03-25',
  useCdn: false // We can't use the CDN for writing
});

export default client;
