import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Divider,
  Button
} from '@mui/material';
import { useState } from 'react';
import { format, differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from '../scrollbar';
import { PagelessPagination } from '../pageless-pagination';
import { applyPagination } from '../../utils/apply-pagination';

const columnHeadings = ['Respondent', 'Date completed', 'NPS status', 'Preferred vendor score', 'Customer status'];

export const RespondentTable = ({ dataProp }) => {
  const [controller, setController] = useState({
    page: 1,
  });
  const navigate = useNavigate();
  const data = dataProp.map((x) => ({ ...x, customer_status: differenceInDays(new Date(), new Date(x.created_at)) <= 90 ? 'Active' : 'Inactive' }));

  const handleSortChange = (property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };
  const collator = new Intl.Collator();
  switch (controller.sort) {
    case 'asc':
      switch (controller.sortBy) {
        case 'Respondent':
          data.sort((a, b) => collator.compare(b.email, a.email));
          break;
        case 'Date completed':
          data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          break;
        case 'NPS status':
          data.sort((a, b) => collator.compare(b.nps, a.nps));
          break;

        case 'Preferred vendor score':
          data.sort((a, b) => b.preferred_vendor - a.preferred_vendor);
          break;

        case 'Customer status':
          data.sort((a, b) => collator.compare(b.customer_status, a.customer_status));
          break;

        default:
          break;
      }
      break;

    case 'desc':
      switch (controller.sortBy) {
        case 'Respondent':
          data.sort((a, b) => collator.compare(a.email, b.email));
          break;
        case 'Date completed':
          data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
          break;
        case 'NPS status':
          data.sort((a, b) => collator.compare(a.nps, b.nps));
          break;

        case 'Preferred vendor score':
          data.sort((a, b) => a.preferred_vendor - b.preferred_vendor);
          break;

        case 'Customer status':
          data.sort((a, b) => collator.compare(a.customer_status, b.customer_status));
          break;

        default:
          break;
      }
      break;

    default:
      break;
  }

  const paginatedData = applyPagination(data, controller.page - 1, 10);
  return (
    <Card
      variant="outlined"
    >
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {columnHeadings.map((column) => (
                <TableCell
                  key={column}
                  padding="normal"
                >

                  <TableSortLabel
                    active={controller.sortBy === column}
                    direction={controller.sortBy === column ? controller.sort : 'asc'}
                        //  disabled={performanceData.isLoading}
                    onClick={() => handleSortChange(column)}
                  >
                    {column}
                  </TableSortLabel>

                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* replace indexed key */}
            {paginatedData.map((item, i) => (
              <TableRow
                key={i}
              >
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                  {item.created_at ? format(new Date(item.created_at), 'PPpp') : 'No created at date'}
                </TableCell>
                <TableCell>
                  {item.nps && item.nps !== null ? `${item.nps}` : 'Not Answered' }
                </TableCell>
                <TableCell>
                  {item.preferred_vendor && item.preferred_vendor !== null ? `${item.preferred_vendor}%` : 'Not Answered' }
                </TableCell>
                <TableCell>
                  {item.customer_status}
                </TableCell>
                <TableCell>

                  <Button
                    sx={{ textDecoration: 'none', color: 'inherit', fontSize: '14px' }}
                    onClick={() => navigate('/dashboard/surveys/drilldown/respondent', {
                      state: {
                        item
                      }
                    })}
                  >
                    Open
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider sx={{ mt: 'auto' }} />
        <PagelessPagination
        // disabled={isLoading}
          onPageChange={handlePageChange}
          page={controller.page}
          isLastPage={(data.length / 10) <= controller.page}
        />
      </Scrollbar>
    </Card>
  );
};

RespondentTable.propTypes = {
  dataProp: PropTypes.arrayOf(PropTypes.object),
};
