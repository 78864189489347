import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Container, Card, CardContent, Grid, Typography, Table, TableBody, TableRow } from '@mui/material';
import { Link as RouterLink, useLocation, Navigate } from 'react-router-dom';

import { format } from 'date-fns';
import { RadarChart } from '../components/survey/drilldown/radar-chart';
/* Utils */
import gtm from '../lib/gtm';

export const SurveyRespondent = () => {
  const location = useLocation();
  const respondentData = location?.state?.item || null;
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  if (location.state) {
    if (!location.state.item) {
      return (
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress
            color="primary"
            size={100}
          />
        </Box>
      );
    }
  } else {
    return <Navigate to="/dashboard/surveys/drilldown/" />;
  }
  return (
    <>
      <Helmet>
        <title>Survey Drilldown | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mb: 1,
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                >
                  Respondent Breakdown
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  component={RouterLink}
                  to="/dashboard/surveys/drilldown"
                >
                  Back
                </Button>
              </Box>
            </Grid>
            {/* Respondent data */}
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  height: '100%'
                }}
              >
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <td>
                          <Typography
                            variant="subtitle3"
                            sx={{ display: 'inline' }}
                          >
                            Respondent:
                            {' '}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {respondentData?.first_name}
                            {' '}
                            {respondentData?.last_name}
                          </Typography>
                        </td>
                      </TableRow>

                      <TableRow>
                        <td>
                          <Typography
                            variant="subtitle3"
                            sx={{ display: 'inline' }}
                          >
                            Response date:
                            {' '}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {format(new Date(respondentData.created_at), 'PPpp')}
                          </Typography>
                        </td>
                      </TableRow>

                      <TableRow>
                        <td>
                          <Typography
                            variant="subtitle3"
                            sx={{ display: 'inline' }}
                          >
                            NPS Status:
                            {' '}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {respondentData.nps}
                          </Typography>
                        </td>
                      </TableRow>

                      <TableRow>
                        <td>
                          <Typography
                            variant="subtitle3"
                            sx={{ display: 'inline' }}
                          >
                            Preferred vendor score:
                            {' '}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {respondentData.preferred_vendor}
                            %
                          </Typography>
                        </td>
                      </TableRow>

                      <TableRow>
                        <td>
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {`This customer is in the top ${Math.round(respondentData.spent_percentile * 100)}% of all survey respondents by total spent`}
                          </Typography>
                        </td>
                      </TableRow>

                      <TableRow>
                        <td>
                          {' '}
                          <Typography
                            variant="body2"
                            sx={{ display: 'inline' }}
                          >
                            {`This customer is in the ${Math.round(respondentData.discount_percentile * 100)}% of all  survey respondents by lowest discounting`}
                          </Typography>
                        </td>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            {/* Radar Chart */}
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              xs={12}
            >

              <RadarChart data={respondentData} />

            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              {/* Respondent Comment */}
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    variant="subtitle3"
                  >
                    Comment:
                  </Typography>
                  <Typography
                    variant="body2"
                  >
                    {respondentData.comments}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  );
};
