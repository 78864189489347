import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Switch, Box, Typography, Grid, Button, Divider, InputAdornment, MenuItem } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// CSS FOR QUILL
import '../components/campaign/quillStyles.css';

/* Icons */
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PublicIcon from '@mui/icons-material/Public';
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

/* Components */
import { Trash as TrashIcon } from '../icons/trash';
import { InputField } from '../components/input-field';
import EditorToolbar, { modules, formats } from '../components/campaign/editor-toolbar';
import { HtmlEditor } from '../components/email/html-editor';
import { ProductComponent } from '../components/email/products-component';
import { ColorPicker } from '../components/color-picker';
import { EmailImageUploader } from '../components/email/email-image-uploader';

const blockDefaultProperties = {
  padding_top: 0,
  padding_bottom: 0,
  padding_left: 0,
  padding_right: 0,
  background_color: '',
  border_style: 'none',
  border_width: 3,
  border_color: '#000000',
  conditional_display: 'Both',
};

const textDefaultProperties = {
  font_family: 'Arial',
  font_size: '14px',
  line_height: '24px',
  font_weight: 'normal',
  align: 'left',
  text_color: '#000000',
};

const repeatDefaultProperties = {
  repeat_enabled: false,
  repeat_for: 'event.items',
  repeat_item: 'item',

};

const blockDefaultFields = [
  // {
  //   fieldType: 'header3',
  //   label: 'Block',
  // },
  {
    fieldName: 'padding_top',
    fieldType: 'number',
    label: 'Padding Top',
    suffix: 'px',
    size: 6,
  },
  {
    fieldName: 'padding_bottom',
    fieldType: 'number',
    label: 'Padding Bottom',
    suffix: 'px',
    size: 6,
  },
  {
    fieldName: 'padding_left',
    fieldType: 'number',
    label: 'Padding Left',
    suffix: 'px',
    size: 6,
  },
  {
    fieldName: 'padding_right',
    fieldType: 'number',
    label: 'Padding Right',
    suffix: 'px',
    size: 6,
  },
  {
    fieldName: 'background_color',
    fieldType: 'color',
    label: 'Background Color',
    size: 6,
  },
  {
    fieldName: 'border_style',
    fieldType: 'select',
    label: 'Border Style',
    size: 6,
    options: ['none', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge']
  },
  {
    fieldName: 'border_width',
    fieldType: 'number',
    label: 'Border Thickness',
    suffix: 'px',
    size: 6,
  },
  {
    fieldName: 'border_color',
    fieldType: 'color',
    label: 'Border Color',
    size: 6,
  },
  {
    fieldName: 'conditional_display',
    fieldType: 'select',
    label: 'Conditional Display',
    size: 6,
    options: ['Both', 'Desktop Only', 'Mobile Only'],
  },
];

const textDefaultFields = [
  {
    fieldType: 'header3',
    label: 'Text',
  },
  {
    fieldName: 'align',
    fieldType: 'select',
    label: 'Align',
    size: 6,
    options: ['left', 'right', 'center', 'justify'],
  },
  {
    fieldName: 'text_color',
    fieldType: 'color',
    label: 'Text Color',
    size: 6,
  },
  {
    fieldName: 'font_family',
    fieldType: 'select',
    // helperText: 'Lorem ipsum',
    label: 'Font Family',
    size: 6,
    options: [
      'Arial',
      'Arial Black',
      'Century Gothic',
      'Comic Sans MS',
      'Courier',
      'Courier New',
      'Georgia',
      'Geneva',
      'Helvetica',
      'MS Serif',
      'New York',
      'Palatino',
      'Tahoma',
      'Times New Roman',
      'Trebuchet MS',
      'Verdana',
    ],
  },
  {
    fieldName: 'font_size',
    fieldType: 'select',
    label: 'Font Size',
    size: 6,
    options: ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '32px', '48px', '72px'],
  },
  {
    fieldName: 'line_height',
    fieldType: 'select',
    label: 'Line Height',
    size: 6,
    options: ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '32px', '48px', '72px'],
  },
  {
    fieldName: 'font_weight',
    fieldType: 'select',
    label: 'Font Weight',
    size: 6,
    options: ['normal', 'bolder', 'lighter']
  },
];

const repeatDefaultFields = [
  {
    fieldType: 'header',
    label: 'Repeat this block',
  },
  {
    fieldType: 'paragraph',
    label: 'To repeat a block, specify the name of the variable you would like to loop over, and the alias you will use in your template.',
  },
  {
    fieldName: 'repeat_enabled',
    fieldType: 'checkbox',
    label: 'Enable',
  },
  {
    fieldName: 'repeat_for',
    fieldType: 'text',
    label: 'Loop For',
    helperText: 'e.g. event.items, event.line_items, event.products, or event.rows.'
  },
  {
    fieldName: 'repeat_item',
    fieldType: 'text',
    label: 'Loop Item Alias',
  },
];

export const EmailSettings = {
  defaultProperties: {
    unsubscribe_message: 'No longer want to receive these emails? {{ unsubscribe }}.<br />\n{{ organization.name }}<br />\n{{ organization.address }}',
    width: 600,
    border_radius: 0,
    email_background: '#F9FAFB',
    ...blockDefaultProperties,
  },
  fields: [
    {
      fieldName: 'unsubscribe_message',
      fieldType: 'html',
      label: 'Unsubscribe Message',
      height: '200px',
    },
    {
      fieldType: 'paragraph',
      label: 'Your unsubscribe message appears at the bottom of the email. If your unsubscribe message is missing the unsubscribe tag will be automatically added to the bottom of the email.',
    },
    {
      fieldName: 'width',
      fieldType: 'number',
      label: 'Email Width',
      suffix: 'px',
      size: 6,
    },
    {
      fieldName: 'border_radius',
      fieldType: 'number',
      label: 'Email Border Radius',
      suffix: 'px',
      size: 6,
    },
    {
      fieldName: 'email_background',
      fieldType: 'color',
      label: 'Email Background Color',
      size: 12,
    },
    ...blockDefaultFields,
  ],
};

export const EmailComponents = [
  {
    id: 'TEXT',
    blockName: 'Text Block',
    defaultProperties: {
      content: '<p>This is a new text block.</p>',
      ...textDefaultProperties,
      ...blockDefaultProperties,
    },
    fields: [
      {
        fieldName: 'content',
        fieldType: 'rte',
      },
      {
        fieldType: 'header',
        label: 'Default Styles',
      },
      ...textDefaultFields,
      {
        fieldType: 'header3',
        label: 'Block',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<TextFieldsIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'IMAGE',
    blockName: 'Image Block',
    defaultProperties: {
      image: '',
      alt: '',
      link: '',
      image_width: '',
      image_height: '',
      image_radius: 0,
      align: 'center',
      image_padding_top: 25,
      image_padding_bottom: 25,
      image_padding_left: 10,
      image_padding_right: 10,
      ...blockDefaultProperties,
    },
    fields: [
      {
        fieldName: 'image',
        fieldType: 'image',
        label: 'Upload an image'
      },
      {
        fieldName: 'alt',
        fieldType: 'text',
        label: 'Alt Text'
      },
      {
        fieldName: 'link',
        fieldType: 'text',
        label: 'Link',
        helperText: 'e.g. https://example.com'
      },
      {
        fieldType: 'header',
        label: 'Default Styles',
      },
      {
        fieldType: 'header3',
        label: 'Image',
      },
      {
        fieldName: 'align',
        fieldType: 'select',
        label: 'Align',
        size: 6,
        options: ['left', 'right', 'center'],
      },
      {
        fieldName: 'image_radius',
        fieldType: 'number',
        label: 'Image Radius',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_width',
        fieldType: 'number',
        label: 'Image Width',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_height',
        fieldType: 'number',
        label: 'Image Height',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_padding_top',
        fieldType: 'number',
        label: 'Padding Top',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_padding_bottom',
        fieldType: 'number',
        label: 'Padding Bottom',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_padding_left',
        fieldType: 'number',
        label: 'Padding Left',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_padding_right',
        fieldType: 'number',
        label: 'Padding Right',
        suffix: 'px',
        size: 6,
      },
      {
        fieldType: 'header3',
        label: 'Block',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<PhotoCameraIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  // IWT COMPONENT
  {
    id: 'GALLERY',
    blockName: 'Gallery Items Block',
    defaultProperties: {
      galleryItems: [{
        type: 'Gallery Item',
        properties: {
          image: '',
          image_width: '140',
          image_height: '140',
          alt: '',
          include_image: true,
          include_text: true,
          image_alignment: 'Top',
          content: '<p>This is a new text block.</p>',
          column_width: '50%',
          ...textDefaultProperties,
          ...blockDefaultProperties,
        }
      }],
      ...blockDefaultProperties,
    },
    fields: [
      {
        fieldName: 'galleryItems',
        fieldType: 'gallery',
        label: 'Gallery Items'
      },
      {
        fieldType: 'header',
        label: 'Block Styles',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<PhotoCameraIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  // IWT COMPONENT
  {
    id: 'COLUMNS',
    blockName: 'Columns Block',
    defaultProperties: {
      columns: [
        {
          columnType: 'TEXT',
          properties: {
            column_width: '50%',
            column_vertical_align: 'top',
            content: '<p>This is a new text block.</p>',
            ...textDefaultProperties,
            ...blockDefaultProperties,
          }
        },
        {
          columnType: 'TEXT',
          properties: {
            column_width: '50%',
            column_vertical_align: 'top',
            content: '<p>This is a new text block.</p>',
            ...textDefaultProperties,
            ...blockDefaultProperties,
          }
        }
      ],
      ...blockDefaultProperties,
      ...repeatDefaultProperties,
    },
    fields: [
      {
        fieldName: 'columns',
        fieldType: 'columns',
        label: 'Columns'
      },
      {
        fieldType: 'header',
        label: 'Block Styles',
      },
      ...blockDefaultFields,
      ...repeatDefaultFields,
    ],
    icon: () => (<VerticalSplitIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'BUTTON',
    blockName: 'Button Block',
    defaultProperties: {
      text: 'Learn More',
      link: '#',
      button_radius: 3,
      button_background_color: '#000000',
      button_width: '',
      button_height: '',
      button_full_width: false,
      button_border_style: 'none',
      button_border_width: 3,
      button_border_color: '#000000',
      ...textDefaultProperties,
      ...blockDefaultProperties,
      text_color: '#ffffff',
      align: 'center',
    },
    fields: [
      {
        fieldName: 'text',
        fieldType: 'text',
        label: 'Button Text',
      },
      {
        fieldName: 'link',
        fieldType: 'text',
        label: 'Button Link',
        helperText: 'e.g. https://example.com'
      },
      {
        fieldType: 'header',
        label: 'Default Styles',
      },
      {
        fieldName: 'button_height',
        fieldType: 'number',
        label: 'Button Height',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_width',
        fieldType: 'number',
        label: 'Button Width',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_radius',
        fieldType: 'number',
        label: 'Button Radius',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_full_width',
        fieldType: 'checkbox',
        label: 'Full Width Button',
      },
      {
        fieldName: 'button_background_color',
        fieldType: 'color',
        label: 'Button Background Color',
        size: 6,
      },
      {
        fieldName: 'button_border_style',
        fieldType: 'select',
        label: 'Button Border Style',
        size: 6,
        options: ['none', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge']
      },
      {
        fieldName: 'button_border_width',
        fieldType: 'number',
        label: 'Button Border Thickness',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_border_color',
        fieldType: 'color',
        label: 'Button Border Color',
        size: 6,
      },
      ...textDefaultFields,
      {
        fieldType: 'header3',
        label: 'Block',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<SmartButtonIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'DIVIDER',
    blockName: 'Divider Block',
    defaultProperties: {
      ...blockDefaultProperties,
      border_style: 'solid',
    },
    fields: [
      ...blockDefaultFields
    ],
    icon: () => (<HorizontalRuleIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'SPACER',
    blockName: 'Spacer Block',
    defaultProperties: {
      padding_top: 20,
      padding_bottom: 20,
      padding_left: 0,
      padding_right: 0,
      background_color: '',
      height: 20,
    },
    fields: [
      {
        fieldName: 'height',
        fieldType: 'number',
        label: 'Spacer Height',
        suffix: 'px',
        size: 6,
      },
      {
        fieldType: 'header3',
        label: 'Block',
      },
      {
        fieldName: 'padding_top',
        fieldType: 'number',
        label: 'Padding Top',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'padding_bottom',
        fieldType: 'number',
        label: 'Padding Bottom',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'padding_left',
        fieldType: 'number',
        label: 'Padding Left',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'padding_right',
        fieldType: 'number',
        label: 'Padding Right',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'background_color',
        fieldType: 'color',
        label: 'Background Color',
        size: 6,
      },
    ],
    icon: () => (<SpaceBarIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  // {
  //   id: 'TABLE',
  //   blockName: 'Table Block',
  //   defaultProperties: {
  //     field: ''
  //   },
  //   icon: () => (<TableChartIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  // },
  {
    id: 'PRODUCTS',
    blockName: 'Products Block',
    defaultProperties: {
      feed: null,
      // layout
      rows: 1,
      columns: 3,
      include_image: true,
      include_title: true,
      include_price: true,
      include_button: true,
      // block
      ...blockDefaultProperties,
      padding_left: 10,
      padding_right: 10,
      // image
      image_height: '',
      image_width: '',
      image_padding_top: 10,
      image_padding_bottom: 10,
      image_padding_left: 10,
      image_padding_right: 10,
      image_align: 'center',
      image_radius: 0,
      image_background_color: '#ffffff',
      image_border_style: 'none',
      image_border_width: 3,
      image_border_color: '#000000',
      // title
      title_padding_top: 0,
      title_padding_bottom: 5,
      title_padding_left: 10,
      title_padding_right: 10,
      title_font_family: 'Arial',
      title_font_size: '14px',
      title_line_height: '24px',
      title_font_weight: 'bolder',
      title_align: 'center',
      title_text_color: '#000000',
      // price
      price_padding_top: 0,
      price_padding_bottom: 5,
      price_padding_left: 10,
      price_padding_right: 10,
      price_font_family: 'Arial',
      price_font_size: '14px',
      price_line_height: '14px',
      price_font_weight: 'normal',
      price_align: 'center',
      price_text_color: '#000000',
      // button
      button_text: 'Shop Now',
      button_radius: 3,
      button_background_color: '#000000',
      button_width: '',
      button_height: '',
      button_full_width: false,
      button_border_style: 'none',
      button_border_width: 3,
      button_border_color: '#000000',
      button_font_family: 'Arial',
      button_font_size: '14px',
      button_line_height: '24px',
      button_font_weight: 'bolder',
      button_align: 'center',
      button_text_color: '#FFFFFF',
    },
    fields: [
      {
        fieldType: 'paragraph',
        label: (
          <span>
            Select a product feed to display. Create a new product feed
            {' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/dashboard/product-feed"
            >
              here
            </a>
            .
          </span>
        ),
      },
      {
        fieldName: 'feed',
        fieldType: 'productfeed',
        label: 'Product Feed',
      },
      // Layout
      {
        fieldType: 'header',
        label: 'Layout',
      },
      {
        fieldName: 'rows',
        fieldType: 'select',
        label: 'Rows',
        size: 6,
        options: [1, 2, 3, 4]
      },
      {
        fieldName: 'columns',
        fieldType: 'select',
        label: 'Columns',
        size: 6,
        options: [1, 2, 3]
      },
      {
        fieldName: 'include_image',
        fieldType: 'checkbox',
        label: 'Show product image?',
      },
      {
        fieldName: 'include_title',
        fieldType: 'checkbox',
        label: 'Show product title?',
      },
      {
        fieldName: 'include_price',
        fieldType: 'checkbox',
        label: 'Show product price?',
      },
      {
        fieldName: 'include_button',
        fieldType: 'checkbox',
        label: 'Show product button?',
      },
      ...blockDefaultFields,
      // Image
      {
        fieldType: 'header',
        label: 'Product Image',
      },
      {
        fieldName: 'image_height',
        fieldType: 'number',
        label: 'Image Height',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_width',
        fieldType: 'number',
        label: 'Image Width',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'image_align',
        fieldType: 'select',
        label: 'Align',
        size: 6,
        options: ['left', 'right', 'center'],
      },
      {
        fieldName: 'image_radius',
        fieldType: 'number',
        label: 'Radius',
        size: 6,
        suffix: 'px'
      },
      ...[...blockDefaultFields].map((x) => ({ ...x, fieldName: `image_${x.fieldName}` })),
      // Title
      {
        fieldType: 'header',
        label: 'Product Title',
      },
      {
        fieldName: 'title_padding_top',
        fieldType: 'number',
        label: 'Padding Top',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'title_padding_bottom',
        fieldType: 'number',
        label: 'Padding Bottom',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'title_padding_left',
        fieldType: 'number',
        label: 'Padding Left',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'title_padding_right',
        fieldType: 'number',
        label: 'Padding Right',
        suffix: 'px',
        size: 6,
      },
      ...[...textDefaultFields].map((x) => ({ ...x, fieldName: `title_${x.fieldName}` })),
      // Price
      {
        fieldType: 'header',
        label: 'Product Price',
      },
      {
        fieldName: 'price_padding_top',
        fieldType: 'number',
        label: 'Padding Top',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'price_padding_bottom',
        fieldType: 'number',
        label: 'Padding Bottom',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'price_padding_left',
        fieldType: 'number',
        label: 'Padding Left',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'price_padding_right',
        fieldType: 'number',
        label: 'Padding Right',
        suffix: 'px',
        size: 6,
      },
      ...[...textDefaultFields].map((x) => ({ ...x, fieldName: `price_${x.fieldName}` })),
      // Button
      {
        fieldType: 'header',
        label: 'Product Button',
      },
      {
        fieldName: 'button_text',
        fieldType: 'text',
        label: 'Button Text',
      },
      {
        fieldName: 'button_height',
        fieldType: 'number',
        label: 'Button Height',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_width',
        fieldType: 'number',
        label: 'Button Width',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_radius',
        fieldType: 'number',
        label: 'Button Radius',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_full_width',
        fieldType: 'checkbox',
        label: 'Full Width Button',
      },
      {
        fieldName: 'button_background_color',
        fieldType: 'color',
        label: 'Button Background Color',
        size: 6,
      },
      {
        fieldName: 'button_border_style',
        fieldType: 'select',
        label: 'Button Border Style',
        size: 6,
        options: ['none', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge']
      },
      {
        fieldName: 'button_border_width',
        fieldType: 'number',
        label: 'Button Border Thickness',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'button_border_color',
        fieldType: 'color',
        label: 'Button Border Color',
        size: 6,
      },
      ...[...textDefaultFields].map((x) => ({ ...x, fieldName: `button_${x.fieldName}` })),
    ],
    icon: () => (<LocalOfferIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'SOCIALS',
    blockName: 'Socials Block',
    defaultProperties: {
      facebook_link: 'https://example.com',
      twitter_link: '',
      google_link: '',
      pinterest_link: '',
      linkedin_link: '',
      tumblr_link: '',
      instagram_link: '',
      web_link: '',
      snapchat_link: '',
      youtube_link: '',
      facebook_text: '',
      twitter_text: '',
      google_text: '',
      pinterest_text: '',
      linkedin_text: '',
      tumblr_text: '',
      instagram_text: '',
      web_text: '',
      snapchat_text: '',
      youtube_text: '',
      icon_size: 20,
      icon_padding: 4,
      icon_radius: 3,
      icon_background: '',
      mode: 'horizontal',
      ...textDefaultProperties,
      ...blockDefaultProperties,
    },
    fields: [
      {
        fieldType: 'header',
        label: 'Social Links',
      },
      {
        fieldName: 'facebook_link',
        fieldType: 'text',
        label: 'Facebook Link',
      },
      {
        fieldName: 'twitter_link',
        fieldType: 'text',
        label: 'Twitter Link',
      },
      {
        fieldName: 'pinterest_link',
        fieldType: 'text',
        label: 'Pinterest Link',
      },
      {
        fieldName: 'linkedin_link',
        fieldType: 'text',
        label: 'LinkedIn Link',
      },
      {
        fieldName: 'instagram_link',
        fieldType: 'text',
        label: 'Instagram Link',
      },
      {
        fieldName: 'web_link',
        fieldType: 'text',
        label: 'Web Link',
      },
      {
        fieldName: 'snapchat_link',
        fieldType: 'text',
        label: 'Snapchat Link',
      },
      {
        fieldName: 'youtube_link',
        fieldType: 'text',
        label: 'YouTube Link',
      },
      {
        fieldType: 'header',
        label: 'Social Labels',
      },
      {
        fieldName: 'facebook_text',
        fieldType: 'text',
        label: 'Facebook Text',
      },
      {
        fieldName: 'twitter_text',
        fieldType: 'text',
        label: 'Twitter Text',
      },
      {
        fieldName: 'pinterest_text',
        fieldType: 'text',
        label: 'Pinterest Text',
      },
      {
        fieldName: 'linkedin_text',
        fieldType: 'text',
        label: 'LinkedIn Text',
      },
      {
        fieldName: 'instagram_text',
        fieldType: 'text',
        label: 'Instagram Text',
      },
      {
        fieldName: 'web_text',
        fieldType: 'text',
        label: 'Web Text',
      },
      {
        fieldName: 'snapchat_text',
        fieldType: 'text',
        label: 'Snapchat Text',
      },
      {
        fieldName: 'youtube_text',
        fieldType: 'text',
        label: 'YouTube Text',
      },
      {
        fieldType: 'header',
        label: 'Default Styles',
      },
      {
        fieldType: 'header3',
        label: 'Icon styles',
      },
      {
        fieldName: 'icon_size',
        fieldType: 'number',
        label: 'Icont Size',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'icon_radius',
        fieldType: 'number',
        label: 'Icon Border Radius',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'icon_padding',
        fieldType: 'number',
        label: 'Icon Padding',
        suffix: 'px',
        size: 6,
      },
      {
        fieldName: 'icon_background',
        fieldType: 'color',
        label: 'Icon Background Color',
        size: 6,
      },
      {
        fieldName: 'mode',
        fieldType: 'select',
        label: 'Orientation',
        size: 6,
        options: ['horizontal', 'vertical']
      },
      ...textDefaultFields,
      {
        fieldType: 'header3',
        label: 'Block',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<PublicIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
  {
    id: 'CODE',
    blockName: 'Raw HTML Block',
    defaultProperties: {
      content: '<p>Add your code...</p>',
      ...textDefaultProperties,
      ...blockDefaultProperties,
    },
    fields: [
      {
        fieldName: 'content',
        fieldType: 'html',
      },
      {
        fieldType: 'header',
        label: 'Default Styles',
      },
      ...textDefaultFields,
      {
        fieldType: 'header3',
        label: 'Block',
      },
      ...blockDefaultFields,
    ],
    icon: () => (<CodeIcon style={{ color: '#A9AFB4', fontSize: 40 }} />)
  },
];

export const sanitizeHex = (hex, defaultHex = '#FFFFFF') => (/^#[0-9A-F]{6}$/i.test(hex) ? hex : defaultHex);

export const sanitizeBorder = (style, width, hex) => {
  if (style && style !== 'none' && width && hex && sanitizeHex(hex, null) !== null) {
    return `${width}px ${style} ${sanitizeHex(hex, '#000000')}`;
  }
  return null;
};

export const renderField = (properties, value = undefined, callback = undefined) => {
  switch (properties.fieldType) {
    case 'columns':
      return (
        <ColumnsField
          value={value}
          callback={callback}
        />
      );
    case 'gallery':
      return (
        <GalleryField
          value={value}
          callback={callback}
        />
      );
    case 'image':
      return (
        <EmailImageUploader
          properties={properties}
          value={value}
          callback={callback}
        />

      );
    case 'html':
      return (
        <>
          {
            properties.label
            && <Typography variant="subtitle2">{properties.label}</Typography>
          }
          <HtmlEditor
            name={properties.editorName ? properties.editorName : properties.fieldName}
            value={value}
            onChange={(html) => {
              callback(html, properties.fieldName);
            }}
            height={properties.height ? properties.height : '400px'}
            width={properties.width ? properties.width : '100%'}
          />
        </>
      );
    case 'rte':
      return (
        <Box
          sx={{ maxHeight: '600px', overflowY: 'auto' }}
          data-text-editor={properties.editorName ? properties.editorName : properties.fieldName}
        >
          <EditorToolbar />
          <ReactQuill
            value={value}
            onChange={(html) => callback(html, properties.fieldName)}
            placeholder="Enter your text"
            modules={modules}
            formats={formats}
            bounds={`[data-text-editor="${properties.editorName ? properties.editorName : properties.fieldName}"]`}
          />
        </Box>
      );
    case 'color':
      return (
        <ColorPicker
          properties={properties}
          callback={callback}
          value={value}
        />
      );
    case 'checkbox':
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Switch
            color="primary"
            name={properties.fieldName}
            checked={value}
            onChange={(event) => callback(event.target.checked, properties.fieldName)}
          />
          <Typography
            color="textPrimary"
            variant="body2"
          >
            {properties.label}
          </Typography>
        </Box>
      );
    case 'text':
      return (
        <InputField
          fullWidth
          helperText={properties.helperText}
          label={properties.label}
          name={properties.fieldName}
          onChange={(event) => callback(event.target.value, properties.fieldName)}
          value={value}
        />
      );
    case 'number':
      return (
        <InputField
          fullWidth
          helperText={properties.helperText}
          label={properties.label}
          name={properties.fieldName}
          onChange={(event) => callback(event.target.value, properties.fieldName)}
          type="number"
          value={value}
          InputProps={{
            min: '0',
            startAdornment: properties.prefix ? (
              <InputAdornment position="start">
                {properties.prefix}
              </InputAdornment>
            ) : undefined,
            endAdornment: properties.suffix ? (
              <InputAdornment position="start">
                {properties.suffix}
              </InputAdornment>
            ) : undefined,
          }}
        />
      );
    case 'productfeed':
      return (
        <ProductComponent
          properties={properties}
          value={value}
          callback={callback}
        />
      );
    case 'select':
      return (
        <InputField
          fullWidth
          helperText={properties.helperText}
          label={properties.label}
          name={properties.fieldName}
          onChange={(event) => callback(event.target.value, properties.fieldName)}
          select
          value={value}
          InputProps={(properties.prefix || properties.suffix) ? {
            startAdornment: properties.prefix ? (
              <InputAdornment position="start">
                {properties.prefix}
              </InputAdornment>
            ) : undefined,
            endAdornment: properties.suffix ? (
              <InputAdornment position="start">
                {properties.suffix}
              </InputAdornment>
            ) : undefined,
          } : undefined}
        >
          {properties.options.map((option) => {
            if (typeof option === 'object') {
              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            );
          })}
        </InputField>
      );
    case 'header':
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="subtitle1">{properties.label}</Typography>
          <Divider />
        </Box>
      );
    case 'header2':
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="subtitle2">{properties.label}</Typography>
          <Divider />
        </Box>
      );
    case 'header3':
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="subtitle2">{properties.label}</Typography>
        </Box>
      );
    case 'paragraph':
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="body2">{properties.label}</Typography>
        </Box>
      );
    default:
      console.log('renderField: fieldType not recongised', properties);
      return (<></>);
  }
};

export const attr = (field, config = {}, value = undefined, fieldDefault = undefined) => {
  if (!field) {
    return '';
  }
  if (value === undefined || value === '') {
    if (fieldDefault === undefined) {
      return '';
    }
    return `${field}="${config.prefix ? config.prefix : ''}${fieldDefault}${config.suffix ? config.suffix : ''}"`;
  }
  return `${field}="${config.prefix ? config.prefix : ''}${value}${config.suffix ? config.suffix : ''}"`;
};

export const attributes = (arr) => arr.map((x) => attr(x.field, x.config, x.value, x.default)).join(' ');

const productSection = (obj, properties) => `
              <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
  ])}>
${obj}
</mj-section>
          `;

const productColumn = (obj, properties) => `
<mj-column>
  ${
  properties.include_image
  && `
  <mj-image ${attributes([
    {
      field: 'alt',
      config: {},
      value: properties.alt
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.image_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.image_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.image_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.image_padding_right
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.image_radius
    },
    {
      field: 'width',
      config: { suffix: 'px' },
      value: properties.image_width
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.image_height
    },
    {
      field: 'align',
      config: {},
      value: properties.image_align
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.image_border_style, properties.image_border_width, properties.image_border_color)
    },
  ])} src="{{ ${obj}.image_src }}" />
  `}

  ${
  properties.include_title
  && `
  <mj-text ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.title_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.title_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.title_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.title_padding_right
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.title_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.title_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.title_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.title_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.title_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.title_line_height
    },

  ])}>
  {{ ${obj}.name }}
  </mj-text>
  `
}

  ${
  properties.include_price
  && `
  <mj-text ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.price_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.price_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.price_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.price_padding_right
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.price_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.price_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.price_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.price_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.price_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.price_line_height
    },
  ])}>
  {% if ${obj}.price_varies == 1 %}from {% endif %}{{ ${obj}.price | money_native }} {% if ${obj}.compare_at_price and ${obj}.compare_at_price > ${obj}.price %} <s>{{ ${obj}.compare_at_price | money_native  }}</s>{% endif %}
  </mj-text>
  `
}
  ${
  properties.include_button
  && `
  <mj-button ${attributes([
    {
      field: 'css-class',
      config: {},
      value: 'button'
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.button_radius
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.button_height
    },
    {
      field: 'width',
      config: { suffix: properties.button_full_width ? undefined : 'px' },
      value: properties.button_full_width ? '100%' : properties.button_width
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.button_background_color, '#FFFFFF')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.button_border_style, properties.button_border_width, properties.button_border_color)
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.button_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.button_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.button_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.button_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.button_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.button_line_height
    },
  ])}>
  ${properties.button_text}
  </mj-button>
  `
}
</mj-column>
`;
// HERE
export const getComponentHtml = (item, previewWidth = 600, isDesktop = true, emailMode = false) => {
  const { properties, type } = item;
  let htmlToRender = '';

  if (!emailMode && ((isDesktop && properties.conditional_display === 'Mobile Only') || (!isDesktop && properties.conditional_display === 'Desktop Only'))) {
    return `<mjml>
              <mj-body width="${previewWidth}px">
              </mj-body>
            </mjml>`;
  }

  switch (type) {
    case 'TEXT':

      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
                <mj-text ${attributes([
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.line_height
    },
  ])}>
                  ${properties.content}
                </mj-text>
              </mj-column>
            </mj-section>
      `;

      break;
    case 'CODE':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
                <mj-text ${attributes([
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.line_height
    },
  ])}>
                  ${properties.content}
                </mj-text>
              </mj-column>
            </mj-section>
      `;
      break;
    case 'SOCIALS':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
                <mj-social ${attributes([
    {
      field: 'icon-size',
      config: { suffix: 'px' },
      value: properties.icon_size
    },
    {
      field: 'icon-padding',
      config: { suffix: 'px' },
      value: properties.icon_padding
    },
    {
      field: 'mode',
      config: {},
      value: properties.mode
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.line_height
    },
  ])}>
                  ${['facebook', 'twitter', 'pinterest', 'linkedin'].filter((x) => properties[`${x}_link`]).map((x) => (
    `<mj-social-element name="${x}-noshare" ${attributes([
      {
        field: 'href',
        config: {},
        value: properties[`${x}_link`]
      },
      {
        field: 'border-radius',
        config: { suffix: 'px' },
        value: properties.icon_radius
      },
      {
        field: 'background-color',
        config: {},
        value: sanitizeHex(properties.icon_background, '')
      },
    ])}>
                      ${properties[`${x}_text`]}
                    </mj-social-element>`
  )).join('')}
                  ${['instagram', 'web', 'snapchat', 'youtube'].filter((x) => properties[`${x}_link`]).map((x) => (
    `<mj-social-element name="${x}" ${attributes([
      {
        field: 'href',
        config: {},
        value: properties[`${x}_link`]
      },
      {
        field: 'border-radius',
        config: { suffix: 'px' },
        value: properties.icon_radius
      },
      {
        field: 'background-color',
        config: {},
        value: sanitizeHex(properties.icon_background, '')
      },
    ])}>
                      ${properties[`${x}_text`]}
                    </mj-social-element>`
  )).join('')}
                </mj-text>
              </mj-column>
            </mj-section>
      `;
      break;
    case 'BUTTON':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: {},
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
  <mj-button   ${attributes([
    {
      field: 'css-class',
      config: {},
      value: 'button'
    },
    {
      field: 'href',
      config: {},
      value: properties.link
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.button_radius
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.button_height
    },
    {
      field: 'width',
      config: { suffix: properties.button_full_width ? undefined : 'px' },
      value: properties.button_full_width ? '100%' : properties.button_width
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.button_background_color, '#FFFFFF')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.button_border_style, properties.button_border_width, properties.button_border_color)
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.line_height
    },
  ])}>
                  ${properties.text}
                </mj-button>
              </mj-column>
            </mj-section>
      `;
      break;
    case 'IMAGE':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: {},
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
    <mj-image ${attributes([
    {
      field: 'alt',
      config: {},
      value: properties.alt
    },

    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.image_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.image_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.image_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.image_padding_right
    },
    {
      field: 'src',
      config: {},
      value: properties.image,
      default: 'https://via.placeholder.com/600x250/EEF0F2/66788A.png?text=+'
    },
    {
      field: 'href',
      config: {},
      value: properties.link
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.image_radius
    },
    {
      field: 'width',
      config: { suffix: 'px' },
      value: properties.image_width
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.image_height
    },
    {
      field: 'align',
      config: {},
      value: properties.align
    },
  ])}/>
              </mj-column>
            </mj-section>
      `;

      break;
    case 'GALLERY':
      htmlToRender = `
    <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: {},
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>

      ${properties.galleryItems.map((galleryItem) => (
    `
          <mj-column ${attributes([
      {
        field: 'width',
        config: {},
        value: isDesktop ? galleryItem.properties.column_width : '100%'
      },
      {
        field: 'vertical-align',
        config: {},
        value: galleryItem.properties.column_vertical_align
      },
      {
        field: 'padding-top',
        config: { suffix: 'px' },
        value: galleryItem.properties.padding_top
      },
      {
        field: 'padding-bottom',
        config: { suffix: 'px' },
        value: galleryItem.properties.padding_bottom
      },
      {
        field: 'padding-left',
        config: { suffix: 'px' },
        value: galleryItem.properties.padding_left
      },
      {
        field: 'padding-right',
        config: { suffix: 'px' },
        value: galleryItem.properties.padding_right
      },
      {
        field: 'background-color',
        config: { },
        value: sanitizeHex(galleryItem.properties.background_color, '')
      },
      {
        field: 'border',
        config: {},
        value: sanitizeBorder(galleryItem.properties.border_style, galleryItem.properties.border_width, galleryItem.properties.border_color)
      },
      {
        field: 'css-class',
        config: {},
        value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
          : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
            : 'both'
      },
    ])}>
          ${galleryItem.properties.image_alignment === 'Top'
      ? `
            ${galleryItem.properties.include_image
      ? `
            <mj-image ${attributes([
      {
        field: 'alt',
        config: {},
        value: galleryItem.properties.alt
      },
      {
        field: 'alt',
        config: {},
        value: properties.alt
      },
      {
        field: 'src',
        config: {},
        value: galleryItem.properties.image,
        default: 'https://via.placeholder.com/600x250/EEF0F2/66788A.png?text=+'
      },
      {
        field: 'width',
        config: { suffix: 'px' },
        value: galleryItem.properties.image_width
      },
      {
        field: 'height',
        config: { suffix: 'px' },
        value: galleryItem.properties.image_height
      },
    ])}/>
            `
      : null
    }
            ${galleryItem.properties.include_text
      ? `
              <mj-text ${attributes([
      {
        field: 'color',
        config: {},
        value: sanitizeHex(galleryItem.properties.text_color, '#000000')
      },
      {
        field: 'align',
        config: {},
        value: galleryItem.properties.align
      },
      {
        field: 'font-family',
        config: { suffix: ', sans-serif' },
        value: galleryItem.properties.font_family
      },
      {
        field: 'font-size',
        config: {},
        value: galleryItem.properties.font_size
      },
      {
        field: 'font-weight',
        config: {},
        value: galleryItem.properties.font_weight
      },
      {
        field: 'line-height',
        config: {},
        value: galleryItem.properties.line_height
      },
    ])}>
                    ${galleryItem.properties.content}
            </mj-text>
              `
      : null
    }

          `
      : `
          ${galleryItem.properties.include_text
      ? `
            <mj-text ${attributes([
      {
        field: 'color',
        config: {},
        value: sanitizeHex(galleryItem.properties.text_color, '#000000')
      },
      {
        field: 'align',
        config: {},
        value: galleryItem.properties.align
      },
      {
        field: 'font-family',
        config: { suffix: ', sans-serif' },
        value: galleryItem.properties.font_family
      },
      {
        field: 'font-size',
        config: {},
        value: galleryItem.properties.font_size
      },
      {
        field: 'font-weight',
        config: {},
        value: galleryItem.properties.font_weight
      },
      {
        field: 'line-height',
        config: {},
        value: galleryItem.properties.line_height
      },
    ])}>
                  ${galleryItem.properties.content}
          </mj-text>
            `
      : null
    }
            ${galleryItem.properties.include_image
      ? `
              <mj-image ${attributes([
      {
        field: 'alt',
        config: {},
        value: galleryItem.properties.alt
      },
      {
        field: 'src',
        config: {},
        value: galleryItem.properties.image,
        default: 'https://via.placeholder.com/600x250/EEF0F2/66788A.png?text=+'
      },
      {
        field: 'width',
        config: { suffix: 'px' },
        value: galleryItem.properties.image_width
      },
      {
        field: 'height',
        config: { suffix: 'px' },
        value: galleryItem.properties.image_height
      },
    ])}/>
              `
      : null
    }
        `}


        </mj-column>
          `))}
      </mj-section>
      `;

      break;
    case 'DIVIDER':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
                <mj-divider ${attributes([
    {
      field: 'border-width',
      config: { suffix: 'px' },
      value: properties.border_width
    },
    {
      field: 'border-color',
      config: {},
      value: sanitizeHex(properties.border_color, '#000000')
    },
    {
      field: 'border-style',
      config: {},
      value: properties.border_style
    },
  ])}
                />
              </mj-column>
            </mj-section>
      `;
      break;
    case 'SPACER':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              <mj-column>
                <mj-spacer ${attributes([
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.height
    }
  ])}
                />
              </mj-column>
            </mj-section>
      `;
      break;
    case 'PRODUCTS':
      if (emailMode === true) {
        htmlToRender = '';
        let pIndex = 0;
        for (let i = 0; i < properties.rows; i++) {
          let columnsToAdd = '';
          for (let xi = 0; xi < properties.columns; xi++) {
            columnsToAdd += productColumn(`products['${properties.feed}'][${pIndex}]`, properties);
            pIndex++;
          }
          htmlToRender += productSection(columnsToAdd, properties);
        }
      } else {
        htmlToRender = `
            ${
  `
                <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
              ${
  `
                <mj-column ${attributes([
    {
      field: 'width',
      config: {},
      value: isDesktop ? undefined : '100%',
    }
  ])}>
                  ${
  properties.include_image
                    && `
  <mj-image ${attributes([
    {
      field: 'alt',
      config: {},
      value: properties.alt
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.image_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.image_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.image_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.image_padding_right
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.image_radius
    },
    {
      field: 'width',
      config: { suffix: 'px' },
      value: properties.image_width
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.image_height
    },
    {
      field: 'align',
      config: {},
      value: properties.image_align
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.image_border_style, properties.image_border_width, properties.image_border_color)
    },
  ])} src="https://via.placeholder.com/600x600/EEF0F2/66788A.png?text=Product+Image" />
                  `}

                  ${
  properties.include_title
                    && `
                    <mj-text ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.title_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.title_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.title_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.title_padding_right
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.title_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.title_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.title_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.title_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.title_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.title_line_height
    },
  ])}>
                      Product Title
                    </mj-text>
                    `
}

                  ${
  properties.include_price
                    && `
                    <mj-text ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.price_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.price_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.price_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.price_padding_right
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.price_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.price_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.price_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.price_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.price_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.price_line_height
    },
  ])}>
                      $0.00
                    </mj-text>
                    `
}
                  ${
  properties.include_button
                    && `
                    <mj-button ${attributes([
    {
      field: 'css-class',
      config: {},
      value: 'button'
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.button_radius
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: properties.button_height
    },
    {
      field: 'width',
      config: { suffix: properties.button_full_width ? undefined : 'px' },
      value: properties.button_full_width ? '100%' : properties.button_width
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.button_background_color, '#FFFFFF')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.button_border_style, properties.button_border_width, properties.button_border_color)
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(properties.button_text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: properties.button_align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: properties.button_font_family
    },
    {
      field: 'font-size',
      config: {},
      value: properties.button_font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: properties.button_font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: properties.button_line_height
    },
  ])}>
                      ${properties.button_text}
                    </mj-button>
                    `
}
                </mj-column>
                `.repeat(properties.columns)
}
              </mj-section>
            `.repeat(properties.rows)
}
      `;
      }
      break;
    case 'COLUMNS':
      htmlToRender = `
            <mj-section ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
            ${
  properties.columns.map((col) => {
    switch (col.columnType) {
      case 'BUTTON':
        return `
                    <mj-column ${attributes([
    {
      field: 'width',
      config: {},
      value: isDesktop ? col.properties.column_width : '100%'
    },
    {
      field: 'vertical-align',
      config: {},
      value: col.properties.column_vertical_align
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: col.properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: col.properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: col.properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: col.properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(col.properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(col.properties.border_style, col.properties.border_width, col.properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
                      <mj-button   ${attributes([
    {
      field: 'css-class',
      config: {},
      value: 'button'
    },
    {
      field: 'href',
      config: {},
      value: col.properties.link
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: col.properties.button_radius
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: col.properties.button_height
    },
    {
      field: 'width',
      config: { suffix: col.properties.button_full_width ? undefined : 'px' },
      value: col.properties.button_full_width ? '100%' : col.properties.button_width
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(col.properties.button_background_color, '#FFFFFF')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(col.properties.button_border_style, col.properties.button_border_width, col.properties.button_border_color)
    },
    {
      field: 'color',
      config: {},
      value: sanitizeHex(col.properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: col.properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: col.properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: col.properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: col.properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: col.properties.line_height
    },
  ])}>
                        ${col.properties.text}
                      </mj-button>
                    </mj-column>
                    `;
      case 'CODE':
        return `
                    <mj-column ${attributes([
    {
      field: 'width',
      config: {},
      value: isDesktop ? col.properties.column_width : '100%'
    },
    {
      field: 'vertical-align',
      config: {},
      value: col.properties.column_vertical_align
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: col.properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: col.properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: col.properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: col.properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(col.properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(col.properties.border_style, col.properties.border_width, col.properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
                      <mj-text ${attributes([
    {
      field: 'color',
      config: {},
      value: sanitizeHex(col.properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: col.properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: col.properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: col.properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: col.properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: col.properties.line_height
    },
  ])}>
                        ${col.properties.content}
                      </mj-text>
                    </mj-column>
                    `;
      case 'IMAGE':
        return `
                    <mj-column ${attributes([
    {
      field: 'width',
      config: {},
      value: isDesktop ? col.properties.column_width : '100%'
    },
    {
      field: 'vertical-align',
      config: {},
      value: col.properties.column_vertical_align
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: col.properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: col.properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: col.properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: col.properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(col.properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(col.properties.border_style, col.properties.border_width, col.properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
  <mj-image ${attributes([
    {
      field: 'alt',
      config: {},
      value: col.properties.alt
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: col.properties.image_padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: col.properties.image_padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: col.properties.image_padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: col.properties.image_padding_right
    },
    {
      field: 'src',
      config: {},
      value: col.properties.image,
      default: 'https://via.placeholder.com/600x250/EEF0F2/66788A.png?text=+'
    },
    {
      field: 'href',
      config: {},
      value: col.properties.link
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: col.properties.image_radius
    },
    {
      field: 'width',
      config: { suffix: 'px' },
      value: col.properties.image_width
    },
    {
      field: 'height',
      config: { suffix: 'px' },
      value: col.properties.image_height
    },
    {
      field: 'align',
      config: {},
      value: col.properties.align
    },
  ])}/>
      </mj-column>
      `;
      case 'TEXT':
        return `
                    <mj-column ${attributes([
    {
      field: 'width',
      config: {},
      value: isDesktop ? col.properties.column_width : '100%'
    },
    {
      field: 'vertical-align',
      config: {},
      value: col.properties.column_vertical_align
    },
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: col.properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: col.properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: col.properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: col.properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(col.properties.background_color, '')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(col.properties.border_style, col.properties.border_width, col.properties.border_color)
    },
    {
      field: 'css-class',
      config: {},
      value: (properties.conditional_display === 'Desktop Only') ? 'hideMobile'
        : (properties.conditional_display === 'Mobile Only') ? 'hideDesktop'
          : 'both'
    },
  ])}>
                      <mj-text ${attributes([
    {
      field: 'color',
      config: {},
      value: sanitizeHex(col.properties.text_color, '#000000')
    },
    {
      field: 'align',
      config: {},
      value: col.properties.align
    },
    {
      field: 'font-family',
      config: { suffix: ', sans-serif' },
      value: col.properties.font_family
    },
    {
      field: 'font-size',
      config: {},
      value: col.properties.font_size
    },
    {
      field: 'font-weight',
      config: {},
      value: col.properties.font_weight
    },
    {
      field: 'line-height',
      config: {},
      value: col.properties.line_height
    },
  ])}>
                        ${col.properties.content}
                      </mj-text>
                    </mj-column>
                    `;
      default:
        return '';
    }
  }).join('')
}
            </mj-section>
      `;

      break;
    default:
      console.log('getComponentHtml: Type not recongised', item);
      break;
  }

  if (emailMode === true) {
    return htmlToRender;
  }

  return `
  <mjml>
    <mj-body width="${previewWidth}px">
    ${htmlToRender}
    </mj-body>
  </mjml>
  `;
};
export const getEmailHtml = (content, properties) => {
  const emailInner = content.map((x) => getComponentHtml(x, undefined, true, true));
  return `
  <mjml>
    <mj-head>
      <mj-style>
      .hideDesktop .hideMobile{
        display:block;
      }
      @media only screen and (max-width: 480px) {
        .hideMobile{
          display:none;
        }
      }
       @media only screen and (min-width: 481px) {
         .hideDesktop{
           display:none;
         }
      }
      .button a{
        display:block !important;
      }
      </mj-style>
    </mj-head>
    <mj-body width="${properties.width ? properties.width : 600}px" ${attributes([
  {
    field: 'background-color',
    config: { },
    value: sanitizeHex(properties.email_background, '#F9FAFB')
  },
])}
    >
      <mj-wrapper ${attributes([
    {
      field: 'padding-top',
      config: { suffix: 'px' },
      value: properties.padding_top
    },
    {
      field: 'padding-bottom',
      config: { suffix: 'px' },
      value: properties.padding_bottom
    },
    {
      field: 'padding-left',
      config: { suffix: 'px' },
      value: properties.padding_left
    },
    {
      field: 'padding-right',
      config: { suffix: 'px' },
      value: properties.padding_right
    },
    {
      field: 'background-color',
      config: { },
      value: sanitizeHex(properties.background_color, '#FFFFFF')
    },
    {
      field: 'border',
      config: {},
      value: sanitizeBorder(properties.border_style, properties.border_width, properties.border_color)
    },
    {
      field: 'border-radius',
      config: { suffix: 'px' },
      value: properties.border_radius
    },
  ])}
      >
      ${emailInner.join('')}
      <mj-section>
        <mj-column>
          <mj-text align="center" line-height="1.2">
            ${properties.unsubscribe_message ? properties.unsubscribe_message : 'No longer want to receive these emails? {{ unsubscribe }}.<br />{{ organization.name }}<br />{{ organization.address }}'}
          </mj-text>
        </mj-column>
      </mj-section>
      </mj-wrapper>
    </mj-body>
  </mjml>
  `;
};

const addColumn = (value, callback) => {
  const newValue = [...value, {
    columnType: 'TEXT',
    properties: {
      column_width: '50%',
      column_vertical_align: 'top',
      content: '<p>This is a new text block.</p>',
      ...textDefaultProperties,
      ...blockDefaultProperties,
    }
  }];
  callback(newValue, 'columns');
};

const removeColumn = (index, value, callback) => {
  const newValue = [...value];
  newValue.splice(index, 1);
  callback(newValue, 'columns');
};

const updateColumn = (columnIndex, fieldValue, field, columns, callback) => {
  const newValue = [...columns];
  newValue[columnIndex].properties[field] = fieldValue;
  callback(newValue, 'columns');
};

const addGallery = (value, callback) => {
  const newValue = [...value, {
    type: 'Gallery Item',
    properties: {
      image: '',
      image_width: '',
      image_height: '',
      alt: '',
      include_image: true,
      include_text: true,
      image_alignment: 'Top',
      content: '<p>This is a new text block.</p>',
      column_width: '50%',
      ...textDefaultProperties,
      ...blockDefaultProperties,
    }
  }];
  callback(newValue, 'galleryItems');
};

const removeGallery = (index, value, callback) => {
  const newValue = [...value];
  newValue.splice(index, 1);
  callback(newValue, 'galleryItems');
};
// updateGallery(i, fieldValue, field, value, callback);
const updateGallery = (galleryIndex, fieldValue, field, value, callback) => {
  const newValue = [...value];
  newValue[galleryIndex].properties[field] = fieldValue;
  callback(newValue, 'galleryItems');
};

const setColumnType = (columnIndex, columnType, columns, callback) => {
  const comp = EmailComponents.find((x) => x.id === columnType);
  const newValue = [...columns];
  newValue[columnIndex].columnType = columnType;
  newValue[columnIndex].properties = {
    column_width: '50%',
    column_vertical_align: 'top',
    ...comp.defaultProperties
  };
  callback(newValue, 'columns');
};

const ColumnSub = ({ onTypeChange, expanded, openColumn, colIndex, properties, columnType, callback, onRemove }) => {
  const comp = EmailComponents.find((x) => x.id === columnType);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
          gap: 1,
        }}
      >
        <Typography variant="subtitle1">
          Column
          {colIndex + 1}
        </Typography>
        {
          (colIndex !== 0)
          && (
          <IconButton
            onClick={() => { onRemove(colIndex); }}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
          )
        }
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={() => { openColumn(colIndex); }}
        >
          { expanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" /> }
        </IconButton>
      </Box>
      <Divider />
      {
        expanded
        && (
        <Grid
          container
          spacing={2}
          sx={{ mt: 0, pb: 1 }}
        >
          <Grid
            item
            xs={12}
          >
            {renderField({
              fieldName: 'columnType',
              fieldType: 'select',
              label: 'Column Type',
              options: [
                {
                  label: 'Text',
                  value: 'TEXT',
                },
                {
                  label: 'Image',
                  value: 'IMAGE',
                },
                {
                  label: 'Button',
                  value: 'BUTTON',
                },
                {
                  label: 'Code',
                  value: 'CODE',
                },
              ],
            }, columnType, (value) => {
              onTypeChange(value);
            })}
          </Grid>
          <Grid
            item
            xs={6}
          >
            {renderField({
              fieldName: 'column_width',
              fieldType: 'select',
              label: 'Column Width',
              options: [
                {
                  label: 'One Half',
                  value: '50%',
                },
                {
                  label: 'One Quarter',
                  value: '25%',
                },
                {
                  label: 'One Third',
                  value: '33.33%',
                },
                {
                  label: 'Two Thirds',
                  value: '66.66%',
                },
                {
                  label: 'Three Quarters',
                  value: '75%',
                },
                {
                  label: 'Full Width',
                  value: '100%',
                },
              ],
            }, properties.column_width, callback)}
          </Grid>
          <Grid
            item
            xs={6}
          >
            {renderField({
              fieldName: 'column_vertical_align',
              fieldType: 'select',
              label: 'Vertical Alignment',
              options: [
                {
                  label: 'Top',
                  value: 'top',
                },
                {
                  label: 'Middle',
                  value: 'middle',
                },
                {
                  label: 'Bottom',
                  value: 'bottom',
                },
              ],
            }, properties.column_vertical_align, callback)}
          </Grid>
          {
            comp.fields.map((x, i) => (
              <Grid
                item
                xs={x.size ? x.size : 12}
                key={`${x.fieldName}-${i}`}
              >
                {renderField(x.fieldType === 'rte' ? { ...x, editorName: `${x.fieldName}-${columnType}-${colIndex}-${i}` } : x, properties[x.fieldName], callback)}
              </Grid>
            ))
          }
        </Grid>
        )
      }
    </>
  );
};

ColumnSub.propTypes = {
  onTypeChange: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  openColumn: PropTypes.func.isRequired,
  colIndex: PropTypes.number,
  properties: PropTypes.object,
  columnType: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

const ColumnsField = ({ value, callback }) => {
  const [openIndex, setColumnIndex] = useState(0);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          const x = value.length;
          addColumn(value, callback);
          setColumnIndex(x);
        }}
        variant="contained"
        sx={{ mt: 1, mb: 0 }}
      >
        Add Column
      </Button>
      {value.map((col, i) => (
        <ColumnSub
          onTypeChange={(columnType) => { setColumnType(i, columnType, value, callback); }}
          expanded={(i === openIndex)}
          openColumn={(index) => { setColumnIndex((index === openIndex) ? -1 : index); }}
          key={`${col.columnType}-${i}`}
          onRemove={(index) => { removeColumn(index, value, callback); }}
          properties={col.properties}
          columnType={col.columnType}
          colIndex={i}
          callback={(fieldValue, field) => { updateColumn(i, fieldValue, field, value, callback); }}
        />
      ))}
    </>
  );
};

ColumnsField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  callback: PropTypes.func.isRequired
};

const GallerySub = ({ expanded, openGallery, galleryIndex, properties, callback, onRemove }) => {
  const textComp = EmailComponents.find((x) => x.id === 'TEXT');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
          gap: 1,
        }}
      >
        <Typography variant="subtitle1">
          Gallery Item
          {galleryIndex + 1}
        </Typography>
        {
          (galleryIndex !== 0)
          && (
          <IconButton
            onClick={() => { onRemove(galleryIndex); }}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
          )
        }
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={() => { openGallery(galleryIndex); }}
        >
          { expanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" /> }
        </IconButton>
      </Box>
      <Divider />
      {
        expanded
        && (
        <Grid
          container
          spacing={2}
          sx={{ mt: 0, pb: 1 }}
        >
          <Grid
            item
            xs={12}
          >

            { renderField({
              fieldName: 'image',
              fieldType: 'image',
              label: 'Upload an Image'
            },
            properties.image,
            callback)}
            { renderField({
              fieldName: 'image_width',
              fieldType: 'number',
              label: 'Image Width',
              suffix: 'px',
            },
            properties.image_width,
            callback)}
            { renderField({
              fieldName: 'image_height',
              fieldType: 'number',
              label: 'Image Height',
              suffix: 'px',
            },
            properties.image_height,
            callback)}
            { renderField({
              fieldName: 'alt',
              fieldType: 'text',
              label: 'Alt text'
            },
            properties.alt,
            callback)}
            { renderField({
              fieldName: 'include_image',
              fieldType: 'checkbox',
              label: 'Show image?'
            },
            properties.include_image,
            callback)}

            {renderField({
              fieldName: 'image_alignment',
              fieldType: 'select',
              label: 'Image Alignment',
              options: ['Top', 'Bottom']
            },
            properties.image_alignment,
            callback)}
            { renderField({
              fieldName: 'include_text',
              fieldType: 'checkbox',
              label: 'Show text?',
            },
            properties.include_text,
            callback)}
            {renderField({
              fieldName: 'column_width',
              fieldType: 'select',
              label: 'Column Width',
              options: [
                {
                  label: 'One Half',
                  value: '50%',
                },
                {
                  label: 'One Quarter',
                  value: '25%',
                },
                {
                  label: 'One Third',
                  value: '33.33%',
                },
                {
                  label: 'Two Thirds',
                  value: '66.66%',
                },
                {
                  label: 'Three Quarters',
                  value: '75%',
                },
                {
                  label: 'Full Width',
                  value: '100%',
                },
              ],
            }, properties.column_width, callback)}

          </Grid>
          {
            textComp.fields.map((x, i) => (
              <Grid
                item
                xs={x.size ? x.size : 12}
                key={`${x.fieldName}-${i}`}
              >
                {renderField(x.fieldType === 'rte' ? { ...x, editorName: `${x.fieldName}` } : x, properties[x.fieldName], callback)}
              </Grid>
            ))
          }
        </Grid>
        )
      }
    </>
  );
};

GallerySub.propTypes = {
  expanded: PropTypes.bool,
  openGallery: PropTypes.func.isRequired,
  galleryIndex: PropTypes.number,
  properties: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};
const GalleryField = ({ value, callback }) => {
  const [openIndex, setGalleryIndex] = useState(0);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          const x = value.length;
          addGallery(value, callback);
          setGalleryIndex(x);
        }}
        variant="contained"
        sx={{ mt: 1, mb: 0 }}
      >
        Add Gallery Item
      </Button>
      {value.map((gallery, i) => (
        <GallerySub
          expanded={(i === openIndex)}
          openGallery={(index) => { setGalleryIndex((index === openIndex) ? -1 : index); }}
          key={`${gallery}-${i}`}
          onRemove={(index) => { removeGallery(index, value, callback); }}
          properties={gallery.properties}
          // columnType={gallery.columnType}
          galleryIndex={i}
          callback={(fieldValue, field) => { updateGallery(i, fieldValue, field, value, callback); }}
        />
      ))}
    </>
  );
};

GalleryField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  callback: PropTypes.func.isRequired
};
