/* eslint-disable no-unused-expressions, prefer-destructuring */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/auth';
import { apiUrl } from '../config';
import { isDev } from '../utils/is-dev';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
  org: null,
  orgs: [],
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken, orgs, org } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken,
      ...orgs && { orgs },
      ...org && { org },
    };
  },
  LOGIN: (state, action) => {
    const { user, accessToken, orgs, org } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      accessToken,
      ...orgs && { orgs },
      ...org && { org },
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    accessToken: null,
    orgs: [],
    org: null,
  }),
  REFRESH: (state, action) => {
    const { accessToken, orgs, org, user } = action.payload;
    return {
      ...state,
      accessToken,
      user,
      isInitialized: true,
      isAuthenticated: true,
      ...orgs && { orgs },
      ...org && { org },
    };
  },
  ORG_CHANGE: (state, action) => {
    const { org } = action.payload;
    return {
      ...state,
      ...org && { org },
    };
  },
  REFRESH_ORGS: (state, action) => {
    const { org, orgs } = action.payload;
    return {
      ...state,
      ...orgs && { orgs },
      ...org && { org },
    };
  },
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  apiRequest: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(),
  changeOrg: () => Promise.resolve(),
  recover: () => Promise.resolve(),
  dispatchEvent: () => Promise.resolve(),
  refreshOrgs: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const logout = async () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const refresh = async (setOrgs = false) => {
    try {
      isDev && console.log('Refreshing...');
      const oldRefreshToken = localStorage.getItem('refreshToken');
      isDev && console.log('Old refresh token:', oldRefreshToken);
      if (oldRefreshToken) {
        const { accessToken, refreshToken } = await authApi.refresh(oldRefreshToken);
        const user = await authApi.me(accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessToken', accessToken);
        if (setOrgs) {
          const defaultOrg = localStorage.getItem('defaultOrg');
          const orgs = await authApi.orgs(accessToken);
          let org = null;
          if (orgs && orgs.length) {
            if (defaultOrg && orgs.findIndex((x) => x.id === defaultOrg) !== -1) {
              org = orgs.find((x) => x.id === defaultOrg);
            } else {
              org = orgs[0];
            }
          }
          dispatch({
            type: 'REFRESH',
            payload: {
              user,
              accessToken,
              orgs,
              org
            }
          });
        } else {
          dispatch({
            type: 'REFRESH',
            payload: {
              user,
              accessToken
            }
          });
        }
        return accessToken;
      }
      await logout();
    } catch (e) {
      console.log('Refresh failed: ', e);
      await logout();
    }
    return null;
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          const user = await authApi.me(accessToken);
          isDev && console.log('Logged In:', accessToken, user);
          const isExpired = await authApi.isExpired(user.userExpires);
          if (isExpired) {
            await refresh(true);
          } else {
            const defaultOrg = localStorage.getItem('defaultOrg');
            const orgs = await authApi.orgs(accessToken);
            isDev && console.log('Orgs:', orgs);
            let org = null;
            if (orgs && orgs.length) {
              if (defaultOrg && orgs.findIndex((x) => x.id === defaultOrg) !== -1) {
                org = orgs.find((x) => x.id === defaultOrg);
              } else {
                org = orgs[0];
              }
            }
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                accessToken,
                user,
                orgs,
                org,
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
              org: null,
              orgs: [],
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
            org: null,
            user: null,
            orgs: [],
          }
        });
      }
    };

    initialize();
  }, []);

  const getAccessToken = async () => {
    const isExpired = await authApi.isExpired(state.user?.userExpires);
    if (isExpired || !state.accessToken) {
      isDev && console.log('Token expired?', isExpired, state?.user);
      return refresh();
    }
    return state.accessToken;
  };

  const refreshOrgs = async (id) => {
    const accessToken = await getAccessToken();
    const orgs = await authApi.orgs(accessToken);
    const org = orgs.find((x) => x.id === id);
    dispatch({
      type: 'REFRESH_ORGS',
      payload: {
        orgs,
        ...(org) && { org }
      }
    });
  };

  const login = async (email, password) => {
    const { accessToken, refreshToken } = await authApi.login({ email, password });
    const user = await authApi.me(accessToken);
    const orgs = await authApi.orgs(accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        accessToken,
        orgs,
        ...(orgs && orgs.length) && { org: orgs[0] }
      }
    });
  };

  const apiRequest = async (url = '', body = {}, method = 'POST', contentType = 'application/json') => {
    isDev && console.log('New API request:', url, body);
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        return await fetch(`${apiUrl}${url}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': contentType,
            ...state?.org?.id && { organisationid: state.org.id }
          },
          method,
          body: (contentType === 'application/json') ? JSON.stringify({
            ...body,
          }) : body
        })
          .then(async (r) => {
            if (r.status === 403) { // If the response gave a 403 it means the accessToken is invalid
              console.log('403, renewing token', accessToken);
              await refresh(); // Renew the token
              return apiRequest(url, body, method, contentType); // Run the api request again
            }
            if (r.ok) { // If the response was successful give back the data
              if (contentType === 'application/json') {
                return r.json().catch((err) => {
                  console.error('[Fetch Error]:', err);
                  return {};
                });
              }
              return r.text().catch((err) => {
                console.error('[Fetch Error]:', err);
                return {};
              });
            }
            return r.text().then((text) => { throw new Error(text); });
          });
      }
      console.log('No accessToken?');
      return null;
    } catch (err) {
      console.error('[Api Error]: ', err);
      throw new Error(err);
    }
  };

  const dispatchEvent = (data) => {
    dispatch(data);
  };

  const changeOrg = async (orgId) => {
    const orgIndex = state.orgs.findIndex((x) => x.id === orgId);
    if (orgIndex !== -1) {
      localStorage.setItem('defaultOrg', state.orgs[orgIndex].id);
      dispatch({
        type: 'ORG_CHANGE',
        payload: {
          org: state.orgs[orgIndex]
        }
      });
    }
  };

  const register = async (email, password = '', token = '') => {
    if (email && password && token) {
      await authApi.finishRegister({ email, password, token });
    } else {
      await authApi.startRegister({ email });
    }
  };

  const recover = async (email, password = '', token = '') => {
    if (email && password && token) {
      await authApi.finishRecover({ email, password, token });
    } else {
      await authApi.startRecover({ email });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        refresh,
        apiRequest,
        changeOrg,
        getAccessToken,
        recover,
        refreshOrgs,
        dispatchEvent
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
