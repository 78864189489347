import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';

export const LoadableButton = (props) => {
  const { children, style, loading, ...other } = props;

  return (
    <Button
      style={{
        position: 'relative',
        ...style,
      }}
      {...other}
    >
      { loading && (
      <CircularProgress
        style={{ position: 'absolute' }}
        size={20}
        sx={{ color: 'white' }}
      />
      ) }
      <span style={{ ...loading && { opacity: 0 } }}>{ children }</span>
    </Button>
  );
};

LoadableButton.propTypes = {
  children: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.string
};
