/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { InputAdornment, Popper, ClickAwayListener, Grid } from '@mui/material';
import { InputField } from './input-field';

export const ColorPicker = ({ properties, value, callback }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [presetColors, setPresetColors] = useState(['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505',
    '#BD10E0', '#9013FE', '#4A90E2', '#2C9B9B', '#50E3C2', '#B8E986', '#000000',
    '#4A4A4A', '#9B9B9B', '#FFFFFF']);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClickAway = () => {
    setAnchorEl(false);
  };
  const styles = reactCSS({
    default: {
      color: {
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        background: value,
      },
      swatch: {
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        margin: '10px',
      },
      popover: {
        position: 'absolute',
        zIndex: '100',

      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',

      },
    },
  });
  const handleColorRecents = (activeColor) => {
    if (!presetColors.includes(activeColor)) {
      const temp = [...presetColors];
      temp.slice(0);
      temp.unshift(activeColor);
      temp.pop();
      setPresetColors(temp);
    }
  };
  return (
    <>
      <InputField
        fullWidth
        helperText="Must be a valid hex code e.g. #FF00FF"
        label={properties ? properties.label : 'Label'}
        name={properties ? properties.fieldName : 'Name'}
        readOnly
        value={value}
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
  >
    <Grid
      style={styles.swatch}
      aria-describedby={id}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      <div style={styles.color} />
    </Grid>

  </InputAdornment>
        }}
      />

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: '9999' }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <SketchPicker
              onChangeComplete={(color) => { handleColorRecents(color.hex); }}
              presetColors={presetColors}
              disableAlpha
              color={value}
              onChange={(color) => {
                if (properties) {
                  callback(color.hex, properties.fieldName);
                } else {
                  callback(color.hex);
                }
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>

    </>

  );
};

ColorPicker.propTypes = {
  properties: PropTypes.object,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
