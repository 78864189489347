/* eslint-disable no-unused-expressions */
import { apiUrl } from '../config';
import { isDev } from '../utils/is-dev';

class AuthApi {
  async login({ email, password }) {
    try {
      return await fetch(`${apiUrl}/users/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }

          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email or password.');
    }
  }

  // Takes a refresh token and returns the updated accessToken and refreshToken
  async refresh(refreshToken) {
    isDev && console.log('Sending refresh token: ', refreshToken);
    try {
      return await fetch(`${apiUrl}/users/refresh-token`, {
        headers: {
          Authorization: 'Bearer foobar',
          'Content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ refreshToken })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }

          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('Token refresh failed.');
    }
  }

  async isExpired(exp) {
    if (!exp) {
      return true;
    }

    const d = new Date(0);
    d.setUTCSeconds(exp);
    return d <= new Date();
  }

  async finishRegister({ email, password, token }) {
    try {
      return await fetch(`${apiUrl}/users/register`, {
        method: 'POST',
        body: JSON.stringify({ email, password, reset_code: token })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid verification code or email.');
    }
  }

  async startRegister({ email }) {
    try {
      return await fetch(`${apiUrl}/users/register`, {
        method: 'POST',
        body: JSON.stringify({ email })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email.');
    }
  }

  async finishRecover({ email, password, token }) {
    try {
      return await fetch(`${apiUrl}/users/reset`, {
        method: 'POST',
        body: JSON.stringify({ email, password, reset_code: token })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid verification code or email.');
    }
  }

  async startRecover({ email }) {
    try {
      return await fetch(`${apiUrl}/users/reset`, {
        method: 'POST',
        body: JSON.stringify({ email })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email.');
    }
  }

  async orgs(accessToken) {
    if (!accessToken) {
      return [];
    }
    try {
      return await fetch(`${apiUrl}/api/organisations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({})
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Orgs Api]: ', err);
      throw new Error('Getting orgs failed.');
    }
  }

  me(accessToken) {
    return new Promise((resolve, reject) => {
      try {
        const { userId, exp, email } = JSON.parse(atob(accessToken.split('.')[1]));
        resolve({
          id: userId,
          userExpires: exp,
          email,
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
