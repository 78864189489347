import PropTypes from 'prop-types';
import {
  Grid,
  Typography, Divider, Card
} from '@mui/material';
import { useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { endOfMonth, endOfYear, format, subDays, subMonths, subYears, differenceInDays } from 'date-fns';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ActionsMenu } from '../actions-menu';

export const DateControls = ({ onDateChange, showCompare, defaultStart, defaultEnd, ...other }) => {
  const newDate = new Date();
  const currentMonthValue = newDate.getMonth();
  const currentDateValue = newDate.getDate();

  const firstOfThisMonth = subDays(newDate, currentDateValue - 1);
  const firstOfLastMonth = subMonths(firstOfThisMonth, 1);
  const endOfLastMonth = endOfMonth(firstOfLastMonth);
  const firstDateThisYear = subMonths(firstOfThisMonth, currentMonthValue);
  const firstDateLastYear = subYears(firstDateThisYear, 1);
  const endOfLastYear = endOfYear(firstDateLastYear);

  const start = defaultStart || firstOfLastMonth;
  const end = defaultEnd || endOfLastMonth;

  const [value, setValue] = React.useState([start, end]);
  const [isClicked, setIsClicked] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [valueDisplayOuterCompare, setValueDisplayOuterCompare] = React.useState([subDays(newDate, 7), newDate]);
  const [valueDisplayOuter, setValueDisplayOuter] = React.useState([start, end]);
  const [displayChecked, setDisplayChecked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [range, setRange] = useState((defaultStart && defaultEnd) ? 'Custom Range' : 'Last Month');
  const [rangeCompare, setRangeCompare] = useState('Last Period');

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const ranges = [
    {
      label: 'Last 7 Days',
      onClick: () => {
        setRange('Last 7 Days');
        setValue([subDays(newDate, 7), newDate]);
      }
    },
    {
      label: 'Last Month',
      onClick: () => {
        setRange('Last Month');
        setValue([firstOfLastMonth, endOfLastMonth]);
      }
    },
    {
      label: 'Last 90 Days',
      onClick: () => {
        setRange('Last 90 Days');
        setValue([subDays(newDate, 90), newDate]);
      }
    },
    {
      label: 'Last Year',
      onClick: () => {
        setRange('Last Year');
        setValue([firstDateLastYear, endOfLastYear]);
      }
    },
  ];

  const rangesCompare = [
    {
      label: 'Last Period',
      onClick: () => {
        setRangeCompare('Last Period');
      }
    },
    {
      label: 'Last Year',
      onClick: () => {
        setRangeCompare('Last Year');
      }
    },

  ];

  const dateUpdater = () => {
    if (rangeCompare === 'Last Period') {
      if (range === 'Last 7 Days') {
        setValueDisplayOuter([subDays(newDate, 7), newDate]);
        setValueDisplayOuterCompare([subDays(newDate, 15), subDays(newDate, 8)]);
      } else if (range === 'Last Month') {
        setValueDisplayOuter([firstOfLastMonth, endOfLastMonth]);
        setValueDisplayOuterCompare([subMonths(subDays(firstOfLastMonth, -1), 1), endOfMonth(subMonths(endOfLastMonth, 1))]);
      } else if (range === 'Last 90 Days') {
        setValueDisplayOuter([subDays(newDate, 90), newDate]);
        setValueDisplayOuterCompare([subDays(newDate, 181), subDays(newDate, 91)]);
      } else if (range === 'Last Year') {
        setValueDisplayOuter([firstDateLastYear, endOfLastYear]);
        setValueDisplayOuterCompare([subYears(firstDateLastYear, 1), subYears(endOfLastYear, 1)]);
      } else if (range === 'Custom Range') {
        setValueDisplayOuter([value[0], value[1]]);
        const compareSubAmount = differenceInDays(value[1], value[0]) + 1;
        setValueDisplayOuterCompare([subDays(value[0], compareSubAmount), subDays(value[1], compareSubAmount)]);
      }
    } else if (rangeCompare === 'Last Year') {
      if (range === 'Last 7 Days') {
        setValueDisplayOuter([subDays(newDate, 7), newDate]);
        setValueDisplayOuterCompare([subYears(subDays(newDate, 7), 1), subYears(newDate, 1)]);
      } else if (range === 'Last Month') {
        setValueDisplayOuter([firstOfLastMonth, endOfLastMonth]);
        setValueDisplayOuterCompare([subYears(firstOfLastMonth, 1), subYears(endOfMonth(endOfLastMonth), 1)]);
      } else if (range === 'Last 90 Days') {
        setValueDisplayOuter([subDays(newDate, 90), newDate]);
        setValueDisplayOuterCompare([subYears(subDays(newDate, 181), 1), subYears(subDays(newDate, 91), 1)]);
      } else if (range === 'Last Year') {
        setValueDisplayOuter([firstDateLastYear, endOfLastYear]);
        setValueDisplayOuterCompare([subYears(firstDateLastYear, 2), subYears(endOfLastYear, 2)]);
      } else if (range === 'Custom Range') {
        setValueDisplayOuter([value[0], value[1]]);
        setValueDisplayOuterCompare([subYears(value[0], 1), subYears(value[1], 1)]);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsClicked(false);
  };

  const handleApply = () => {
    setDisplayChecked(isChecked);
    dateUpdater();
    handleClose();
    if (isChecked && showCompare) {
      onDateChange(value[0], value[1], rangeCompare);
    } else {
      onDateChange(value[0], value[1]);
    }
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setIsClicked(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsClicked(true);
  };

  const checkBoxHandler = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Grid
      container
      // spacing={2}
      display="column"
      sx={{ width: 'auto', maxWidth: '320px', ml: 0, mt: 0 }}
      alignContent="center"
      {...other}
    >

      <Button
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon sx={{ transform: isClicked ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: '18px' }} />}
        sx={{ pl: 0 }}
      >
        <Grid
          container

        >
          <Grid
            container
            item
            // justifyContent="flex-end"
          >
            <Typography sx={{ display: 'flex', fontSize: '13px', fontWeight: '500', minWidth: 'max-content', whiteSpace: 'nowrap' }}>
              {' '}
              {`${(valueDisplayOuter && valueDisplayOuter[0]) ? format(valueDisplayOuter[0], 'dd/MM/yyyy') : ''} - ${(valueDisplayOuter && valueDisplayOuter[1]) ? format(valueDisplayOuter[1], 'dd/MM/yyyy') : ''}`}
            </Typography>
          </Grid>
          {displayChecked
            ? (
              <Grid
                container
                item
              >
                <Typography
                  color="textSecondary"
                  sx={{ display: 'flex', mr: '0.5em', fontSize: '13px', fontWeight: '500' }}
                  variant="caption"
                >
                  Compare to:
                </Typography>
                <Typography
                  color="textSecondary"
                  sx={{ display: 'flex', mr: '0.5em', fontSize: '13px', fontWeight: '500' }}
                  variant="caption"
                >
                  {`${(valueDisplayOuterCompare && valueDisplayOuterCompare[0]) ? format(valueDisplayOuterCompare[0], 'dd/MM/yyyy') : ''} - ${(valueDisplayOuterCompare && valueDisplayOuterCompare[1]) ? format(valueDisplayOuterCompare[1], 'dd/MM/yyyy') : ''}`}
                </Typography>
              </Grid>
            )
            : <></>}
        </Grid>
      </Button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-start"

      >
        {/* POPOVER CONTENT START */}
        <ClickAwayListener onClickAway={handleClose}>
          <Card variant="outlined">
            <Grid
              item
              sx={{ mx: 2, padding: '1em', }}
            >
              <Grid
                item
                sx={{ marginBottom: '1em' }}
              >
                <Typography
                  variant="caption"
                  sx={{ display: 'inline', fontSize: '13px', fontWeight: '500' }}
                >
                  Date range:
                </Typography>
                <ActionsMenu
                  actions={ranges}
                  label={range}
                  size="small"
                  variant="text"
                  sx={{ ml: '1em' }}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  inputFormat="dd/MM/yyyy"
                  startText="Start date"
                  endText="End Date"
                  value={value}
                  sx={{ marginTop: '1em' }}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        onClick={() => {
                          setRange('Custom Range');
                        }}
                        {...startProps}
                      />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField
                        sx={{ mb: '1em' }}
                        onClick={() => {
                          setRange('Custom Range');
                        }}
                        {...endProps}
                      />
                    </>
                  )}
                />
              </LocalizationProvider>
              {/* HIDE COMPARE TO */}
              {showCompare
                ? (
                  <Grid
                    container
                    alignItems="center"
                  >
                    <Checkbox
                      checked={isChecked}
                      onChange={checkBoxHandler}
                      color="primary"
                    />
                    <Typography
                      variant="caption"
                      sx={{ display: 'inline', fontWeight: '500', fontSize: '13px' }}
                    >
                      Compare to:
                    </Typography>
                    <ActionsMenu
                      actions={rangesCompare}
                      label={rangeCompare}
                      size="small"
                      variant="text"
                      sx={{ ml: '1em' }}
                    />
                  </Grid>
                )
                : null}
              <Divider sx={{ marginBottom: '0.5em' }} />
              <Button
                variant="contained"
                sx={{ mr: '0.5em' }}
                onClick={() => {
                  handleApply();
                }}
              >
                Apply
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Card>
        </ClickAwayListener>
      </Popper>

    </Grid>

  );
};

DateControls.propTypes = {
  onDateChange: PropTypes.func,
  showCompare: PropTypes.bool,
  defaultStart: PropTypes.instanceOf(Date),
  defaultEnd: PropTypes.instanceOf(Date)
};
