import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  TableSortLabel
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import numeral from 'numeral';
import { format, subMonths, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { Scrollbar } from '../scrollbar';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { DateControls } from '../insights-root/date-controls';
import { demoId } from '../../utils/demo';
import demoTypologyPerformanceData from '../../demo/rawTypologyPerformance.json';

const columnHeadings = ['Typology', 'Top-Selling Brand', 'Net Orders', 'Net Sales'];

export const Performance = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const firstOfLastMonth = startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
  const [performanceData, setPerformanceData] = useState({ isLoading: true, data: [] });
  const [selectedDateRange, setSelectedDateRange] = useState({ start: firstOfLastMonth, end: endOfLastMonth });

  const [controller, setController] = useState({ sort: 'asc', sortBy: 'Typology' });

  const handleSortChange = (property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    });
  };

  const getPerformance = useCallback(async () => {
    setPerformanceData(() => ({ isLoading: true, data: '' }));
    const startDate = format(selectedDateRange.start, 'yyyy-MM-dd');
    const endDate = format(selectedDateRange.end, 'yyyy-MM-dd');
    try {
      let performance = org.id === demoId ? demoTypologyPerformanceData : await apiRequest('/api/sales/typology-performance', {
        startDate, endDate
      });
      if (performance !== undefined && performance.length > 0) {
        performance = performance.filter((x) => x.context !== 'All Customers');
        // performance.find((typology) => typology.context.toLowerCase() === 'default').context = 'No Typology';
      }

      if (mounted.current) {
        setPerformanceData(() => ({ isLoading: false, data: performance || [] }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setPerformanceData(() => ({
          isLoading: true,
          error: err.message
        }));
      }
    }
  }, [org, selectedDateRange]);

  useEffect(() => {
    getPerformance().catch(console.error);
  }, [org, selectedDateRange]);

  const filteredData = [...performanceData.data];
  if (performanceData.isLoading === false) {
    switch (controller.sort) {
      case 'asc':
        switch (controller.sortBy) {
          case 'Typology':
            filteredData.sort((a, b) => b.context.localeCompare(a.context));
            break;

          case 'Top-Selling Brand':
            filteredData.sort((a, b) => b.brand.localeCompare(a.brand));
            break;

          case 'Net Orders':
            filteredData.sort((a, b) => b.orders_total - a.orders_total);
            break;

          case 'Net Sales':
            filteredData.sort((a, b) => b.total_price - a.total_price);
            break;

          default:
            break;
        }
        break;

      case 'desc':
        switch (controller.sortBy) {
          case 'Typology':
            filteredData.sort((a, b) => a.context.localeCompare(b.context));
            break;

          case 'Top-Selling Brand':
            filteredData.sort((a, b) => a.brand.localeCompare(b.brand));
            break;

          case 'Net Orders':
            filteredData.sort((a, b) => a.orders_total - b.orders_total);
            break;

          case 'Net Sales':
            filteredData.sort((a, b) => a.total_price - b.total_price);
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }

  return (
    <Card
      variant="outlined"
    >
      <CardHeader
        title="Performance by Typology"
        subheader={`Last ${differenceInDays(selectedDateRange.end, selectedDateRange.start)} days`}
        action={(
          <DateControls
            sx={{ width: 'auto' }}
            showCompare={false}
            onDateChange={(start, end) => {
              setSelectedDateRange({ start, end });
            }}
          />
)}
        sx={{
          '& .MuiCardHeader-action': { alignSelf: 'center' },
        }}
      />
      <Scrollbar sx={{ height: '100%' }}>
        {performanceData.isLoading === false
          ? (
            <Table>
              <TableHead>
                <TableRow>

                  {columnHeadings.map((column) => (
                    <TableCell
                      key={column}
                      padding="normal"
                    >

                      <TableSortLabel
                        active={controller.sortBy === column}
                        direction={controller.sortBy === column ? controller.sort : 'asc'}
                        disabled={performanceData.isLoading}
                        onClick={() => handleSortChange(column)}
                      >
                        {column}
                      </TableSortLabel>

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item) => (
                  <TableRow
                    key={item.context}
                    hover
                  >
                    <TableCell>
                      {item.context.toLowerCase() === 'default' ? 'No Typology' : item.context}
                    </TableCell>
                    <TableCell>
                      {item.brand === 'Not Set' ? <i>{item.brand}</i> : item.brand}
                    </TableCell>
                    <TableCell>
                      {item.orders_total}
                    </TableCell>
                    <TableCell>
                      {`${numeral(item.total_price).format('$0,0.00')}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
          : (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <CircularProgress
                color="primary"
                size={100}
              />

            </Box>
          )}
      </Scrollbar>
    </Card>
  );
};
