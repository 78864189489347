import React from 'react';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon
      className="ql-fill ql-stroke"
      points="6 10 4 12 2 10 6 10"
    />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon
      className="ql-fill ql-stroke"
      points="12 10 14 12 16 10 12 10"
    />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '32px', '48px', '72px'];
Quill.register(SizeStyle, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      // emoji: function () {}
    },
  },
  'emoji-toolbar': true,
  'emoji-shortname': true,
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
  'emoji'
];
// Quill.register("modules/imageResize", ImageResize);
Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji
  },
  true
);
// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    {
      /*
      <select
        className="ql-font"
        placeholder="Select a font"
        defaultValue=""
      >
        <option value="">Font</option>
        <option value="arial">Arial</option>
        <option value="arial-black">Arial Black</option>
        <option value="century-gothic">Century Gothic</option>
        <option value="comic-sans-ms">Comic Sans MS</option>
        <option value="courier">Courier</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="geneva">Geneva</option>
        <option value="helvetica">Helvetica</option>
        <option value="ms-serif">MS Serif</option>
        <option value="new-york">New York</option>
        <option value="palatino">Palatino</option>
        <option value="times-new-roman">Times New Roman</option>
        <option value="trebuchet-ms">Trebuchet MS</option>
        <option value="verdana">Verdana</option>
      </select>
      */
    }
    <select
      className="ql-header"
      defaultValue=""
    >
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="4">Heading 4</option>
      <option value="5">Heading 5</option>
      <option value="6">Heading 6</option>
      <option value="">Normal</option>
    </select>
    <select
      className="ql-size"
      defaultValue=""
    >
      <option value="">Size</option>
      <option value="8px">8px</option>
      <option value="9px">9px</option>
      <option value="10px">10px</option>
      <option value="11px">11px</option>
      <option value="12px">12px</option>
      <option value="14px">14px</option>
      <option value="16px">16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
      <option value="22px">22px</option>
      <option value="24px">24px</option>
      <option value="26px">26px</option>
      <option value="32px">32px</option>
      <option value="48px">48px</option>
      <option value="72px">72px</option>
    </select>

    <span className="ql-formats">
      {/* <select className="ql-align" /> */}
      <select
        aria-label="Color Picker"
        className="ql-color"
      />
      <select
        aria-label="Background Picker"
        className="ql-background"
      />
    </span>
    <span className="ql-formats">
      <button
        aria-label="Bold Text"
        type="button"
        className="ql-bold"
      />
      <button
        aria-label="Italic Text"
        type="button"
        className="ql-italic"
      />
      <button
        aria-label="Underline Text"
        type="button"
        className="ql-underline"
      />
      <button
        aria-label="Strike Text"
        type="button"
        className="ql-strike"
      />
    </span>
    <span className="ql-formats">
      <button
        aria-label="Ordered List"
        type="button"
        className="ql-list"
        value="ordered"
      />
      <button
        aria-label="Bullet List"
        className="ql-list"
        value="bullet"
        type="button"
      />
      {
        /*
      <button
        className="ql-indent"
        value="-1"
      />
      <button
        className="ql-indent"
        value="+1"
      />
      */
      }
    </span>
    {
      /*
      <span className="ql-formats">
        <button
          className="ql-script"
          value="super"
        />
        <button
          className="ql-script"
          value="sub"
        />
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      */
    }
    <span className="ql-formats">
      <button
        aria-label="Link"
        type="button"
        className="ql-link"
      />
      <button
        aria-label="Clean"
        type="button"
        className="ql-clean"
      />
    </span>
    {
      /*
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-clean" />
    </span>
      */
    }
    <span className="ql-formats">
      <button
        type="button"
        className="ql-undo"
      >
        <CustomUndo />
      </button>
      <button
        type="button"
        className="ql-redo"
      >
        <CustomRedo />
      </button>
    </span>

    <button
      aria-label="Emoji"
      type="button"
      className="ql-emoji"
    />

  </div>
);

export default QuillToolbar;
