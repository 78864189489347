/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography, Grid, CircularProgress, CardContent } from '@mui/material';
import { format, subYears } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

/* Components */
import { SurveyTable } from '../components/survey/survey-table';
import { Submissions } from '../components/survey/submissions';
import { GrowthLineChart } from '../components/survey/growth-line-chart';
import { PreferredLineChart } from '../components/survey/preferred-line-chart';
import { Plus as PlusIcon } from '../icons/plus';
import { GenericMetricCard } from '../components/summary-root/generic-metric-card';
/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Utils */
import gtm from '../lib/gtm';
import { Typologies } from '../utils/typology';
import { AutomationTemplates } from '../utils/automation-templates';

import { demoId } from '../utils/demo';
import demoForms from '../demo/rawFormsData.json';
import demoFormData from '../demo/rawFormData.json';
import demoFormEvents from '../demo/rawFormEvents.json';

const propertiesArr = [
  'expectations_product_selection', 'expectations_ease_of_use', 'expectations_fulfillment', 'expectations_quality', 'expectations_pricing', 'expectations_communication', 'expectations_returns', 'expectations_service',
  'performance_product_selection', 'performance_ease_of_use', 'performance_fulfillment', 'performance_quality', 'performance_pricing', 'performance_communication', 'performance_returns', 'performance_service'
];

export const SurveyScreen = () => {
  const navigate = useNavigate();

  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [controller, setController] = useState({
    page: 0,
  });
  const [formsState, setFormsState] = useState({ isLoading: true });
  const [loading, setLoading] = useState(true);
  const [formSubmitData, setFormSubmitData] = useState(null);
  const [genericData, setGenericData] = useState({ isLoading: true });

  const newSurvey = async (data = {}) => {
    try {
      const flowData = AutomationTemplates.find((x) => x.name.toLowerCase() === 'vocation survey').data;
      if (org.id !== demoId) {
        const flowRequest = await apiRequest('/flows/create', {
          name: ' Automation for: My new survey',
          eventTrigger: [],
          wait: [],
          eventFilters: [],
          eventAppliedDisjunctively: false,
          customerFilters: [],
          customerAppliedDisjunctively: false,
          ...flowData
        });
        const request = await apiRequest('/api/form/create', {
          name: 'My new survey',
          formType: 'FEEDBACK',
          formJson: JSON.stringify(flowRequest),
          ...data,
        });
        if (request?.id && flowRequest?.id) {
          navigate(`/dashboard/surveys/edit/${request.id}`, {
            state: {
              flowId: flowRequest.id,
            }
          });
        }
      } else {
        navigate('/dashboard/surveys/edit/e9466107-eb7e-11ec-982a-0a6cc190ea84', {
          state: {
            flowId: '66a7e107-eac3-11ec-982a-0a6cc190ea84',
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function getData() {
    const sentUnsent = org.id === demoId ? demoFormData : await apiRequest('/api/form/data', {
      startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    });

    const data = org.id === demoId ? demoFormEvents : await apiRequest('/api/events', {
      eventType: 'FORM_SUBMIT',
      startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    });

    setFormSubmitData(data);

    const parsedData = [...data].map((x) => {
      const parsedProperties = Object.assign({}, ...JSON.parse(x.properties));
      return { ...parsedProperties, created_at: x.created_at, typology: x.typology };
    });

    // key focus
    let splitArr = propertiesArr.map((property) => ({ [property]: (parsedData.map((obj) => {
      if (obj[property]) {
        return +obj[property];
      }
      return null;
    }).filter(Number))
    }));
    splitArr = splitArr.map((x) => {
      const { length } = Object.values(x)[0];
      const mean = Object.values(x)[0].reduce((acc, curr) => acc + +curr, 0) / length;
      const key = Object.keys(x)[0];
      return { key, value: mean };
    });

    const keyFocus = splitArr.filter((y) => y.key.includes('expectation')).map((x, i) => {
      const result = splitArr[i].value - splitArr[i + 8].value;
      return { [x.key]: result };
    });
    const temp = Object.keys(keyFocus.sort((a, b) => Object.values(b)[0] - Object.values(a)[0])[0])[0].split('_').filter((x) => x.toLowerCase() !== 'expectations');
    const humanReadable = temp.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
    // key focus

    // happy typology
    const typologyByVendorAvg = Typologies.map((typology) => {
      const singleTypeArr = parsedData.map((x) => {
        if (((typology.value === x.typology) || (typology.value === 'DEFAULT' && x.typology === null)) && x.preferred_vendor) {
          return x.preferred_vendor;
        }
        return null;
      });
      const arr = singleTypeArr.filter((x) => x !== null);
      const mean = arr.reduce((acc, curr) => acc + +curr, 0) / arr.length;
      return { typology: typology.label, meanVendorScore: mean };
    });

    if (sentUnsent && data && mounted.current) {
      setGenericData({
        isLoading: false,
        responseRate: Math.round((sentUnsent.total_submitted / sentUnsent.total_sent) * 100),
        surveyRequests: sentUnsent.total_sent,
        happiestTypology: typologyByVendorAvg.sort((a, b) => b.meanVendorScore - a.meanVendorScore)[0].typology,
        keyFocus: humanReadable,
        sentUnsent
      });
    }
    setLoading(false);
  }

  async function getForms() {
    try {
      setFormsState(() => ({ isLoading: true }));
      const result = org.id === demoId ? demoForms : await apiRequest('/api/forms', {
        limit: 50,
        page: controller.page,
        formType: 'FEEDBACK',
      }) || [];
      if (mounted.current) {
        setFormsState(() => ({
          isLoading: false,
          data: result
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setFormsState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }

  const archive = async (id) => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/api/form/archive', {
          id
        });
      }
      getForms();
      toast.success('Archived');
    } catch (e) {
      console.log(e);
      toast.error('Error');
    }
  };

  useEffect(() => {
    getForms();
  }, [controller, org]);

  useEffect(() => {
    setLoading(true);
    gtm.push({ event: 'page_view' });
    getData();
  }, []);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1
    });
  };
  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Surveys | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Surveys
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  newSurvey();
                }}
              >
                <Box display={{ xs: 'none', md: 'block' }}>New Survey</Box>
              </Button>
            </Box>
          </Box>
          {genericData.sentUnsent.total_submitted && typeof genericData.sentUnsent.total_submitted === 'number' && genericData.sentUnsent.total_submitted > 0
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xl={8}
                  lg={12}
                  xs={12}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1
                    }}
                  >
                    <SurveyTable
                      forms={formsState.data?.forms}
                      error={formsState.error}
                      isLoading={formsState.isLoading}
                      onPageChange={handlePageChange}
                      archive={archive}
                      page={controller.page}
                    />
                  </Card>
                  <Grid
                    container
                    spacing={3}
                    sx={{ mt: 0, mb: 3 }}
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      xs={12}
                    >
                      <GrowthLineChart
                        sx={{ height: '100%' }}
                        data={formSubmitData}
                      />
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      xs={12}
                    >
                      <PreferredLineChart
                        sx={{ height: '100%' }}
                        data={formSubmitData}
                      />
                    </Grid>
                  </Grid>
                  <Submissions data={formSubmitData} />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={12}
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    {/* response rate */}
                    <Grid
                      item
                      xs={12}
                    >
                      <GenericMetricCard
                        data={`${+genericData.responseRate > 100 ? 100 : genericData.responseRate}%`}
                        title="Survey Response Rate"
                        subtitle="Last 12 Months"
                        icon="send"
                      />
                    </Grid>
                    {/* survey requests */}
                    <Grid
                      item
                      xs={12}
                    >
                      <GenericMetricCard
                        data={`${genericData.surveyRequests}`}
                        title="Survey Requests"
                        subtitle="Last 12 Months"
                        icon="send"
                      />
                    </Grid>
                    {/* key focus */}
                    <Grid
                      item
                      xs={12}
                    >
                      <GenericMetricCard
                        data={`${genericData.keyFocus}`}
                        title="Key Focus"
                        subtitle="For improving customer satisfaction"
                        icon="star"
                      />
                    </Grid>
                    {/* happiest typology */}
                    <Grid
                      item
                      xs={12}
                    >
                      <GenericMetricCard
                        data={`${genericData.happiestTypology}`}
                        title="Happiest Typology"
                        subtitle="By survey respondents"
                        icon="smile"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
            : (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1
                    }}
                  >
                    <SurveyTable
                      forms={formsState.data?.forms}
                      error={formsState.error}
                      isLoading={formsState.isLoading}
                      onPageChange={handlePageChange}
                      archive={archive}
                      page={controller.page + 1}
                    />
                  </Card>

                </Grid>
                <Grid
                  item
                  xs={12}
                >

                  <Card>
                    <CardContent>
                      <Typography>
                        As more of your customer data is collected and your customers’ typologies are better understood, we will begin to deliver key insights that will help you improve your business.
                      </Typography>
                    </CardContent>
                  </Card>

                </Grid>
              </Grid>
            )}

        </Container>
      </Box>

    </>
  );
};
