/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GroupBys } from '../../../utils/group-by-date';

export const PreferredLineChart = ({ data, range, ...rest }) => {
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const xAxisRange = range;

  const formatted_data = [...xAxisRange].map((x) => {
    const fData = [...data].filter((y) => {
      const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
      return (formattedDate === x);
    }).reduce((acc, curr) => {
      let properties = curr.properties ? JSON.parse(curr.properties) : [];
      properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
      if (properties && properties.preferred_vendor !== undefined) {
        console.log(properties.preferred_vendor);
        acc.push(+properties.preferred_vendor);
      }
      return acc;
    }, []);

    if (!fData.length) {
      return 0;
    }
    return ([...fData].reduce((acc, curr) => acc + curr, 0) / fData.length).toFixed(0);
  });

  const chartOptions = {
    chart: {
      type: 'line',
      zoom: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    colors: ['#21D59B'],
    dataLabels: {
      enabled: false,
    },
    // fill: {
    //   type: 'gradient',
    // },
    grid: {
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      curve: 'straight',
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: '#555',
        show: true,
      },
      axisTicks: {
        color: '#555',
        show: true,
      },
      categories: xAxisRange,
      labels: {
        style: {
          colors: '#555',
        },
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter(val) {
          if (+val === 100) {
            return `Most Preferred ${val.toFixed(0)}`;
          }
          if (+val === 0) {
            return `Least Preferred ${val.toFixed(0)}`;
          }
          return val.toFixed(0);
        },
        offsetX: -12,
        style: {
          colors: '#555',
        },
      },
    },
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title="1. Am I my customers' preferred vendor?"
      />
      <Divider />
      <CardContent>
        <Box sx={{ px: 0 }}>
          <Chart
            height="400"
            options={chartOptions}
            series={[{ name: '', data: formatted_data }]}
            type="line"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

PreferredLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  range: PropTypes.array,
};
