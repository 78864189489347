import { PropTypes } from 'prop-types';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { apiUrl } from '../config';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    await fetch(`${apiUrl}/api/create-cloudwatch-log`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        title: error.toString(),
        message: errorInfo,
      }),
    });
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Typography
            color="textPrimary"
            variant="h2"
            sx={{ mb: 1 }}
          >
            Something went wrong.
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
            sx={{ mb: 1 }}
          >
            Try to refresh this page or contact us if the problem persists.
          </Typography>
        </Grid>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};
