import { format, parseISO } from 'date-fns';
import {
  Badge,
  Box,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListSubheader,
  Grid
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState, useEffect, useCallback } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { usePopover } from '../hooks/use-popover';
import { Bell as BellIcon } from '../icons/bell';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

const icons = [
  {
    label: 'info',
    icon: InfoIcon
  },
];

export const NotificationsPopover = (props) => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [notificationState, setNotificationState] = useState([]);
  const [reloadApi, setReloadApi] = useState(false);
  const [requestStatus, setRequestStatus] = useState({ isLoading: true });

  const getLoadingStatus = useCallback(async () => {
    setRequestStatus(() => (true));
    try {
      const notifications = await apiRequest('/api/notifications') || [];
      if (mounted.current) {
        setNotificationState(notifications);
        setRequestStatus(() => ({ isLoading: false }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setRequestStatus(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, [org]);

  useEffect(() => {
    // get data
    getLoadingStatus().catch(console.error);
  }, [org, reloadApi]);

  return (
    <>

      <Badge
        color="success"
        variant="dot"
        invisible={!(notificationState?.length)}
        {...props}
      >
        <IconButton
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            color: 'primary.contrastText'
          }}
        >
          <BellIcon />
        </IconButton>
      </Badge>
      {!requestStatus.isLoading
        ? (
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom'
            }}
            keepMounted
            onClose={handleClose}
            open={open}
            PaperProps={{
              sx: { width: 320 }
            }}
          >
            <List>
              <ListSubheader sx={{ py: 1 }}>
                Notifications
              </ListSubheader>
              {notificationState.length !== 0
                ? notificationState.map((notification, index) => {
                  const Icon = icons.find((x) => x.label === notification.icon).icon;
                  return (
                    <ListItem
                      disableGutters
                      divider={notificationState.length > index + 1}
                      key={notification.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        p: 2
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', width: '100%' }}
                        justifyContent="space-between"
                      >
                        <Grid display="flex">
                          <Icon
                            fontSize="small"
                            sx={{ color: '#5046E4' }}
                          />
                          <Typography
                            color="textPrimary"
                            sx={{ ml: 1.25 }}
                            variant="body1"
                          >
                            {notification.subject}
                          </Typography>
                        </Grid>
                        <IconButton
                          sx={{ p: 0, }}
                          aria-label="dismiss"
                          onClick={() => {
                            apiRequest('/api/notifications/dismiss ', {
                              id: notification.id
                            }).then(() => {
                              setReloadApi(!reloadApi);
                            }).catch((err) => console.log(err));
                            const newState = [...notificationState];
                            newState.splice(index, 1);
                            setNotificationState(newState);
                          }}
                        >

                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {notification.text}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                      >
                        {format(parseISO(notification.timestamp), 'dd/MM/yyyy hh:mm a')}
                      </Typography>
                    </ListItem>
                  );
                })
                : (
                  <ListItem
                    disableGutters
                    key="empty"
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      p: 2
                    }}
                  >
                    <Typography>No notifications at the moment.</Typography>
                  </ListItem>
                )}
            </List>
          </Popover>
        )
        : null}
    </>
  );
};
