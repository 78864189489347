import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { InputField } from './input-field';
import { useAuth } from '../hooks/use-auth';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { Logout as LogoutIcon } from '../icons/logout';

export const AccountPopover = (props) => {
  const {
    // currentOrganization,
    // darkMode,
    onLanguageChange,
    // onOrganizationChange,
    onSwitchDirection,
    // onSwitchTheme,
    // organizations,
    rtlDirection,
    ...other
  } = props;
  // const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { changeOrg, orgs, logout, user, org } = useAuth();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  // const handleLanguageChange = (event) => {
  //   onLanguageChange(event.target.value);
  // };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2
        }}
        {...other}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: 'success.main',
            color: 'text.primary',
            height: 40,
            width: 40
          }}
        >
          {user?.email && user.email.charAt(0).toUpperCase()}
        </Avatar>
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            },
            flex: 1,
            ml: 1,
            minWidth: 120
          }}
        >
          <div>
            <Typography
              sx={{ color: 'primary.contrastText' }}
              variant="subtitle2"
            >
              {user?.email && user.email}
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: 'primary.contrastText',
              ml: 1
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <InputField
          fullWidth
          onChange={(event) => { changeOrg(event.target.value); }}
          select
          SelectProps={{ native: true }}
          value={org.id}
          sx={{
            display: {
              md: 'none'
            },
            pt: 2,
            px: 2
          }}
        >
          {orgs.map((organization) => (
            <option
              key={organization.id}
              value={organization.id}
            >
              {organization.name}
            </option>
          ))}
        </InputField>
        <List>
          <ListItem divider>
            <ListItemText
              secondary={user?.email && user.email}
              primary={org.name}
            />
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  // currentOrganization: PropTypes.object.isRequired,
  // darkMode: PropTypes.bool.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  // onOrganizationChange: PropTypes.func.isRequired,
  onSwitchDirection: PropTypes.func.isRequired,
  // onSwitchTheme: PropTypes.func.isRequired,
  // organizations: PropTypes.array.isRequired,
  rtlDirection: PropTypes.bool.isRequired
};
