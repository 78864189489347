/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Container, Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format, subMonths, startOfMonth } from 'date-fns';
import percentile from 'percentile';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { useAuth } from '../hooks/use-auth';
import { GroupBys } from '../utils/group-by-date';
import { DateControls } from '../components/insights-root/date-controls';
import { InputField } from '../components/input-field';

/* Utils */
import gtm from '../lib/gtm';

import { RadarChart } from '../components/survey/inactive/radar-chart';
import { Submissions } from '../components/survey/submissions';
import { Trends } from '../components/survey/value/trends';

import { demoId } from '../utils/demo';
import demoInsightsValueData from '../demo/rawInsightsValue.json';

export const SurveyValue = () => {
  const { apiRequest, org } = useAuth();
  const selectedGroupBy = GroupBys[2];
  const [loading, setLoading] = useState(true);
  const [topSpend, setTopSpend] = useState(25);
  const [topDiscount, setTopDiscount] = useState(25);
  const [controller, setController] = useState({
    data: null
  });

  const [selectedDateRange, setSelectedDateRange] = useState({ start: startOfMonth(subMonths(new Date(), 11)), end: new Date() });
  const xAxisRange = org.id === demoId ? selectedGroupBy.getRange(new Date('2021-09-01'), new Date('2022-08-31')) : selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));

  async function getData() {
    setLoading(true);
    const data = org.id === demoId ? demoInsightsValueData : await apiRequest('/api/insights/value', {
      start: format(selectedDateRange.start, 'yyyy-MM-dd'),
      end: format(selectedDateRange.end, 'yyyy-MM-dd'),
    });
    // get by spend
    const spend_percentile = percentile(
      100 - topSpend,
      [...data].sort((a, b) => +a.total_price - +b.total_price),
      (item) => +item.total_price
    );

    // get by discount
    const discount_percentile = percentile(
      topDiscount,
      [...data].sort((a, b) => +a.total_discount - +b.total_discount),
      (item) => +item.total_discount
    );

    const filtered_data = [...data].filter((x) => (+x.total_price >= +spend_percentile.total_price) && (+x.total_discount <= +discount_percentile.total_discount));
    setController({
      ...controller,
      data: filtered_data
    });

    setLoading(false);
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getData();
  }, [selectedDateRange]);
  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: md ? 1 : 2,
                flexDirection: md ? 'column' : 'row',
                textAlign: md ? 'center' : 'unset',
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/surveys"
                variant="text"
              >
                Return to surveys
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="subtitle2">Themes:</Typography>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/referrals"
                variant="text"
              >
                Growing customer referrals
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/inactive"
                variant="text"
              >
                Why do customers stop buying?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/value"
                variant="text"
              >
                Am I satisfying my high value customers?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/wallet"
                variant="text"
              >
                How do I increase my share of wallet?
              </Button>
            </Box>
          </Box>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: md ? '0' : 2,
                      flexDirection: md ? 'column' : 'row',
                      mb: 1,
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{ mb: 1 }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Am I satisfying my high value customers?
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="subtitle2">Select Date Range:</Typography>
                    <DateControls
                      defaultStart={selectedDateRange.start}
                      defaultEnd={selectedDateRange.end}
                      showCompare={false}
                      onDateChange={(start, end = null) => {
                        setSelectedDateRange({ start, end });
                      }}
                    />
                  </Box>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                  >
                    Understanding the needs and satisfaction of high value customers is key to retaining and growing this important customer segment. If you haven’t already done so, define your high value customer below then follow the analysis steps below to understand their customer satisfaction.
                  </Typography>

                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                  >
                    Define your high value customers
                  </Typography>

                  <Box sx={{ display: 'flex', flexGap: 1 }}>
                    <InputField
                      helperText="Top percentage of customers by highest spend"
                      label="Customer Spend"
                      name="topSpend"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <Typography
                            variant="caption"
                            sx={{ mr: 1 }}
                          >
                            %
                          </Typography>)
                      }}
                      onChange={(event) => setTopSpend(event.target.value)}
                      value={topSpend}
                    />
                    <Typography
                      sx={{ mt: 4, mx: 2 }}
                      gutterBottom
                      variant="subtitle2"
                      component="div"
                    >
                      and
                    </Typography>
                    <InputField
                      helperText="Top percentage of customers by lowest discount ratio"
                      label="Discount Ratio"
                      name="topDiscount"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <Typography
                            variant="caption"
                            sx={{ mr: 1 }}
                          >
                            %
                          </Typography>)
                      }}
                      onChange={(event) => setTopDiscount(event.target.value)}
                      value={topDiscount}
                    />

                  </Box>

                  <Button
                    sx={{ mt: 2 }}
                    onClick={() => { getData(); }}
                    variant="contained"
                  >
                    Apply
                  </Button>

                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <RadarChart
                title="1. High value customer satisfaction"
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    This chart compares your high value customers’ general expectations for service attributes (orange line) to their experience of these attributes from your business (blue area). A gap between the orange line and the blue area indicates a service attribute with poor customer satisfaction and this should be addressed lest it prompts high value customers to divert their spending to your competitors.
                    <br />
                    <br />
                    Prioritize those attributes with high expectations and poor experience for improvement.
                    <br />
                    <br />
                    For greater insight, look into customer satisfaction trends in chart 2 below.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Trends
                title="2. Trends in high value customer satisfaction scores"
                range={xAxisRange}
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    {'This chart allows you to analyse trends in your high value customers’ expectations and experience. Filter by customer experience attribute to identify any attributes for which there is a consistent and significant decline in experience compared to expectation. Any such attribute needs urgent attention.  '}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    Conversely, attributes for which the gap is closing are already improving and can be deprioritized.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Submissions
                title="3. Review survey comments for further insight"
                hasProperties={false}
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                  >
                    Pinpoint what should be improved
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    {'To pinpoint what needs to be improved, search for clues in the comments provided by survey respondents. '}
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    Also, look for recurring issues raised in:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your customer support communications.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your social media presence.
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    And, ideally, talk to some customers who have had unsatisfactory experiences.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
};
