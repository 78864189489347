import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle

} from '@mui/material';
import { Trash as TrashIcon } from '../icons/trash';
import { imagePath } from '../config';
import { Upload as UploadIcon } from '../icons/upload';
import { useDialog } from '../hooks/use-dialog';
import { ImagesUploaderGeneric } from './images-uploader-generic';

export const AssetModal = ({ handleSelect, value }) => {
  const [openDialog, handleOpenDialog, handleCloseDialog] = useDialog();
  const [selectedImages, setSelectedImages] = useState([]);
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          borderColor: 'neutral.200',
          borderRadius: 1,
          borderStyle: 'dashed',
          borderWidth: 1,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          outline: 'none',
          width: '100%',
          maxWidth: '126px',
          height: '126px',
          py: 2,
          '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: 'action.hover'
          },
        }}
        onClick={handleOpenDialog}
      >

        <Avatar
          sx={{
            backgroundColor: 'rgba(238, 240, 242, 1)',
            color: 'text.secondary',
            height: 36,
            width: 36,
            mb: 1,
          }}
        >
          <UploadIcon />
        </Avatar>

        <Button
          color="primary"
          variant="contained"
          component="span"
        >
          Upload
        </Button>

        <Typography
          align="center"
          sx={{ color: 'text.secondary', mt: 1 }}
          variant="caption"
        >
          Select images
        </Typography>
      </Box>
      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '690px'
          }
        }}
      >
        <DialogTitle>
          Select image
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={12}
            sx={{ mb: 2 }}
          >
            <Typography
              color="textPrimary"
              sx={{ mb: 1.25 }}
              variant="subtitle2"
            >
              Select an asset
            </Typography>
            {value
              ? (
                <Box
                  sx={{
                    borderRadius: 1,
                    boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
                    display: 'flex',
                    position: 'relative',
                    width: 'fit-content',
                    '& img': {
                      borderRadius: 1,
                      display: 'block',
                      maxWidth: 126
                    },
                    '&:hover': {
                      boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                      '& > button': {
                        display: 'block'
                      },
                      '& img': {
                        opacity: 0.3
                      }
                    }
                  }}
                >
                  <img
                    alt="Uploaded"
                    src={`${imagePath}${value}`}
                  />
                  <IconButton
                    color="error"
                    onClick={() => { setSelectedImages([]); }}
                    sx={{
                      display: 'none',
                      left: 0,
                      position: 'absolute',
                      top: 0

                    }}
                  >
                    <TrashIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <ImagesUploaderGeneric
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                />
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseDialog}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleSelect(selectedImages[0]);
            }}
            variant="contained"
          >
            Select Image
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AssetModal.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
