export const Typologies = [
  {
    value: 'ANALYTICAL',
    label: 'Analytical',
    color: '#1e8ed1'
  },
  {
    value: 'CONFORMIST',
    label: 'Conformist',
    color: '#73bf73'
  },
  {
    value: 'INTELLECTUAL',
    label: 'Intellectual',
    color: '#2940a7'
  },
  {
    value: 'TRADITIONALIST',
    label: 'Traditionalist',
    color: '#23a363'
  },
  {
    value: 'PIONEER',
    label: 'Pioneer',
    color: '#8f2c57'
  },
  {
    value: 'CONSPICUOUS',
    label: 'Conspicuous',
    color: '#deb821'
  },
  {
    value: 'INDIVIDUALIST',
    label: 'Individualist',
    color: '#df873f'
  },
  {
    value: 'ASPIRANT',
    label: 'Aspirant',
    color: '#b26486'
  },
  {
    value: 'AFFLUENTIAL',
    label: 'Affluential',
    color: '#6d39a0'
  },
  {
    value: 'PROTECTIONIST',
    label: 'Protectionist',
    color: '#af3e3b'
  },
  {
    value: 'ECCENTRIC',
    label: 'Eccentric',
    color: '#2dc7c4'
  },
  {
    value: 'SUBSISTENT',
    label: 'Subsistent',
    color: '#854e33'
  },
  {
    value: 'DEFAULT',
    label: 'No Typology',
    color: '#656565'
  }
];

export const randomTypology = () => Typologies[Math.floor(Math.random() * (12))];

export const getTypology = (typology) => Typologies.find((x) => x.value?.toUpperCase() === typology?.toUpperCase())?.label || 'Not Set';
