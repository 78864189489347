/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Avatar, Box, Button, Typography, IconButton, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { imagePath } from '../../config';
import { Upload as UploadIcon } from '../../icons/upload';

import { demoId } from '../../utils/demo';
import demoAssets from '../../demo/rawAssets.json';

export const ImagesUploader = ({ setSelectedImages, images, setImages, selectedImages, handleEdit, multiSelect, reload }) => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();

  async function getAssets() {
    try {
      const assets = org.id === demoId ? demoAssets : await apiRequest('/api/assets');
      if (mounted.current) {
        setImages(assets);
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setImages([]);
      }
    }
  }

  useEffect(() => {
    getAssets();
  }, [org, reload || null]);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleSaveImages = async (event) => {
    if (demoId !== org.id) {
      const x = await toBase64(event.target.files[0]);
      apiRequest('/api/assets/create', {
        assetData: x, fileType: event.target.files[0].type
      }).then(() => {
        getAssets();
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: !images.length ? '1fr' : ({
            md: 'repeat(auto-fill, 140px)',
            sm: 'repeat(4, 1fr)',
            xs: 'repeat(2, 1fr)'
          }),
          '& img': {
            borderRadius: 1,
            maxWidth: '100%'
          }
        }}
      >
        <label htmlFor="contained-button-file">
          <input
            accept="image/jpeg, image/png"
            type="file"
            onChange={handleSaveImages}
            style={{ display: 'none' }}
            id="contained-button-file"
          />

          <Box
            sx={{
              height: '100%',
              alignItems: 'center',
              borderColor: 'neutral.200',
              borderRadius: 1,
              borderStyle: 'dashed',
              borderWidth: 1,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              outline: 'none',
              width: '100%',
              py: 2,
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'action.hover'
              },
            }}
          >

            <Avatar
              sx={{
                backgroundColor: 'rgba(238, 240, 242, 1)',
                color: 'text.secondary',
                height: 36,
                width: 36,
                mb: 1,
              }}
            >
              <UploadIcon />
            </Avatar>

            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              Upload
            </Button>

            <Typography
              align="center"
              sx={{ color: 'text.secondary', mt: 1 }}
              variant="caption"
            >
              Select images
            </Typography>
          </Box>
        </label>

        {images.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((image) => (
          <Box
            key={image.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}
          >
            <Box
              sx={{
                aspectRatio: '1',
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
                '&::before': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: 1,
                  bottom: 0,
                  content: '""',
                  display: 'none',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0
                },
                ...selectedImages.includes(image.id) && {
                  boxShadow: (theme) => `0px 0px 0px 2px ${theme.palette.primary.main}`,
                  '&::before': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 1,
                    bottom: 0,
                    content: '""',
                    display: 'block',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0
                  },
                  '& button': {
                    display: 'inline-flex'
                  }
                },
                '&:hover': {
                  boxShadow: selectedImages.includes(image.id) ? (theme) => `0px 0px 0px 2px ${theme.palette.primary.main}` : (theme) => `0px 0px 0px 1px ${theme.palette.primary.main}`,
                  '&::before': {
                    display: 'block'
                  },
                  '& button': {
                    display: 'inline-flex'
                  }
                }
              }}
            >
              <img
                alt=""
                src={`${imagePath}${image.src}`}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />

              <IconButton
                color="primary"
                onClick={() => {
                  multiSelect === true
                    ? selectedImages.includes(image.id) ? setSelectedImages([...selectedImages.filter((x) => x !== image.id)]) : setSelectedImages([...selectedImages, image.id])
                    : selectedImages.includes(image.id) ? setSelectedImages([...selectedImages.filter((x) => x !== image.id)]) : setSelectedImages([image.id]);
                }}
                sx={{
                  p: '0',
                  m: '0',
                  top: 2,
                  color: 'text.secondary',
                  display: 'none',
                  position: 'absolute',
                  left: 2
                }}
              >
                <Checkbox
                  checked={selectedImages.includes(image.id)}
                />
              </IconButton>
              {handleEdit
                ? selectedImages.includes(image.id) ? null
                  : (
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(image.id)}
                      sx={{
                        p: '0',
                        m: '8px',
                        top: 2,
                        color: 'text.secondary',
                        display: 'none',
                        position: 'absolute',
                        right: 2,
                        border: '2px solid grey'
                      }}
                    >
                      <EditIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )

                : null }

            </Box>
          </Box>
        ))}
      </Box>

    </>
  );
};

ImagesUploader.propTypes = {
  setSelectedImages: PropTypes.func.isRequired,
  selectedImages: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  setImages: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  multiSelect: PropTypes.bool.isRequired,
  reload: PropTypes.bool,
};
