import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography, CircularProgress } from '@mui/material';

import toast from 'react-hot-toast';
import gtm from '../lib/gtm';

/* Components */
import { Submissions } from '../components/loyalty/submissions';
import { RewardsTable } from '../components/loyalty/rewards-table';
import { Info } from '../components/loyalty/info';
import { LoyaltyDialog } from '../components/loyalty/loyalty-dialog';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

import { demoId } from '../utils/demo';
import demoRewards from '../demo/rawLoyaltyRewards.json';
import demoEvents from '../demo/rawLoyaltyEvents.json';
import demoDiscounts from '../demo/rawDiscounts.json';
import demoOrg from '../demo/rawOrgData.json';

export const Loyalty = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [eventState, setEventState] = useState({ isLoading: true, data: [] });
  const [rewardId, setRewardId] = useState();
  const [modalEvent, setModalEvent] = useState('None');
  const [loyaltyRewards, setLoyaltyRewards] = useState({ isLoading: true, data: [] });
  const [loyaltyFlat, setLoyaltyFlat] = useState({ redeemed: [], changed: [] });
  const [reload, setReload] = useState(false);
  const [discounts, setDiscounts] = useState({ isLoading: true, data: [] });
  const [organizationState, setOrganizationState] = useState({ isLoading: true, data: [] });
  const getOrg = useCallback(async () => {
    setOrganizationState(() => ({ isLoading: true }));
    try {
      const orgData = org.id === demoId ? demoOrg : await apiRequest('/api/organisation');
      if (mounted.current) {
        setOrganizationState(() => ({ isLoading: false, data: orgData }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setOrganizationState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);
  const getLoyaltyRewards = useCallback(async () => {
    setLoyaltyRewards(() => ({ isLoading: true }));
    try {
      const loyaltyRewardsData = org.id === demoId ? demoRewards : await apiRequest('/loyalty/get-rewards');
      if (mounted.current) {
        setLoyaltyRewards(() => ({ isLoading: false, data: loyaltyRewardsData }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setLoyaltyRewards(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);
  const getEvents = useCallback(async () => {
    setEventState(() => ({ isLoading: true }));
    try {
      const eventsData = org.id === demoId ? demoEvents : await apiRequest('/api/events', {
        eventType: 'LOYALTY_CHANGE,LOYALTY_REDEEMED,LOYALTY_SIGNUP'
      });
      if (mounted.current) {
        setEventState(() => ({ isLoading: false, data: eventsData }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setEventState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    async function getDiscounts() {
      setDiscounts({ isLoading: true, data: [] });
      try {
        const discountsData = org.id === demoId ? demoDiscounts : await apiRequest('/loyalty/get-discounts') || [];
        if (mounted.current) {
          setDiscounts({ isLoading: false, data: discountsData });
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setDiscounts({
            isLoading: false,
            data: [],
          });
        }
      }
    }
    getDiscounts();
    getOrg();
    getEvents();
    getLoyaltyRewards();
    gtm.push({ event: 'page_view' });
  }, [org]);

  useEffect(() => {
    getLoyaltyRewards();
  }, [reload]);

  useEffect(() => {
    if (eventState.data && loyaltyRewards.data) {
      const loyaltyRedeemed = eventState?.data?.filter((x) => x.event_type === 'LOYALTY_REDEEMED').map((y) => {
        if (y?.properties) {
          const parsedProps = JSON.parse(y?.properties);
          return parsedProps.reduce((acc, curr) => ({
            ...acc,
            ...curr,
          }), {});
        }
        return {};
      });

      const loyaltyChange = eventState?.data?.filter((x) => x.event_type === 'LOYALTY_CHANGE').map((y) => {
        if (y?.properties) {
          const parsedProps = JSON.parse(y?.properties);
          return parsedProps.reduce((acc, curr) => ({
            ...acc,
            ...curr,
          }), {});
        }
        return {};
      });

      const loyaltySum = eventState?.data?.map((y) => {
        if (y?.properties) {
          const parsedProps = JSON.parse(y?.properties);
          return {
            ...y,
            properties: parsedProps.reduce((acc, curr) => ({
              ...acc,
              ...curr,
            }), {})
          };
        }
        return {
          ...y,
          properties: {}
        };
      });

      setLoyaltyFlat({ redeemed: loyaltyRedeemed, changed: loyaltyChange, all: loyaltySum, });
    }
  }, [loyaltyRewards, eventState]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleDelete = (deleteID) => {
    if (org.id !== demoId) {
      apiRequest('/loyalty/delete-reward', {
        id: deleteID
      }).then(() => {
        toast.success('Loyalty reward deleted');
        setReload(!reload);
      }).catch((err) => {
        console.log(err);
        toast.error('Loyalty reward not deleted');
      });
    } else {
      toast.success('Loyalty reward deleted');
    }
  };

  return (
    <>
      <Helmet>
        <title>Loyalty | AudienceZen Dashboard</title>
      </Helmet>
      <>
        {!eventState.isLoading && !loyaltyRewards.isLoading && !discounts.isLoading
          ? (
            <Box sx={{ backgroundColor: 'background.default' }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  pb: 2,
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  Loyalty
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xl={4}
                  lg={12}
                  xs={12}
                >
                  <Info
                    sx={{ height: '100%' }}
                    loyaltyFlat={loyaltyFlat}
                    organizationState={organizationState}
                  />
                </Grid>
                <Grid
                  item
                  xl={8}
                  xs={12}
                >
                  <RewardsTable
                    handleOpen={handleOpen}
                    setDialogOpen={setDialogOpen}
                    sx={{ height: '100%' }}
                    eventState={eventState}
                    setRewardId={setRewardId}
                    rewardId={rewardId}
                    setModalEvent={setModalEvent}
                    handleDelete={handleDelete}
                    loyaltyRewards={loyaltyRewards}
                    loyaltyFlat={loyaltyFlat}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Submissions
                    sx={{ height: '100%' }}
                    loyaltyFlat={loyaltyFlat}
                  />
                </Grid>
              </Grid>
              <LoyaltyDialog
                open={dialogOpen}
                onClose={handleClose}
                loyaltyRewards={loyaltyRewards}
                modalEvent={modalEvent}
                setModalEvent={setModalEvent}
                rewardId={rewardId}
                discounts={discounts}
                setReload={setReload}
                reload={reload}
              />
            </Box>
          )
          : (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <CircularProgress
                color="primary"
                size={100}
              />

            </Box>
          )}
      </>
    </>
  );
};
