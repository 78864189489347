import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { RoleGuard } from './components/role-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';
import { Onboard } from './containers/onboard';
import { EmailLayout } from './containers/email-layout';
import { EmailTemplates } from './containers/email-templates';
import { EmailTemplatesCreate } from './containers/email-templates-create';

/* Our Parent Frames */
import { Simple } from './containers/simple';

/* Our Page Frames */
import { SummaryRoot } from './containers/summary-root';
import { InsightsRoot } from './containers/insights-root';
import { InsightsReports } from './containers/insights-reports';
import { InsightsScheduled } from './containers/insights-scheduled';
// import { Nps } from './containers/insights-nps';
// import { InsightsFeedback } from './containers/insights-feedback';
import { CampaignSummary } from './containers/campaign-summary';
import { CampaignScreen } from './containers/campaign-screen';
import { TextEditor } from './containers/text-editor';
import { CodeEditor } from './containers/code-editor';
import { SurveyReferrals } from './containers/survey-referrals';
import { SurveyDrilldown } from './containers/survey-drilldown';
import { SurveyRespondent } from './containers/survey-respondent';
import { SurveyValue } from './containers/survey-value';
import { SurveyWallet } from './containers/survey-wallet';
import { SurveyInactive } from './containers/survey-inactive';
import { SurveyScreen } from './containers/survey-screen';
import { SurveyEditor } from './containers/survey-editor';
import { AutomationsScreen } from './containers/automations-screen';
import { AutomationsCreate } from './containers/automations-create';
import { AutomationsEditor } from './containers/automations-editor';
import { CampaignEditor } from './containers/campaign-editor';
import { Loyalty } from './containers/loyalty';
import { Discounts } from './containers/discounts';
import { ProductFeed } from './containers/product-feed';
import { CreateNewFeed } from './containers/create-new-feed';
import { Assets } from './containers/assets';
import { GettingStarted } from './containers/getting-started';
import { Analysis } from './containers/analysis';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
const Register = Loadable(lazy(() => import('./containers/register').then((module) => ({ default: module.Register }))));
const VerifyCode = Loadable(lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))));

const Customers = Loadable(lazy(() => import('./containers/customers').then((module) => ({ default: module.Customers }))));
const CustomerSummary = Loadable(lazy(() => import('./containers/customer-summary').then((module) => ({ default: module.CustomerSummary }))));

const Organization = Loadable(lazy(() => import('./containers/organization').then((module) => ({ default: module.Organization }))));
const OrganizationBilling = Loadable(lazy(() => import('./containers/organization-billing').then((module) => ({ default: module.OrganizationBilling }))));
const OrganizationGeneral = Loadable(lazy(() => import('./containers/organization-general').then((module) => ({ default: module.OrganizationGeneral }))));
const OrganizationTeam = Loadable(lazy(() => import('./containers/organization-team').then((module) => ({ default: module.OrganizationTeam }))));
const OrganizationIntegrations = Loadable(lazy(() => import('./containers/organization-integrations').then((module) => ({ default: module.OrganizationIntegrations }))));
const OrganizationScripts = Loadable(lazy(() => import('./containers/organization-scripts').then((module) => ({ default: module.OrganizationScripts }))));

const Typologies = Loadable(lazy(() => import('./containers/typologies').then((module) => ({ default: module.Typologies }))));

const routes = [
  {
    path: '/',
    element: (
      <Navigate
        to="/dashboard/summary"
        replace
      />
    )
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: 'onboard',
    element: (
      <AuthGuard>
        <Onboard />
      </AuthGuard>
    )
  },
  {
    path: 'email-editor',
    element: (
      <AuthGuard>
        <EmailLayout />
      </AuthGuard>
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboard/summary"
            replace
          />
        )
      },
      {
        path: 'getting-started',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <GettingStarted />
          }
        ]
      },
      {
        path: 'summary',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <SummaryRoot />
          }
        ]
      },
      {
        path: 'insights',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <InsightsRoot />
          },
          {
            path: 'reports',
            element: <InsightsReports />
          },
          {
            path: 'scheduled',
            element: <InsightsScheduled />
          },
          // {
          //   path: 'NPS',
          //   element: <Nps />
          // },
          // {
          //   path: 'feedback',
          //   element: <InsightsFeedback />
          // },
        ]
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <Customers />,
          },
          {
            path: '/:customerId',
            element: <CustomerSummary />,
          },
        ]
      },
      {
        path: 'Analysis',
        children: [
          {
            path: '/',
            element: <Analysis />,
          },
        ]
      },
      {
        path: 'organization',
        element: (
          <RoleGuard>
            <Organization />
          </RoleGuard>
        ),
        children: [
          {
            path: '/',
            element: <OrganizationGeneral />
          },
          {
            path: '/team',
            element: <OrganizationTeam />
          },
          {
            path: '/billing',
            element: <OrganizationBilling />
          },
          {
            path: '/integrations',
            element: <OrganizationIntegrations />
          },
          {
            path: '/scripts',
            element: <OrganizationScripts />
          },
        ]
      },
      {
        path: '/text-editor',
        element: <TextEditor />
      },
      {
        path: '/html-editor',
        element: <CodeEditor />
      },
      {
        path: 'campaigns',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <CampaignScreen />
          },
          {
            path: '/edit/:campaignId',
            element: <CampaignEditor />
          },
          {
            path: ':campaignId',
            element: <CampaignSummary />
          },
        ]
      },
      {
        path: 'email-templates',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <EmailTemplates />
          },
          {
            path: '/create',
            element: <EmailTemplatesCreate />
          },
        ]
      },
      {
        path: 'loyalty',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <Loyalty />
          },
        ]
      },
      {
        path: 'discounts',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <Discounts />
          },
        ]
      },
      {
        path: 'assets',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <Assets />
          },
        ]
      },
      {
        path: 'product-feed',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <ProductFeed />
          },
          {
            path: '/create-new-feed',
            element: <CreateNewFeed />
          },
        ]
      },
      {
        path: 'automations',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <AutomationsScreen />
          },
          {
            path: '/create',
            element: <AutomationsCreate />
          },
          {
            path: '/edit/:automationId',
            element: <AutomationsEditor />
          },
        ]
      },
      {
        path: 'surveys',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <SurveyScreen />
          },
          {
            path: '/edit/:surveyId',
            element: <SurveyEditor />
          },
          {
            path: '/referrals',
            element: <SurveyReferrals />
          },
          {
            path: '/drilldown',
            element: <SurveyDrilldown />
          },
          {
            path: '/drilldown/respondent',
            element: <SurveyRespondent />
          },
          {
            path: '/inactive',
            element: <SurveyInactive />
          },
          {
            path: '/value',
            element: <SurveyValue />
          },
          {
            path: '/wallet',
            element: <SurveyWallet />
          },
        ]
      },
      {
        path: 'typologies',
        element: <Typologies />
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
