import Chart from 'react-apexcharts';
import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  Typography,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { StatusBadge } from '../status-badge';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { Typologies } from '../../utils/typology';
import { DateControls } from './date-controls';
import { demoId } from '../../utils/demo';
import demoTypologyPerformanceData from '../../demo/rawTypologyPerformance.json';

export const DonutChart = (props) => {
  const theme = useTheme();
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const firstOfLastMonth = org.id === demoId ? startOfMonth(subMonths(new Date('2022-08-01'), 1)) : startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = org.id === demoId ? endOfMonth(subMonths(new Date('2022-08-01'), 1)) : endOfMonth(subMonths(new Date(), 1));
  const [performanceData, setPerformanceData] = useState({ isLoading: true, data: [] });
  const [selectedDateRange, setSelectedDateRange] = useState({ start: firstOfLastMonth, end: endOfLastMonth });

  const getPerformance = useCallback(async () => {
    setPerformanceData(() => ({ isLoading: true, data: '' }));
    const startDate = format(selectedDateRange.start, 'yyyy-MM-dd');
    const endDate = format(selectedDateRange.end, 'yyyy-MM-dd');
    try {
      const performance = org.id === demoId ? demoTypologyPerformanceData : await apiRequest('/api/sales/typology-performance', {
        startDate, endDate
      }) || [];
      if (mounted.current) {
        setPerformanceData(() => ({ isLoading: false, data: performance }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setPerformanceData(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, [org, selectedDateRange]);

  useEffect(() => {
    getPerformance().catch(console.error);
  }, [org, selectedDateRange]);

  if (performanceData.isLoading) {
    return (
      <Card
        variant="outlined"
        {...props}
        sx={{ '& .MuiCardHeader-root': { flexDirection: 'column', alignItems: 'flex-start' },
          '& .MuiCardHeader-action': {
            mt: '0.3em' },
          height: '100%',
        }}
      >
        <CardHeader
          title="Top 3 Typologies"
          sx={{ height: '80px' }}
          action={(
            <DateControls
              sx={{ width: 'auto', display: 'flex' }}
              showCompare={false}
              onDateChange={(start, end) => {
                setSelectedDateRange({ start, end });
              }}
            />
)}
        />
        <Divider />
        <CardContent sx={{ height: 'calc(100% - 80px)' }}>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress
              color="primary"
              size={100}
            />

          </Box>
        </CardContent>
      </Card>
    );
  }

  let dataset = [];

  const performanceDataArray = performanceData.data?.length
    ? Typologies.filter((typology) => typology.value.toUpperCase() !== 'DEFAULT' && performanceData.data.some((x) => x.context.toUpperCase() === typology.value.toUpperCase())).map((typology) => {
      const dataPoints = performanceData.data.find((x) => x.context.toUpperCase() === typology.value.toUpperCase());
      return dataPoints;
    }) : [];

  // total from all typologies, excluding the default unset
  const preformaceSum = performanceDataArray.map((x, i) => (
    performanceDataArray[i].total_price
  )).reduce((acc, curr) => acc + curr, 0);

  performanceDataArray.sort((a, b) => b.total_price - a.total_price);

  // loop through only 3 times
  dataset = performanceDataArray.length ? ['', '', ''].map((x, i) => ({
    color: `${Typologies.find((y) => y.value.toUpperCase() === performanceDataArray[i].context.toUpperCase()).color}`,
    name: `${Typologies.find((y) => y.value.toUpperCase() === performanceDataArray[i].context.toUpperCase()).label}`,
    data: performanceDataArray[i].total_price
  })) : [];

  // total from all typologies, excluding the default unset - the sum of the top 3
  const newRemainderSum = preformaceSum - (dataset.map((item) => item.data).reduce((previousValue, currentValue) => previousValue + currentValue, 0));

  dataset.push({ color: '#21D59B', name: 'Other', data: newRemainderSum });

  const chartOptions = {
    chart: {
      background: 'transparent',
      animations: {
        enabled: false,
      }
    },
    colors: dataset.map((item) => item.color),
    labels: dataset.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      show: false
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${(val / 1000).toFixed(1)}K`;
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    theme: {
      mode: theme.palette.mode
    }
  };
  return (
    <Card
      variant="outlined"
      {...props}
      sx={{ '& .MuiCardHeader-root': { flexDirection: 'column', alignItems: 'flex-start' },
        '& .MuiCardHeader-action': {
          mt: '0.3em',
          height: '100%'
        },
        height: '100%'
      }}
    >
      <CardHeader
        title="Top 3 Typologies"
        sx={{ height: '80px' }}
        action={(
          <DateControls
            sx={{ width: 'auto', display: 'flex',

            }}
            showCompare={false}
            onDateChange={(start, end) => {
              setSelectedDateRange({ start, end });
            }}
          />
)}
      />
      <Divider />
      <CardContent>
        <Chart
          options={chartOptions}
          type="donut"
          series={dataset.map((item) => item.data)}
        />
        <List>
          {dataset.map((item, index) => (
            <ListItem
              disableGutters
              divider={dataset.length > index + 1}
              key={item.name}
              sx={{ display: 'flex' }}
            >
              <StatusBadge
                color={item.color}
                sx={{ mr: 1 }}
              />
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {item.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {`$${item.data.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
