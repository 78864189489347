/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useState } from 'react';
import {
  // Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { GroupBys } from '../../../utils/group-by-date';
import { ActionsMenu } from '../../actions-menu';

const options = [
  'Product Selection', 'Ease of Use', 'Fulfillment', 'Product Quality', 'Competitive Price', 'Communication', 'Returns', 'Customer Support'
];

export const Trends = ({ title = '3. Trends in customer satisfaction', range, data, ...rest }) => {
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const [selectedOption, setSelectedOption] = useState(0);

  const ranges = options.map((x, i) => ({
    label: x,
    onClick: () => { setSelectedOption(i); }
  }));

  const seriesRaw = [...range].map((x) => {
    const fData = [...data].filter((y) => {
      const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
      return (formattedDate === x);
    }).reduce((acc, curr) => {
      let properties = curr.properties ? JSON.parse(curr.properties) : [];
      properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
      if (properties && properties.nps !== undefined && properties.nps < 86) {
        acc = {
          expectations: [
            acc.expectations[0] + +properties.expectations_product_selection,
            acc.expectations[1] + +properties.expectations_ease_of_use,
            acc.expectations[2] + +properties.expectations_fulfillment,
            acc.expectations[3] + +properties.expectations_quality,
            acc.expectations[4] + +properties.expectations_pricing,
            acc.expectations[5] + +properties.expectations_communication,
            acc.expectations[6] + +properties.expectations_returns,
            acc.expectations[7] + +properties.expectations_service,
          ],
          expectations_freq: [
            acc.expectations_freq[0] + 1,
            acc.expectations_freq[1] + 1,
            acc.expectations_freq[2] + 1,
            acc.expectations_freq[3] + 1,
            acc.expectations_freq[4] + 1,
            acc.expectations_freq[5] + 1,
            acc.expectations_freq[6] + 1,
            acc.expectations_freq[7] + 1,
          ],
          performance: [
            acc.performance[0] + +properties.performance_product_selection,
            acc.performance[1] + +properties.performance_ease_of_use,
            acc.performance[2] + +properties.performance_fulfillment,
            acc.performance[3] + +properties.performance_quality,
            acc.performance[4] + +properties.performance_pricing,
            acc.performance[5] + +properties.performance_communication,
            acc.performance[6] + +properties.performance_returns,
            acc.performance[7] + +properties.performance_service,
          ],
          performance_freq: [
            acc.performance_freq[0] + 1,
            acc.performance_freq[1] + 1,
            acc.performance_freq[2] + 1,
            acc.performance_freq[3] + 1,
            acc.performance_freq[4] + 1,
            acc.performance_freq[5] + 1,
            acc.performance_freq[6] + 1,
            acc.performance_freq[7] + 1,
          ],
        };
      }
      return acc;
    }, {
      expectations: [0, 0, 0, 0, 0, 0, 0, 0],
      expectations_freq: [0, 0, 0, 0, 0, 0, 0, 0],
      performance: [0, 0, 0, 0, 0, 0, 0, 0],
      performance_freq: [0, 0, 0, 0, 0, 0, 0, 0],
    });

    return {
      expectations: fData.expectations[selectedOption] ? (fData.expectations[selectedOption] / fData.expectations_freq[selectedOption]).toFixed(2) : 0,
      performance: fData.performance[selectedOption] ? (fData.performance[selectedOption] / fData.performance_freq[selectedOption]).toFixed(2) : 0,
    };
  });

  const series = [
    {
      name: 'Expectations',
      data: [
        ...seriesRaw.map((x) => x.expectations),
      ],
    },
    {
      name: 'Experience',
      data: [
        ...seriesRaw.map((x) => x.performance),
      ]
    }
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false,
      }
    },
    colors: ['#F68338', '#00B0F0'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      // padding: {
      //   left: 50,
      //   right: 50
      // },
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: range,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter(val) {
          return val.toFixed(0);
        },
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title={title}
        action={(
          <ActionsMenu
            actions={ranges}
            label={options[selectedOption]}
            size="small"
            variant="text"
          />
        )}
      />
      <Divider />
      <CardContent>
        <Chart
          options={chartOptions}
          series={series}
          type="area"
          height={400}
        />
      </CardContent>
    </Card>
  );
};

Trends.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};
