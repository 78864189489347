import { Grid, Box, Typography, Divider } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const InfoTable = (props) => {
  const { caption, value, highlightCell, divider, textDecor, headerStyle } = props;
  InfoTable.propTypes = {
    caption: PropTypes.string,
    value: PropTypes.string,
    textDecor: PropTypes.string,
    headerStyle: PropTypes.string,
    highlightCell: PropTypes.bool,
    divider: PropTypes.bool,
  };

  return (
    <>
      <Box
        variant="outlined"
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: '4em',
          border: 'none',
          backgroundColor: highlightCell ? '#F9FAFB' : 'inherit',
          borderRadius: 0,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 2, display: 'grid', gridAutoFlow: 'column' }}
          gap={5}
        >
          <Typography
            variant={headerStyle}
            sx={{ textDecoration: textDecor, wordBreak: 'break-all' }}
          >
            {caption}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: '#555' }}
          >
            {value}
          </Typography>
        </Grid>
      </Box>
      {divider && <Divider />}
    </>
  );
};
