import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { withStyles } from '@mui/styles';
import { Slider, Typography, MenuItem, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import getCroppedImg from '../../utils/crop-image';
import { styles } from './styles';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { imagePath } from '../../config';

import { demoId } from '../../utils/demo';

const aspectList = [
  { label: '1:1', value: '1,1' },
  { label: '2:1', value: '2,1' },
  { label: '1:2', value: '1,2' },
  { label: '1.91:1', value: '1.91,1' },
  { label: '2:3', value: '2,3' },
  { label: '3:2', value: '3,2' },
  { label: '4:3', value: '4,3' },
  { label: '3:4', value: '3,4' },
  { label: '16:9', value: '16,9' },
  { label: '9:16', value: '9,16' }
];

const Demo = ({ classes, asset, setAsset }) => {
  const { apiRequest, org } = useAuth();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspect] = useState([4, 3]);

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const onCropComplete = useCallback((croppedArea, scopedCroppedAreaPixels) => {
    setCroppedAreaPixels(scopedCroppedAreaPixels);
  }, []);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        `${imagePath}${asset?.src}`,
        croppedAreaPixels,
        rotation
      );
      const b64Data = await toBase64(croppedImage);
      if (org.id !== demoId) {
        apiRequest('/api/assets/create', {
          assetData: b64Data, fileType: 'image/png'
        }).then(() => {
          setAsset(null);
        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handleAspectChange = (a, b) => {
    setAspect([a, b]);
  };
  return (
    <div>
      <div className={classes.cropContainer}>

        <Cropper
          image={`${imagePath}${asset?.src}`}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatio[0] / aspectRatio[1]}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Grid
        container
        sx={{ marginY: '1em' }}
        gap={3}
      >
        <Grid
          container
          gap={3}
          sx={{ flexDirection: matchesMD ? 'column' : 'row', marginLeft: '16px' }}
        >
          <Grid className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoomAmount) => setZoom(zoomAmount)}
            />
          </Grid>
          <Grid className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Rotation
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={2}
              aria-labelledby="Rotation"
              classes={{ root: classes.slider }}
              onChange={(e, rotationAmount) => setRotation(rotationAmount)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          gap={5}
          sx={{ flexDirection: matchesMD ? 'column' : 'row' }}
        >
          <InputField
            fullWidth={matchesMD}
            label="Aspect Ratio Numerator"
            value={aspectRatio[0]}
            onChange={(e) => (e.target.value > 0 ? handleAspectChange(e.target.value, aspectRatio[1]) : null)}
            type="number"
            sx={{ maxWidth: matchesMD ? 'inherit' : '200px', width: '100%' }}

          />
          <InputField
            fullWidth={matchesMD}
            label="Aspect Ratio Denominator"
            value={aspectRatio[1]}
            onChange={(e) => (e.target.value > 0 ? handleAspectChange(aspectRatio[0], e.target.value) : null)}
            type="number"
            sx={{ maxWidth: matchesMD ? 'inherit' : '200px', width: '100%' }}
          />
          <InputField
            fullWidth={matchesMD}
            defaultValue={aspectList[0].value}
            label="Aspect Ratio"
            onChange={(e) => {
              handleAspectChange(e.target.value.split(',')[0], e.target.value.split(',')[1]);
            }}
            select
            sx={{ maxWidth: matchesMD ? 'inherit' : '200px', width: '100%' }}
          >
            {aspectList.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </InputField>

          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
          >
            Save Image
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const ImageEditor = withStyles(styles)(Demo);

export default ImageEditor;

Demo.propTypes = {
  classes: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  setAsset: PropTypes.func.isRequired,
};
