import { Box, Button, Card, Container, Table, TableBody, TableCell, TableRow, Typography, Grid, TableHead, Paper, TableContainer } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { InputField } from '../input-field';
import { Scrollbar } from '../scrollbar';
/* Components */
import { Plus as PlusIcon } from '../../icons/plus';
import { useAuth } from '../../hooks/use-auth';

export const ProductsEditor = ({ productFeeds, connections, feedId, setNavState, navState, setReload, reload }) => {
  const { apiRequest } = useAuth();
  const currentFeed = productFeeds.data.feeds.filter((x) => x.id === feedId)[0];
  // filter returns array of objects hence my usage of index
  const shopifyConnectionID = connections.filter((x) => x.source === 'SHOPIFY')[0]?.id;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // format, check if nav state is edit, if so we want to check for the data inside of the selected product feed. If the data exists it will be added to the array, if not it will
      // generate an array with a single empty string to render a single blank textfield. To accomplish this i have chained ternaries.
      // if navState is Edit -> if data isnt empty set value to data -> else set to array with empty string -> else navState isnt Edit set value to array with empty string
      name: navState === 'Edit' ? currentFeed.name ? currentFeed.name : '' : '',
      brands: navState === 'Edit' ? currentFeed.brands?.split(',') ? currentFeed.brands?.split(',') : [''] : [''],
      types: navState === 'Edit' ? currentFeed.types?.split(',') ? currentFeed.types?.split(',') : [''] : [''],
      skus: navState === 'Edit' ? currentFeed.skus?.split(',') ? currentFeed.skus?.split(',') : [''] : [''],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255)
        .test(
          'Product Feed name not unique',
          'Product Feed name not unique',
          (value) => {
            const isUnique = !productFeeds.data.feeds.some((x) => x?.name?.toLowerCase() === value?.toLowerCase());

            return navState === 'Edit' ? true : isUnique;
          }
        )
        .required('Name is required'),
      brands: Yup.array().of(Yup.string()),
      types: Yup.array().of(Yup.string()),
      skus: Yup.array().of(Yup.string()),
    }),
    onSubmit: async (values, helpers) => {
      try {
        switch (navState) {
          case 'Create':
            apiRequest('/feeds/create', {
              name: values.name,
              data_connection_id: shopifyConnectionID,
              brands: formik.values.brands.map((x) => x.trim()).filter((x) => x !== '').join(),
              types: formik.values.types.map((x) => x.trim()).filter((x) => x !== '').join(),
              skus: formik.values.skus.map((x) => x.trim()).filter((x) => x !== '').join(),
            }).then(() => {
              toast.success('Product Feed created');
              setReload(!reload);
            }).catch((err) => {
              toast.success('Product Feed failed to be created');
              console.log(err);
            });
            break;

          case 'Edit':
            apiRequest('/feeds/update', {
              id: feedId,
              name: values.name,
              data_connection_id: shopifyConnectionID,
              brands: formik.values.brands.map((x) => x.trim()).filter((x) => x !== '').join(),
              types: formik.values.types.map((x) => x.trim()).filter((x) => x !== '').join(),
              skus: formik.values.skus.map((x) => x.trim()).filter((x) => x !== '').join(),
            }).then(() => {
              setReload(!reload);
              toast.success('Product Feed updated');
            }).catch((err) => {
              toast.success('Product Feed failed to update');
              console.log(err);
            });
            break;

          default:
            break;
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        formik.resetForm();
        setNavState('Overview');
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >

          <Button
            size="large"
            startIcon={<ArrowBackIcon fontSize="small" />}
            onClick={() => {
              setNavState('Overview');
            }}
          >
            <Box display={{ xs: 'none', md: 'block' }}>Return to Overview</Box>
          </Button>

        </Box>
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          md={navState === 'Create' ? 12 : 12}
          xl={navState === 'Create' ? 12 : 4}
          xs={12}
          item
        >
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginBottom: '2em',
              gap: 1,
              padding: 2,
              mb: 2,
            }}
          >
            <Typography variant="h5">{`${navState} Feed`}</Typography>
            <Grid
              container
              direction="column"
              gap="10px"
            >
              <Typography variant="subtitle1">Name</Typography>
              <InputField
                name="name"
                sx={{ mb: '1em' }}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Typography variant="subtitle1">Brands</Typography>
              {formik.values.brands.map((brand, index) => {
                const isTouched = (formik.touched.brands?.length && formik.touched.brands[index]) || [];
                const isErrored = (formik.errors.brands?.length && formik.errors.brands[index]) || [];
                return (
                  <Grid
                    container
                    key={`${index}a`}
                    sx={{ mb: '10px' }}
                  >
                    <InputField
                      fullWidth
                      name={`brands.${index}.brand`}
                      error={Boolean(isTouched && isErrored)}
                      helperText={isTouched && isErrored}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue(`brands.${index}`, e.target.value);
                      }}
                      value={brand}
                    />
                  </Grid>
                );
              })}
              <Grid container>
                <Button
                  color="primary"
                  onClick={() => {
                    if (formik.values.brands[formik.values.brands.length - 1].trim().length > 0) {
                      formik.setFieldValue(`brands.${formik.values.brands.length}`, '');
                    }
                  }}
                  size="small"
                  startIcon={<PlusIcon />}
                  sx={{ mr: 2 }}
                  variant="text"
                >
                  Add Brand
                </Button>
                <Button
                  onClick={() => {
                    if (formik.values.brands.length > 1) {
                      formik.setFieldValue('brands', formik.values.brands.slice(0, -1));
                    } else {
                      formik.setFieldValue('brands.0', '');
                    }
                  }}
                  size="small"
                  sx={{ color: 'text.secondary' }}
                  variant="text"
                >
                  Remove
                </Button>
              </Grid>
              <Typography variant="subtitle1">Types</Typography>
              {formik.values.types.map((type, index) => {
                const isTouched = (formik.touched.types?.length && formik.touched.types[index]) || [];
                const isErrored = (formik.errors.types?.length && formik.errors.types[index]) || [];
                return (
                  <Grid
                    container
                    key={`${index}a`}
                    sx={{ mb: '10px', }}
                  >
                    <InputField
                      fullWidth
                      name={`types.${index}.type`}
                      error={Boolean(isTouched && isErrored)}
                      helperText={isTouched && isErrored}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue(`types.${index}`, e.target.value);
                      }}
                      value={type}
                    />
                  </Grid>
                );
              })}
              <Grid container>
                <Button
                  color="primary"
                  onClick={() => {
                    if (formik.values.types[formik.values.types.length - 1].trim().length > 0) {
                      formik.setFieldValue(`types.${formik.values.types.length}`, '');
                    }
                  }}
                  size="small"
                  startIcon={<PlusIcon />}
                  sx={{ mr: 2 }}
                  variant="text"
                >
                  Add Type
                </Button>
                <Button
                  onClick={() => {
                    if (formik.values.types.length > 1) {
                      formik.setFieldValue('types', formik.values.types.slice(0, -1));
                    } else {
                      formik.setFieldValue('types.0', '');
                    }
                  }}
                  size="small"
                  sx={{ color: 'text.secondary' }}
                  variant="text"
                >
                  Remove
                </Button>
              </Grid>
              <Typography variant="subtitle1">SKUs</Typography>
              {formik.values.skus.map((sku, index) => {
                const isTouched = (formik.touched.skus?.length && formik.touched.skus[index]) || [];
                const isErrored = (formik.errors.skus?.length && formik.errors.skus[index]) || [];
                return (
                  <Grid
                    container
                    key={`${index}a`}
                    sx={{ mb: '10px', }}
                  >
                    <InputField
                      fullWidth
                      name={`skus.${index}.sku`}
                      error={Boolean(isTouched && isErrored)}
                      helperText={isTouched && isErrored}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue(`skus.${index}`, e.target.value);
                      }}
                      value={sku}
                    />
                  </Grid>
                );
              })}
              <Grid container>
                <Button
                  color="primary"
                  onClick={() => {
                    if (formik.values.skus[formik.values.skus.length - 1].trim().length > 0) {
                      formik.setFieldValue(`skus.${formik.values.skus.length}`, '');
                    }
                  }}
                  size="small"
                  startIcon={<PlusIcon />}
                  sx={{ mr: 2 }}
                  variant="text"
                >
                  Add SKU
                </Button>
                <Button
                  onClick={() => {
                    if (formik.values.skus.length > 1) {
                      formik.setFieldValue('skus', formik.values.skus.slice(0, -1));
                    } else {
                      formik.setFieldValue('skus.0', '');
                    }
                  }}
                  size="small"
                  sx={{ color: 'text.secondary' }}
                  variant="text"
                >
                  Remove
                </Button>
              </Grid>
              <Grid container>
                <Button
                  color="primary"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  sx={{ mr: 2 }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    setNavState('Overview');
                  }}
                  sx={{ color: 'text.secondary' }}
                  variant="text"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid
          xl={8}
          md={12}
          xs={12}
          item
        >

          {navState === 'Edit'
            ? (
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Scrollbar>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Product Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Brand
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                            >
                              SKU
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentFeed.products.map((item) => (
                          <TableRow
                            key={item.name}
                            sx={{
                              '&:nth-of-type(even)': {
                                backgroundColor: '#F3F4F7',
                              }
                            }}
                          >
                            <TableCell width="25%">
                              <Grid
                                container
                                gap="10px"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  display="flex"
                                  alignItems="center"
                                  sx={{ height: '120px', width: '120px' }}
                                >
                                  {item.image_src
                                    ? (
                                      <img
                                        src={item.image_src}
                                        alt={item.name}
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                      />
                                    )
                                    : <CardGiftcardIcon sx={{ height: '100%', width: '100%' }} />}

                                </Grid>
                                {item.name}
                              </Grid>
                            </TableCell>

                            <TableCell width="25%">
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {' '}
                                {item.brand}
                              </Typography>
                            </TableCell>

                            <TableCell width="25%">
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {' '}
                                {item.type}
                              </Typography>
                            </TableCell>

                            <TableCell width="25%">
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {' '}
                                {item.sku}
                              </Typography>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Scrollbar>
              </Card>
            )
            : navState === 'Edit' ? (
              <Grid
                container
                direction="column"
                sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
              >
                <Typography variant="h5">Save feed to view products</Typography>
              </Grid>
            )
              : null}

        </Grid>
      </Grid>

    </Container>
  );
};

ProductsEditor.propTypes = {
  productFeeds: PropTypes.object,
  connections: PropTypes.arrayOf(PropTypes.object),
  feedId: PropTypes.string,
  setNavState: PropTypes.func,
  navState: PropTypes.string,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
};
