/* eslint-disable camelcase */
import { useLocation, useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography, Grid, IconButton, MenuItem, ListItemIcon, ListItemText, List, ListItem, CircularProgress, InputAdornment } from '@mui/material';
import 'react-sortable-tree/style.css';
import SortableTree, { removeNodeAtPath, changeNodeAtPath, getNodeAtPath } from 'react-sortable-tree';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import numeral from 'numeral';
import { Scrollbar } from '../components/scrollbar';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { useDialog } from '../hooks/use-dialog';
import { useAuth } from '../hooks/use-auth';
import { isDev } from '../utils/is-dev';
import { Adjustments as AdjustmentsIcon } from '../icons/adjustments';
import { InputField } from '../components/input-field';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
  isGreaterThanPercent,
  isLessThanPercent,
} from '../utils/filter-operators';
import { FilterDialog } from '../components/filter-dialog';
import { CustomerProperties } from '../utils/properties';
import { EventTypes } from '../utils/event-types';
import { Plus as PlusIcon } from '../icons/plus';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { StatusSelect } from '../components/status-select';
import { ActionDialog } from '../components/automation/action-dialog';
import { PropertyList } from '../components/property-list';
import { PropertyListItem } from '../components/property-list-item';
import { demoId } from '../utils/demo';
import demoFlow from '../demo/rawFlowEditorData.json';
import demoCampaigns from '../demo/rawCampaigns.json';
import demoGroups from '../demo/groups.json';
import demoForms from '../demo/rawFormsData.json';

/* Utils */
import gtm from '../lib/gtm';

const statusOptions = [
  {
    color: 'info.main',
    label: 'Draft',
    value: 'DRAFT'
  },
  {
    color: 'success.main',
    label: 'Published',
    value: 'PUBLISHED'
  }
];

const customerFilterProperties = [...CustomerProperties];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
  isGreaterThanPercent,
  isLessThanPercent,
];

export const AutomationsEditor = () => {
  const { apiRequest, org } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedAutomation = useParams();

  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const [openCustomerFilterDialog, setOpenCustomerFilterDialog] = useState(false);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [selectedNode, setSelectedNode] = useState({ path: null, node: null });
  const [controller, setController] = useState({
    treeData: [],
    customer_filters: [],
    trigger: '',
    status: 'DRAFT',
    trigger_context: '',
    wait: 0,
    name: '',
    id: null,
    total_sent: 0,
    total_opened: 0,
    total_clicked: 0,
    unsubscribed: 0,
    spam_complaints: 0,
    bounced: 0
  });

  const [triggerContextOptions, setTriggerContextOptions] = useState([]);
  const [loadingTCO, setLoadingTCO] = useState(false);
  const [loadingFlow, setLoadingFlow] = useState(true);

  const default_action = {
    title: 'Action',
    subtitle: 'Edit to configure',
    type: 'ACTION',
    smart_send: false,
    loyalty_points_change: 0,
    customer_updates: [],
    from_email: org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`,
    from_name: org.name,
    subject: '',
    preview_text: '',
    email_json: {},
    emailType: null,
    email_html: '',
    sendEmail: false,
    changePoints: false,
    changeCustomer: false,
    exclude_days: null,
    survey_frequency: null,
  };

  const default_split = {
    title: 'Split',
    subtitle: 'Edit to configure',
    type: 'SPLIT',
    expanded: true,
    children: [default_action],
    filters: []
  };

  useEffect(() => {
    async function getAutomation() {
      setLoadingFlow(true);
      const data = org.id === demoId ? demoFlow : await apiRequest('/flows/get-flow', {
        id: selectedAutomation.automationId
      });
      if (data) {
        const newState = {
          ...controller,
          ...data.flow_steps && { treeData: JSON.parse(data.flow_steps) },
          customer_filters: data.customer_filters,
          trigger: data.event_trigger,
          status: data.status,
          ...data.trigger_context_id && { trigger_context: data.trigger_context_id },
          name: data.name,
          id: data.id,
          wait: data.wait || 0,
          total_sent: data.total_sent || 0,
          total_opened: data.total_opened || 0,
          total_clicked: data.total_clicked || 0,
          unsubscribed: data.unsubscribed || 0,
          spam_complaints: data.spam_complaints || 0,
          bounced: data.bounced || 0
        };

        if (location?.state?.updateAt) {
          if (isDev) {
            console.log('Updating node', location?.state);
          }
          const oldNode = getNodeAtPath({
            treeData: newState.treeData,
            path: location.state.updateAt.path,
            getNodeKey: ({ treeIndex }) => treeIndex,
          }).node;

          newState.treeData = changeNodeAtPath({
            treeData: newState.treeData,
            path: location.state.updateAt.path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: {
              ...oldNode,
              ...location?.state?.emailType && { emailType: location?.state?.emailType },
              ...location?.state?.json && { email_json: location?.state?.json },
              ...location?.state?.html && { email_html: location?.state?.html },
            }
          });
          if (org.id !== demoId) {
            await apiRequest('/flows/update', {
              eventFilters: [],
              eventAppliedDisjunctively: false,
              customerAppliedDisjunctively: false,
              id: newState.id,
              customerFilters: newState.customer_filters,
              triggerContextId: newState.trigger_context,
              eventTrigger: newState.trigger,
              status: newState.status,
              name: newState.name,
              wait: newState.wait,
              flowSteps: JSON.stringify(newState.treeData),
            });
          }
          toast.success('Automation saved');
        }

        setController(newState);
        setLoadingFlow(false);
      }
    }
    gtm.push({ event: 'page_view' });
    getAutomation();
  }, []);

  useEffect(() => {
    async function getContext() {
      const EventType = [...EventTypes].find((x) => x.value === controller.trigger);
      if (!EventType) {
        return;
      }
      switch (EventType.context) {
        case 'EMAIL': {
          setLoadingTCO(true);
          const { campaigns } = org.id === demoId ? demoCampaigns : await apiRequest('/email/campaigns', {
          }) || [];
          setTriggerContextOptions([...campaigns].map((x) => ({
            label: x.name,
            value: x.id,
          })));
          setLoadingTCO(false);
        }
          break;
        case 'GROUP': {
          setLoadingTCO(true);
          const groups = org.id === demoId ? demoGroups : await apiRequest('/api/groups', {
          }) || [];
          setTriggerContextOptions([...groups].map((x) => ({
            label: x.name,
            value: x.id,
          })));
          setLoadingTCO(false);
        }
          break;
        case 'FORM': {
          setLoadingTCO(true);
          const { forms } = org.id === demoId ? demoForms : await apiRequest('/api/forms', {
          }) || [];
          setTriggerContextOptions([...forms].map((x) => ({
            label: x.name,
            value: x.id,
          })));
          setLoadingTCO(false);
        }
          break;
        default:
          setTriggerContextOptions([]);
      }
    }

    getContext();
  }, [controller.trigger]);

  if (isDev) {
    console.log(JSON.stringify({
      eventFilters: [],
      eventAppliedDisjunctively: false,
      customerAppliedDisjunctively: false,
      // id: controller.id,
      customerFilters: controller.customer_filters,
      triggerContextId: controller.trigger_context,
      eventTrigger: controller.trigger,
      status: controller.status,
      name: controller.name,
      wait: controller.wait,
      flowSteps: JSON.stringify(controller.treeData),
    }));
  }

  const selectedEventTrigger = [...EventTypes].find((x) => x.value === controller.trigger);

  const saveFlow = async (updates = {}) => {
    try {
      if (updates.status && updates.status === 'DRAFT') {
        if (org.id !== demoId) {
          await apiRequest('/flows/unpublish', {
            id: controller.id,
          });
        }
      }

      if (updates.status && updates.status === 'PUBLISHED') {
        if (org.id !== demoId) {
          await apiRequest('/flows/publish', {
            id: controller.id,
          });
        }
      }

      const newController = {
        ...controller,
        ...updates,
      };

      if (org.id !== demoId) {
        await apiRequest('/flows/update', {
          eventFilters: [],
          eventAppliedDisjunctively: false,
          customerAppliedDisjunctively: false,
          id: newController.id,
          customerFilters: newController.customer_filters,
          triggerContextId: newController.trigger_context,
          eventTrigger: newController.trigger,
          status: newController.status,
          name: newController.name,
          wait: newController.wait,
          flowSteps: JSON.stringify(newController.treeData),
        });
      }
      toast.success('Automation saved');
      setController(newController);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditEmail = async (nodeData) => {
    if (nodeData) {
      const { submit, ...actionUpdates } = nodeData;
      await saveFlow({
        treeData: changeNodeAtPath({
          treeData: controller.treeData,
          path: selectedAction.path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: {
            ...selectedAction.node,
            ...actionUpdates,
          }
        })
      });
    } else {
      await saveFlow();
    }
    if (selectedAction.node.emailType === 'DESIGN') {
      navigate('/email-editor', {
        state: {
          ...selectedAction.node.email_json,
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'DESIGN',
            }
          }
        }
      });
    }

    if (selectedAction.node.emailType === 'TEXT') {
      navigate('/dashboard/text-editor', {
        state: {
          html: selectedAction.node.email_html,
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'TEXT',
            }
          }
        }
      });
    }
    if (selectedAction.node.emailType === 'CODE') {
      navigate('/dashboard/html-editor', {
        state: {
          html: selectedAction.node.email_html,
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'CODE',
            }
          }
        }
      });
    }
  };

  const handleNewEmail = async (type, nodeData) => {
    if (nodeData) {
      const { submit, ...actionUpdates } = nodeData;
      await saveFlow({
        treeData: changeNodeAtPath({
          treeData: controller.treeData,
          path: selectedAction.path,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: {
            ...selectedAction.node,
            ...actionUpdates,
          }
        })
      });
    } else {
      await saveFlow();
    }

    if (type === 'DESIGN') {
      navigate('/dashboard/email-templates/create', {
        state: {
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'DESIGN',
            }
          }
        }
      });
    }
    if (type === 'TEXT') {
      navigate('/dashboard/text-editor', {
        state: {
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'TEXT',
            }
          }
        }
      });
    }
    if (type === 'CODE') {
      navigate('/dashboard/html-editor', {
        state: {
          callback: {
            url: `/dashboard/automations/edit/${controller.id}`,
            data: {
              updateAt: selectedAction,
              emailType: 'CODE',
            }
          }
        }
      });
    }
  };

  const handleDelete = () => {
    setController({
      ...controller,
      treeData: removeNodeAtPath({
        treeData: controller.treeData,
        path: selectedNode.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      })
    });
    handleCloseDeleteDialog();
  };

  const handleCustomerFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      column: filter.column.name,
      condition: filter.condition,
      relationType: filter.relationType.value
    }));

    setController({
      ...controller,
      customer_filters: parsedFilters
    });
  };

  const handleCustomerFiltersClear = () => {
    setController({
      ...controller,
      customer_filters: []
    });
  };

  const handleFiltersApply = (newFilters) => {
    let subtitle = `On ${newFilters.length} filters`;
    if (newFilters.length === 1) {
      subtitle = `${newFilters[0].column.name} ${newFilters[0].relationType.label} ${newFilters[0].condition}`;
    }

    const parsedFilters = newFilters.map((filter) => ({
      column: filter.column.name,
      condition: filter.condition,
      relationType: filter.relationType.value
    }));

    setController({
      ...controller,
      treeData: changeNodeAtPath({
        treeData: controller.treeData,
        path: selectedNode.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: {
          ...selectedNode.node,
          filters: parsedFilters,
          subtitle
        }
      })
    });
  };

  const handleFiltersClear = () => {
    setController({
      ...controller,
      treeData: changeNodeAtPath({
        treeData: controller.treeData,
        path: selectedNode.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: {
          ...selectedNode.node,
          filters: [],
          subtitle: 'Edit to configure'
        }
      })
    });
  };

  if (loadingFlow) {
    return (
      <Box sx={{ height: '100%' }}>
        <Helmet>
          <title>Automations: New | AudienceZen Dashboard</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
            height: '100%'
          }}
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress
              color="primary"
              size={100}
            />

          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Automations: New | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            px: 0
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/automations"
                variant="text"
              >
                Return to Automations
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <StatusSelect
                onChange={(ev) => { saveFlow({ status: ev.target.value }); }}
                options={statusOptions}
                value={controller.status}
                sx={{ width: '150px' }}
              />
              <Button
                disabled={!controller.trigger}
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  saveFlow();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
          >
            <Grid
              xl={4}
              lg={6}
              md={12}
              xs={12}
              item
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  gap: 1,
                  padding: 2,
                  mb: 2,
                }}
              >

                <InputField
                  fullWidth
                  label="Name"
                  name="name"
                  helperText="Used in the admin for reporting"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      name: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 2 }}
                  value={controller.name}
                />

                <InputField
                  fullWidth
                  helperText="The event to trigger this automations on"
                  label="Trigger"
                  name="trigger"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      trigger: ev.target.value,
                      trigger_context: '',
                    });
                  }}
                  select
                  sx={{ mt: 2 }}
                  value={controller.trigger}
                >
                  {[...EventTypes].map((op) => (
                    <MenuItem
                      key={op.value}
                      value={op.value}
                    >
                      <ListItemIcon>
                        {
                          op?.context === 'EMAIL'
                          && <EmailIcon fontSize="small" />
                        }
                        {
                          op?.context === 'GROUP'
                          && <GroupIcon fontSize="small" />
                        }
                        {
                          op?.context === 'FORM'
                          && <ReceiptLongIcon fontSize="small" />
                        }
                        {
                          !op?.context
                          && <SettingsIcon fontSize="small" />
                        }
                      </ListItemIcon>
                      <ListItemText>{op.label}</ListItemText>
                    </MenuItem>
                  ))}
                </InputField>

                {
                  Boolean(selectedEventTrigger?.context)
                  && (
                  <>
                    {
                      loadingTCO
                        ? <CircularProgress style={{ margin: '10px auto' }} />
                        : (
                          <InputField
                            fullWidth
                            helperText="Limit to a specific context"
                            label="Filter"
                            name="filter"
                            onChange={(ev) => {
                              setController({
                                ...controller,
                                trigger_context: ev.target.value,
                              });
                            }}
                            select
                            sx={{ mt: 2 }}
                            value={controller.trigger_context}
                          >
                            {[...triggerContextOptions].map((op) => (
                              <MenuItem
                                key={op.value}
                                value={op.value}
                              >
                                {op.label}
                              </MenuItem>
                            ))}
                          </InputField>
                        )
                    }
                  </>
                  )
                }

                <InputField
                  fullWidth
                  helperText="Amount of days to wait after trigger to run automation"
                  label="Wait for"
                  name="wait"
                  textFieldProps={{
                    min: 0,
                    max: 9999,
                    step: 1
                  }}
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      wait: (ev.target.value < 0 ? 0 : parseInt(ev.target.value, 10)) || 0,
                    });
                  }}
                  type="number"
                  sx={{ mt: 2 }}
                  value={controller.wait}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        days
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  color="primary"
                  onClick={() => setOpenCustomerFilterDialog(true)}
                  startIcon={<AdjustmentsIcon />}
                  size="large"
                  variant={controller.customer_filters.length ? 'contained' : 'text'}
                >
                  {`Customer Filters${controller.customer_filters.length ? ` (${controller.customer_filters.length})` : ''}`}
                </Button>

                <Divider />

                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<CallSplitIcon fontSize="small" />}
                    onClick={() => setController({
                      ...controller,
                      treeData: controller.treeData.concat(JSON.parse(JSON.stringify(default_split)))
                    })}
                  >
                    Add Split
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<ForwardToInboxIcon fontSize="small" />}
                    onClick={() => setController({
                      ...controller,
                      treeData: controller.treeData.concat(JSON.parse(JSON.stringify(default_action)))
                    })}
                  >
                    Add Action
                  </Button>
                </Box>
              </Card>
            </Grid>
            <Grid
              xl={8}
              lg={6}
              md={12}
              xs={12}
              item
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  padding: 2,
                }}
              >
                <Scrollbar>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1 }}
                  >
                    Steps
                  </Typography>

                  <SortableTree
                    treeData={controller.treeData}
                    canNodeHaveChildren={(node) => node.type === 'SPLIT'}
                    onChange={(treeData) => setController({
                      ...controller,
                      treeData: [...treeData]
                    })}
                    isVirtualized={false}
                    generateNodeProps={({ node, path }) => ({
                      title: (
                        <Typography
                          variant="caption"
                        >
                          { node.title }
                        </Typography>
                      ),
                      subtitle: (
                        <Typography
                          variant="caption"
                          sx={{ fontStyle: 'italic', maxWidth: '120px' }}
                        >
                          { node.subtitle }
                        </Typography>
                      ),
                      buttons: [
                        node.type === 'SPLIT'
                          ? (
                            <SplitButton
                              onOpen={() => {
                                setSelectedNode({ node, path });
                              }}
                              initialFilters={node.filters}
                              onFiltersApply={handleFiltersApply}
                              onFiltersClear={handleFiltersClear}
                            />
                          )
                          : (
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setSelectedAction({ node, path });
                                setOpenActionDialog(true);
                              }}
                            >
                              <CreateIcon />
                            </IconButton>
                          ),
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedNode({ node, path });
                            handleOpenDeleteDialog();
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      ]
                    })}
                  />
                </Scrollbar>
              </Card>
            </Grid>
            {controller?.total_sent && controller?.total_sent !== null && controller.total_sent !== undefined ? (
              <Grid
                xl={4}
                lg={6}
                md={12}
                xs={12}
                item
              >
                <Card
                  variant="outlined"
                >
                  <PropertyList>
                    <PropertyListItem
                      divider
                      label="Total Unique Sends"
                      value={controller?.total_sent && controller?.total_sent !== null && controller?.total_sent !== undefined ? numeral(controller?.total_sent).format('0,0') : 0}
                    />
                    <PropertyListItem
                      divider
                      label="Opened"
                      value={controller?.total_sent && controller?.total_sent !== null && controller?.total_sent !== undefined && controller?.total_opened && controller?.total_opened !== null && controller?.total_opened !== undefined ? numeral(controller?.total_opened / controller?.total_sent).format('0.0%') : '0%'}
                    />
                    <PropertyListItem
                      divider
                      label="Clicked"
                      value={controller?.total_sent && controller?.total_sent !== null && controller?.total_sent !== undefined && controller?.total_clicked && controller?.total_clicked !== null && controller?.total_clicked !== undefined ? numeral(controller?.total_clicked / controller?.total_sent).format('0.0%') : '0%'}
                    />
                    <PropertyListItem
                      divider
                      label="Attributed Revenue"
                      value={numeral(controller?.revenue && controller?.revenue !== null && controller?.revenue !== undefined ? controller?.revenue : 0).format('$0,0.00')}
                    />
                    <PropertyListItem
                      divider
                      label="Spam Rate"
                      value={controller?.total_sent && controller?.total_sent !== null && controller?.total_sent !== undefined && controller?.spam_complaints && controller?.spam_complaints !== null && controller?.spam_complaints !== undefined ? numeral(controller?.spam_complaints / controller?.total_sent).format('0.0%') : '0%'}
                    />
                    <PropertyListItem
                      divider
                      label="Bounce Rate"
                      value={controller?.total_sent && controller?.total_sent !== null && controller?.total_sent !== undefined && controller?.bounced && controller?.bounced !== null && controller?.bounced !== undefined ? numeral(controller?.bounced / controller?.total_sent).format('0.0%') : '0%'}
                    />
                    <PropertyListItem
                      label="Unsubscribes"
                      value={controller?.unsubscribed && controller?.unsubscribed !== null && controller?.unsubscribed !== undefined ? numeral(controller?.unsubscribed).format('0,0') : 0}
                    />
                  </PropertyList>
                </Card>
              </Grid>
            ) : <></>}
          </Grid>
        </Container>
        <ActionDialog
          onClose={(data = null) => {
            if (data?.values) {
              const { submit, ...actionUpdates } = data.values;
              setController({
                ...controller,
                treeData: changeNodeAtPath({
                  treeData: controller.treeData,
                  path: selectedAction.path,
                  getNodeKey: ({ treeIndex }) => treeIndex,
                  newNode: {
                    ...selectedAction.node,
                    ...actionUpdates,
                  }
                })
              });
            }
            setOpenActionDialog(false);
          }}
          onNewEmail={handleNewEmail}
          onEditEmail={handleEditEmail}
          open={openActionDialog}
          action={selectedAction?.node}
        />
        <ConfirmationDialog
          message="Are you sure you want to delete this step? This can't be undone."
          onCancel={handleCloseDeleteDialog}
          onConfirm={handleDelete}
          open={deleteDialogOpen}
          title="Delete step"
          variant="error"
        />
        <FilterDialog
          onApply={handleCustomerFiltersApply}
          onClear={handleCustomerFiltersClear}
          onClose={() => setOpenCustomerFilterDialog(false)}
          open={openCustomerFilterDialog}
          operators={filterOperators}
          properties={customerFilterProperties}
          initialFilters={controller.customer_filters}
        />
      </Box>
    </>
  );
};

const SplitButton = ({ onOpen, onFiltersClear, onFiltersApply, initialFilters }) => {
  const [splitButtonDialogOpened, setSplitButtonDialogOpened] = useState(false);

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => {
          onOpen();
          setSplitButtonDialogOpened(true);
        }}
      >
        <SettingsIcon />
      </IconButton>
      <FilterDialog
        initialFilters={initialFilters}
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setSplitButtonDialogOpened(false)}
        open={splitButtonDialogOpened}
        operators={filterOperators}
        properties={customerFilterProperties}
      />
    </>
  );
};
SplitButton.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  initialFilters: PropTypes.array.isRequired,
};

const TypologyCard = ({ typology, dos, donts }) => {
  const [expanded, setExpanded] = useState(true);
  const totalRecc = [...dos, ...donts].length;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
          gap: 1,
          backgroundColor: 'background.default',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          position: 'relative',
          cursor: 'pointer',
          borderRadius: 2,
          px: 3,
          py: 2,
          mb: 1,
        }}
        onClick={() => { setExpanded(!expanded); }}
      >
        <Typography variant="subtitle1">{typology}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        { expanded ? <RemoveIcon fontSize="medium" /> : <AddIcon fontSize="medium" /> }
        {
          (totalRecc > 0)
          && (
          <Box
            backgroundColor="primary.main"
            sx={{
              position: 'absolute',
              width: 30,
              height: 30,
              borderRadius: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: -5,
              top: -5,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: '#fff',
                textAlign: 'center'
              }}
            >
              {totalRecc}
            </Typography>
          </Box>
          )
        }
      </Box>
      {
        expanded
        && (
        <Box sx={{ px: 3 }}>
          <Typography
            sx={{ mb: 0 }}
            color="success.main"
            variant="overline"
            fontWeight="bold"
          >
            DO:
          </Typography>
          <List sx={{ pb: 1, pt: 0 }}>
            {dos.map((feature) => (
              <ListItem
                sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                disableGutters
                key={feature}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 1,
                    mt: 0.8,
                  }}
                >
                  <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.main' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={feature}
                />
              </ListItem>
            ))}
          </List>
          <Typography
            color="error.main"
            variant="overline"
            fontWeight="bold"
          >
            DON&apos;T:
          </Typography>
          <List sx={{ pb: 2 }}>
            {donts.map((feature) => (
              <ListItem
                sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                disableGutters
                key={feature}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 1,
                    mt: 0.8,
                  }}
                >
                  <CancelOutlinedIcon sx={{ color: 'error.main' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={feature}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        )
      }
    </>
  );
};

TypologyCard.propTypes = {
  typology: PropTypes.string.isRequired,
  dos: PropTypes.arrayOf(PropTypes.string).isRequired,
  donts: PropTypes.arrayOf(PropTypes.string).isRequired,
};
