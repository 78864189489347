import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableRow,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Scrollbar } from '../scrollbar';
import { RewardsMenu } from './rewards-menu';
import { imagePath } from '../../config';

export const RewardsTable = ({ handleOpen, loyaltyRewards, setModalEvent, setRewardId, handleDelete, loyaltyFlat }) => (
  <Card
    variant="outlined"
  >
    <CardHeader
      action={(
        <Button
          text="Add Reward"
          onClick={() => {
            handleOpen();
            setModalEvent('Create');
          }}
        >
          Add Reward
        </Button>
    )}
      title="Rewards"
    />
    <CardContent sx={{ pt: 0 }}>
      <Typography
        color="textSecondary"
        variant="body2"
      >
        Give your customers the opportunity to convert on-site into loyal, repeat buying consumers with a bespoke loyalty program.
      </Typography>
      <Scrollbar>
        <Table>
          <TableBody>
            {loyaltyRewards?.data?.loyaltyRewards.map((item) => {
              const thumbnail = item.action_json ? JSON.parse(item.action_json).thumbnail : '';
              return (
                <TableRow
                  key={item.name}
                  hover
                >
                  <TableCell>
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      sx={{ height: '60px', width: '60px' }}
                    >
                      {thumbnail
                        ? (
                          <img
                            src={`${imagePath}${thumbnail}`}
                            alt={item.name}
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          />
                        )
                        : <LocalOfferIcon sx={{ height: '100%', width: '100%' }} />}

                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.name}</Typography>
                    <Typography color="textSecondary">{`${item.points} points`}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{loyaltyFlat.redeemed.filter((x) => x.rewardId === item.id).length}</Typography>
                    <Typography color="textSecondary">Redeemed</Typography>

                  </TableCell>
                  <TableCell align="right">

                    <RewardsMenu
                      setRewardId={setRewardId}
                      rewardId={item.id}
                      setModalEvent={setModalEvent}
                      handleDelete={handleDelete}
                      handleOpen={handleOpen}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </CardContent>
  </Card>

);

RewardsTable.propTypes = {
  handleOpen: PropTypes.func,
  setModalEvent: PropTypes.func,
  setRewardId: PropTypes.func,
  handleDelete: PropTypes.func,
  loyaltyRewards: PropTypes.object,
  loyaltyFlat: PropTypes.object,
};
