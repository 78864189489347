import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { Box, CircularProgress, Container, Card, CardContent, Grid, Typography } from '@mui/material';
import { format, subMonths, startOfMonth } from 'date-fns';
import percentile from 'percentile-rank';
import { useAuth } from '../hooks/use-auth';
import { GroupBys } from '../utils/group-by-date';
import { DateControls } from '../components/insights-root/date-controls';
/* Utils */
import gtm from '../lib/gtm';
import { FeedbackBars } from '../components/survey/drilldown/feedback-bars';
import { Submissions } from '../components/survey/submissions';
import { RespondentTable } from '../components/survey/respondent-table';

import { demoId } from '../utils/demo';
import demoInsightsValueData from '../demo/rawInsightsValue.json';
import demoFormData from '../demo/rawFormData.json';

export const SurveyDrilldown = () => {
  const { apiRequest, org } = useAuth();
  const selectedGroupBy = GroupBys[2];
  const [radarData, setRadarData] = useState({
    isLoading: true,
    data: null
  });
  const [formData, setFormData] = useState({
    isLoading: true,
    data: null
  });

  const [selectedDateRange, setSelectedDateRange] = useState({ start: startOfMonth(subMonths(new Date(), 11)), end: new Date() });
  const xAxisRange = org.id === demoId ? selectedGroupBy.getRange(new Date('2021-09-01'), new Date('2022-08-31')) : selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  async function getRadarData() {
    const data = org.id === demoId ? demoInsightsValueData : await apiRequest('/api/insights/value', {
      start: format(selectedDateRange.start, 'yyyy-MM-dd'),
      end: format(selectedDateRange.end, 'yyyy-MM-dd'),
    });
    const spentArr = data.map((x) => (x.total_price !== null && x.total_price ? x.total_price : 0));
    const discountArr = data.map((x) => (x.total_discount !== null && x.total_discount ? x.total_discount : 0));

    setRadarData({
      isLoading: false,
      data: data.map((x) => ({
        ...x,
        nps: x.nps >= 86 ? 'Promoter' : x.nps <= 65 ? 'Detractor' : 'Passive',
        spent_percentile: 1 - percentile(spentArr, x.total_price !== null && x.total_price ? x.total_price : 0),
        discount_percentile: percentile(discountArr, x.total_discount !== null && x.total_discount ? x.total_discount : 0),
      })),
    });
  }

  async function getData() {
    const data = org.id === demoId ? demoFormData : await apiRequest('/api/form/data', {
      startDate: format(selectedDateRange.start, 'yyyy-MM-dd'),
      endDate: format(selectedDateRange.end, 'yyyy-MM-dd'),
    });
    setFormData({ isLoading: false, data });
  }
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getRadarData();
    getData();
  }, [selectedDateRange]);

  if (formData.isLoading || radarData.isLoading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey Drilldown | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >

        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: sm ? '0' : 2,
                  mb: 1,
                  flexDirection: sm ? 'column' : 'row',
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                >
                  Drilldown
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="subtitle2">Select Date Range:</Typography>
                <DateControls
                  defaultStart={selectedDateRange.start}
                  defaultEnd={selectedDateRange.end}
                  showCompare={false}
                  onDateChange={(start, end = null) => {
                    setSelectedDateRange({ start, end });
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>

                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                  >
                    It is important to gather a steady stream of survey responses every month but also avoid over surveying. We recommend that you aim to have at least 3% of your customers complete a survey each month. If your completion rate is unsatisfactory, you should consider either adjusting the number of days between the purchase event and sending the survey request (the ’Wait for’ field in the survey configuration form or increasing the number of loyalty points offered as a reward for competing the survey.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* REPLACE DATA */}
            <Grid
              item
              xl={6}
              lg={6}
              xs={12}
            >
              <FeedbackBars
                title="Survey requests and completions"
                range={xAxisRange}
                data={formData.data}
                vsTotal={false}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              xs={12}
            >
              <FeedbackBars
                title="Survey requests and completions vs total customers"
                range={xAxisRange}
                data={formData.data}
                vsTotal
                percentType
              />
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <RespondentTable dataProp={radarData.data} />
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Submissions
                preFormattedData
                data={radarData.data}
              />
            </Grid>
          </Grid>
        </Container>

      </Box>

    </>
  );
};
