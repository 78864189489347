import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableRow,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Scrollbar } from '../scrollbar';
import { DiscountsMenu } from './discounts-menu';

export const DiscountsTable = ({ handleApiReload, discounts, loyaltyRewards }) => {
  const determineLabel = (type, value, name) => {
    switch (type) {
      case 'FIXED':
        return <>{`${numeral(value).format('$0,0.00')} off any order`}</>;

      case 'PERCENTAGE':
        return <>{`${numeral(value / 100).format('0%')} off any order`}</>;

      case 'FREE_SHIPPING':
        return <>Free Shipping on any order</>;

      case 'PRODUCT_FIXED':
        return <>{`${numeral(value).format('$0,0.00')} off on ${name}`}</>;

      case 'PRODUCT_PERCENTAGE':
        return <>{`${numeral(value / 100).format('0%')} off on ${name}`}</>;

      default:
        break;
    }
    return null;
  };
  return (
    <Card
      variant="outlined"
    >
      {!discounts.isLoading
        ? (
          <>
            <CardContent sx={{ pt: 0 }}>
              <Scrollbar>
                {discounts.data.discounts.length

                  ? (
                    <Table sx={{ mt: '1em', }}>
                      <TableBody>
                        {discounts.data.discounts.map((item) => (
                          <TableRow
                            key={item.name}
                          >

                            <TableCell
                              sx={{ width: '50%' }}
                            >
                              <Grid
                                container
                                display="flex"
                                alignItems="center"
                                gap="20px"
                              >
                                <Grid
                                  item
                                  display="flex"
                                  alignItems="center"
                                  sx={{ height: '60px', width: '60px' }}
                                >
                                  {item.product_image_src
                                    ? (
                                      <img
                                        src={item.product_image_src}
                                        alt={item.name}
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                      />
                                    )
                                    : <CardGiftcardIcon sx={{ height: '100%', width: '100%' }} />}

                                </Grid>
                                <Grid item>
                                  <Typography color="textPrimary">{item.name}</Typography>
                                  <Typography color="textSecondary">{determineLabel(item.discount_type, item.value, item.product_name)}</Typography>
                                </Grid>
                              </Grid>
                            </TableCell>

                            <TableCell align="right">
                              <DiscountsMenu
                                discountid={item.id}
                                loyaltyRewards={loyaltyRewards}
                                handleApiReload={handleApiReload}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )
                  : (
                    <Grid
                      container
                      direction="column"
                      sx={{ mt: '2em' }}
                    >
                      <Typography variant="subtitle1">No Discounts Currently Exist</Typography>
                      <Typography color="textSecondary">Click the Create Discount button in the top right to add a discount</Typography>
                    </Grid>
                  )}

              </Scrollbar>
            </CardContent>
          </>
        )
        : null}

    </Card>
  );
};

DiscountsTable.propTypes = {
  discounts: PropTypes.object,
  loyaltyRewards: PropTypes.object,
  handleApiReload: PropTypes.func
};
