import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export const ImagesUploaderDialog = ({ onCancel, open, onDelete, selectedImagesCount, ...other }) => (
  <Dialog
    onClose={onCancel}
    open={open}
    PaperProps={{
      sx: {
        width: '100%'
      }
    }}
    {...other}
  >
    <DialogTitle>
      {selectedImagesCount > 1 ? `Are you sure you want to delete ${selectedImagesCount} images?` : 'Are you sure you want to delete this image?'}
    </DialogTitle>
    <DialogContent>
      {selectedImagesCount > 1 ? 'Once deleted from assets, you can always add them back if you change your mind.' : 'Once deleted from assets, you can always add it back if you change your mind.'}
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={onCancel}
        variant="text"
      >
        Cancel
      </Button>
      <Button
        color="primary"
        onClick={() => {
          onDelete();
          onCancel();
        }}
        variant="contained"
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

ImagesUploaderDialog.defaultProps = {
  open: false
};

ImagesUploaderDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  selectedImagesCount: PropTypes.number.isRequired,
  open: PropTypes.bool,
  onDelete: PropTypes.func,
};
