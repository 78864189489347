/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { Box, Button, Card, Typography } from '@mui/material';
import { PropertyList } from './property-list';
import { PropertyListItem } from './property-list-item';

export const WizardConfirmation = (props) => {
  const { onPreviousStep, onComplete, values } = props;

  return (
    <div>
      <Typography
        color="textPrimary"
        sx={{
          mb: 5,
          mt: 2
        }}
        variant="h6"
      >
        Step 3. Confirmation
      </Typography>
      <Card variant="outlined">
        <PropertyList>
          <PropertyListItem
            divider
            label="Account Type"
            value={values.accountType}
          />
          <PropertyListItem
            divider
            label="Organisation Name"
            value={values.name}
          />
          <PropertyListItem
            divider
            label="Owner Name"
            value={values.ownername}
          />
          <PropertyListItem
            divider
            label="Owner Job Title"
            value={values.ownerjobtitle}
          />
          <PropertyListItem
            divider
            label="Phone"
            value={values.phone}
          />
          <PropertyListItem
            divider
            label="Address 1"
            value={values.address1}
          />
          <PropertyListItem
            divider
            label="Address 2"
            value={values.address2}
          />
          <PropertyListItem
            divider
            label="City"
            value={values.city}
          />
          <PropertyListItem
            divider
            label="State/Province"
            value={values.state}
          />
          <PropertyListItem
            divider
            label="Country"
            value={values.country}
          />
          <PropertyListItem
            divider
            label="Currency"
            value={values.currency}
          />
        </PropertyList>
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 3
        }}
      >
        <Button
          color="primary"
          onClick={onPreviousStep}
          size="large"
          sx={{ mr: 2 }}
          type="button"
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={onComplete}
        >
          Confirm
        </Button>
      </Box>
    </div>
  );
};

WizardConfirmation.propTypes = {
  onPreviousStep: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};
