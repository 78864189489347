/* eslint-disable camelcase */
import { useState } from 'react';
import { allFilters } from '../utils/filter-operators';
import { CustomerProperties } from '../utils/properties';
//  property, operator, value, => column, relationType, condition
const blankFilter = {
  column: {
    name: '',
    type: ''
  },
  relationType: {
    value: '',
    typesAccepted: []
  },
  condition: ''
};

export const useFilters = (properties, operators, initialFilters = []) => {
  let formatted_initialFilters = [];
  if (initialFilters && initialFilters.length) {
    formatted_initialFilters = initialFilters.map((x) => {
      const column = CustomerProperties.find((z) => z.name.toUpperCase() === x.column?.toUpperCase());
      const { condition } = x;
      const relationType = allFilters.find((z) => {
        if (x.relationType) {
          return z.value.toUpperCase() === x?.relationType?.toUpperCase();
        }
        return z.value.toUpperCase() === x?.relation?.toUpperCase();
      });
      return {
        column: column || JSON.parse(JSON.stringify(blankFilter.column)),
        condition: (condition !== undefined && condition !== null) ? condition : '',
        relationType: relationType || JSON.parse(JSON.stringify(blankFilter.relationType)),
      };
    });
  }

  const [filters, setFilters] = useState(() => (formatted_initialFilters?.length
    ? formatted_initialFilters
    : [blankFilter]));

  let shouldApplyFilters = true;

  for (let i = 0; i < filters.length; i++) {
    if (!filters[i].column.name
      || !filters[i].relationType.value
      || (!filters[i].relationType.typesAccepted.includes('blank')
        && !filters[i].condition)) {
      shouldApplyFilters = false;
      break;
    }
  }

  const addFilter = (index) => {
    if (!shouldApplyFilters) {
      return;
    }

    const temp = [...filters];
    temp.splice(index, 0, blankFilter);
    setFilters(temp);
  };

  const clearFilters = () => {
    setFilters([blankFilter]);
  };

  const removeFilter = (index) => {
    if (filters.length === 1) {
      clearFilters();
      return;
    }

    setFilters((prevFilters) => prevFilters.filter((_, _index) => _index !== index));
  };

  const handlePropertyChange = (filterIndex, selectedPropertyName) => {
    const temp = [...filters];
    const selectedProperty = properties.find((property) => property.name === selectedPropertyName);
    temp[filterIndex] = {
      column: selectedProperty,
      relationType: {
        value: '',
        typesAccepted: []
      },
      condition: ''
    };
    setFilters(temp);
  };

  const handleOperatorChange = (filterIndex, selectedOperatorValue) => {
    const temp = [...filters];
    const selectedOperator = operators.find((operator) => operator.value === selectedOperatorValue);
    temp[filterIndex] = {
      ...temp[filterIndex],
      relationType: selectedOperator
    };

    setFilters(temp);
  };

  const handleValueChange = (filterIndex, value) => {
    //  filters[filterIndex].column.type === 'number' ? Number(value) : value
    const temp = [...filters];
    temp[filterIndex] = {
      ...temp[filterIndex],
      condition: value
    };
    setFilters(temp);
  };
  return {
    addFilter,
    clearFilters,
    filters,
    handlePropertyChange,
    handleOperatorChange,
    handleValueChange,
    removeFilter,
    shouldApplyFilters
  };
};
