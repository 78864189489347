import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { format } from 'date-fns';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Scrollbar } from '../scrollbar';
import { ResourceUnavailable } from '../resource-unavailable';
import { ResourceError } from '../resource-error';

export const ScheduledReportsTable = (props) => {
  const {
    data: dataProp,
    error,
    isLoading,
    archive,
  } = props;

  const [data, setData] = useState(dataProp);

  useEffect(() => {
    setData(dataProp);
  }, [dataProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !data?.length);

  const [anchorEL, setAnchorEL] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e, row) => {
    setSelectedRow(row);
    setAnchorEL(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEL(null);
    setOpen(false);
    setSelectedRow(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 600 }}>

          <TableBody>
            {data?.map((row) => (
              <TableRow
                hover
                key={row.created_at}
              >
                <TableCell
                  width="50px"
                  sx={{ paddingRight: '10px' }}
                >
                  <Grid
                    container
                    direction="column"
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: 'info.main',
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                    }}
                  >
                    <EventAvailableIcon sx={{ fontSize: 32, color: 'white' }} />
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid
                    display="flex"
                    direction="column"
                    container
                  >
                    <Typography
                      variant="h5"
                      sx={{ textDecoration: 'underline' }}
                    >
                      {row.name}
                    </Typography>
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid
                    display="flex"
                    direction="column"
                    container
                  >
                    <Typography
                      variant="caption"
                    >
                      Sends
                    </Typography>
                    <Typography
                      variant="body1"
                    >
                      {row.frequency}
                      {' '}
                      (
                      {row.frequency === 'WEEKLY' ? format(new Date(row.start), 'E') : format(new Date(row.start), 'do')}
                      )
                    </Typography>
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid
                    display="flex"
                    direction="column"
                    container
                  >
                    <Typography
                      variant="caption"
                    >
                      Email To
                    </Typography>
                    <Typography
                      variant="body1"
                    >
                      {row.emailTo}
                    </Typography>
                  </Grid>
                </TableCell>

                <TableCell sx={{ paddingLeft: '8em', cursor: 'pointer' }}>
                  <Grid
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MoreVertIcon
                      aria-owns={anchorEL ? 'simple-menu' : undefined}
                      aria-haspopup={anchorEL ? 'true' : undefined}
                      onClick={(event) => handleClick(event, row)}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEL}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        sx={{ marginTop: '-2em' }}
      >
        <MenuItem onClick={() => { archive(selectedRow.created_at); }}>
          Archive
        </MenuItem>
      </Menu>
    </Box>
  );
};

ScheduledReportsTable.defaultProps = {
  data: [],
};

ScheduledReportsTable.propTypes = {
  data: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  archive: Proptypes.func,
};
