import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel
} from '@mui/material';
import numeral from 'numeral';
import { PagelessPagination } from '../pageless-pagination';
import { ResourceError } from '../resource-error';
import { ResourceUnavailable } from '../resource-unavailable';
import { Scrollbar } from '../scrollbar';
import { SalesDataProperties } from '../../utils/properties';

export const TableData = (props) => {
  const {
    data: dataProp,
    error,
    isLoading,
    onPageChange,
    onSortChange,
    page,
    sort,
    sortBy,
    groupBy,
    columns,
    view
  } = props;
  const [data, setData] = useState(dataProp);

  useEffect(() => {
    setData(dataProp);
  }, [dataProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !data?.length);
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {
                groupBy !== 'NONE' ? (
                  <TableCell
                    padding="normal"
                  >
                    <TableSortLabel
                      active={sortBy === 'orders.created_at'}
                      direction={sortBy === 'orders.created_at' ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, 'orders.created_at')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                )
                  : <></>
              }
              {
                view !== 'NONE' ? (
                  <TableCell
                    padding="normal"
                  >
                    <TableSortLabel
                      active={sortBy === 'context'}
                      direction={sortBy === 'context' ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, 'context')}
                    >
                      { view.charAt(0).toUpperCase() + view.toLowerCase().slice(1) }
                    </TableSortLabel>
                  </TableCell>
                )
                  : <></>
              }
              {[...SalesDataProperties].filter((x) => columns.includes(x.name)).map((column) => (
                <TableCell
                  key={column.name}
                  padding={column.disablePadding ? 'none' : 'normal'}
                >
                  {
                    column.sortable
                      ? (
                        <TableSortLabel
                          active={sortBy === column.name}
                          direction={sortBy === column.name ? sort : 'asc'}
                          disabled={isLoading}
                          onClick={(event) => onSortChange(event, column.name)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )
                      : column.label
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                hover
                key={Object.values(row).join('-')}
              >
                {
                  groupBy !== 'NONE' ? (
                    <TableCell>
                      { row.formatted_date }
                      {
                      row.compare?.formatted_date
                      && (
                      <i sx={{ display: 'block' }}>
                        <br />
                        (
                        {row.compare.formatted_date}
                        )
                      </i>
                      )
                    }
                    </TableCell>
                  )
                    : <></>
                }
                {
                  view !== 'NONE' && row.context
                    ? (
                      <TableCell>
                        { row.context.charAt(0).toUpperCase() + row.context.toLowerCase().slice(1) }
                      </TableCell>
                    )
                    : <></>
                }
                {[...SalesDataProperties].filter((x) => columns.includes(x.name)).map((column) => (
                  <TableCell key={column.name}>
                    { column.format(row[column.name]) }
                    {
                      row.compare && row.compare[column.name]
                      && (
                      <i sx={{ display: 'block' }}>
                        <br />
                        {column.format(row.compare[column.name])}
                        {
                          column.type === 'number'
                          && (
                          <Typography
                            variant="overline"
                            color={row[column.name] > row.compare[column.name] ? 'success.main' : 'error.main'}
                          >
                            {' '}
                            (
                            {numeral((row[column.name] - row.compare[column.name]) / row.compare[column.name]).format('+0%')}
                            )
                          </Typography>
                          )
                        }
                      </i>
                      )
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <PagelessPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        isLastPage={Boolean(data?.length < 50)}
      />
    </Box>
  );
};
//  removed dataCount at eslint suggestion...

TableData.defaultProps = {
  data: [],
  page: 1,
  sort: 'desc',
  columns: []
};

TableData.propTypes = {
  data: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  columns: Proptypes.array,
  groupBy: Proptypes.string,
  view: Proptypes.string,
};
