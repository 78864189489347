import PropTypes from 'prop-types';
import { Box, Button, Card, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { getEvent } from '../../utils/event-types';
import { useDialog } from '../../hooks/use-dialog';
import { ConfirmationDialog } from '../confirmation-dialog';

export const WizardConfirmation = (props) => {
  const { onPreviousStep, onComplete, values, preview } = props;
  const [campaignDialogOpen, handleOpenCampaignDialog, handleCloseCampaignDialog] = useDialog();

  return (
    <div>
      <Typography
        color="textPrimary"
        sx={{
          mb: 2,
          mt: 2
        }}
        variant="h6"
      >
        Review
      </Typography>
      <Card variant="outlined">
        <PropertyList>
          <PropertyListItem
            divider
            label="Survey name"
            value={values.survey_name}
          />
          <PropertyListItem
            divider
            label="Trigger"
            value={getEvent(values.trigger)}
          />
          <PropertyListItem
            divider
            label="Wait for"
            value={`${values.wait} days`}
          />
          <PropertyListItem
            divider
            label="Exclude customers that have received a marketing email in"
            value={`${values.returns_question} days`}
          />
          <PropertyListItem
            divider
            label="Survey Frequency"
            value={`${values.survey_frequency}`}
          />
          <PropertyListItem
            divider
            label="Showing returns question?"
            value={values.returns_question ? 'Yes' : 'No'}
          />
          <PropertyListItem
            divider
            label="Asking for job title?"
            value={values.include_vocation ? 'Yes' : 'No'}
          />
          <PropertyListItem
            divider
            label="Reward for submission"
            value={`${values.loyalty_points} loyalty points`}
          />
          <PropertyListItem
            divider
            label="Send from"
            value={values.from_email}
          />
          <PropertyListItem
            divider
            label="Sender name"
            value={values.from_name}
          />
          <PropertyListItem
            divider
            label="Subject"
            value={values.subject}
          />
          <PropertyListItem
            divider
            label="Survey Opening Text"
            value={values.survey_opening_text}
          />
          <PropertyListItem
            divider
            label="Survey Closing Text"
            value={values.survey_closing_text}
          />
          {
            /*
            <PropertyListItem
              divider
              label="Other questions"
              value={values.form_questions.filter((x) => x.enabled).map((x) => x.label).join(', ')}
            />
            */
          }
        </PropertyList>
      </Card>
      <Typography
        color="textPrimary"
        sx={{
          mb: 2,
          mt: 2
        }}
        variant="h6"
      >
        Email Preview
      </Typography>
      <EmailTemplateCard preview={preview} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 3
        }}
      >
        <Button
          color="primary"
          onClick={onPreviousStep}
          size="large"
          sx={{ mr: 2 }}
          type="button"
          variant="text"
        >
          Back
        </Button>
        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleOpenCampaignDialog}
        >
          Publish Survey
        </Button>
      </Box>
      <ConfirmationDialog
        message="Are you sure you want to publish this survey? Your survey cannot be edited after it is published and if you have a published survey already, it will be unpublished."
        onCancel={handleCloseCampaignDialog}
        onConfirm={onComplete}
        open={campaignDialogOpen}
        title="Publish survey"
      />
    </div>
  );
};

WizardConfirmation.propTypes = {
  onPreviousStep: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  preview: PropTypes.string.isRequired
};

const EmailPreview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.9);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  user-drag: none;
  pointer-events: none;
  ::-webkit-scrollbar {
    display: none;
  }
  & img {
    user-select: none;
    user-drag: none;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const EmailTemplateCard = ({ preview }) => (
  <Card
    variant="outlined"
    sx={{ height: '550px', backgroundColor: '#F9FAFB', position: 'relative' }}
  >
    <Grid
      justifyContent="center"
      container
      sx={{
        width: 500,
        overflow: 'hidden',
      }}
    >
      <EmailPreview>
        <div dangerouslySetInnerHTML={{ __html: preview }} />
      </EmailPreview>
    </Grid>
  </Card>
);

EmailTemplateCard.propTypes = {
  preview: PropTypes.string.isRequired
};
