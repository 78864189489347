import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography, Alert, Box, Card, CardContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Integrations } from './integrations';
import { Stepper } from '../components/stepper';
import { Scripts } from '../components/getting-started/scripts';
import { Email } from '../components/getting-started/email';

/* Components */

/* Hooks */
import { useAuth } from '../hooks/use-auth';

/* Utils */
import gtm from '../lib/gtm';

const steps = [
  {
    title: 'Step 1',
    content: 'Connect your data source'
  },
  {
    title: 'Step 2',
    content: 'Install your tracking scripts'
  },
  {
    title: 'Step 3',
    content: '(Optional) Set your sending email'
  },
];

function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
}

export const GettingStarted = () => {
  const { org, refreshOrgs } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { sit } = useQueryParams();

  useEffect(() => {
    if (sit) {
      localStorage.setItem('sit', sit);
    }
    if (org.data_connection_type === 'SHOPIFY' || org.data_connection_type === 'VEND') {
      navigate('/dashboard/organization/integrations');
    }
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Getting Started | AudienceZen Dashboard</title>
      </Helmet>
      <Grid
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          height: '100%'
        }}
      >

        <Card
          variant="outlined"
        >
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <Stepper
                  steps={steps}
                  currentStep={currentStep}
                />
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
              >
                {
                  currentStep === 0
                  && (
                  <>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ mb: 1 }}
                    >
                      Connect your data source
                    </Typography>

                    <Alert
                      severity="info"
                      sx={{ width: '100%', mb: 2 }}
                    >
                      Choose your data source, then click &#34;Mark as completed&#34; to proceed.
                    </Alert>
                    <Integrations />
                  </>
                  )
                }

                {
                  currentStep === 1
                  && <Scripts />
                }

                {
                  currentStep === 2
                  && (
                  <>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ mb: 2 }}
                    >
                      (Optional) Set your sending email
                    </Typography>
                    <Alert
                      severity="info"
                      sx={{ width: '100%' }}
                    >
                      Set up your sending email, or press &#34;Mark as completed&#34; to skip.
                    </Alert>
                    <Email />
                  </>
                  )
                }

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3
                  }}
                >
                  {
                    currentStep !== 0
                    && (
                    <Button
                      color="primary"
                      size="large"
                      sx={{ mr: 2 }}
                      type="button"
                      variant="text"
                      onClick={() => {
                        setCurrentStep(currentStep - 1);
                      }}
                    >
                      Back
                    </Button>
                    )
                  }
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    startIcon={<CheckIcon />}
                    variant="contained"
                    disabled={!org.data_connection_type}
                    onClick={async () => {
                      if (currentStep === 2) {
                        await refreshOrgs(org.id);
                        navigate('/dashboard/organization/billing');
                      } else {
                        setCurrentStep(currentStep + 1);
                      }
                    }}
                  >
                    Mark as completed
                  </Button>
                </Box>

              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </>
  );
};
