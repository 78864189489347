import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Distribution = ({ data, ...rest }) => {
  const theme = useTheme();

  const chartData = [
    {
      data: [data.active_customers_g3[0].total, data.active_customers_g2[0].total, data.active_customers_g1[0].total],
      name: 'Active Customers'
    },
    {
      data: [data.total_customers_g3[0].total, data.total_customers_g2[0].total, data.total_customers_g1[0].total],
      name: 'Inactive Customers'
    },
  ];

  const chartOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['#00A800', '#BFBFBF'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode,
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
      x: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: ['13 - 18 months ago', '7 - 12 months ago', '0 - 6 months ago'],
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        formatter(val) {
          return `${val}%`;
        },
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title="1. How many of my customers are inactive?"
      />
      <Divider />
      <Box sx={{ px: 1 }}>
        <Chart
          height="350"
          options={chartOptions}
          series={chartData}
          type="bar"
        />
      </Box>
    </Card>
  );
};

Distribution.propTypes = {
  data: PropTypes.object.isRequired,
};
