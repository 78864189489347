import { Button, Card, CardHeader, CircularProgress, Divider, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';

export const Info = ({ loyaltyFlat, organizationState }) => {
  const totalPointsSpent = loyaltyFlat.redeemed.map((item) => item.points).filter((x) => x < 0).reduce(((prev, next) => prev - next), 0);
  const totalPointsEarnt = loyaltyFlat.changed.map((item) => item.points).reduce(((prev, next) => +prev + +next), 0);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -85;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <Box sx={{ height: '100%' }}>
      {organizationState && !organizationState.isLoading
        ? (
          <Card
            variant="outlined"
          >
            <CardHeader
              action={(

                <Button
                  component={Link}
                  smooth
                  elementId="LoyaltySettings"
                  to="/dashboard/organization"
                  scroll={(el) => scrollWithOffset(el)}
                  underline="none"
                  color="primary"
                  onClick={() => {}}
                  variant="text"
                >
                  Edit Loyalty Widget
                </Button>

      )}
              title="Program Details"
            />
            <Divider />
            <PropertyList>
              <PropertyListItem
                divider
                label="Status"
                value={organizationState.data && organizationState.data.loyalty_enabled ? 'Enabled' : 'Disabled'}
              />
              <PropertyListItem
                divider
                label="Points Per Dollar"
                value={organizationState.data && organizationState.data.loyalty_program_points_per_dollar ? organizationState.data.loyalty_program_points_per_dollar : 'Not Set'}
              />
              <PropertyListItem
                divider
                label="Points Earnt"
                value={totalPointsEarnt}
              />
              <PropertyListItem
                divider
                label="Points Spent"
                value={totalPointsSpent}
              />
              <PropertyListItem
                label="Total Loyalty Rewards Claimed"
                value={loyaltyFlat.redeemed.length}
              />
            </PropertyList>
          </Card>
        )
        : (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress
              color="primary"
              size={100}
            />

          </Box>
        )}
    </Box>
  );
};

Info.propTypes = {
  loyaltyFlat: PropTypes.object,
  organizationState: PropTypes.object
};
