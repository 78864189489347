import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Tab, Tabs } from '@mui/material';
import { subYears, subDays, differenceInDays } from 'date-fns';
import { Adjustments as AdjustmentsIcon } from '../../icons/adjustments';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
  isGreaterThanPercent,
  isLessThanPercent,
} from '../../utils/filter-operators';
import { FilterDialog } from '../filter-dialog';
import { ActionsMenu } from '../actions-menu';

import { TablePopover } from './table-popover';
import { DateControls } from '../insights-root/date-controls';
import { SalesDataProperties } from '../../utils/properties';

const views = [
  {
    label: 'All Sales',
    value: 'NONE'
  },
  {
    label: 'Campaigns',
    value: 'CAMPAIGN'
  },
  {
    label: 'Automations',
    value: 'FLOW'
  },
  {
    label: 'Groups',
    value: 'GROUP'
  },
  {
    label: 'Typologies',
    value: 'TYPOLOGY'
  }
];

const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
  isGreaterThanPercent,
  isLessThanPercent,
];

export const TableFilter = (props) => {
  const {
    disabled,
    filters,
    columns,
    handleDateChange,
    onFiltersApply,
    handleColumnsChange,
    onFiltersClear,
    onViewChange,
    view,
    groupBy,
    groupByOptions,
  } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  return (
    <>
      <Grid
        container
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid
          item
          sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}
        >
          <Tabs
            onChange={(event, value) => onViewChange?.(value)}
            allowScrollButtonsMobile
            value={view}
            variant="scrollable"
          >
            {views.map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid
          item
          sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}
        >
          <DateControls
            showCompare
            onDateChange={(start, end, compare = null) => {
              let compareStart = null;
              let compareEnd = null;
              if (compare && compare === 'Last Year') {
                compareStart = subYears(start, 1);
                compareEnd = subYears(end, 1);
              }
              if (compare && compare === 'Last Period') {
                const days = differenceInDays(end, start);
                compareStart = subDays(start, days + 1);
                compareEnd = subDays(start, 1);
              }
              handleDateChange({ start, end, compareStart, compareEnd, comparePeriod: compare });
            }}
          />
          <ActionsMenu
            actions={groupByOptions}
            label={groupByOptions.find((x) => x.key === groupBy).label}
            size="small"
            variant="text"
            sx={{ mr: 1 }}
          />
          <TablePopover
            onClose={handleColumnsChange}
            defaultValue={columns}
          />
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<AdjustmentsIcon />}
            size="large"
            sx={{ order: 3, width: '8em' }}
            variant={filters.length ? 'contained' : 'text'}
          >
            Filter
          </Button>
        </Grid>
      </Grid>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        operators={filterOperators}
        properties={[...SalesDataProperties].filter((x) => x.filterable)}
      />
    </>
  );
};

TableFilter.defaultProps = {
  filters: [],
  view: 'all'
};

TableFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.string),
  groupByOptions: PropTypes.arrayOf(PropTypes.object),
  groupBy: PropTypes.string,
  handleDateChange: PropTypes.func,
  onFiltersApply: PropTypes.func,
  handleColumnsChange: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string
};
