import PropTypes from 'prop-types';
import { Button, Box, IconButton, Avatar, Typography } from '@mui/material';
import { useState } from 'react';
import { SelectAssetDialog } from './select-asset-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { imagePath } from '../../config';
import { Trash as TrashIcon } from '../../icons/trash';
import { Upload as UploadIcon } from '../../icons/upload';

export const EmailImageUploader = ({ properties, value, callback }) => {
  const [uploadDialogOpen, handleOpenUploadDialog, handleCloseUploadDialog] = useDialog();
  const [selectedImage, setSelectedImage] = useState([]);
  const [images, setImages] = useState([]);
  return (
    <>

      {value
        ? (
          <Box
            sx={{
              borderRadius: 1,
              boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
              display: 'flex',
              position: 'relative',
              width: 'fit-content',
              '& img': {
                borderRadius: 1,
                display: 'block',
                maxWidth: 126
              },
              '&:hover': {
                boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                '& > button': {
                  display: 'block'
                },
                '& img': {
                  opacity: 0.3
                }
              }
            }}
          >
            <img
              alt="Uploaded"
              src={value}
            />
            <IconButton
              color="error"
              onClick={() => {
                setSelectedImage('');
                callback('', properties.fieldName);
              }}
              sx={{
                display: 'none',
                left: 0,
                position: 'absolute',
                top: 0

              }}
            >
              <TrashIcon fontSize="small" />
            </IconButton>
          </Box>
        )
        : (
          <Box
            sx={{
              height: '136px',
              alignItems: 'center',
              borderColor: 'neutral.200',
              borderRadius: 1,
              borderStyle: 'dashed',
              borderWidth: 1,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              outline: 'none',
              width: '136px',
              py: 2,
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'action.hover'
              },
            }}
            onClick={handleOpenUploadDialog}
          >

            <Avatar
              sx={{
                backgroundColor: 'rgba(238, 240, 242, 1)',
                color: 'text.secondary',
                height: 36,
                width: 36,
                mb: 1,
              }}
            >
              <UploadIcon />
            </Avatar>

            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              Upload
            </Button>

            <Typography
              align="center"
              sx={{ color: 'text.secondary', mt: 1 }}
              variant="caption"
            >
              Select images
            </Typography>
          </Box>
        )}
      <SelectAssetDialog
        onCancel={handleCloseUploadDialog}
        open={uploadDialogOpen}
        handleSelect={() => callback(`${imagePath}${images.find((x) => x.id === selectedImage[0]).src}`, properties.fieldName)}
        properties={properties}
        value={value}
        callback={callback}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        setImages={setImages}
        images={images}
      />

    </>
  );
};

EmailImageUploader.propTypes = {
  properties: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
