import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography, CircularProgress, Button } from '@mui/material';

import gtm from '../lib/gtm';
import { useMounted } from '../hooks/use-mounted';
import { useAuth } from '../hooks/use-auth';

/* Components */
import { DiscountsTable } from '../components/discounts/discounts-table';
import { DiscountDialog } from '../components/discounts/discount-dialog';
import { Plus as PlusIcon } from '../icons/plus';

import { demoId } from '../utils/demo';
import demoRewards from '../demo/rawLoyaltyRewards.json';
import demoDiscounts from '../demo/rawDiscounts.json';
import demoConnections from '../demo/rawConnections.json';

export const Discounts = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [connections, setConnections] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [loyaltyRewards, setLoyaltyRewards] = useState({ isLoading: true, data: [] });
  const [discounts, setDiscounts] = useState({ isLoading: true, data: [] });

  const getLoyaltyRewards = useCallback(async () => {
    setLoyaltyRewards(() => ({ isLoading: true }));
    try {
      const loyaltyRewardsData = org.id === demoId ? demoRewards : await apiRequest('/loyalty/get-rewards');
      if (mounted.current) {
        setLoyaltyRewards(() => ({ isLoading: false, data: loyaltyRewardsData }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setLoyaltyRewards(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  useEffect(() => {
    async function getDiscounts() {
      setDiscounts({ isLoading: true, data: [] });
      try {
        const discountsData = org.id === demoId ? demoDiscounts : await apiRequest('/loyalty/get-discounts') || [];
        if (mounted.current) {
          setDiscounts({ isLoading: false, data: discountsData });
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setDiscounts({
            isLoading: false,
            data: [],
          });
        }
      }
    }
    getLoyaltyRewards();
    getDiscounts();
  }, [org, formSubmit]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    if (org.id !== demoId) {
      apiRequest('/api/connections',).then((result) => {
        setConnections(result);
      }).catch((err) => console.log(err));
    } else {
      setConnections(demoConnections);
    }
  }, [org]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleOpen = () => {
    setDialogOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>Discounts | AudienceZen Dashboard</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', height: '100%' }}>
        {!discounts.isLoading
          ? (
            <>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  pb: 2,
                }}
                justifyContent="space-between"
                spacing={2}
              >
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  Discounts
                </Typography>
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  startIcon={<PlusIcon />}
                >
                  Create Discount
                </Button>
              </Box>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <DiscountsTable
                    handleOpen={handleOpen}
                    sx={{ height: '100%' }}
                    formSubmit={formSubmit}
                    handleApiReload={() => setFormSubmit(!formSubmit)}
                    discounts={discounts}
                    loyaltyRewards={loyaltyRewards}
                  />
                </Grid>
              </Grid>
              <DiscountDialog
                handleOpen={handleOpen}
                open={dialogOpen}
                onClose={handleClose}
                handleApiReload={() => setFormSubmit(!formSubmit)}
                connections={connections}
                discounts={discounts}
              />
            </>
          )
          : (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <CircularProgress
                color="primary"
                size={100}
              />

            </Box>
          )}
      </Box>
    </>
  );
};
