export const equalOperator = {
  label: 'equals',
  typesAccepted: ['number', 'percent_number', 'string'],
  value: 'EQUALS'
};

export const notEqualOperator = {
  label: 'not equals',
  typesAccepted: ['number', 'percent_number', 'string'],
  value: 'NOT_EQUALS'
};

export const containsOperator = {
  label: 'contains',
  typesAccepted: ['string'],
  value: 'CONTAINS'
};

export const notContainsOperator = {
  label: 'not contains',
  typesAccepted: ['string'],
  value: 'NOT_CONTAINS'
};

export const startsWithOperator = {
  label: 'starts with',
  typesAccepted: ['string'],
  value: 'STARTS_WITH'
};

export const endsWithOperator = {
  label: 'ends with',
  typesAccepted: ['string'],
  value: 'ENDS_WITH'
};

export const greaterThanOperator = {
  label: 'greater than',
  typesAccepted: ['number', 'percent_number'],
  value: 'GREATER_THAN'
};

export const lessThanOperator = {
  label: 'less than',
  typesAccepted: ['number', 'percent_number'],
  value: 'LESS_THAN'
};

export const isAfterOperator = {
  label: 'is after',
  typesAccepted: ['date'],
  value: 'IS_AFTER'
};

export const isBeforeOperator = {
  label: 'is before',
  typesAccepted: ['date'],
  value: 'IS_BEFORE'
};

export const isBlankOperator = {
  label: 'is blank',
  typesAccepted: ['blank'],
  value: 'IS_BLANK'
};

export const isPresentOperator = {
  label: 'is present',
  typesAccepted: ['blank'],
  value: 'IS_PRESENT'
};

export const isDaysLessThanOperator = {
  label: 'is less than days ago',
  typesAccepted: ['date'],
  value: 'DAYS_LESS_THAN'
};

export const isDaysGreaterThanOperator = {
  label: 'is greater than days ago',
  typesAccepted: ['date'],
  value: 'DAYS_GREATER_THAN'
};

export const isDaysEqualOperator = {
  label: 'is equal days ago',
  typesAccepted: ['date'],
  value: 'DAYS_EQUAL'
};

export const isDaysNotEqualOperator = {
  label: 'is not equal days ago',
  typesAccepted: ['date'],
  value: 'DAYS_NOT_EQUAL'
};

export const isGreaterThanPercent = {
  label: 'is greater than %',
  typesAccepted: ['percent_number'],
  value: 'GREATER_THAN_PERCENT'
};

export const isLessThanPercent = {
  label: 'is less than %',
  typesAccepted: ['percent_number'],
  value: 'LESS_THAN_PERCENT'
};

export const allFilters = [
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
  isGreaterThanPercent,
  isLessThanPercent,
];
