import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  TextField,
  Grid
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { InputField } from '../input-field';
import { LoadableButton } from '../loadable-button';
import { useAuth } from '../../hooks/use-auth';

export const ScheduleDialog = (props) => {
  const { open, onClose, onSubmit, ...other } = props;
  const { user } = useAuth();
  const formik = useFormik({
    initialValues: {
      name: '',
      start: new Date(),
      frequency: 'WEEKLY',
      emailTo: user.email || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required.').max(255, 'Name must be less than 255 characters'),
      start: Yup.date().required('Date is required.'),
      frequency: Yup.string().required('Frequency is required.'),
      emailTo: Yup.string().required('Email is required.').email('Must be a valid email'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit(values);
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle>
        Schedule Email Report
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              label="Report Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.emailTo && formik.errors.emailTo)}
              fullWidth
              helperText={formik.touched.emailTo && formik.errors.emailTo}
              label="Email To"
              name="emailTo"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.emailTo}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InputField
              fullWidth
              error={Boolean(formik.touched.frequency && formik.errors.frequency)}
              helperText={formik.touched.frequency && formik.errors.frequency}
              label="Frequency"
              name="frequency"
              select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.frequency}
            >
              {[
                {
                  value: 'WEEKLY',
                  label: 'Weekly',
                },
                {
                  value: 'MONTHLY',
                  label: 'Monthly',
                },
              ].map((option) => {
                if (typeof option === 'object') {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                );
              })}
            </InputField>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ mt: 1 }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Start Date"
                minDate={new Date()}
                value={formik.values.start}
                sx={{ ml: '1em' }}
                onChange={(newValue) => {
                  formik.setFieldValue('start', newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadableButton
          loading={formik.isSubmitting}
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="contained"
        >
          Create
        </LoadableButton>
      </DialogActions>
    </Dialog>
  );
};

ScheduleDialog.defaultProps = {
  open: false
};

ScheduleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
