import { differenceInDays } from 'date-fns';

export const typologyCheck = (org) => {
  if (!org) {
    return false;
  }
  if (org.data_connection_created && differenceInDays(new Date(), new Date(org.data_connection_created)) > 14) {
    return true;
  }
  const estimateRequired = org.total_customers > 3300 ? 1000 : (org.total_customers <= 250 ? 75 : Math.floor(org.total_customers * 0.3));
  return org.total_typologies >= estimateRequired;
};

export const typologyWarningCheck = (org) => {
  if (!org) {
    return false;
  }
  const estimateRequired = org.total_customers > 3300 ? 1000 : (org.total_customers <= 250 ? 75 : Math.floor(org.total_customers * 0.3));
  return org.total_typologies >= estimateRequired;
};
