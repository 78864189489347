import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,

} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import numeral from 'numeral';
import { format } from 'date-fns';
import { Scrollbar } from '../scrollbar';
import { ResourceUnavailable } from '../resource-unavailable';
import { ResourceError } from '../resource-error';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { applyPagination } from '../../utils/apply-pagination';
import { Pagination } from '../pagination';

export const CampaignTable = (props) => {
  const {
    campaigns: campaignsProp,
    error,
    isLoading,
    onPageChange,
    cancelCampaignSend,
    archiveCampaign,
    page,
  } = props;

  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState(campaignsProp);

  const [archiveDialogOpen, handleOpenArchiveDialog, handleCloseArchiveDialog] = useDialog();
  useEffect(() => {
    setCampaigns(campaignsProp);
  }, [campaignsProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !campaigns?.length);

  const [anchorEL, setAnchorEL] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e, campaign) => {
    setSelectedCampaign(campaign);
    setAnchorEL(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEL(null);
    setOpen(false);
    // setSelectedCampaign(null);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table>

          <TableBody>
            {applyPagination(campaigns?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)), page, 20).map((campaign) => (
              <TableRow
                hover
                key={campaign.id}
              >
                <TableCell
                  width="50px"
                  sx={{ paddingRight: '10px' }}
                >
                  <Grid
                    container
                    direction="column"
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: '#ccc',
                      ...(campaign.status === 'PUBLISHED' || campaign.status === 'SCHEDULED') && { backgroundColor: 'success.main' },
                      ...(campaign.status === 'DRAFT') && { backgroundColor: 'info.main' },
                      ...(campaign.status === 'ARCHIVED') && { backgroundColor: '#ccc' },
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                    }}
                  >
                    <EmailIcon sx={{ fontSize: 32, color: 'white' }} />
                  </Grid>
                </TableCell>

                <TableCell>
                  <Grid
                    display="flex"
                    direction="column"
                    container
                  >
                    {
                      (campaign.status === 'PUBLISHED' || campaign.status === 'DRAFT')
                        ? (
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={campaign.status === 'PUBLISHED' ? `/dashboard/campaigns/${campaign.id}` : `/dashboard/campaigns/edit/${campaign.id}`}
                            underline="none"
                            variant="subtitle2"
                          >
                            <Typography
                              variant="h5"
                              sx={{ textDecoration: 'underline' }}
                            >
                              {campaign.name}
                            </Typography>
                          </Link>
                        )
                        : (
                          <Typography
                            variant="h5"
                            sx={{ textDecoration: 'underline' }}
                          >
                            {campaign.name}
                          </Typography>
                        )
                    }

                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        ...(campaign.status === 'PUBLISHED' || campaign.status === 'SCHEDULED') && { color: 'success.main' },
                        ...(campaign.status === 'DRAFT') && { color: 'info.main' },
                        ...(campaign.status === 'ARCHIVED') && { color: '#ccc' },
                      }}
                    >
                      { campaign.status }
                    </Typography>

                    {
                      campaign.status === 'DRAFT' && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Edited On: '}
                        {format(new Date(campaign.updated_at), 'dd/MM/yyyy p')}
                      </Typography>
                      )
}
                    {
                      campaign.status === 'ARCHIVED' && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Edited On: '}
                        {format(new Date(campaign.updated_at), 'dd/MM/yyyy p')}
                      </Typography>
                      )
}
                    {
                      campaign.status === 'SCHEDULED' && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Scheduled For: '}
                        {format(new Date(campaign.scheduled_for), 'dd/MM/yyyy p')}
                      </Typography>
                      )
}
                    {
                      campaign.status === 'PUBLISHED' && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Scheduled For: '}
                        {format(new Date(campaign.sent_at), 'dd/MM/yyyy p')}
                      </Typography>
                      )
}

                  </Grid>
                </TableCell>

                {
                  campaign.delivered
                    ? (
                      <TableCell>
                        <Grid
                          container
                          direction="column"
                          display="flex"
                          textAlign="center"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            Delivered
                          </Typography>
                          <Typography variant="subtitle1">{campaign.delivered}</Typography>
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell />
                }

                {
                  campaign.opened
                    ? (
                      <TableCell>
                        <Grid
                          container
                          direction="column"
                          display="flex"
                          textAlign="center"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            Opened
                          </Typography>
                          <Typography variant="subtitle1">{campaign.opened}</Typography>
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell />
                }

                {
                  campaign.clicked
                    ? (
                      <TableCell>
                        <Grid
                          container
                          direction="column"
                          display="flex"
                          textAlign="center"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            Clicked
                          </Typography>
                          <Typography variant="subtitle1">{campaign.clicked}</Typography>
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell />
                }

                {
                  campaign.revenue
                    ? (
                      <TableCell>
                        <Grid
                          container
                          direction="column"
                          display="flex"
                          textAlign="center"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            Revenue
                          </Typography>
                          <Typography variant="subtitle1">{numeral(campaign.revenue).format('$0,0.00')}</Typography>
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell />
                }

                {
                  (campaign.status !== 'ARCHIVED' && campaign.status !== 'PUBLISHED')
                    ? (
                      <TableCell sx={{ maxWidth: '8em', cursor: 'pointer' }}>
                        <Grid
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <MoreVertIcon
                            aria-owns={anchorEL ? 'simple-menu' : undefined}
                            aria-haspopup={anchorEL ? 'true' : undefined}
                            onClick={(event) => handleClick(event, campaign)}
                          />
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell sx={{ maxWidth: '8em', cursor: 'pointer' }} />
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      {/* <PagelessPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        isLastPage={Boolean(campaigns?.length < 50)}
      /> */}
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page + 1}
        rowsCount={campaigns?.length}
        pageSize={20}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEL}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        sx={{ marginTop: '-2em' }}
      >
        {
          selectedCampaign?.status === 'DRAFT' && (
          <MenuItem onClick={() => {
            navigate(`/dashboard/campaigns/edit/${selectedCampaign.id}`);
          }}
          >
            Edit
          </MenuItem>
          )
}
        {
          selectedCampaign?.status === 'SCHEDULED' && (
          <MenuItem onClick={() => { cancelCampaignSend(selectedCampaign.id); }}>
            Cancel Campaign
          </MenuItem>
          )
}
        <MenuItem onClick={handleOpenArchiveDialog}>
          Archive
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        message="Are you sure you want to archive this campaign?"
        onCancel={handleCloseArchiveDialog}
        onConfirm={() => {
          archiveCampaign(selectedCampaign.id);
          setSelectedCampaign(null);
          handleCloseArchiveDialog();
        }}
        open={archiveDialogOpen}
        title="Archive Campaign"
        variant="error"

      />
    </Box>
  );
};

CampaignTable.defaultProps = {
  campaigns: [],
  page: 1,
};

CampaignTable.propTypes = {
  campaigns: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  archiveCampaign: Proptypes.func,
  cancelCampaignSend: Proptypes.func,
  page: Proptypes.number,
};
