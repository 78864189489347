import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';
import { useAuth } from '../../hooks/use-auth';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useDialog } from '../../hooks/use-dialog';

export const DiscountsMenu = ({ handleApiReload, loyaltyRewards, discountid, ...props }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { apiRequest } = useAuth();
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const handleDelete = () => {
    if (!(loyaltyRewards.data.loyaltyRewards.map((x) => (x.discount_object_id)).includes(discountid))) {
      apiRequest('/loyalty/delete-discount', {
        id: discountid
      }).then(() => {
        // after api request then reload the group list
        handleApiReload();
        toast.success('Discount deleted');
      }).catch((err) => {
        console.log(err);
        toast.error('Discount not deleted');
      });
    } else {
      handleCloseDeleteDialog();
      toast.error('Please delete associated Loyalty reward before trying to proceed.');
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        {...props}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => {
          handleOpenDeleteDialog();
          handleClose();
        }}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        message="Are you sure you want to delete this discount? This can't be undone."
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDelete}
        open={deleteDialogOpen}
        title="Delete Discount"
        variant="error"
      />
    </>
  );
};

DiscountsMenu.propTypes = {
  discountid: PropTypes.string,
  loyaltyRewards: PropTypes.object,
  handleApiReload: PropTypes.func
};
