import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, CircularProgress, Box } from '@mui/material';
import toast from 'react-hot-toast';

/* Components */
import { ProductsOverview } from '../components/product-feed/products-overview';
import { ProductsEditor } from '../components/product-feed/products-editor';
/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Utils */
import gtm from '../lib/gtm';

export const ProductFeed = () => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [productFeeds, setProductFeeds] = useState({ isLoading: true, data: [] });
  const [feedId, setFeedId] = useState();
  const [navState, setNavState] = useState('Overview');
  const [reload, setReload] = useState(false);
  const [connections, setConnections] = useState();

  async function getProductFeeds() {
    try {
      setProductFeeds(() => ({ isLoading: true }));
      const feeds = await apiRequest('/feeds/');
      if (mounted.current) {
        setProductFeeds(() => ({
          isLoading: false,
          data: feeds
        }));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setProductFeeds(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }

  useEffect(() => {
    getProductFeeds();
  }, [org, reload]);

  useEffect(() => {
    apiRequest('/api/connections',).then((result) => {
      setConnections(result);
    }).catch((err) => console.log(err));
    gtm.push({ event: 'page_view' });
  }, [org]);

  const handleDelete = (deleteID) => {
    apiRequest('/feeds/delete', {
      id: deleteID
    }).then(() => {
      setReload(!reload);
      toast.success('Product Feed deleted');
    }).catch((err) => {
      console.log(err);
      toast.error('Product Feed not deleted');
    });
  };
  return (
    <>
      <Helmet>
        <title>Product Feeds | AudienceZen Dashboard</title>
      </Helmet>
      <Grid
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          height: '100%'
        }}
      >
        {!productFeeds.isLoading
          ? navState === 'Overview'
            ? (
              <ProductsOverview
                setFeedId={setFeedId}
                productFeeds={productFeeds}
                setNavState={setNavState}
                handleDelete={handleDelete}
                connections={connections}
              />
            )
            : (
              <ProductsEditor
                setFeedId={setFeedId}
                productFeeds={productFeeds}
                setNavState={setNavState}
                navState={navState}
                feedId={feedId}
                setReload={setReload}
                reload={reload}
                connections={connections}
              />
            )
          : (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <CircularProgress
                color="primary"
                size={100}
              />

            </Box>
          )}
      </Grid>

    </>
  );
};
