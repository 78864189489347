import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import mjml2html from 'mjml-browser';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { RecommendationsDialog } from '../components/email/recommendations-dialog';
import { EmailNavbar } from '../components/email/email-navbar';
import { EmailSidebar } from '../components/email/email-sidebar';
import { EmailEditor } from '../components/email/email-editor';
import { useDialog } from '../hooks/use-dialog';
import { useAuth } from '../hooks/use-auth';
import { isDev } from '../utils/is-dev';
import { EmailComponents, EmailSettings, getEmailHtml } from '../utils/email-components';
import gtm from '../lib/gtm';
import { demoId } from '../utils/demo';

const EmailLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 64,
}));

const EmailLayoutContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

const emailBase = [];

const reorder = (arr, startIndex, endIndex) => {
  const result = [...arr];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const addTo = (arr, component, index) => {
  const result = [...arr];
  result.splice(index, 0, {
    id: uuidv4(),
    type: component.id,
    properties: JSON.parse(JSON.stringify({ ...component.defaultProperties }))
  });
  return result;
};

export const EmailLayout = () => {
  const { apiRequest, org } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [emailTemplateId, setEmailTemplateId] = useState(null);
  const [emailName, setEmailName] = useState('My Email Template');
  const [loading, setLoading] = useState(false);

  const [blocks, setBlocks] = useState(emailBase); // Holds all of our email blocks (content)

  const [emailProperties, setEmailProperties] = useState({ ...EmailSettings.defaultProperties }); // Holds all of our email settings
  const [selectedBlock, selectBlock] = useState(null); // Holds a clone of our selected box (id, properties, etc)
  const [controller, setController] = useState(null); // Holds a clone of our controller
  const [view, setView] = useState('blocks'); // blocks,styles,preview,optimize,block_properties
  const [previewType, changePreviewType] = useState('desktop'); // mobile (320), desktop (600)
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [previewStatus, setPreviewStatus] = useState('');

  const setBlockHandler = (block) => {
    setPreviewStatus('');
    setBlocks(block);
  };
  const setEmailPropertiesHandler = (properties) => {
    setPreviewStatus('');
    setEmailProperties(properties);
  };

  if (isDev) {
    console.log({
      properties: emailProperties,
      blocks,
      id: emailTemplateId,
      name: emailName
    });
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    if (location?.state?.content) {
      setBlockHandler(location.state.content);
    }
    if (location?.state?.properties) {
      setEmailPropertiesHandler(location.state.properties);
    }
    if (location?.state?.id) {
      setEmailTemplateId(location.state.id);
    }
    if (location?.state?.name) {
      setEmailName(location.state.name);
    }
    if (location?.state?.callback?.data?.controller && location?.state?.callback?.data?.controller?.typologyBreakdown) {
      const controllerState = location?.state?.callback?.data?.controller;
      const topTypology = controllerState?.typologyBreakdown.find((x) => x.count === Math.max(...Object.values(controllerState?.typologyBreakdown.map((y) => y.count))));
      setController({ ...controllerState, topTypology });
    }
  }, []);

  const handleTemplateSave = async () => {
    if (loading) {
      return;
    }
    const emailJSON = {
      content: blocks,
      properties: emailProperties
    };

    const getHtml = async () => {
      try {
        const mjmlHtml = getEmailHtml(blocks, emailProperties);
        const { html, errors } = mjml2html(mjmlHtml);
        if (errors && errors.length) {
          console.log('mjml errors:', errors);
          return '';
        }
        return html;
      } catch (e) {
        console.log(e);
        return '';
      }
    };

    try {
      setLoading(true);
      const data = {
        emailJSON: JSON.stringify(emailJSON),
        name: emailName,
        ...emailTemplateId && { id: emailTemplateId },
      };
      const emailObj = org.id === demoId ? {} : await apiRequest(`/email/${data.id ? 'update-template' : 'create-template'}`, data);
      setLoading(false);
      if (emailObj?.id && location?.state?.callback) {
        const emailHtml = await getHtml();
        const paramsData = location?.state?.callback?.data || {};
        navigate(location?.state?.callback?.url, {
          state: {
            ...paramsData,
            ...data,
            html: emailHtml,
            json: {
              ...emailJSON,
              name: emailName,
              id: emailObj.id,
            },
          }
        });
      } else {
        navigate('/dashboard/email-templates');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePreviewSend = async (email, placeholders) => {
    try {
      if (!(email && /^\S+@\S+\.\S+$/.test(email))) {
        toast.error('Invalid email');
        setPreviewStatus('Invalid email');
        return;
      }
      const placeholderData = placeholders ? JSON.parse(placeholders) : {};
      const mjmlHtml = getEmailHtml(blocks, emailProperties);
      const { html, errors } = mjml2html(mjmlHtml);
      if (errors && errors.length) {
        console.log('mjml errors:', errors);
        toast.error('Issue with parsing your email template');
        return;
      }
      if (org.id !== demoId) {
        await apiRequest('/email/preview', {
          fromEmail: org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`,
          fromName: org.name,
          previewText: `Preview Your Email Template for ${emailName}`,
          subject: `AudienceZen: Preview for ${emailName}`,
          emailHtml: html,
          toEmail: email,
          placeholders: placeholderData,
        });
      }
      toast.success('Email sent');
      setPreviewStatus('Email Sent');
    } catch (e) {
      console.log(e);
      toast.error('Invalid placeholder');
      setPreviewStatus('Invalid placeholder');
    }
  };

  // On selecting a block
  const handleSelect = (newBlock) => {
    selectBlock({
      id: newBlock.id,
      properties: { ...newBlock.properties }
    });
    if (view !== 'block_properties') {
      setView('block_properties');
    }
  };

  // After we finish dragging and dropping a draggable instance (block)
  const handleDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;

    if (sourceId === destinationId) {
      const items = reorder(blocks, source.index, destination.index);
      setBlockHandler(items);
    } else {
      const items = addTo(blocks, EmailComponents[source.index], destination.index);
      setBlockHandler(items);
      handleSelect(items[destination.index]);
    }
  };

  // Unselecting a block
  const unsetBlock = () => {
    selectBlock(null);
    if (view === 'block_properties') {
      setView('blocks');
    }
  };

  // On resettings/cancelling a block settings
  const resetBlock = () => {
    const newState = [...blocks];
    const index = newState.findIndex((x) => x.id === selectedBlock.id);
    if (index !== -1) {
      newState[index].properties = selectedBlock.properties;
      setBlockHandler(newState);
      unsetBlock();
    }
  };

  // Updates a block's properties
  const updateBlock = (value, field) => {
    const newState = [...blocks];
    const index = newState.findIndex((x) => x.id === selectedBlock.id);
    if (index !== -1) {
      newState[index].properties[field] = value;
      setBlockHandler(newState);
    }
  };

  const handleDeleteBlock = () => {
    const newState = [...blocks];
    const index = newState.findIndex((x) => x.id === selectedBlock.id);
    if (index !== -1) {
      unsetBlock();
      newState.splice(index, 1);
      setBlockHandler(newState);
    }
    handleCloseDeleteDialog();
  };

  // Updates a email settings
  const updateEmailSettings = (value, field) => {
    const newState = { ...emailProperties };
    newState[field] = value;
    setEmailPropertiesHandler(newState);
  };

  return (
    <EmailLayoutRoot>
      <Helmet>
        <title>Email Editor | AudienceZen Dashboard</title>
      </Helmet>
      <EmailNavbar
        loading={loading}
        emailName={emailName}
        onEmailNameChange={(emailNameChange) => setEmailName(emailNameChange)}
        previewType={previewType}
        changePreviewType={changePreviewType}
        onSave={handleTemplateSave}
      />
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <EmailSidebar
          emailProperties={emailProperties}
          updateEmailSettings={updateEmailSettings}
          onDelete={handleOpenDeleteDialog}
          view={view}
          setView={setView}
          updateBlock={updateBlock}
          resetBlock={resetBlock}
          unsetBlock={unsetBlock}
          emailComponents={EmailComponents}
          blocks={blocks}
          selectedBlock={selectedBlock}
          handlePreviewSend={handlePreviewSend}
          previewStatus={previewStatus}
          setPreviewStatus={setPreviewStatus}
        />
        <EmailLayoutContent
          sx={{
            ml: '500px'
          }}
        >
          <EmailEditor
            emailProperties={emailProperties}
            previewType={previewType}
            selectedBlock={selectedBlock}
            onSelect={handleSelect}
            items={blocks}
          />
          <RecommendationsDialog
            data={controller}
            needLevel={org.need_level}
          />
        </EmailLayoutContent>

      </DragDropContext>
      <ConfirmationDialog
        message="Are you sure you want to delete this block? This can't be undone."
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteBlock}
        open={deleteDialogOpen}
        title="Delete block"
        variant="error"
      />

    </EmailLayoutRoot>
  );
};
