import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Card

} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import mjml2html from 'mjml-browser';
import { Scrollbar } from '../scrollbar';
import { ResourceUnavailable } from '../resource-unavailable';
import { ResourceError } from '../resource-error';
import { PagelessPagination } from '../pageless-pagination';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { PreviewEmailDialog } from './preview-email-dialog';
import { getComponentHtml } from '../../utils/email-components';

const EmailPreview = styled.div`
  position: absolute;
  width: 600px;
  margin: 0 auto;
  background: ${({ emailProperties }) => (emailProperties?.background_color ? emailProperties.background_color : '#fff')};
  padding-left: ${({ emailProperties }) => (emailProperties?.padding_left ? emailProperties.padding_left : '0')}px;
  padding-right: ${({ emailProperties }) => (emailProperties?.padding_right ? emailProperties.padding_right : '0')}px;
  padding-top: ${({ emailProperties }) => (emailProperties?.padding_top ? emailProperties.padding_top : '0')}px;
  padding-bottom: ${({ emailProperties }) => (emailProperties?.padding_bottom ? emailProperties.padding_bottom : '0')}px;
  border-style: ${({ emailProperties }) => (emailProperties?.border_style ? emailProperties.border_style : 'none')};
  border-width: ${({ emailProperties }) => (emailProperties?.border_width ? emailProperties.border_width : '1')}px;
  border-color: ${({ emailProperties }) => (emailProperties?.border_color ? emailProperties.border_color : '#000000')};
  border-radius: ${({ emailProperties }) => (emailProperties?.border_radius ? emailProperties.border_radius : '0')}px;
  transform: scale(0.3) translate(0, -117%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  user-drag: none;
  ::-webkit-scrollbar {
    display: none;
  }
  & img {
    user-select: none;
    user-drag: none;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

export const EmailTable = (props) => {
  const {
    emails: emailsProp,
    error,
    isLoading,
    onPageChange,
    archiveEmail,
    page,
  } = props;

  const navigate = useNavigate();

  const [emails, setEmails] = useState(emailsProp);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [previewDialogOpen, handleOpenPreviewDialog, handleClosePreviewDialog] = useDialog();
  useEffect(() => {
    setEmails(emailsProp);
  }, [emailsProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !emails?.length);

  const [anchorEL, setAnchorEL] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e, email) => {
    setSelectedEmail(email);
    setAnchorEL(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEL(null);
    setOpen(false);
  };

  const renderComponent = (item) => {
    const { html, errors } = mjml2html(getComponentHtml(item, 600, true), {
      validationLevel: 'soft'
    });

    if (errors && errors.length) {
      console.log('mjml errors:', errors);
      return { __html: errors.map(({ message }) => `<pre style="font-size: 0.8em; padding: 5px 15px; background: #fff; border: 1px solid rgba(255,0,0,0.4); white-space: pre-wrap;">${message}</pre>`).join('') };
    }

    return { __html: html };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 1000 }}>

          <TableBody>
            {emails?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).map((email) => {
              const parsedEmailJson = JSON.parse(email.email_json);
              const { properties } = parsedEmailJson;
              const { content } = parsedEmailJson;
              return (
                <TableRow
                  hover
                  key={email.id}
                >
                  <TableCell
                    width="200px"
                    sx={{ paddingRight: '10px' }}
                  >

                    <Card
                      sx={{ width: 'fit-content', height: '240px', backgroundColor: '#354147', m: '1em', p: '1em', position: 'relative', }}
                    >

                      <Grid
                        justifyContent="center"
                        container
                        sx={{
                          width: 180,
                          overflow: 'hidden'
                        }}
                      >

                        <EmailPreview
                          widthProps={600}
                          emailProperties={properties}
                        >
                          { content.map((x) => (
                            <div
                              key={x.id}
                              dangerouslySetInnerHTML={renderComponent(x)}
                            />
                          )) }
                        </EmailPreview>
                      </Grid>
                    </Card>

                  </TableCell>

                  <TableCell>
                    <Grid
                      display="flex"
                      direction="column"
                      container
                    >
                      <Typography
                        variant="h5"
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/email-editor', {
                            state: {
                              ...JSON.parse(email.email_json),
                              id: email.id,
                              name: email.name,
                            }
                          });
                        }}
                      >
                        {email.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          color: 'success.main'
                        }}
                      >
                        { email.status }
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Edited On: '}
                        {format(new Date(email.updated_at), 'dd/MM/yyyy p')}
                      </Typography>

                    </Grid>
                  </TableCell>

                  <TableCell sx={{ paddingLeft: '8em', cursor: 'pointer' }}>
                    <Grid
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <MoreVertIcon
                        aria-owns={anchorEL ? 'simple-menu' : undefined}
                        aria-haspopup={anchorEL ? 'true' : undefined}
                        onClick={(event) => handleClick(event, email)}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <PagelessPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        isLastPage={Boolean(emails?.length < 50)}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEL}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        sx={{ marginTop: '-2em' }}
      >
        <MenuItem onClick={() => {
          navigate('/email-editor', {
            state: {
              ...JSON.parse(selectedEmail.email_json),
              id: selectedEmail.id,
              name: selectedEmail.name,
            }
          });
        }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenPreviewDialog}>
          Preview Email
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteDialog}>
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        message="Are you sure you want to delete this email template?"
        onCancel={handleCloseDeleteDialog}
        onConfirm={() => {
          archiveEmail(selectedEmail.id);
          setSelectedEmail(null);
          handleCloseDeleteDialog();
        }}
        open={deleteDialogOpen}
        title="Delete Email Template"
        variant="error"

      />
      {selectedEmail
        ? (
          <PreviewEmailDialog
            onCancel={() => {
              handleClosePreviewDialog();
              setSelectedEmail(null);
            }}
            open={previewDialogOpen}
            selectedEmail={selectedEmail}
          />
        )
        : null}
    </Box>
  );
};

EmailTable.defaultProps = {
  emails: [],
  page: 1,
};

EmailTable.propTypes = {
  emails: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  archiveEmail: Proptypes.func,
  page: Proptypes.number,
};
