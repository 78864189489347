import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/use-auth';

export const RoleGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  // If they're not an admin, redirect them to the dashboard.
  if (!auth?.org?.access?.includes('admin') && !auth?.org?.access?.includes('owner')) {
    return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node
};
