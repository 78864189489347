import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Avatar, Box, Button, Typography, IconButton, Checkbox } from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';
import { imagePath } from '../config';
import { Upload as UploadIcon } from '../icons/upload';

export const ImagesUploaderGeneric = ({ setSelectedImages, selectedImages }) => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [images, setImages] = useState([]);
  async function getAssets() {
    try {
      const assets = await apiRequest('/api/assets');
      if (mounted.current) {
        setImages(assets);
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setImages([]);
      }
    }
  }

  useEffect(() => {
    getAssets();
  }, [org]);

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleSaveImages = async (event) => {
    const x = await toBase64(event.target.files[0]);
    apiRequest('/api/assets/create', {
      assetData: x, fileType: event.target.files[0].type
    }).then(() => {
      getAssets();
    }).catch((err) => {
      console.log(err);
    });
  };
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: !images.length ? '1fr' : ({
            md: 'repeat(auto-fill, 140px)',
            sm: 'repeat(4, 1fr)',
            xs: 'repeat(2, 1fr)'
          }),
          '& img': {
            borderRadius: 1,
            maxWidth: '100%'
          }
        }}
      >
        <label htmlFor="contained-button-file">
          <input
            accept="image/jpeg, image/png"
            type="file"
            onChange={handleSaveImages}
            style={{ display: 'none' }}
            id="contained-button-file"
          />

          <Box
            sx={{
              alignItems: 'center',
              borderColor: 'neutral.200',
              borderRadius: 1,
              borderStyle: 'dashed',
              borderWidth: 1,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              outline: 'none',
              width: '100%',
              maxWidth: '126px',
              height: '126px',
              py: 2,
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'action.hover'
              },
            }}
          >

            <Avatar
              sx={{
                backgroundColor: 'rgba(238, 240, 242, 1)',
                color: 'text.secondary',
                height: 36,
                width: 36,
                mb: 1,
              }}
            >
              <UploadIcon />
            </Avatar>

            <Button
              color="primary"
              variant="contained"
              component="span"
            >
              Upload
            </Button>

            <Typography
              align="center"
              sx={{ color: 'text.secondary', mt: 1 }}
              variant="caption"
            >
              Select images
            </Typography>
          </Box>
        </label>

        {images && images.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((image) => (
          <Box
            key={image.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

            }}
            onClick={() => {
              if (selectedImages.includes(image.id)) {
                setSelectedImages([...selectedImages.filter((x) => x !== image.id)]);
              } else {
                setSelectedImages([image.src, image.id]);
              }
              //  selectedImages.includes(image.id) ? setSelectedImages([...selectedImages.filter((x) => x !== image.id)]) : setSelectedImages([image.src, image.id]);
            }}
          >
            <Box
              sx={{
                aspectRatio: '1',
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
                '&::before': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: 1,
                  bottom: 0,
                  content: '""',
                  display: 'none',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0
                },
                ...selectedImages.includes(image.id) && {
                  boxShadow: (theme) => `0px 0px 0px 2px ${theme.palette.primary.main}`,
                  '&::before': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 1,
                    bottom: 0,
                    content: '""',
                    display: 'block',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0
                  },
                  '& button': {
                    display: 'inline-flex'
                  }
                },
                '&:hover': {
                  boxShadow: selectedImages.includes(image.id) ? (theme) => `0px 0px 0px 2px ${theme.palette.primary.main}` : (theme) => `0px 0px 0px 1px ${theme.palette.primary.main}`,
                  '&::before': {
                    display: 'block'
                  },
                  '& button': {
                    display: 'inline-flex'
                  }
                }
              }}
            >
              <img
                alt=""
                src={`${imagePath}${image.src}`}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />

              <IconButton
                color="primary"
                sx={{
                  p: '0',
                  m: '0',
                  top: 2,
                  color: 'text.secondary',
                  display: 'none',
                  position: 'absolute',
                  left: 2
                }}
              >
                <Checkbox
                  checked={selectedImages.includes(image.id)}
                />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

    </>
  );
};

ImagesUploaderGeneric.propTypes = {
  setSelectedImages: PropTypes.func.isRequired,
  selectedImages: PropTypes.array.isRequired,
};
