import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, Divider, CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { parse, format, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { ActionsMenu } from '../actions-menu';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { Typologies } from '../../utils/typology';
import { GroupBys } from '../../utils/group-by-date';
import { DateControls } from './date-controls';
import { demoId } from '../../utils/demo';
import demoCustomerGrowth from '../../demo/rawCustomerGrowth.json';

export const CustomersOverTime = (props) => {
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const theme = useTheme();
  const [category, setCategory] = useState(Typologies[0].label);

  const categoryOptions = Typologies.map((typology) => (
    { label: typology.label, onClick: () => { setCategory(typology.label); } }
  ));

  const [customerGrowth, setCustomerGrowth] = useState({ isLoading: true, data: [] });

  let xAxisRange = [];
  const firstOfLastMonth = org.id === demoId ? startOfMonth(subMonths(new Date('2022-06-01'), 1)) : startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = org.id === demoId ? endOfMonth(subMonths(new Date('2022-06-01'), 1)) : endOfMonth(subMonths(new Date(), 1));
  const [selectedDateRange, setSelectedDateRange] = useState({ start: firstOfLastMonth, end: endOfLastMonth });
  xAxisRange = GroupBys[1].getRange(selectedDateRange.start, selectedDateRange.end);
  useEffect(() => {
    async function getCustomerGrowth() {
      setCustomerGrowth({ isLoading: true, data: [] });
      const startDate = format(selectedDateRange.start, 'yyyy-MM-dd');
      const endDate = format(selectedDateRange.end, 'yyyy-MM-dd');
      try {
        const customerGrowthData = org.id === demoId ? demoCustomerGrowth : await apiRequest('/api/customers/growth', {
          startDate, endDate
        }) || [];
        const customerData = [
          ...Typologies.map((typology) => ({
            name: typology.label,
            data: [...xAxisRange.map((formattedDate) => {
              const dataPoint = customerGrowthData.find((x) => x.context.toUpperCase() === typology.value.toUpperCase() && x.formatted_date === formattedDate);
              return (dataPoint && dataPoint.customers) ? dataPoint.customers : 0;
            })]
          })),
          {
            name: 'Total Customers',
            data: [...xAxisRange.map((formattedDate) => customerGrowthData.reduce((acc, curr) => ((curr.formatted_date === formattedDate && curr.customers) ? (acc + curr.customers) : acc), 0))]
          }
        ];
        if (mounted.current) {
          setCustomerGrowth({ isLoading: false, data: customerData });
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          setCustomerGrowth({
            isLoading: false,
            data: [],
          });
        }
      }
    }
    getCustomerGrowth();
  }, [org, selectedDateRange]);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false,
      }
    },
    colors: ['#0058FF', '#21D59B'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      // padding: {
      //   left: 50,
      //   right: 50
      // },
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: xAxisRange?.map((x) => format(parse(x, 'd MMM yyyy', new Date()), 'dd MMM')),
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...props}
    >
      <Grid
        container
        alignItems="center"
        sx={{ display: 'flex' }}
        gap={1}
      >
        <CardHeader
          title="Customer growth"
          sx={{ display: 'flex', flexDirection: 'row' }}
        />
        <ActionsMenu
          actions={categoryOptions}
          label={category}
          size="small"
          variant="text"
        />
        <Grid
          item
          display="flex"
        >
          <DateControls
            sx={{ width: 'auto', display: 'flex', }}
            showCompare={false}
            onDateChange={(start, end) => {
              setSelectedDateRange({ start, end });
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ px: 2 }}>
        { customerGrowth.isLoading === false
          ? (
            <Chart
              height="400"
              options={chartOptions}
              series={customerGrowth.data.filter((x) => x.name === category || x.name === 'Total Customers')}
              type="area"
            />
          )
          : (
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <CircularProgress
                color="primary"
                size={100}
              />

            </Box>
          )}
      </Box>
    </Card>
  );
};
