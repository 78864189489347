import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

export const Simple = () => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      flexGrow: 1,
      py: 4
    }}
  >
    <Container
      maxWidth="xl"
      className="asdasd"
      sx={{ height: '100%' }}
    >
      <Outlet />
    </Container>
  </Box>
);
