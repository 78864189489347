import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ImageComponent } from './image-component';

export const SelectAssetDialog = ({ onCancel, open, handleSelect, properties, value, callback, setSelectedImage, selectedImage, setImages, images, ...other }) => (
  <Dialog
    onClose={onCancel}
    open={open}
    fullWidth
    maxWidth="md"
    PaperProps={{
      sx: {
        width: '690px'
      }
    }}
    {...other}
  >
    <DialogTitle>
      Select image
    </DialogTitle>
    <DialogContent>
      <ImageComponent
        properties={properties}
        value={value}
        callback={callback}
        selectedImages={selectedImage}
        setImages={setImages}
        setSelectedImages={setSelectedImage}
        images={images}
      />
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={onCancel}
        variant="text"
      >
        Cancel
      </Button>
      <Button
        color="primary"
        onClick={() => {
          handleSelect();
          onCancel();
        }}
        variant="contained"
      >
        Select Image
      </Button>
    </DialogActions>
  </Dialog>
);

SelectAssetDialog.defaultProps = {
  open: false
};

SelectAssetDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  properties: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  setSelectedImages: PropTypes.func,
  selectedImages: PropTypes.array,
  images: PropTypes.array.isRequired,
  setImages: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  selectedImage: PropTypes.array.isRequired,
};
