import { Alert, Typography, Divider, Button, Grid } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { CodeBlock } from '../code-block';

export const Scripts = () => {
  const { org } = useAuth();
  if (org.data_connection_type === 'SHOPIFY') {
    return (
      <>
        {
        org.data_connection_status === 'ACTIVE' ? (
          <>
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ mb: 1 }}
            >
              Installing Site Tracking
            </Typography>
            <Typography
              color="textPrimary"
              variant="body2"
              sx={{ mb: 1 }}
            >
              Install the AudienceZen tracking scripts on your website to record customer events.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #1 Open your Shopify admin.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #2 Click &#34;Settings&#34; in the bottom left of the Shopify admin and press &#34;Checkout&#34;.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #3 Paste this code snippet at the bottom of the &#34;Scripts&#34; field under &#34;Order status page scripts&#34;.
            </Typography>
            <CodeBlock
              code={`{% if first_time_accessed and order.email %}
      <!-- CHECKOUT: START AUDIENCEZEN TRACKING SCRIPT -->
      <script src="https://track.audiencezen.com/track.js"></script>
      
      {% comment %}
        We track unique attributes for products based off product tags set by the merchant.
      {% endcomment %}
      {% assign hsc = false %} {% comment %}High Social Capital{% endcomment %}
      {% assign ceb = false %} {% comment %}Common Established Brands{% endcomment %}
      {% assign sp = false %} {% comment %}Socially Popular{% endcomment %}
      {% assign tb = false %} {% comment %}Time Based{% endcomment %}
      
      {%- for line_item in order.line_items -%}
        {% if line_item.product.tags contains 'az:hsc' %}
          {% assign hsc = true %}
        {% endif %}
        {% if line_item.product.tags contains 'az:ceb' %}
          {% assign ceb = true %}
        {% endif %}
        {% if line_item.product.tags contains 'az:sp' %}
          {% assign sp = true %}
        {% endif %}
        {% if line_item.product.tags contains 'az:tb' %}
          {% assign tb = true %}
        {% endif %}
      {%- endfor -%}
      
      <script>
        const order_check = window.localStorage.getItem('{{ order.name }}');
        if(!order_check) {
          window.localStorage.setItem('{{ order.name }}', \`FINISHED_CHECKOUT\`);
          window.audiencezen_org = "${org.id}";
          var _az = _az || [];
      
          /* if identifier set but no identified date, send record */
          if(audiencezen_org && localStorage.getItem('az-identifier') && !localStorage.getItem('az-identified-date')) {
            localStorage.setItem('az-identified-date', +(new Date()));
            window.customerIdentified("{{ order.email }}", localStorage.getItem('az-identifier'))
          }
      
          _az.push(['FINISHED_CHECKOUT', '{{ order.email }}', {
            "order_id": "{{ order.name }}",
            "delivery_country": "{{ shipping_address.country_code }}",
            "currency": "${org.currency}",
            "hsc": "{{ hsc }}",
            "ceb": "{{ ceb }}",
            "sp": "{{ sp }}",
            "tb": "{{ tb }}",
            "value": "{{ subtotal_price | money_without_currency }}",
            "discounts": "{{ order.total_discounts | money_without_currency }}",
            "line_items": "{%- for line_item in order.line_items -%}{{ line_item.variant_id }}:{{ line_item.quantity }}:{{ line_item.product.type }}:{{ line_item.vendor }}{%- unless forloop.last -%},{%- endunless -%}{%- endfor -%}"
          }]);
        }
      </script>
      <!-- CHECKOUT: START AUDIENCEZEN TRACKING SCRIPT -->
      {% endif %}`}
            />
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #4 Activate AudienceZen Settings on your current theme by pressing the button below.
            </Typography>
            <Grid>
              <Button
                underline="none"
                color="primary"
                size="large"
                variant="contained"
                onClick={() => window.open(`https://${org.data_connection_domain}/admin/themes/current/editor?context=apps&activateAppId=fed52ea5-d80e-4f8f-87aa-d1f69eab43d8/az-settings`)}
              >
                Activate Settings
              </Button>
            </Grid>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1, mt: 2 }}
            >
              #5 Copy the text snippet below into the text field called &apos;Organization ID&apos;.
            </Typography>
            <CodeBlock
              code={`${org.id}`}
            />
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1, mt: 1 }}
            >
              #6 Press the Save button in the top right corner of the page.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #7 Lastly, activate AudienceZen Tracking on your current theme by pressing the button below.
            </Typography>
            <Grid>
              <Button
                underline="none"
                color="primary"
                size="large"
                variant="contained"
                onClick={() => window.open(`https://${org.data_connection_domain}/admin/themes/current/editor?context=apps&activateAppId=fed52ea5-d80e-4f8f-87aa-d1f69eab43d8/az-tracking`)}
              >
                Activate Tracking
              </Button>
            </Grid>
            <Divider sx={{ my: 4 }} />
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ mb: 1 }}
            >
              (Optional) Integrating your loyalty system
            </Typography>
            <Alert
              severity="info"
              sx={{ width: '100%', mb: 2 }}
            >
              This is only required if you wish to use AudienceZen&rsquo;s loyalty features.
            </Alert>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #1 Activate AudienceZen Loyalty on your current theme by pressing the button below.
            </Typography>
            <Button
              underline="none"
              color="primary"
              size="large"
              variant="contained"
              onClick={() => window.open(`https://${org.data_connection_domain}/admin/themes/current/editor?context=apps&activateAppId=fed52ea5-d80e-4f8f-87aa-d1f69eab43d8/az-loyalty`)}
            >
              Activate Loyalty System
            </Button>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1, mt: 2 }}
            >
              #2 Copy the text snippet below into the text field called &apos;Secret&apos;.
            </Typography>
            <CodeBlock
              code={`${org.secret_key}`}
            />
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1, mt: 1 }}
            >
              #3 Press the Save button in the top right corner of the page.
            </Typography>
            <Divider sx={{ my: 4 }} />
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ mb: 1 }}
            >
              Deactivating Tracking and Loyalty Systems
            </Typography>
            <Alert
              severity="info"
              sx={{ width: '100%', mb: 2 }}
            >
              This is only required if you wish to stop using AudienceZen&rsquo;s tracking and loyalty features.
            </Alert>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #1 Open your Shopify admin.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #2 Click on &#34;Online Store&#34;.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #3 On your Current theme, press &#34;Customize&#34;.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #4 Click on &#34;Theme settings&#34; in the bottom left corner.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #5 Click on &#34;App embeds&#34; in the top right corner.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #6 Search for &#34;Tracking&#34; and &#34;Loyalty&#34; and toggle the button next to each embed that you wish to deactivate.
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ mb: 1 }}
            >
              #7 After making your changes, click &#34;Save&#34; in the top right corner.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ mb: 1 }}
            >
              Installing Site Tracking and Loyalty
            </Typography>
            <Typography
              color="textPrimary"
              variant="body2"
              sx={{ mb: 1 }}
            >
              You must have a currently active Integration before tracking and loyalty is available.
            </Typography>
          </>
        )
      }
      </>
    );
  }
  return (
    <>
      <Typography
        color="textPrimary"
        variant="h6"
        sx={{ mb: 1 }}
      >
        Tracking & Website Scripts
      </Typography>
      <Typography
        color="textPrimary"
        variant="body2"
        sx={{ mb: 1 }}
      >
        You must add a Shopify Integration before viewing this page.
      </Typography>
    </>
  );
};
