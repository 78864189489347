import { useEffect, useState } from 'react';
import { format, subMonths, startOfMonth } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Container, Card, CardContent, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { useAuth } from '../hooks/use-auth';
import { DateControls } from '../components/insights-root/date-controls';

/* Utils */
import gtm from '../lib/gtm';

import { Distribution } from '../components/survey/inactive/distribution';
import { RadarChart } from '../components/survey/inactive/radar-chart';
import { Submissions } from '../components/survey/submissions';

import { demoId } from '../utils/demo';
import demoInsightsInactiveData from '../demo/rawInsightsInactive.json';

export const SurveyInactive = () => {
  const { apiRequest, org } = useAuth();
  const [loading, setLoading] = useState(true);
  const [controller, setController] = useState({
    data: null
  });

  const [selectedDateRange, setSelectedDateRange] = org.id === demoId ? useState({ start: new Date('2021-09-01'), end: new Date('2022-08-31') }) : useState({ start: startOfMonth(subMonths(new Date(), 11)), end: new Date() });
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));

  async function getData() {
    setLoading(true);
    const data = org.id === demoId ? demoInsightsInactiveData : await apiRequest('/api/insights/inactive', {
      start: format(selectedDateRange.start, 'yyyy-MM-dd'),
      end: format(selectedDateRange.end, 'yyyy-MM-dd'),
    });
    setController({
      ...controller,
      data
    });

    setLoading(false);
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getData();
  }, [selectedDateRange]);

  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: md ? 1 : 2,
                flexDirection: md ? 'column' : 'row',
                textAlign: md ? 'center' : 'unset',
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/surveys"
                variant="text"
              >
                Return to surveys
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="subtitle2">Themes:</Typography>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/referrals"
                variant="text"
              >
                Growing customer referrals
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/inactive"
                variant="text"
              >
                Why do customers stop buying?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/value"
                variant="text"
              >
                Am I satisfying my high value customers?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/wallet"
                variant="text"
              >
                How do I increase my share of wallet?
              </Button>
            </Box>
          </Box>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: md ? '0' : 2,
                      flexDirection: md ? 'column' : 'row',
                      mb: 1,
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{ mb: 1 }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Why do customers stop buying?
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="subtitle2">Select Date Range:</Typography>
                    <DateControls
                      defaultStart={selectedDateRange.start}
                      defaultEnd={selectedDateRange.end}
                      showCompare={false}
                      onDateChange={(start, end = null) => {
                        setSelectedDateRange({ start, end });
                      }}
                    />
                  </Box>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                  >
                    Customer satisfaction survey data can provide insight into why some customers stop buying from you. The analysis steps below will help you identify with which customer experience attributes your inactive customers are most dissatisfied.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Distribution data={controller.data} />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    It is important to monitor how many of your customers are inactive and understand why this is occurring. An increasing trend of inactive customers is particularly of concern. Look to chart 2 for insight.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <RadarChart data={controller.data.inactive_range} />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    This chart compares your inactive customers’ general expectations for service attributes (orange line) to their experience of these attributes from your business (blue area). A gap between the orange line and the blue area indicates a service attribute with poor customer satisfaction and this may be causing customers to stop buying from you.
                    <br />
                    <br />
                    Prioritize those attributes with high expectations and poor experience for improvement.
                    <br />
                    <br />
                    Communicating your customer experience improvements to inactive customers may prompt them to resume buying.
                    <br />
                    <br />
                    The data is from inactive customers surveyed in the 6 months prior to their last purchase.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Submissions
                title="3. Review survey comments for further insight"
                hasProperties={false}
                data={controller.data.inactive_range}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                  >
                    Pinpoint what should be improved
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    {'To pinpoint what needs to be improved, search for clues in the comments provided by survey respondents. '}
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    Also, look for recurring issues raised in:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your customer support communications.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your social media presence.
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    And, ideally, talk to some customers who have had unsatisfactory experiences.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
};
