import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Parser } from 'json2csv';
import { useAuth } from '../../hooks/use-auth';

export const ImportDialog = (props) => {
  const { open, onClose, onUpload, ...other } = props;
  const { org } = useAuth();

  const onDrop = (files) => {
    onUpload(files);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop
  });

  const exportData = () => {
    const parser = new Parser({ fields: ['email', 'firstName', 'lastName', 'acceptsMarketing', ...org?.accountType === 'Business' ? [] : ['loyaltyPoints']] });
    const csv = parser.parse({
      email: 'john@exampleemail.com',
      firstName: 'John',
      lastName: 'Smith',
      acceptsMarketing: 'TRUE',
      ...org?.accountType === 'Business' && {
        loyaltyPoints: 0
      }
    });
    const linkSource = `data:text/csv;charset=utf-8,${csv}`;
    const downloadLink = document.createElement('a');
    const fileName = `export-${(new Date()).toISOString()}.csv`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      {...other}
    >
      <DialogTitle>
        <Grid
          item
          container
          sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}
        >
          <Typography variant="h5">
            Upload a CSV file or download a copy of the template
          </Typography>

          <Button
            onClick={onClose}
            variant="contained"
            sx={{ height: '30px', width: '30px', minWidth: '30px', p: 0, backgroundColor: '#ff605c', '&:hover': { backgroundColor: '#c24b48' }, }}
          >
            <span style={{ lineHeight: '10px' }}>&#10006;</span>
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: '10px !important' }}>
        <Grid
          item
          container
          sx={{ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '1fr fr', gap: '20px', alignItems: 'center' }}
        >

          <Button
            color="primary"
            fullWidth
            variant="outlined"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Typography>Upload</Typography>
          </Button>
          <Button
            color="primary"
            onClick={() => exportData()}
            fullWidth
            variant="contained"
          >
            <Typography>Download Template</Typography>
          </Button>
        </Grid>

      </DialogContent>

    </Dialog>
  );
};

ImportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
};
