import { useLocation, useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
  CardActionArea,
  CardContent,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  Alert,
  ListItem,
  FormGroup,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import numeral from 'numeral';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import DraftsIcon from '@mui/icons-material/Drafts';
import CodeIcon from '@mui/icons-material/Code';
import * as Yup from 'yup';
import Tooltip from '@mui/material/Tooltip';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import styled from '@emotion/styled';
import mjml2html from 'mjml-browser';
import { Plus as PlusIcon } from '../icons/plus';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { useDialog } from '../hooks/use-dialog';
import { useAuth } from '../hooks/use-auth';
import { isDev } from '../utils/is-dev';
import { InputField } from '../components/input-field';
import { AutocompleteField } from '../components/autocomplete-field';
import { Typologies } from '../utils/typology';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { TypologyBreakdownChart } from '../components/campaign/typology-breakdown-chart';
import { FilterDialog } from '../components/filter-dialog';
import { Adjustments as AdjustmentsIcon } from '../icons/adjustments';
import { PhoneIphone as PhoneIphoneIcon } from '../icons/phone-iphone';
import { demoId } from '../utils/demo';
import demoGroups from '../demo/groups.json';
import demoCampaign from '../demo/rawCampaignSummary.json';

/* Utils */
import gtm from '../lib/gtm';
import {
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  lessThanOperator,
  notContainsOperator,
  notEqualOperator,
  startsWithOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
} from '../utils/filter-operators';
import { CustomerProperties } from '../utils/properties';

import { getComponentHtml } from '../utils/email-components';

const EmailPreview = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${({ emailProperties }) => (emailProperties?.background_color ? emailProperties.background_color : '#fff')};
  padding-left: ${({ emailProperties }) => (emailProperties?.padding_left ? emailProperties.padding_left : '0')}px;
  padding-right: ${({ emailProperties }) => (emailProperties?.padding_right ? emailProperties.padding_right : '0')}px;
  padding-top: ${({ emailProperties }) => (emailProperties?.padding_top ? emailProperties.padding_top : '0')}px;
  padding-bottom: ${({ emailProperties }) => (emailProperties?.padding_bottom ? emailProperties.padding_bottom : '0')}px;
  border-style: ${({ emailProperties }) => (emailProperties?.border_style ? emailProperties.border_style : 'none')};
  border-width: ${({ emailProperties }) => (emailProperties?.border_width ? emailProperties.border_width : '1')}px;
  border-color: ${({ emailProperties }) => (emailProperties?.border_color ? emailProperties.border_color : '#000000')};
  border-radius: ${({ emailProperties }) => (emailProperties?.border_radius ? emailProperties.border_radius : '0')}px;
  
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  user-drag: none;
  ::-webkit-scrollbar {
    display: none;
  }
  & img {
    user-select: none;
    user-drag: none;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`;
const filterOperators = [
  equalOperator,
  notEqualOperator,
  containsOperator,
  notContainsOperator,
  startsWithOperator,
  endsWithOperator,
  greaterThanOperator,
  lessThanOperator,
  isAfterOperator,
  isBeforeOperator,
  isBlankOperator,
  isPresentOperator,
  isDaysLessThanOperator,
  isDaysGreaterThanOperator,
  isDaysEqualOperator,
  isDaysNotEqualOperator,
];

const customerFilterProperties = [...CustomerProperties];

const schema = Yup.object().shape({
  name: Yup.string().min(1).required(),
  fromEmail: Yup.string().min(1).required(),
  fromName: Yup.string().min(1).required(),
  subject: Yup.string().min(1).required(),
  previewText: Yup.string().min(1).required(),
  emailHtml: Yup.string().min(1).required(),
  group: Yup.string().min(1).required(),
  id: Yup.string().min(1).required(),
  scheduleFor: Yup.date().required(),
});

export const CampaignEditor = () => {
  const { apiRequest, org } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [campaignDialogOpen, handleOpenCampaignDialog, handleCloseCampaignDialog] = useDialog();
  const [pieChartData, setPieChartData] = useState();
  const [activityTotal, setActivityTotal] = useState(null);
  const [valid, setValid] = useState(false);
  const [openCustomerFilterDialog, setOpenCustomerFilterDialog] = useState(false);
  const [previewType, setPreviewType] = useState('desktop');
  const [controller, setController] = useState({
    loading: true,
    customerFilters: [],
    groups: [],
    smartSend: false,
    fromEmail: '',
    fromName: '',
    subject: '',
    name: '',
    previewText: '',
    emailJSON: null,
    emailType: null,
    emailHtml: '',
    group: null,
    groupObject: null,
    id: null,
    scheduleFor: new Date(),
    needLevel: 'Desired',
  });

  const desktopWidth = controller?.emailJSON?.properties?.width ? controller?.emailJSON?.properties?.width : 600;

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        custom: 700,
        lg: 1300,
        xl: 1536,
      },
    },
  });
  const custom = useMediaQuery(theme.breakpoints.down('custom'));
  const saveCampaign = async (newState) => {
    try {
      const newController = {
        ...controller,
        ...newState,
      };
      const { groups, scheduleFor, groupObject, loading, ...data } = newController;
      if (org.id !== demoId) {
        await apiRequest('/email/update-campaign', {
          ...data,
          emailJSON: JSON.stringify(data.emailJSON),
          appliedDisjunctively: false,
        });
      }
      toast.success('Campaign saved');
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    schema.isValid(controller).then((isValid) => {
      if (isDev) {
        console.log('Campaign ready to publish?', isValid);
      }
      if (isValid) {
        setValid(true);
      } else {
        setValid(false);
      }
    });
  }, [controller]);

  useEffect(() => {
    // update pieChartData on controller change
    const seriesData = controller.groupObject?.typologies?.map((typology) => ({ ...typology, typology: Typologies.find((x) => x.value === typology.typology.toUpperCase()).label, color: Typologies.find((x) => x.value === typology.typology.toUpperCase()).color }));

    const countSum = seriesData?.map((typology) => typology.count).reduce((acc, curr) => acc + curr, 0);

    const returnData = seriesData?.map((typology) => ({ ...typology, count: ((typology.count / countSum) * 100) }));

    setPieChartData(returnData);
  }, [controller.groupObject]);

  useEffect(() => {
    async function getCampaign() {
      const groups = org.id === demoId ? demoGroups : await apiRequest('/api/groups', {
      }) || [];
      const groupOptions = groups.sort((a, b) => b.total - a.total).map((x) => ({
        value: x.id,
        label: `${x.name} (${x.total})`
      }));

      const data = org.id === demoId ? demoCampaign : await apiRequest('/email/campaign', {
        id: params.campaignId,
      });
      if (data && groups) {
        const newState = {
          ...controller,
          loading: false,
          customerFilters: data.customer_filters,
          groups: groups.sort((a, b) => b.total - a.total).map((x) => ({
            value: x.id,
            label: `${x.name} (${x.total})`,
            typologies: x.typologies
          })),
          name: data.name,
          smartSend: data.smart_send,
          fromEmail: data.from_email,
          fromName: data.from_name,
          subject: data.subject || '',
          needLevel: data.need_level || org?.need_level || 'Desired',
          previewText: data.preview_text || '',
          ...data.email_json && { emailJSON: JSON.parse(data.email_json) },
          ...data.email_type && { emailType: data.email_type },
          ...data.email_html && { emailHtml: data.email_html },
          id: data.id,
          group: data.group_id,
          ...data.group_id && { groupObject: groupOptions.find((x) => x.value === data.group_id) },
          // callbacks
          ...location?.state?.emailType && { emailType: location?.state?.emailType },
          ...location?.state?.json && { emailJSON: location?.state?.json },
          ...location?.state?.html && { emailHtml: location?.state?.html },
        };
        setController(newState);
        // set typology breakdown vars

        const seriesData = newState?.groups.find((x) => x.value === data.group_id)?.typologies?.map((typology) => ({ ...typology, typology: Typologies.find((x) => x.value.toUpperCase() === typology.typology.toUpperCase()).label, color: Typologies.find((x) => x.value.toUpperCase() === typology.typology.toUpperCase()).color }));

        const countSum = seriesData?.map((typology) => typology.count).reduce((acc, curr) => acc + curr, 0);

        const returnData = seriesData?.map((typology) => ({ ...typology, count: ((typology.count / countSum) * 100) }));

        setPieChartData(returnData);

        if (location?.state?.emailType || location?.state?.html || location?.state?.json) {
          saveCampaign(newState);
        }
      }
    }

    gtm.push({ event: 'page_view' });
    getCampaign();
  }, []);

  useEffect(() => {
    async function getActivityTotal() {
      const { total } = org.id === demoId ? { total: 57 } : await apiRequest('/api/groups/activity', {
        id: controller.group
      }) || [];
      setActivityTotal(total);
    }

    setActivityTotal(null);

    if (controller?.group) {
      getActivityTotal();
    }
  }, [controller.group]);
  const renderComponent = (item) => {
    const { html, errors } = mjml2html(getComponentHtml(item, (previewType === 'desktop' ? desktopWidth : 320), (previewType === 'desktop')), {
      validationLevel: 'soft'
    });

    if (errors && errors.length) {
      console.log('mjml errors:', errors);
      return { __html: errors.map(({ message }) => `<pre style="font-size: 0.8em; padding: 5px 15px; background: #fff; border: 1px solid rgba(255,0,0,0.4); white-space: pre-wrap;">${message}</pre>`).join('') };
    }

    return { __html: html };
  };

  const handlePublish = async () => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/email/publish-campaign', {
          id: controller.id,
          scheduledFor: controller.scheduleFor
        });
      }
      toast.success('Campaign published');
      navigate('/dashboard/campaigns');
    } catch (e) {
      console.log(e);
    }
  };
  const audienceGroups = controller?.groups?.map((group) => {
    let groupKey;
    if (Typologies.some((x) => group.label.toUpperCase().includes(x.value))) {
      groupKey = 'Typologies';
    } else if (group.label.toUpperCase().includes('ALL CUSTOMERS')) {
      groupKey = 'General';
    } else {
      groupKey = 'Custom Group';
    }
    return {
      groupKey,
      ...group,
    };
  });
  const handleEditEmail = async () => {
    await saveCampaign();

    if (controller.emailType === 'DESIGN') {
      navigate('/email-editor', {
        state: {
          ...controller.emailJSON,
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'DESIGN',
              controller: { ...controller, typologyBreakdown: pieChartData },

            }
          }
        }
      });
    }

    if (controller.emailType === 'TEXT') {
      navigate('/dashboard/text-editor', {
        state: {
          html: controller.emailHtml,
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'TEXT',
            }
          }
        }
      });
    }
    if (controller.emailType === 'CODE') {
      navigate('/dashboard/html-editor', {
        state: {
          html: controller.emailHtml,
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'CODE',
            }
          }
        }
      });
    }
  };

  const handleNewEmail = async (type) => {
    await saveCampaign();
    if (type === 'DESIGN') {
      navigate('/dashboard/email-templates/create', {
        state: {
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'DESIGN',
              controller: { ...controller, typologyBreakdown: pieChartData },
            }
          }
        }
      });
    }
    if (type === 'TEXT') {
      navigate('/dashboard/text-editor', {
        state: {
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'TEXT',
            }
          }
        }
      });
    }
    if (type === 'CODE') {
      navigate('/dashboard/html-editor', {
        state: {
          callback: {
            url: `/dashboard/campaigns/edit/${controller.id}`,
            data: {
              emailType: 'CODE',
            }
          }
        }
      });
    }
  };

  const handleCustomerFiltersClear = () => {
    setController({
      ...controller,
      customerFilters: []
    });
  };
  const handleCustomerFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      column: filter.column.name,
      condition: filter.condition,
      relationType: filter.relationType.value
    }));

    setController({
      ...controller,
      customerFilters: parsedFilters
    });
  };
  if (isDev) {
    console.log(controller, activityTotal);
  }
  if (controller.loading) {
    return (
      <>
        <Helmet>
          <title>Campaigns: New | AudienceZen Dashboard</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            flexGrow: 1,
            height: '100%'
          }}
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress
              color="primary"
              size={100}
            />

          </Box>
        </Box>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>Campaigns: New | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/campaigns"
                variant="text"
              >
                Return to Campaigns
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  saveCampaign();
                }}
              >
                Save as draft
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
          >
            <Grid
              xl={4}
              lg={6}
              md={12}
              xs={12}
              item
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  gap: 1,
                  padding: 2,
                  mb: 2,
                }}
              >

                <Typography
                  sx={{ pb: 1 }}
                  variant="h6"
                >
                  Campaign Information
                </Typography>

                <InputField
                  fullWidth
                  label="Name"
                  name="name"
                  helperText="Used in the admin for reporting"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      name: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 0 }}
                  value={controller.name}
                />

                <Divider sx={{ mt: 2 }} />

                <InputField
                  fullWidth
                  label="Subject line"
                  name="subject"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      subject: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 2 }}
                  value={controller.subject}
                />

                <InputField
                  fullWidth
                  label="Preview Text"
                  name="previewText"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      previewText: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 2 }}
                  value={controller.previewText}
                />

                <InputField
                  fullWidth
                  label="Sender Name"
                  name="fromName"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      fromName: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 2 }}
                  value={controller.fromName}
                />

                <InputField
                  fullWidth
                  label="Sender Email"
                  disabled
                  name="fromEmail"
                  onChange={(ev) => {
                    setController({
                      ...controller,
                      fromEmail: ev.target.value,
                    });
                  }}
                  type="text"
                  sx={{ mt: 2 }}
                  value={controller.fromEmail}
                />
                <Typography
                  variant="caption"
                >
                  You can set your sending email in your
                  {' '}
                  {' '}
                  <Typography
                    variant="caption"
                    component={RouterLink}
                    to="/dashboard/organization"
                  >
                    organization settings
                  </Typography>
                  .
                </Typography>
              </Card>

              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  gap: 1,
                  padding: 2,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ pb: 1 }}
                  variant="h6"
                >
                  Targeting
                </Typography>

                <AutocompleteField
                  filterSelectedOptions
                  fullWidth
                  placeholder="Select a customer group"
                  label="Audience Selector"
                  options={audienceGroups.sort((a, b) => -b.groupKey.localeCompare(a.groupKey))}
                  groupBy={(ag) => ag.groupKey}
                  value={controller.groupObject === null ? '' : controller.groupObject}
                  onChange={(event, newValue) => {
                    setController({
                      ...controller,
                      groupObject: newValue || null,
                      group: (newValue && newValue.value) ? newValue.value : null,
                    });
                  }}
                  // options={controller.groups}
                />

                <>
                  <Alert
                    severity="info"
                  >
                    {numeral(activityTotal).format('0,0')}
                    {' recipients from this group received a campaign email less than a week ago.'}
                  </Alert>

                  <FormGroup sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="smartSend"
                          onChange={() => {
                            setController({
                              ...controller,
                              smartSend: !controller.smartSend,
                            });
                          }}
                          checked={Boolean(controller.smartSend)}
                        />
                )}
                      label="Skip recently emailed customers?"
                    />
                  </FormGroup>
                </>
                <Tooltip
                  placement="top"
                  title="Consumers see products as either a need (required) or a want (desired).
                Consumers choose a required product mainly because of how well it meets their practical needs.
                The decision to choose a desired product is driven more by how the consumer expects owning it
                will make them feel, or by what it might say about them to others. AudienceZen’s sales
                communication guidance varies according to the category in which your product belongs."
                >
                  <Grid
                    item
                    xs={12}
                  >

                    <InputField
                      fullWidth
                      label="Are you products required or desired?"
                      select
                      value={controller.needLevel}
                      onChange={(event) => {
                        setController({
                          ...controller,
                          needLevel: event.target.value
                        });
                      }}
                    >
                      {['Required', 'Desired'].map((option) => {
                        if (typeof option === 'object') {
                          return (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        }
                        return (
                          <MenuItem
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </InputField>

                  </Grid>
                </Tooltip>
                <Button
                  color="primary"
                  onClick={() => setOpenCustomerFilterDialog(true)}
                  startIcon={<AdjustmentsIcon />}
                  size="large"
                  variant={controller.customerFilters.length ? 'contained' : 'text'}
                >
                  {`Customer Filters${controller.customerFilters.length ? ` (${controller.customerFilters.length})` : ''}`}
                </Button>
              </Card>
              {pieChartData

                ? <TypologyBreakdownChart data={pieChartData} />

                : null }
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  gap: 1,
                  padding: 2,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ pb: 1 }}
                  variant="h6"
                >
                  Review & Send
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Select Date & Time"
                    minDate={new Date()}
                    value={controller.scheduleFor}
                    sx={{ ml: '1em' }}
                    onChange={(newValue) => {
                      setController({
                        ...controller,
                        scheduleFor: newValue
                      });
                    }}
                    renderInput={(paramsDatePicker) => <TextField {...paramsDatePicker} />}
                  />
                </LocalizationProvider>

                <Button
                  disabled={!valid}
                  size="large"
                  startIcon={<PlusIcon fontSize="small" />}
                  variant="contained"
                  onClick={() => {
                    handleOpenCampaignDialog();
                  }}
                >
                  Publish Campaign
                </Button>

              </Card>
            </Grid>

            <Grid
              xl={8}
              lg={6}
              md={12}
              xs={12}
              item
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  marginBottom: '2em',
                  gap: 1,
                  padding: 2,
                  mb: 2,
                }}
              >

                <Typography
                  sx={{ pb: 1 }}
                  variant="h6"
                >
                  Design your email
                </Typography>
                {
                  (controller.emailType && controller.emailHtml)
                    ? (
                      <Card
                        variant="outlined"
                        sx={{ mb: 1 }}
                      >
                        <CardActionArea
                          onClick={() => { handleEditEmail(); }}
                        >
                          <CardContent sx={{ p: 1 }}>
                            <Grid
                              container
                              alignItems="center"
                              sx={{ flexDirection: 'row', gap: '50px' }}
                            >

                              <Grid
                                item
                              >
                                {
                              controller.emailType === 'DESIGN' && <ViewQuiltIcon sx={{ fontSize: '5em' }} />
                            }

                                {
                              controller.emailType === 'TEXT' && <DraftsIcon sx={{ fontSize: '5em' }} />
                            }
                                {
                              controller.emailType === 'CODE' && <CodeIcon sx={{ fontSize: '5em' }} />
                            }
                              </Grid>

                              <Grid
                                item
                                xs={5}
                              >
                                <Typography
                                  variant="h5"
                                  color="primary"
                                  sx={{ wordBreak: 'break-word' }}
                                >
                                  Edit your email
                                </Typography>
                              </Grid>

                            </Grid>

                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )
                    : (
                      <>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { handleNewEmail('DESIGN'); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                spacing={custom ? 0 : 2}
                                sx={{ flexDirection: custom ? 'column' : 'row' }}
                                alignItems="center"
                              >

                                <Grid
                                  item
                                >
                                  <ViewQuiltIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  item
                                  xs={5}
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                    sx={{ wordBreak: 'break-word' }}
                                  >
                                    Drag and Drop
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    sx={{ wordBreak: 'break-word' }}
                                  >
                                    Create an email using our drag-and-drop editor.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { handleNewEmail('TEXT'); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                alignItems="center"
                                spacing={custom ? 0 : 2}
                                sx={{ flexDirection: custom ? 'column' : 'row' }}
                              >

                                <Grid
                                  item
                                >
                                  <DraftsIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  item
                                  xs={5}
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                    sx={{ wordBreak: 'break-word' }}
                                  >
                                    Text Only
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    sx={{ wordBreak: 'break-word' }}
                                  >
                                    Send a plain text email with basic formatting.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                        <Card
                          variant="outlined"
                          sx={{ mb: 1 }}
                        >
                          <CardActionArea
                            onClick={() => { handleNewEmail('CODE'); }}
                          >
                            <CardContent sx={{ p: 1 }}>
                              <Grid
                                container
                                spacing={custom ? 0 : 2}
                                sx={{ flexDirection: custom ? 'column' : 'row' }}
                                alignItems="center"
                              >

                                <Grid
                                  item
                                >
                                  <CodeIcon sx={{ fontSize: '5em' }} />
                                </Grid>

                                <Grid
                                  item
                                >
                                  <Typography
                                    variant="h5"
                                    color="primary"
                                  >
                                    HTML
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    Supply your own email code for complete control.
                                  </Typography>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </CardActionArea>

                        </Card>

                      </>
                    )
                }
                <Box sx={{ width: '100%', }}>
                  {
                              controller.emailType === 'DESIGN' && controller.emailJSON?.content && controller.emailJSON?.content?.length
                                ? (
                                  <>
                                    <Box
                                      sx={{ width: '100%', backgroundColor: '#354147', position: 'relative', padding: '1em', borderRadius: ' 10px 10px 0 0' }}
                                    >

                                      <Grid
                                        justifyContent="center"
                                        container
                                        sx={{
                                          width: '100%',

                                        }}
                                      >

                                        <EmailPreview
                                          widthProps={(previewType === 'desktop' ? '100%' : 320)}
                                          emailProperties={controller.emailJSON.properties}
                                        >
                                          { controller.emailJSON.content.map((x) => (
                                            <div
                                              key={x.id}
                                              dangerouslySetInnerHTML={renderComponent(x)}
                                            />
                                          )) }
                                        </EmailPreview>

                                      </Grid>

                                    </Box>
                                    <Box sx={{ background: 'rgb(53,65,71)', display: 'flex', justifyContent: 'center', borderRadius: '0 0 10px 10px ', pb: '1em' }}>
                                      <Box
                                        onClick={() => setPreviewType('desktop')}
                                        sx={{

                                          width: '50px',
                                          height: '50px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                          cursor: 'pointer',
                                          borderRadius: 2,
                                          transition: '0.2s background',
                                          ...(previewType === 'desktop') && { background: 'rgba(255,255,255,0.2)' },
                                          ...(previewType !== 'desktop') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
                                          mr: 1,
                                        }}
                                      >
                                        <DesktopMacIcon
                                          fontSize="large"
                                          style={{
                                            color: '#fff',
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        onClick={() => setPreviewType('mobile')}
                                        sx={{
                                          width: '50px',
                                          height: '50px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                          cursor: 'pointer',
                                          borderRadius: 2,
                                          transition: '0.2s background',
                                          ...(previewType === 'mobile') && { background: 'rgba(255,255,255,0.2)' },
                                          ...(previewType !== 'mobile') && { '&:hover': { background: 'rgba(255,255,255,0.2)' } },
                                        }}
                                      >
                                        <PhoneIphoneIcon
                                          fontSize="large"
                                          sx={{
                                            color: '#fff',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                )
                                : null
                            }
                </Box>
              </Card>
            </Grid>

          </Grid>
          <ConfirmationDialog
            message="Are you sure you want to publish this campaign? Your email will be sent at the scheduled date you set."
            onCancel={handleCloseCampaignDialog}
            onConfirm={handlePublish}
            open={campaignDialogOpen}
            title="Publish Campaign"
          />
          <FilterDialog
            onApply={handleCustomerFiltersApply}
            onClear={handleCustomerFiltersClear}
            onClose={() => setOpenCustomerFilterDialog(false)}
            open={openCustomerFilterDialog}
            operators={filterOperators}
            properties={customerFilterProperties}
            initialFilters={controller.customerFilters}
          />
        </Container>
      </Box>
    </>
  );
};

const TypologyCard = ({ typology, dos, donts }) => {
  const [expanded, setExpanded] = useState(true);
  const totalRecc = [...dos, ...donts].length;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
          gap: 1,
          backgroundColor: 'background.default',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          position: 'relative',
          cursor: 'pointer',
          borderRadius: 2,
          px: 3,
          py: 2,
          mb: 1,
        }}
        onClick={() => { setExpanded(!expanded); }}
      >
        <Typography variant="subtitle1">{typology}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        { expanded ? <RemoveIcon fontSize="medium" /> : <AddIcon fontSize="medium" /> }
        {
          (totalRecc > 0)
          && (
          <Box
            backgroundColor="primary.main"
            sx={{
              position: 'absolute',
              width: 30,
              height: 30,
              borderRadius: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: -5,
              top: -5,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: '#fff',
                textAlign: 'center'
              }}
            >
              {totalRecc}
            </Typography>
          </Box>
          )
        }
      </Box>
      {
        expanded
        && (
        <Box sx={{ px: 3 }}>
          <Typography
            sx={{ mb: 0 }}
            color="success.main"
            variant="overline"
            fontWeight="bold"
          >
            DO:
          </Typography>
          <List sx={{ pb: 1, pt: 0 }}>
            {dos.map((feature) => (
              <ListItem
                sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                disableGutters
                key={feature}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 1,
                    mt: 0.8,
                  }}
                >
                  <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.main' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={feature}
                />
              </ListItem>
            ))}
          </List>
          <Typography
            color="error.main"
            variant="overline"
            fontWeight="bold"
          >
            DON&apos;T:
          </Typography>
          <List sx={{ pb: 2 }}>
            {donts.map((feature) => (
              <ListItem
                sx={{ pb: 0, pt: 0, alignItems: 'flex-start' }}
                disableGutters
                key={feature}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    mr: 1,
                    mt: 0.8,
                  }}
                >
                  <CancelOutlinedIcon sx={{ color: 'error.main' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={feature}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        )
      }
    </>
  );
};

TypologyCard.propTypes = {
  typology: PropTypes.string.isRequired,
  dos: PropTypes.arrayOf(PropTypes.string).isRequired,
  donts: PropTypes.arrayOf(PropTypes.string).isRequired,
};
