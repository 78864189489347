/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Button
} from '@mui/material';
import { useState } from 'react';
import { Parser } from 'json2csv';
import { Query } from '../query';
import { Scrollbar } from '../scrollbar';
import { PagelessPagination } from '../pageless-pagination';
import { applyPagination } from '../../utils/apply-pagination';

export const Submissions = ({ data, preFormattedData = false, hasProperties = true, title = 'Recent Customer Comments' }) => {
  const [controller, setController] = useState({
    page: 1,
  });
  const formatted_data = preFormattedData ? data.map((object) => ({ comments: object.comments })).filter((x) => x.comments !== null && x.comments.trim() !== '')
    : [...data].reduce((acc, curr) => {
      if (hasProperties) {
        let properties = curr.properties ? JSON.parse(curr.properties) : [];
        properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
        if (properties && properties.comments !== undefined) {
          acc.push({
            id: curr.id,
            email: curr.email,
            comments: properties.comments,
          });
        }
      } else {
        acc.push({
          id: curr.email,
          email: curr.email,
          comments: curr.comments,
        });
      }
      return acc;
    }, []).filter((x) => x.comments !== null && x.comments.trim() !== '');

  const paginatedData = applyPagination(controller.query
    ? formatted_data.filter((x) => x.comments.toLowerCase().includes(controller.query.toLowerCase())).map((obj) => {
      const regEx = new RegExp(controller.query, 'ig');
      const response = obj.comments.replace(regEx, (str) => `<strong style='color: #008944;'>${str}</strong>`);

      const x = <div dangerouslySetInnerHTML={{ __html: response }} />;
      return { ...obj, comments: x };
    })
    : formatted_data, controller.page - 1, 10);

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };
  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery
    });
  };

  const exportData = () => {
    const parser = new Parser({ fields: ['comments'] });
    const csv = parser.parse(formatted_data);
    const linkSource = `data:text/csv;charset=utf-8,${csv}`;
    const downloadLink = document.createElement('a');
    const fileName = `export-${(new Date()).toISOString()}.csv`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <Card
      variant="outlined"
    >
      <CardHeader
        title={title}
        action={(
          <Button
            aria-label="export csv"
            variant="text"
            onClick={exportData}
          >
            Export csv
          </Button>
)}
      />
      <Query
        // disabled={disabled}
        onChange={handleQueryChange}
        sx={{
          order: {
            sm: 2,
            xs: 1
          }
        }}
        value={controller.query}
      />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Comment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((item, i) => (
              <TableRow
                key={item.id || item.comments + i}
                hover
              >
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                  {item.comments}

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider sx={{ mt: 'auto' }} />
        <PagelessPagination
        // disabled={isLoading}
          onPageChange={handlePageChange}
          page={controller.page}
          isLastPage={(data.length / 10) <= controller.page}
        />
      </Scrollbar>
    </Card>
  );
};

Submissions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  hasProperties: PropTypes.bool,
  preFormattedData: PropTypes.bool
};
