import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,

} from '@mui/material';
import toast from 'react-hot-toast';
import React from 'react';
import { useAuth } from '../../hooks/use-auth';

export const OrganizationShopifyConnectionDialog = ({ onClose, open, handleApiReload, connections, token }) => {
  const { apiRequest, org, refreshOrgs } = useAuth();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          minWidth: '600px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            variant="inherit"
          >
            Confirm Shopify Integration
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ width: '95%' }}>You&apos;re about to create a data connection to Shopify. Are you sure you want to continue?</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              apiRequest('/api/connections/create', {
                source: 'SHOPIFY_APP',
                primary: connections.length === 0,
                integrationToken: token
              }).then(async () => {
                await refreshOrgs(org.id);
                handleApiReload();
                toast.success('Data Connection Created');
                onClose?.();
              }).catch((err) => {
                toast.error('Failed to Created Data Connection');
                console.log(err);
              });
            }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

OrganizationShopifyConnectionDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleApiReload: PropTypes.func,
  token: PropTypes.string,
  connections: PropTypes.array
};
