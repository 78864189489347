/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { subYears, format } from 'date-fns';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { nps } from '../../utils/nps';
import { GroupBys } from '../../utils/group-by-date';
import { useAuth } from '../../hooks/use-auth';
import { demoId } from '../../utils/demo';

export const GrowthLineChart = (props) => {
  const { org } = useAuth();
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const xAxisRange = org.id === demoId ? selectedGroupBy.getRange(new Date('2021-07-01'), new Date('2022-06-01')) : selectedGroupBy.getRange(subYears(new Date(), 1), new Date());
  const { data, ...rest } = props;

  const formatted_data = [...xAxisRange].map((x) => {
    const fData = [...data].filter((y) => {
      const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
      return (formattedDate === x);
    }).reduce((acc, curr) => {
      let properties = curr.properties ? JSON.parse(curr.properties) : [];
      properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
      if (properties && properties.nps !== undefined) {
        acc.push(+properties.nps);
      }
      return acc;
    }, []);
    return nps(fData).toFixed(0);
  });

  const smallestYAxis = (dataRange) => {
    const smallest_value = Math.min(...Object.values(dataRange).map((num) => Number(num)));
    if (smallest_value < 0) {
      return Math.ceil((smallest_value + -10) / 10) * 10;
    }
    return 0;
  };

  const numberOfTicks = (dataRange) => {
    const min = Math.min(...Object.values(dataRange).map((num) => Number(num)));
    if (min < 0) {
      return (100 + -smallestYAxis(dataRange)) / 10;
    }
    return 10;
  };

  const chartOptions = {
    chart: {
      type: 'line',
      zoom: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    colors: ['#0058FF', '#21D59B'],
    dataLabels: {
      enabled: false,
    },
    // fill: {
    //   type: 'gradient',
    // },
    grid: {
      strokeDashArray: 7,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      curve: 'straight',
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: '#555',
        show: true,
      },
      axisTicks: {
        color: '#555',
        show: true,
      },
      categories: xAxisRange,
      labels: {
        style: {
          colors: '#555',
        },
      },
    },
    yaxis: {
      max: 100,
      min: smallestYAxis(formatted_data),
      tickAmount: numberOfTicks(formatted_data),
      labels: {
        formatter(val) {
          if (+val === 100) {
            return `Stronger ${val}`;
          }
          if (+val === smallestYAxis(formatted_data)) {
            return `Weaker ${val}`;
          }
          return val;
        },
        offsetX: -12,
        style: {
          colors: '#555',
        },
      },
    },
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title="Business growth indicator based on customer surveys"
      />
      <Divider />
      <CardContent>
        <Box sx={{ px: 0 }}>
          <Chart
            height="400"
            options={chartOptions}
            series={[{ name: '', data: formatted_data }]}
            type="line"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

GrowthLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};
