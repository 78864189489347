/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { GroupBys } from '../../../utils/group-by-date';

export const FeedbackBars = ({ range, data, title = '1. How many of my customers are Promoters?', ...rest }) => {
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const chartData = [
    {
      data: [...range].map((x) => [...data].filter((y) => {
        const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
        return (formattedDate === x);
      }).reduce((acc, curr) => {
        let properties = curr.properties ? JSON.parse(curr.properties) : [];
        properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
        if (properties && properties.nps !== undefined && properties.nps < 66) {
          acc++;
        }
        return acc;
      }, 0)),
      name: 'Detractors'
    },
    {
      data: [...range].map((x) => [...data].filter((y) => {
        const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
        return (formattedDate === x);
      }).reduce((acc, curr) => {
        let properties = curr.properties ? JSON.parse(curr.properties) : [];
        properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
        if (properties && properties.nps !== undefined && properties.nps >= 66 && properties.nps < 86) {
          acc++;
        }
        return acc;
      }, 0)),
      name: 'Passives'
    },
    {
      data: [...range].map((x) => [...data].filter((y) => {
        const formattedDate = format(new Date(y.created_at), selectedGroupBy.format);
        return (formattedDate === x);
      }).reduce((acc, curr) => {
        let properties = curr.properties ? JSON.parse(curr.properties) : [];
        properties = properties.reduce((a, b) => ({ ...a, ...b }), {});
        if (properties && properties.nps !== undefined && properties.nps >= 86) {
          acc++;
        }
        return acc;
      }, 0)),
      name: 'Promoters'
    },
  ];

  const chartOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['#E90003', '#BFBFBF', '#00A800'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode,
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
      x: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: range,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        formatter(val) {
          return `${val}%`;
        },
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <Box sx={{ px: 2 }}>
        <Chart
          height="350"
          options={chartOptions}
          series={chartData}
          type="bar"
        />
      </Box>
    </Card>
  );
};

FeedbackBars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  paddingX: PropTypes.number
};
