import PropTypes from 'prop-types';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { Plus as PlusIcon } from '../icons/plus';
import { DateField } from './date-field';
import { InputField } from './input-field';
import { Typologies } from '../utils/typology';

export const FilterDialogItem = (props) => {
  const {
    properties,
    filter,
    index,
    onAddFilter,
    onRemoveFilter,
    onPropertyChange,
    onOperatorChange,
    onValueChange,
    operators
  } = props;
  return (
    <div>
      <Typography
        color="textSecondary"
        sx={{ mb: 1 }}
        variant="caption"
      >
        Where
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ pb: 1 }}
      >
        <Grid
          item
          xs={6}
        >
          <InputField
            fullWidth
            onChange={(event) => {
              onPropertyChange(index, event.target.value);
            }}
            select
            value={filter.column.name}
          >
            {properties.map((property) => (
              <MenuItem
                key={property.name}
                value={property.name}
              >
                {property.label}
              </MenuItem>
            ))}
          </InputField>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <InputField
            fullWidth
            value={filter.relationType.value}
            select
            onChange={(event) => {
              onOperatorChange(index, event.target.value);
            }}
          >
            {operators
              .filter((operator) => (operator.typesAccepted.includes(filter.column.type)
                || operator.typesAccepted.includes('blank')))
              .map((operator) => (
                <MenuItem
                  key={operator.value}
                  value={operator.value}
                >
                  {operator.label}
                </MenuItem>
              ))}
          </InputField>
        </Grid>
        {filter.column.name === 'typology'
          ? (filter.relationType.value === 'IS_BLANK' || filter.relationType.value === 'IS_PRESENT')
            ? null
            : (
              <Grid
                item
                xs={12}
              >
                {/* make dropdown */}
                <InputField
                  fullWidth
                  select
                  onChange={(event) => {
                    onValueChange(index, event.target.value);
                  }}
                  value={filter.condition}
                >
                  {Typologies.filter((x) => x.value.toUpperCase() !== 'DEFAULT').map((typology) => (
                    <MenuItem
                      key={typology.value}
                      value={typology.value}
                    >
                      {typology.label}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
            )
          : (!filter.relationType.typesAccepted.includes('blank')
            && ((filter.relationType.value === 'IS_AFTER' || filter.relationType.value === 'IS_BEFORE')
              ? (

                <Grid
                  item
                  xs={12}
                >

                  <DateField
                    fullWidth
                    onChange={(date) => {
                      onValueChange(index, date);
                    }}
                    value={filter.condition ? new Date(filter.condition) : new Date()}
                  />

                </Grid>
              )
              : (
                <Grid
                  item
                  xs={12}
                >

                  <InputField
                    fullWidth
                    onChange={(event) => {
                      onValueChange(index, event.target.value);
                    }}
                    value={filter.condition}
                  />

                </Grid>
              )))}

      </Grid>
      <Button
        color="primary"
        onClick={() => onAddFilter(index + 1)}
        size="small"
        startIcon={<PlusIcon />}
        sx={{ mr: 2 }}
        variant="text"
      >
        Add Filter
      </Button>
      <Button
        onClick={() => onRemoveFilter(index)}
        size="small"
        sx={{ color: 'text.secondary' }}
        variant="text"
      >
        Remove
      </Button>
    </div>
  );
};

FilterDialogItem.propTypes = {
  properties: PropTypes.array,
  filter: PropTypes.object,
  index: PropTypes.number,
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  onPropertyChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  onValueChange: PropTypes.func,
  operators: PropTypes.array
};
