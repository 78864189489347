import { useCallback, useEffect, useState } from 'react';
import { format, subMonths, startOfMonth, endOfMonth, subYears, subDays, differenceInDays, parse } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography, Button } from '@mui/material';
import toast from 'react-hot-toast';
import DownloadIcon from '@mui/icons-material/Download';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Parser } from 'json2csv';
import { TableFilter } from '../components/insights-reports/table-filter';
import { TableData } from '../components/insights-reports/table-data';
import { useMounted } from '../hooks/use-mounted';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';
import { GroupBys } from '../utils/group-by-date';
import { isDev } from '../utils/is-dev';
import { ScheduleDialog } from '../components/insights-reports/schedule-dialog';
import { useDialog } from '../hooks/use-dialog';

import { demoId } from '../utils/demo';
import demoReportData from '../demo/rawInsightsReportData.json';

export const InsightsReports = () => {
  const [scheduleOpen, handleOpenSchedule, handleCloseSchedule] = useDialog();
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const firstOfLastMonth = startOfMonth(subMonths(new Date(), 1));
  const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    sort: 'desc',
    sortBy: 'orders.created_at',
    view: 'NONE',
    groupBy: 'DAY',
    startDate: firstOfLastMonth,
    endDate: endOfLastMonth,
    compareStart: null,
    compareEnd: null,
    comparePeriod: null,
    columns: ['line_items.total_price', 'orders_total']
  });

  const groupByOptions = [
    ...GroupBys.map((g) => ({
      label: `Group by ${g.label}`,
      key: g.key,
      onClick: () => {
        setController({
          ...controller,
          page: 0,
          groupBy: g.key
        });
      }
    })),
    {
      label: 'None',
      key: 'NONE',
      onClick: () => {
        setController({
          ...controller,
          page: 0,
          groupBy: 'NONE'
        });
      }
    }
  ];

  const [dataState, setDataState] = useState({ isLoading: true });

  const scheduleExport = async (values) => {
    try {
      const requestBody = {
        contextType: controller.view === 'NONE' ? '' : controller.view,
        startDate: format(controller.startDate, 'yyyy-MM-dd'),
        endDate: format(controller.endDate, 'yyyy-MM-dd'),
        filters: controller.filters,
        appliedDisjunctively: false,
        groupBy: controller.groupBy,
        dateField: 'orders.created_at',
        sortColumn: controller.sortBy,
        sortDirection: controller.sort.toUpperCase(),
        columns: [
          ...controller.columns.filter((x) => !/(line_items_total|orders_total)/gi.test(x)),
        ],
        limit: 1000,
        page: 1,
      };

      if (org.id !== demoId) {
        await apiRequest('/email/exports/update', {
          data: requestBody,
          emailTo: values.emailTo,
          start: +(values.start),
          frequency: values.frequency,
          name: values.name,
        });
      }
      handleCloseSchedule();
      toast.success('Scheduled reporting saved');
    } catch (e) {
      console.log(e);
      toast.error('Error saving scheduled report');
    }
  };
  const exportData = async () => {
    try {
      // Skip if demo
      if (org.id === demoId) {
        return;
      }
      const requestBody = {
        contextType: controller.view === 'NONE' ? '' : controller.view,
        startDate: format(controller.startDate, 'yyyy-MM-dd'),
        endDate: format(controller.endDate, 'yyyy-MM-dd'),
        filters: controller.filters,
        appliedDisjunctively: false,
        groupBy: controller.groupBy,
        dateField: 'orders.created_at',
        sortColumn: controller.sortBy,
        sortDirection: controller.sort.toUpperCase(),
        columns: [
          ...controller.columns.filter((x) => !/(line_items_total|orders_total)/gi.test(x)),
        ],
        limit: 1000,
        page: 1,
      };

      let rawSalesData = await apiRequest('/api/sales', requestBody) || [];

      // Get compare sales data if exists
      let compareRawSalesData = null;
      if (controller.compareStart && controller.compareEnd) {
        const compareBody = {
          contextType: controller.view === 'NONE' ? '' : controller.view,
          startDate: format(controller.compareStart, 'yyyy-MM-dd'),
          endDate: format(controller.compareEnd, 'yyyy-MM-dd'),
          filters: controller.filters,
          appliedDisjunctively: false,
          groupBy: controller.groupBy,
          dateField: 'orders.created_at',
          sortColumn: controller.sortBy,
          sortDirection: controller.sort.toUpperCase(),
          columns: [
            ...controller.columns.filter((x) => !/(line_items_total|orders_total)/gi.test(x)),
          ],
          limit: 1000,
          page: 1,
        };
        compareRawSalesData = await apiRequest('/api/sales', compareBody) || [];
      }

      if (compareRawSalesData?.length && rawSalesData?.length) {
        if (controller.groupBy === 'NONE' || !controller.groupBy) {
          //  if not working remove the below destruction and disable eslint  prefer-destructuring
          rawSalesData[0].compare = { ...compareRawSalesData[0] };
        } else if (controller.comparePeriod === 'Last Period') {
          const groupObject = GroupBys.find((x) => x.key === controller.groupBy);
          const daysToSubtract = differenceInDays(controller.endDate, controller.startDate) + 1;
          rawSalesData = rawSalesData.map((row) => {
            const parsedDate = parse(row.formatted_date, groupObject.format, new Date());
            const matchString = format(subDays(parsedDate, daysToSubtract), groupObject.format);
            const matchRow = compareRawSalesData.find((compareRow) => compareRow.formatted_date === matchString);
            return {
              ...row,
              ...matchRow && {
                ...Object.entries(matchRow).reduce((acc, curr) => ({
                  ...acc,
                  [`compare_${curr[0]}`]: curr[1]
                }), {})
              }
            };
          });
        } else if (controller.comparePeriod === 'Last Year') {
          const groupObject = GroupBys.find((x) => x.key === controller.groupBy);

          rawSalesData = rawSalesData.map((row) => {
            const parsedDate = parse(row.formatted_date, groupObject.format, new Date());
            const matchString = format(subYears(parsedDate, 1), groupObject.format);
            const matchRow = compareRawSalesData.find((compareRow) => compareRow.formatted_date === matchString);
            return {
              ...row,
              ...matchRow && { compare: matchRow }
            };
          });
        }
      }
      const parser = new Parser();
      const csv = parser.parse(rawSalesData);
      const linkSource = `data:text/csv;charset=utf-8,${csv}`;
      const downloadLink = document.createElement('a');
      const fileName = `export-${(new Date()).toISOString()}.csv`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (e) {
      console.error(e);
      toast.error('Failed to export');
    }
  };
  const getData = useCallback(async () => {
    setDataState(() => ({ isLoading: true }));

    try {
      const requestBody = {
        contextType: controller.view === 'NONE' ? '' : controller.view,
        startDate: format(controller.startDate, 'yyyy-MM-dd'),
        endDate: format(controller.endDate, 'yyyy-MM-dd'),
        filters: controller.filters,
        appliedDisjunctively: false,
        groupBy: controller.groupBy,
        dateField: 'orders.created_at',
        sortColumn: controller.sortBy,
        sortDirection: controller.sort.toUpperCase(),
        columns: [
          ...controller.columns.filter((x) => !/(line_items_total|orders_total)/gi.test(x)),
        ],
        limit: 50,
        page: controller.page + 1,
      };

      if (isDev) console.log('Updating sales data: ', requestBody);
      if (isDev) console.log(JSON.stringify(requestBody));
      let rawSalesData = org.id === demoId ? demoReportData : await apiRequest('/api/sales', requestBody) || [];
      if (isDev) console.log(rawSalesData);

      // Get compare sales data if exists
      let compareRawSalesData = null;
      if (controller.compareStart && controller.compareEnd) {
        const compareBody = {
          contextType: controller.view === 'NONE' ? '' : controller.view,
          startDate: format(controller.compareStart, 'yyyy-MM-dd'),
          endDate: format(controller.compareEnd, 'yyyy-MM-dd'),
          filters: controller.filters,
          appliedDisjunctively: false,
          groupBy: controller.groupBy,
          dateField: 'orders.created_at',
          sortColumn: controller.sortBy,
          sortDirection: controller.sort.toUpperCase(),
          columns: [
            ...controller.columns.filter((x) => !/(line_items_total|orders_total)/gi.test(x)),
          ],
          limit: 50,
          page: controller.page + 1,
        };

        if (isDev) console.log(compareBody);
        compareRawSalesData = org.id === demoId ? demoReportData : await apiRequest('/api/sales', compareBody) || [];
        if (isDev) console.log(compareRawSalesData);
      }

      if (compareRawSalesData?.length && rawSalesData?.length) {
        if (controller.groupBy === 'NONE' || !controller.groupBy) {
          //  if not working remove the below destruction and disable eslint  prefer-destructuring
          rawSalesData[0].compare = { ...compareRawSalesData[0] };
        } else if (controller.comparePeriod === 'Last Period') {
          const groupObject = GroupBys.find((x) => x.key === controller.groupBy);

          const daysToSubtract = differenceInDays(controller.endDate, controller.startDate) + 1;

          rawSalesData = rawSalesData.map((row) => {
            const parsedDate = parse(row.formatted_date, groupObject.format, new Date());
            const matchString = format(subDays(parsedDate, daysToSubtract), groupObject.format);
            const matchRow = compareRawSalesData.find((compareRow) => compareRow.formatted_date === matchString);

            return {
              ...row,
              ...matchRow && { compare: matchRow }
            };
          });
        } else if (controller.comparePeriod === 'Last Year') {
          const groupObject = GroupBys.find((x) => x.key === controller.groupBy);

          rawSalesData = rawSalesData.map((row) => {
            const parsedDate = parse(row.formatted_date, groupObject.format, new Date());
            const matchString = format(subYears(parsedDate, 1), groupObject.format);
            const matchRow = compareRawSalesData.find((compareRow) => compareRow.formatted_date === matchString);
            return {
              ...row,
              ...matchRow && { compare: matchRow }
            };
          });
        }
      }
      if (mounted.current) {
        setDataState(() => ({
          isLoading: false,
          data: Array.isArray(rawSalesData) ? rawSalesData : []
        }));
      }
    } catch (err) {
      console.error(err);

      if (mounted.current) {
        setDataState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, [controller]);

  useEffect(() => {
    getData().catch(console.error);
  }, [controller]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleViewChange = (newView) => {
    setController({
      ...controller,
      page: 0,
      view: newView
    });
  };
  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      column: filter.column.name,
      condition: filter.condition,
      relationType: filter.relationType.value
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters
    });
  };

  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: []
    });
  };

  const handleDateChange = ({ start, end, compareStart, compareEnd, comparePeriod }) => {
    setController({
      ...controller,
      page: 0,
      startDate: start,
      endDate: end,
      compareStart,
      compareEnd,
      comparePeriod,
    });
  };

  const handleColumnsChange = (columns) => {
    setController({
      ...controller,
      page: 0,
      columns
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1
    });
  };

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    });
  };

  return (
    <>
      <Helmet>
        <title>Insights: Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Insights - Reports
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Button
                color="primary"
                onClick={() => exportData()}
                size="large"
                startIcon={<DownloadIcon fontSize="small" />}
                variant="contained"
              >
                <Box display={{ xs: 'none', md: 'block' }}>Download Report</Box>
              </Button>

              <Button
                color="primary"
                onClick={() => {
                  handleOpenSchedule();
                }}
                size="large"
                startIcon={<EventAvailableIcon fontSize="small" />}
                variant="contained"
                sx={{ ml: 2 }}
              >
                <Box display={{ xs: 'none', md: 'block' }}>Schedule Report</Box>
              </Button>

            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
          >
            <TableFilter
              disabled={dataState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onViewChange={handleViewChange}
              view={controller.view}
              groupByOptions={groupByOptions}
              groupBy={controller.groupBy}
              handleColumnsChange={handleColumnsChange}
              handleDateChange={handleDateChange}
              columns={controller.columns}
            />
            <Divider />
            <TableData
              data={dataState.data}
              dataCount={1000}
              error={dataState.error}
              isLoading={dataState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
              columns={controller.columns}
              groupBy={controller.groupBy}
              view={controller.view}
            />
          </Card>
        </Container>
        <ScheduleDialog
          onClose={() => {
            handleCloseSchedule();
          }}
          onSubmit={(values) => {
            scheduleExport(values);
          }}
          open={scheduleOpen}
        />
      </Box>
    </>
  );
};
