import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem,
  Box,
  FormHelperText,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  IconButton

} from '@mui/material';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { imagePath } from '../../config';
import { Trash as TrashIcon } from '../../icons/trash';
import { AssetModal } from '../asset-modal';

import { demoId } from '../../utils/demo';

const rewardHelperText = (
  <p>
    Create new rewards at
    {' '}
    <a href="/dashboard/discounts">the Discounts screen</a>
  </p>
);

export const LoyaltyDialog = ({ open, onClose, loyaltyRewards, modalEvent, setModalEvent, rewardId, discounts, setReload, reload }) => {
  const { apiRequest, org } = useAuth();

  const selectedReward = loyaltyRewards?.data?.loyaltyRewards.filter((x) => x.id === rewardId)[0];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: modalEvent === 'Edit' ? selectedReward ? selectedReward.name : '' : '',
      points: modalEvent === 'Edit' ? selectedReward ? selectedReward.points : 0 : 0,
      rewards: modalEvent === 'Edit' ? selectedReward ? discounts?.data?.discounts.filter((x) => x.id === selectedReward.discount_object_id)[0]?.name : '' : '',
      shopify_id: modalEvent === 'Edit' ? selectedReward ? JSON.parse(selectedReward.action_json).shopify_id : '' : '',
      actionJSON: modalEvent === 'Edit' ? selectedReward ? JSON.parse(selectedReward.action_json) : { add_to_cart: false, show_on_site: false, show_on_pos: false, thumbnail: '', description: '' }
        : { add_to_cart: false, show_on_site: false, show_on_pos: false, thumbnail: '', description: '' },
      submit: null

    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255)
        .test(
          'Loyalty Reward name not unique',
          'Loyalty Reward name not unique',
          (value) => {
            const isUnique = !loyaltyRewards?.data?.loyaltyRewards?.some((x) => x?.name?.toLowerCase() === value?.toLowerCase());

            return modalEvent === 'Edit' ? true : isUnique;
          }
        )
        .required('Name is required'),
      points: Yup.number().required('Points are required'),
      rewards: Yup
        .string()
        .when('actionJSON.add_to_cart', {
          is: false,
          then: Yup.string().required('Discount is invalid'),
        }),
      shopify_id: Yup
        .string()
        .when('actionJSON.add_to_cart', {
          is: true,
          then: Yup.string().required('Shopify Variant ID is required')
        })
    }),
    onSubmit: async (values, helpers) => {
      try {
        formik.values.actionJSON.shopify_id = formik.values.shopify_id; // add shopify_id back in
        if (org.id !== demoId) {
          switch (modalEvent) {
            case 'Create':
              apiRequest('/loyalty/create-reward', {
                name: values.name,
                points: values.points,
                discountObject: values.rewards ? discounts.data.discounts.filter((x) => x.name === values.rewards)[0].id : null,
                actionJSON: JSON.stringify(formik.values.actionJSON),
              }).then(() => {
              // reload
                setReload(!reload);
                toast.success('Reward saved');
              }).catch((err) => {
                toast.error('Reward failed to saved');
                console.log(err);
              });
              break;
            case 'Edit':
              apiRequest('/loyalty/update-reward', {
                id: rewardId,
                name: values.name,
                points: values.points,
                discountObject: values.rewards ? discounts.data.discounts.filter((x) => x.name === values.rewards)[0].id : null,
                actionJSON: JSON.stringify(formik.values.actionJSON),
              }).then(() => {
              // reload
                setReload(!reload);
                toast.success('Reward saved');
              }).catch((err) => {
                toast.error('Reward failed to saved');
                console.log(err);
              });
              break;
            default:
              break;
          }
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);

        setModalEvent('None');

        formik.resetForm();
        onClose?.();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (

    <Dialog
      open={open}
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
      PaperProps={{
        sx: {
          // width: '30%'
          width: 1000
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="inherit"
            sx={{ ml: 2 }}
            variant="inherit"
          >
            Loyalty Rewards
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ maxWidth: '400px', width: '100%' }}
          >
            <InputField
              id="name-field"
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ mb: '1em' }}
            />

            <InputField
              type="number"
              label="Points"
              name="points"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.points}
              error={Boolean(formik.touched.points && formik.errors.points)}
              helperText={formik.touched.points && formik.errors.points}
              sx={{ mb: '1em' }}
            />

            <InputField
              name="rewards"
              label="Reward"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.rewards}
              defaultValue={formik.values.rewards}
              error={Boolean(formik.touched.rewards && formik.errors.rewards)}
              select
              sx={{ mb: '1em' }}
              helperText={formik.touched.rewards && formik.errors.rewards}
            >

              {discounts.data.discounts.map((option) => (

                <MenuItem
                  key={option.name}
                  value={option.name}
                >
                  {option.name}
                </MenuItem>
              ))}
            </InputField>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                xs={6}
              >
                <Typography>Upload optional Thumbnail</Typography>
              </Grid>
              {formik.values.actionJSON.thumbnail
                ? (
                  <Box
                    sx={{

                      borderRadius: 1,
                      boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
                      display: 'flex',
                      position: 'relative',
                      maxWidth: 126,
                      height: 126,

                      '& img': {
                        borderRadius: 1,
                        display: 'block',
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                      },
                      '&:hover': {
                        boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                        '& > button': {
                          display: 'block'
                        },
                        '& img': {
                          opacity: 0.3
                        }
                      }
                    }}
                  >
                    <img
                      alt="Loyalty Logo"
                      src={`${imagePath}${formik.values.actionJSON.thumbnail}`}
                    />
                    <IconButton
                      color="error"
                      onClick={() => formik.setFieldValue('actionJSON.thumbnail', '')}
                      sx={{
                        display: 'none',
                        left: 0,
                        position: 'absolute',
                        top: 0

                      }}
                    >
                      <TrashIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )
                : (
                  <AssetModal
                    value={formik.values.actionJSON.thumbnail}
                    handleSelect={(selectedImage) => formik.setFieldValue('actionJSON.thumbnail', selectedImage)}
                  />
                )}
            </Grid>

            <InputField
              label="Description"
              name="actionJSON.description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.actionJSON.description}
              sx={{ mb: '1em' }}
            />
            <FormGroup sx={{ mb: '1em' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="actionJSON.show_on_site"
                    onClick={formik.handleChange}
                    checked={formik.values.actionJSON.show_on_site}
                  />
              )}
                label="Show reward on website"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="actionJSON.show_on_pos"
                    onClick={formik.handleChange}
                    checked={formik.values.actionJSON.show_on_pos}
                  />
              )}
                label="Show reward on point of sale"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="actionJSON.add_to_cart"
                    onClick={formik.handleChange}
                    checked={formik.values.actionJSON.add_to_cart}
                  />
              )}
                label="Add To Cart"
              />
            </FormGroup>
            {formik.values.actionJSON.add_to_cart === true
              ? (
                <>
                  <InputField
                    type="text"
                    label="Shopify Variant ID"
                    name="shopify_id"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.shopify_id}
                    error={Boolean(formik.touched.shopify_id && formik.errors.shopify_id)}
                    helperText={formik.touched.shopify_id && formik.errors.shopify_id}
                    sx={{ mb: '1em' }}
                  />
                  {/* <InputField
                    type="text"
                    label="Vend ID"
                    name="actionJSON.vend_id"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.actionJSON.vend_id}
                    sx={{ mb: '1em' }}
                  /> */}

                </>

              )
              : null}
            <Typography variant="subtitle2">{rewardHelperText}</Typography>
          </Box>
          {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            color="primary"
            onClick={() => {
              onClose();
              formik.resetForm();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => { formik.handleSubmit(); }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>

  );
};
LoyaltyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loyaltyRewards: PropTypes.object,
  modalEvent: PropTypes.string,
  setModalEvent: PropTypes.func,
  rewardId: PropTypes.string,
  discounts: PropTypes.object,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
};
