import {
  Card,
  Button,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import PsychologyIcon from '@mui/icons-material/Psychology';

export const VersantPrompt = ({ lowData }) => (
  <Card variant="outlined">
    <Grid
      container
      spacing={0}
    >
      <Grid
        item
        xl={1}
        display={{ xl: 'block', md: 'none', sm: 'none', xs: 'none' }}
      >
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PsychologyIcon sx={{ fontSize: 60, my: 2 }} />
        </Box>
      </Grid>
      <Grid
        item
        xl={11}
        md={12}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridAutoFlow: 'row',
            padding: 3
          }}
        >
          <Typography
            color="inherit"
            variant="body2"
          >
            <span>
              Use AudienceZen to boost sales with more effective sales communication.
              AudienceZen will segment your audience into groups of customers who
              share a similar purchase decision mentality.
              AudienceZen then provides expert, easy to use guidance for writing sales emails
              and in-store content to suit each audience segment.
              AudienceZen’s segmentation model provides twelve psychographic
              purchase decision-mentality typologies,
              each of which comes with specific communication insights.
            </span>
            <br />
            <br />
            {
              lowData
                ? <b><i>AudienceZen is still learning from your audience to group them into typology segments. You can accelerate this process by pressing &#34;Get Typologies&#34; below.</i></b>
                : <b><i>AudienceZen is currently learning from your audience to group them into typology segments. This process typically takes 2 weeks, you can accelerate this process by pressing &#34;Get Typologies&#34; below.</i></b>
            }

          </Typography>

          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <Button
                component={Link}
                smooth
                elementId="VocationSettings"
                to="/dashboard/organization"
                underline="none"
                color="primary"
                size="large"
                variant="contained"
              >
                Get Typologies
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>

  </Card>
);

VersantPrompt.propTypes = {
  lowData: PropTypes.bool,
};
