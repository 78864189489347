/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

/* Components */
import { ScheduledReportsTable } from '../components/insights-scheduled/insights-scheduled-table';
import { Plus as PlusIcon } from '../icons/plus';

/* Hooks */
import { useAuth } from '../hooks/use-auth';
import { useMounted } from '../hooks/use-mounted';

/* Utils */
import gtm from '../lib/gtm';

/* Demo */
import { demoId } from '../utils/demo';
import demoExportData from '../demo/rawEmailExports.json';

export const InsightsScheduled = () => {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { apiRequest, org } = useAuth();
  const [controller, setController] = useState({ isLoading: true });

  async function getReports() {
    try {
      setController(() => ({ isLoading: true }));
      const result = org.id === demoId ? demoExportData : await apiRequest('/email/exports', {}) || [];
      if (mounted.current) {
        setController({
          isLoading: false,
          data: result
        });
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setController(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }

  const archive = async (created_at) => {
    try {
      if (org.id !== demoId) {
        await apiRequest('/email/exports/delete', {
          created_at
        });
      }
      getReports();
      toast.success('Deleted');
    } catch (e) {
      console.log(e);
      toast.error('Error');
    }
  };

  useEffect(() => {
    getReports();
  }, [org]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Scheduled Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Scheduled Reports
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Button
                size="large"
                startIcon={<PlusIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  navigate('/dashboard/insights/reports');
                }}
              >
                <Box display={{ xs: 'none', md: 'block' }}>New Scheduled Report</Box>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >

              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <ScheduledReportsTable
                  data={controller.data}
                  error={controller.error}
                  isLoading={controller.isLoading}
                  archive={archive}
                />
              </Card>

            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  );
};
