import { Avatar, Box, Card, Typography, Skeleton } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useEffect, useState } from 'react';
import { format, endOfMonth, subMonths } from 'date-fns';
import numeral from 'numeral';
import { useMounted } from '../../hooks/use-mounted';
import { useAuth } from '../../hooks/use-auth';
import { demoId } from '../../utils/demo';

export const TotalCustomersSummary = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [controller, setController] = useState({
    loading: true,
    value: null,
    compareValue: null,
  });

  useEffect(() => {
    async function getData() {
      try {
        const { total: value } = org.id === demoId ? { total: 25897 } : await apiRequest('/api/customers/count');
        const { total: compareValue } = org.id === demoId ? { total: 25897 } : await apiRequest('/api/customers/count', {
          startDate: '2000-01-01', endDate: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        });
        if (mounted.current) {
          setController({
            loading: false,
            value: value || 0,
            compareValue: compareValue || 0
          });
        }
      } catch (e) {
        console.log(e);
      }
    }

    getData().catch(console.error);
  }, [org]);

  return (

    <Card
      sx={{ height: '100%' }}
      variant="outlined"
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          pt: 2,
          px: 3,
          pb: 0,
        }}
      >
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Total Customers
        </Typography>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          py: 0,
          px: 3
        }}
      >
        <Box
          sx={{
            flexGrow: 'grow',
          }}
        >
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {
              !controller.loading
                ? numeral(controller?.value).format('0,0')
                : (
                  <Skeleton
                    height={42}
                    width={220}
                    sx={{ p: 0, m: 0 }}
                  />
                )
            }
          </Typography>
        </Box>
        <Avatar
          sx={{
            backgroundColor: 'primary.main',
            height: 56,
            width: 56
          }}
        >
          <PeopleIcon sx={{ color: 'primary.contrastText', fontSize: 28 }} />
        </Avatar>
      </Box>
      <Box
        sx={{
          pt: 0,
          px: 3,
          pb: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start'
        }}
      >
        <Typography
          color={(controller?.value - controller?.compareValue) / controller?.compareValue < 0 ? 'error.main' : 'success.main'}
          variant="h6"
        >
          {
              !controller.loading
                ? numeral((controller?.value - controller?.compareValue) / controller?.compareValue).format('+0%')
                : (
                  <Skeleton
                    height={42}
                    width={50}
                    sx={{ p: 0, m: 0 }}
                  />
                )
            }
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          sx={{ ml: 1 }}
        >
          Since last month
        </Typography>
      </Box>
    </Card>
  );
};
