import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';

const PaginationRoot = styled('div')((({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2)
})));

export const PagelessPagination = (props) => {
  const { disabled, onPageChange, page, isLastPage, ...other } = props;
  const isFirstPage = page === 1;

  const handlePreviousPage = () => {
    onPageChange?.(page - 1);
  };

  const handleNextPage = () => {
    onPageChange?.(page + 1);
  };

  return (
    <PaginationRoot {...other}>
      <Typography
        color="textSecondary"
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
        variant="body2"
        whiteSpace="nowrap"
      >
        Page
        {' '}
        <Typography
          color="textPrimary"
          component="span"
          sx={{ mx: 1 }}
          variant="subtitle2"
        >
          {page}
        </Typography>
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        disabled={isFirstPage || disabled}
        onClick={handlePreviousPage}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        disabled={isLastPage || disabled}
        onClick={handleNextPage}
      >
        <ChevronRightIcon />
      </IconButton>
    </PaginationRoot>
  );
};

PagelessPagination.defaultProps = {
  disabled: false,
  isLastPage: false,
  page: 1
};

PagelessPagination.propTypes = {
  disabled: PropTypes.bool,
  isLastPage: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number
};
