import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const RadarChart = ({ title = '2. What made inactive customers dissatisfied?', data, ...rest }) => {
  const theme = useTheme();

  // 'Product Selection', 'Ease of Use', 'Fulfillment', 'Product Quality', 'Competitive Price', 'Communication', 'Returns', 'Customer Support'
  const seriesRaw = [...data].reduce((acc, curr) => {
    acc = { // eslint-disable-line no-param-reassign
      expectations: [
        acc.expectations[0] + +curr.expectations_product_selection,
        acc.expectations[1] + +curr.expectations_ease_of_use,
        acc.expectations[2] + +curr.expectations_fulfillment,
        acc.expectations[3] + +curr.expectations_quality,
        acc.expectations[4] + +curr.expectations_pricing,
        acc.expectations[5] + +curr.expectations_communication,
        acc.expectations[6] + +curr.expectations_returns,
        acc.expectations[7] + +curr.expectations_service,
      ],
      expectations_freq: [
        acc.expectations_freq[0] + 1,
        acc.expectations_freq[1] + 1,
        acc.expectations_freq[2] + 1,
        acc.expectations_freq[3] + 1,
        acc.expectations_freq[4] + 1,
        acc.expectations_freq[5] + 1,
        acc.expectations_freq[6] + 1,
        acc.expectations_freq[7] + 1,
      ],
      performance: [
        acc.performance[0] + +curr.performance_product_selection,
        acc.performance[1] + +curr.performance_ease_of_use,
        acc.performance[2] + +curr.performance_fulfillment,
        acc.performance[3] + +curr.performance_quality,
        acc.performance[4] + +curr.performance_pricing,
        acc.performance[5] + +curr.performance_communication,
        acc.performance[6] + +curr.performance_returns,
        acc.performance[7] + +curr.performance_service,
      ],
      performance_freq: [
        acc.performance_freq[0] + 1,
        acc.performance_freq[1] + 1,
        acc.performance_freq[2] + 1,
        acc.performance_freq[3] + 1,
        acc.performance_freq[4] + 1,
        acc.performance_freq[5] + 1,
        acc.performance_freq[6] + 1,
        acc.performance_freq[7] + 1,
      ],
    };
    return acc;
  }, {
    expectations: [0, 0, 0, 0, 0, 0, 0, 0],
    expectations_freq: [0, 0, 0, 0, 0, 0, 0, 0],
    performance: [0, 0, 0, 0, 0, 0, 0, 0],
    performance_freq: [0, 0, 0, 0, 0, 0, 0, 0],
  });

  const series = [
    {
      name: 'Expectations',
      data: [
        ...seriesRaw.expectations.map((x, i) => (x / seriesRaw.expectations_freq[i]).toFixed(0)),
      ],
    },
    {
      name: 'Experience',
      data: [
        ...seriesRaw.performance.map((x, i) => (x / seriesRaw.performance_freq[i]).toFixed(0)),
      ]
    }
  ];

  const chartOptions = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false
      },
      // sparkline: {
      //   enabled: true
      // },
      zoom: {
        enabled: false
      }
    },
    // legend: {
    //   show: false
    // },
    tooltip: {
      enabled: true,
    },
    colors: ['#F68338', '#00B0F0'],
    labels: {
      show: true,
      style: {
        fontSize: '11px'
      }
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: [0.1, 0.6]
    },
    markers: {
      size: 0
    },
    yaxis: {
      tickAmount: 5,
      max: 100,
      min: 0,
    },
    xaxis: {
      categories: ['Product Selection', 'Ease of Use', 'Fulfillment', 'Product Quality', 'Competitive Price', 'Communication', 'Returns', 'Customer Support'],
    },
    theme: {
      mode: theme.palette.mode
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColor: '#e8e8e8',
          fill: {
            colors: ['#f8f8f8', '#fff']
          }
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent>
        <Chart
          options={chartOptions}
          series={series}
          type="radar"
          height={400}
        />
      </CardContent>
    </Card>
  );
};

RadarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
