/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import toast from 'react-hot-toast';
import mjml2html from 'mjml-browser';
import { getEmailHtml } from '../../utils/email-components';
import { PreviewEmailComponent } from './preview-email-component';
import { useAuth } from '../../hooks/use-auth';
import { demoId } from '../../utils/demo';

export const PreviewEmailDialog = ({ onCancel, open, selectedEmail }) => {
  const { apiRequest, org } = useAuth();
  const [previewEmail, setPreviewEmail] = useState('');
  const [previewJson, setPreviewJson] = useState('');
  const [previewStatus, setPreviewStatus] = useState('');

  const email_json = JSON.parse(selectedEmail?.email_json);
  const blocks = email_json?.content;
  const emailProperties = email_json?.properties;
  const emailName = selectedEmail?.name;

  const handlePreviewSend = async (email, placeholders) => {
    try {
      if (!(email && /^\S+@\S+\.\S+$/.test(email))) {
        toast.error('Invalid email');
        setPreviewStatus('Invalid email');
        return;
      }
      const placeholderData = placeholders ? JSON.parse(placeholders) : {};
      const mjmlHtml = getEmailHtml(blocks, emailProperties);
      const { html, errors } = mjml2html(mjmlHtml);
      if (errors && errors.length) {
        console.log('mjml errors:', errors);
        toast.error('Issue with parsing your email template');
        return;
      }
      if (demoId !== org.id) {
        await apiRequest('/email/preview', {
          fromEmail: org.domain_verified === 1 && org.email ? org.email : `${org.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}@audiencezen.com`,
          fromName: org.name,
          previewText: `Preview Your Email Template for ${emailName}`,
          subject: `AudienceZen: Preview for ${emailName}`,
          emailHtml: html,
          toEmail: email,
          placeholders: placeholderData,
        });
      }
      toast.success('Email sent');
      setPreviewStatus('Email Sent');
    } catch (e) {
      console.log(e);
      toast.error('Invalid placeholder');
      setPreviewStatus('Invalid placeholder');
    }
  };

  return (
    <Dialog
      onClose={() => {
        onCancel();
        setPreviewEmail('');
        setPreviewJson('');
        setPreviewStatus('');
      }}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <InfoIcon
          color="info"
          fontSize="large"
        />
        <Typography
          color="inherit"
          sx={{ ml: 2 }}
          variant="inherit"
        >
          Email Preview
        </Typography>
      </DialogTitle>
      <DialogContent>
        <PreviewEmailComponent
          setPreviewEmail={setPreviewEmail}
          setPreviewJson={setPreviewJson}
          previewJson={previewJson}
          previewEmail={previewEmail}
          previewStatus={previewStatus}
          setPreviewStatus={setPreviewStatus}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setPreviewEmail('');
            setPreviewJson('');
            setPreviewStatus('');
            onCancel();
          }}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handlePreviewSend(previewEmail, previewJson);
          }}
          variant="contained"
        >
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewEmailDialog.defaultProps = {
  open: false
};

PreviewEmailDialog.propTypes = {
  open: PropTypes.bool,
  selectedEmail: PropTypes.object,
  onCancel: PropTypes.func,
};
