/* eslint-disable */
import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
  Popper,
  Fade,
  CardContent,
  Card
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import { TypologyCard } from './typology-card';
import { useMounted } from '../../hooks/use-mounted';
import { useAuth } from '../../hooks/use-auth';

export const RecommendationsDialog = ({ needLevel, data }) => {
  const { apiRequest, org } = useAuth();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [guidance, setGuidance] = useState({ isLoading: true, data: {} });
  const mounted = useMounted();

  const handleClick = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    }
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  async function getTypologyData(typology) {
    const { result } = await apiRequest('/analytics/guidanceByTypology', {
      rd: org?.need_level || "Desired",
      typology,
    }) || { result: null };

    if(!result || !result?.length) {
      return null;
    }

    const intro = result?.find((x) => x.StatementType === 'Intro');
    const groups = result?.reduce((acc, curr) => {
      if(curr.StatementType === 'Intro') {
        return acc;
      }

      let sIndex = acc.findIndex((x) => x.name === curr.Section);
      if(sIndex === -1) {
        acc.push({
          name: curr.Section,
          items: [curr]
        });
      } else {
        acc[sIndex].items = [...acc[sIndex].items, curr];
      }

      return acc;
    }, []);

    return {
      intro,
      groups,
    };
  }

  const getTypologyGuidance = useCallback(async () => {
    try {
      let x = null;
      if (data?.topTypology?.typology) {
        x = await getTypologyData(data.topTypology.typology);
      }

      if (x !== null && mounted.current) {
        setGuidance({
          isLoading: false,
          data: x
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [data]);

  useEffect(() => {
    getTypologyGuidance();
  }, [data]);

  return (
    data?.topTypology && data?.topTypology.typology.toUpperCase() !== 'DEFAULT' && data?.topTypology.typology.toUpperCase() !== 'NO TYPOLOGY' ? (
      <>
        <Button
          variant="contained"
          sx={{ maxWidth: '250px',
            gap: '10px',
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: 'white',
            color: 'black',
            boxShadow: '0px 7px 15px rgb(9 30 66 / 12%)!important',
            '&:hover': {
              backgroundColor: 'white',
            }
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'relative',
              py: 1,
              cursor: 'pointer',
              borderRadius: 2,
              transition: '0.2s background',

            }}
          >
            <InsightsIcon
              fontSize="large"
              sx={{ color: '#A9AFB4' }}
            />
          </Box>
          Recommendations
        </Button>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              <Box sx={{ maxWidth: '400px', width: '100%', p: 1, }}>
                <Card sx={{ overflowY: 'scroll', maxHeight: '600px' }}>
                  <CardContent sx={{ pt: 0 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',

                      }}
                    >
                      <div>
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 0, }}
                        >
                          <Grid
                            item
                            container
                            sx={{ flexDirection: 'row', alignItems: 'center', gap: '10px' }}
                          >

                            <Grid sx={{ maxWidth: '250px' }}>
                              <Typography
                                color="contrastText"
                                variant="body2"
                                fontWeight="bold"
                              >
                                {`Top Typology: ${data.topTypology.typology} at ${Math.round(data.topTypology.count)}%`}
                              </Typography>
                            </Grid>
                            <Button
                              onClick={handleClick}
                              variant="text"
                              sx={{ fontSize: '25px', color: 'black', p: 0, }}
                            >
                              &#10005;
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                          >
                            {!guidance.isLoading
                              ? (
                                <TypologyCard
                                  needLevel={needLevel}
                                  guidance={guidance}
                                />
                              )
                              : null}
                          </Grid>

                        </Grid>
                      </div>
                      <Box sx={{ flexGrow: 1 }} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Fade>
          )}
        </Popper>
      </>
    )
      : null

  );
};

RecommendationsDialog.propTypes = {
  data: PropTypes.object,
  needLevel: PropTypes.string
};
