import { Box, Button, Card, Container, Table, TableBody, TableCell, TableRow, Typography, Grid, List, ListItem, TableHead, Paper, TableContainer } from '@mui/material';
import PropTypes from 'prop-types';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Scrollbar } from '../scrollbar';

/* Components */
import { Plus as PlusIcon } from '../../icons/plus';
import { ProductsMenu } from './products-menu';

export const ProductsOverview = ({ productFeeds, setFeedId, setNavState, handleDelete, connections }) => {
  const shopifyConnectionID = connections?.filter((x) => x.source === 'SHOPIFY')[0]?.id;
  if (!shopifyConnectionID) {
    return (
      <>
        <Typography
          color="textPrimary"
          variant="h6"
          sx={{ mb: 1 }}
        >
          Product Feeds
        </Typography>
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{ mb: 1 }}
        >
          You must add a Shopify Integration before viewing this page.
        </Typography>
      </>
    );
  }
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="textPrimary"
            variant="h4"
          >
            Product Feeds
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            size="large"
            startIcon={<PlusIcon fontSize="small" />}
            variant="contained"
            onClick={() => {
              setNavState('Create');
            }}
          >
            <Box display={{ xs: 'none', md: 'block' }}>New Feed</Box>
          </Button>
        </Box>
      </Box>
      {!productFeeds.isLoading
        ? productFeeds.data.feeds.length
          ? (
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
              }}
            >
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography sx={{ textTransform: 'capitalize' }}>Feed Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textTransform: 'capitalize' }}>Brands</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textTransform: 'capitalize' }}>Types</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textTransform: 'capitalize' }}>SKUs</Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productFeeds.data.feeds.map((item) => (
                        <TableRow
                          key={item.name}
                          hover
                        >
                          <TableCell
                            width="20%"
                            onClick={() => {
                              setNavState('Edit');
                              setFeedId(item.id);
                            }}
                          >
                            <Grid
                              container
                              gap="10px"
                              alignItems="center"
                            >
                              <ShoppingCartIcon sx={{ width: '50px', height: '50px' }} />
                              {item.name}
                            </Grid>
                          </TableCell>
                          <TableCell
                            width="20%"
                            onClick={() => {
                              setNavState('Edit');
                              setFeedId(item.id);
                            }}
                          >
                            {item.brands !== ''
                              ? (
                                <List>
                                  {item.brands.split(',').map((x, i) => (
                                    <ListItem
                                      key={x + i}
                                      sx={{ p: '0px' }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {x}
                                      </Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              )
                              : (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  No associated brands
                                </Typography>
                              ) }
                          </TableCell>
                          {/* feed types */}
                          <TableCell width="20%">
                            {item.types !== ''
                              ? (
                                <List>
                                  {item.types.split(',').map((x, i) => (
                                    <ListItem
                                      key={x + i}
                                      sx={{ p: '0px' }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {x}
                                      </Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              )
                              : (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  No associated types
                                </Typography>
                              ) }
                          </TableCell>
                          {/* skus */}
                          <TableCell width="20%">
                            {item.skus !== ''
                              ? (
                                <List>
                                  {item.skus.split(',').map((x, i) => (
                                    <ListItem
                                      key={x + i}
                                      sx={{ p: '0px' }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {x}
                                      </Typography>
                                    </ListItem>
                                  ))}
                                </List>
                              )
                              : (
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  No associated SKUs
                                </Typography>
                              ) }
                          </TableCell>
                          <TableCell
                            align="right"
                            width="20%"
                          >
                            <ProductsMenu
                              setFeedId={setFeedId}
                              feedId={item.id}
                              setNavState={setNavState}
                              handleDelete={handleDelete}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              {/* </CardContent> */}
            </Card>
          )
          : (
            <Grid
              container
              direction="column"
              sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
            >
              <Typography variant="h5">No Product Feeds Currently Exist</Typography>
              <Typography color="textSecondary">Click the New Feed button in the top right to add a Product Feed</Typography>
            </Grid>
          )
        : null}
    </Container>
  );
};

ProductsOverview.propTypes = {
  productFeeds: PropTypes.object,
  setNavState: PropTypes.func,
  setFeedId: PropTypes.func,
  handleDelete: PropTypes.func,
  connections: PropTypes.arrayOf(PropTypes.object)
};
