import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Alert, Autocomplete, TextField } from '@mui/material';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';

export const PreviewEmailComponent = ({ setPreviewJson, previewJson, setPreviewEmail, previewEmail, previewStatus, setPreviewStatus }) => {
  const { apiRequest, org } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [values, setValues] = useState([]);
  const [customerList, setCustomerList] = useState({ data: [], loading: true });

  const getCustomers = async () => {
    try {
      const data = await apiRequest('/api/customers', {
        customerSort: 'NAME', sortDirection: 'asc', query: searchQuery, limit: '300', page: 0, customerFilters: [], appliedDisjunctively: false
      });
      setCustomerList({
        data: data.customers,
        loading: false
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [org]);

  useEffect(() => {
    getCustomers();
  }, [searchQuery]);

  return (
    <div>
      <Typography
        color="contrastText"
        variant="body2"
      >
        Send a copy of this email template to a designated email address.
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mt: 0 }}
      >
        <Grid
          item
          xs={12}
        >
          <InputField
            fullWidth
            helperText="Email address to receive the email"
            label="Email"
            name="email"
            onChange={(event) => {
              setPreviewEmail(event.target.value);
              setPreviewStatus('');
            }}
            value={previewEmail}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          {customerList.loading
            ? (
              <TextField
                sx={{ width: '100%' }}
                label="Choose a customer"
                name="password"
                disabled
                autoComplete="off"
              />
            )
            : (
              <Autocomplete
                id="customer-select"
                freeSolo
                disableClearable
                sx={{ width: '100%', alignItems: 'flex-start' }}
                options={customerList?.data}
                autoHighlight
                getOptionLabel={(option) => option.email}
                inputValue={searchQuery}
                multiple
                value={values}
                onChange={(event, newInputValue, reason) => {
                  if (reason === 'selectOption') {
                    if (values.length < 1) {
                      setValues(newInputValue);
                      setPreviewJson(JSON.stringify(newInputValue[0]));
                      setPreviewStatus('');
                    } else if (values.length === 1) {
                      const temp = values;
                      temp.splice(0, 1, newInputValue[1]);
                      setValues(temp);
                      setPreviewJson(JSON.stringify(newInputValue[1]));
                      setPreviewStatus('');
                    }
                  }
                  if (reason === 'removeOption') {
                    setPreviewStatus('');
                    setPreviewJson('');
                    setValues([]);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setSearchQuery(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start !important', alignContent: 'flex-start !important' }}
                  >
                    <Typography>
                      {option.first_name}
                      {' '}
                      (
                      {option.typology === undefined || option.typology === null ? 'Not Set' : option.typology}
                      )
                    </Typography>
                    <Typography>{option.email}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a customer"
                    inputProps={{
                      ...params.inputProps,
                      name: 'password',
                      autoComplete: 'off'
                    }}
                  />
                )}
              />
            )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <InputField
            fullWidth
            helperText="Test placeholders by specifying their values with JSON. Must be valid JSON to send the email."
            label="Email Placeholders"
            name="json"
            multiline
            rows={4}
            onChange={(event) => {
              setPreviewJson(event.target.value);
              setPreviewStatus('');
            }}
            value={previewJson}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          {
          previewStatus !== ''
            ? <Alert severity={previewStatus === 'Email Sent' ? 'success' : 'error'}>{previewStatus}</Alert>

            : null
        }
        </Grid>
      </Grid>
    </div>
  );
};

PreviewEmailComponent.propTypes = {
  setPreviewJson: PropTypes.func,
  setPreviewStatus: PropTypes.func,
  setPreviewEmail: PropTypes.func,
  previewJson: PropTypes.string,
  previewEmail: PropTypes.string,
  previewStatus: PropTypes.string,
};
