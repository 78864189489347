import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../icons/dots-vertical';

export const RewardsMenu = (props) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
      >
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => {
          // navigate to edit screen
          props.setRewardId(props.rewardId);
          props.setModalEvent('Edit');
          props.handleOpen();
          handleClose();
        }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={() => {
          props.setRewardId(props.rewardId);
          props.handleDelete(props.rewardId);
          handleClose();
        }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

RewardsMenu.propTypes = {
  setModalEvent: PropTypes.func,
  setRewardId: PropTypes.func,
  rewardId: PropTypes.string,
  handleOpen: PropTypes.func,
  handleDelete: PropTypes.func,
};
