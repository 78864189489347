import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Card, CardHeader, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { GroupBys } from '../../../utils/group-by-date';

export const FeedbackBars = ({ range, data, percentType = false, vsTotal = false, title = '1. How many of my customers are Promoters?', ...rest }) => {
  const theme = useTheme();
  const selectedGroupBy = GroupBys[2];
  const chartData = [
    {
      data: [...range].map((x) => (data.sent_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x) ? data.sent_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x).sent - (data.submitted_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x) ? data.submitted_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x).submitted : 0) : null)),
      name: 'Sent'
    },
    {
      data: [...range].map((x) => (data.submitted_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x) ? data.submitted_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x).submitted : null)),
      name: 'Completed'
    },
  ];

  if (vsTotal) {
    chartData.push({
      data: [...range].map((x) => (data.customers_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x) ? data.customers_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x).customers - (data.sent_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x) ? data.sent_by_month.find((y) => format(new Date(y.month), selectedGroupBy.format) === x).sent : 0) : null)),
      name: 'Total'
    });
  }

  const chartOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
      stacked: true,
      stackType: percentType ? '100%' : null,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['#5B9BD5', '#ED7D31', '#BFBFBF'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    stroke: {
      curve: 'straight'
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode,
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
      x: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: range,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        formatter(val) {
          return percentType ? `${val}%` : val;
        },
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  return (
    <Card
      variant="outlined"
      {...rest}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <Box sx={{ px: 2 }}>
        <Chart
          height="350"
          style={{ maxWidth: 650, width: '100%' }}
          options={chartOptions}
          series={chartData}
          type="bar"
        />
      </Box>
    </Card>
  );
};

FeedbackBars.propTypes = {
  data: PropTypes.object.isRequired,
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  paddingX: PropTypes.number,
  percentType: PropTypes.bool,
  vsTotal: PropTypes.bool,
};
