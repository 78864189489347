import PropTypes from 'prop-types';
import { Box, ListItem, Typography } from '@mui/material';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { getEvent } from '../../utils/event-types';
import { getTypology } from '../../utils/typology';

export const CustomerActivityItem = ({ event }) => (
  <ListItem
    divider
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <div>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          <Typography
            color="textPrimary"
            variant="subtitle2"
            component="span"
          >
            {
              (event.first_name || event.last_name)
                ? (
                  <>
                    {event.first_name ? event.first_name : ''}
                    {' '}
                    {event.last_name ? event.last_name : ''}
                  </>
                )
                : event.email
            }
            {' '}
            (
            {event.typology === null ? (<i>Not Set</i>) : getTypology(event.typology)}
            )
          </Typography>
          {' '}
          {getEvent(event.event_type)}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          sx={{ fontWeight: 400 }}
        >
          {formatDistanceToNowStrict(parseISO(event?.created_at))}
        </Typography>
      </div>
    </Box>
  </ListItem>
);

CustomerActivityItem.propTypes = {
  event: PropTypes.object,
};
