/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { format } from 'date-fns';
import { Scrollbar } from '../scrollbar';
import { ResourceUnavailable } from '../resource-unavailable';
import { ResourceError } from '../resource-error';
import { ConfirmationDialog } from '../confirmation-dialog';
import { useDialog } from '../../hooks/use-dialog';
import { applyPagination } from '../../utils/apply-pagination';
import { Pagination } from '../pagination';

export const SurveyTable = (props) => {
  const {
    forms: formsProp,
    error,
    isLoading,
    onPageChange,
    archive,
    page,
  } = props;

  const navigate = useNavigate();

  const [forms, setForms] = useState(formsProp);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  useEffect(() => {
    setForms(formsProp);
  }, [formsProp]);

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !forms?.length);

  const [anchorEL, setAnchorEL] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e, form) => {
    setSelectedForm(form);
    setAnchorEL(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEL(null);
    setOpen(false);
  };

  const selectedForm_formJson = selectedForm?.form_json ? JSON.parse(selectedForm.form_json) : {};
  const selectedForm_isComplete = !!selectedForm_formJson.automationId;
  const formsContext = forms.filter((x) => x.status === 'ACTIVE' && x.form_json);
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <Scrollbar>
        <Table>
          <TableBody>
            {applyPagination(forms?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)), page, 20).map((form) => {
              const formJson = form.form_json ? JSON.parse(form.form_json) : {};
              const isComplete = !!formJson.automationId;
              return (
                <TableRow
                  hover
                  key={form.id}
                >
                  <TableCell
                    width="50px"
                    sx={{ paddingRight: '10px' }}
                  >
                    <Grid
                      container
                      direction="column"
                      display="flex"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'info.main',
                        ...(isComplete) && { backgroundColor: 'success.main' },
                        ...(form.status === 'ARCHIVED') && { backgroundColor: '#ccc' },
                        height: '50px',
                        width: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 1,
                      }}
                    >
                      <ForumIcon sx={{ fontSize: 32, color: 'white' }} />
                    </Grid>
                  </TableCell>

                  <TableCell>
                    <Grid
                      display="flex"
                      direction="column"
                      container
                    >
                      <Typography
                        variant="h5"
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                          if (!isComplete && form.status === 'ACTIVE') {
                            navigate(`/dashboard/surveys/edit/${form.id}`, {
                              state: {
                                formsContext
                              }
                            });
                          } else {
                            // Open popup with embed code and survey link?
                          }
                        }}
                      >
                        {form.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          color: 'info.main',
                          ...(isComplete) && { color: 'success.main' },
                          ...(form.status === 'ARCHIVED') && { color: '#ccc' },
                        }}
                      >
                        { isComplete && form.status !== 'ARCHIVED' ? 'PUBLISHED' : ''}
                        { (!isComplete && form.status === 'ACTIVE') ? 'DRAFT' : '' }
                        { (form.status === 'ARCHIVED') ? 'ARCHIVED' : '' }
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'block', width: '100%' }}
                      >
                        {'Last updated: '}
                        {format(new Date(form.updated_at), 'dd/MM/yyyy p')}
                      </Typography>

                    </Grid>
                  </TableCell>

                  {
                  form.status !== 'ARCHIVED'
                    ? (
                      <TableCell sx={{ maxWidth: '8em', cursor: 'pointer' }}>
                        <Grid
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <MoreVertIcon
                            aria-owns={anchorEL ? 'simple-menu' : undefined}
                            aria-haspopup={anchorEL ? 'true' : undefined}
                            onClick={(event) => handleClick(event, form)}
                          />
                        </Grid>
                      </TableCell>
                    )
                    : <TableCell />
                }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      {/* <PagelessPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        isLastPage={Boolean(forms?.length < 50)}
      /> */}
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page + 1}
        rowsCount={forms?.length}
        pageSize={20}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEL}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        elevation={0}
        sx={{ marginTop: '-2em' }}
      >
        {
          (!selectedForm_isComplete && selectedForm?.status === 'ACTIVE')
          && (
          <MenuItem onClick={() => {
            navigate(`/dashboard/surveys/edit/${selectedForm.id}`, {
              state: {
                formsContext
              }
            });
          }}
          >
            Edit
          </MenuItem>
          )
        }
        <MenuItem onClick={handleOpenDeleteDialog}>
          Archive
        </MenuItem>
        {
          (selectedForm_isComplete && selectedForm.status !== 'ARCHIVED')
          && (
          <MenuItem onClick={() => {
            navigate(`/dashboard/automations/edit/${selectedForm_formJson.automationId}`);
          }}
          >
            Open Automation
          </MenuItem>
          )
        }
        {
          (selectedForm_isComplete && selectedForm.status !== 'ARCHIVED')
          && (
          <MenuItem onClick={() => {
            window.open(`https://survey.audiencezen.com/form/${selectedForm.id}`, '_blank');
          }}
          >
            Open Form
          </MenuItem>
          )
        }
      </Menu>
      {/* archive(selectedForm.id);  */}
      <ConfirmationDialog
        message="Are you sure you want to archive this survey form?"
        onCancel={handleCloseDeleteDialog}
        onConfirm={() => {
          archive(selectedForm.id);
          setSelectedForm(null);
          handleCloseDeleteDialog();
        }}
        open={deleteDialogOpen}
        title="Archive Survey Form"
        variant="error"

      />
    </Box>
  );
};

SurveyTable.defaultProps = {
  forms: [],
  page: 1,
};

SurveyTable.propTypes = {
  forms: Proptypes.array,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  archive: Proptypes.func,
  page: Proptypes.number,
};
