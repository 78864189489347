import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { isDev } from '../utils/is-dev';
import { useAuth } from '../hooks/use-auth';
import { Login } from '../containers/login';
// import { Billing } from '../containers/billing';

function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
}

export const AuthGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, org, getAccessToken } = useAuth();

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(null);
  const { sit } = useQueryParams();

  useEffect(() => {
    async function checkToken() {
      if (isDev) console.log('Checking token', location.pathname);
      await getAccessToken();
      if (isDev) console.log('Finished checking token', location.pathname);
      setLoadingLocation(location.pathname);
    }
    if (isAuthenticated && org) {
      checkToken();
    }
  }, [isAuthenticated, org, location.pathname]);

  useEffect(() => {
    if (sit) {
      localStorage.setItem('sit', sit);
    }
  }, [sit]);

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <Login />
    );
  }

  // If we don't have an org and we're not the onbard screen, redirect us to the onboard screen.
  if (!org && location.pathname !== '/onboard') {
    return <Navigate to="/onboard" />;
  }

  // Bypass billing initial requirement
  // if (org && org.status !== 'TRIAL' && org.status !== 'ACTIVE') {
  //   return (
  //     <Billing />
  //   );
  // }

  // If we don't have a data connection we need to onboard
  if (org && !(org.data_connection_type === 'SHOPIFY' || org.data_connection_type === 'VEND' || org?.accountType === 'Business') && !location.pathname.includes('/organization') && location.pathname !== '/dashboard/getting-started' && location.pathname !== '/onboard') {
    return <Navigate to="/dashboard/getting-started" />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (org && location.pathname !== loadingLocation) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
