import { format, eachDayOfInterval, eachHourOfInterval, eachMonthOfInterval, eachYearOfInterval } from 'date-fns';

export const groupByDate = (data, column, formatter = 'dd MM yyyy') => data.reduce((acc, curr) => {
  const newAcc = [...acc];
  const keyDate = format(curr[column], formatter);
  const index = newAcc.findIndex((x) => x.key === keyDate);
  if (index === -1) {
    newAcc.push({
      key: keyDate,
      objects: [curr],
    });
  } else {
    newAcc[index].objects.push(curr);
  }
  return newAcc;
}, []);

export const GroupBys = [
  {
    key: 'HOUR',
    label: 'hour',
    format: 'd MMM, hh:mm a',
    getRange: (startDate = new Date(), endDate = new Date(), dateFormat = 'd MMM, hh:mm a') => eachHourOfInterval({ start: startDate, end: endDate }).map((x) => format(x, dateFormat))
  },
  {
    key: 'DAY',
    label: 'day',
    format: 'd MMM yyyy',
    getRange: (startDate = new Date(), endDate = new Date(), dateFormat = 'd MMM yyyy') => eachDayOfInterval({ start: startDate, end: endDate }).map((x) => format(x, dateFormat))
  },
  {
    key: 'MONTH',
    label: 'month',
    format: 'MMM yyyy',
    getRange: (startDate = new Date(), endDate = new Date(), dateFormat = 'MMM yyyy') => eachMonthOfInterval({ start: startDate, end: endDate }).map((x) => format(x, dateFormat))
  },
  {
    key: 'YEAR',
    label: 'year',
    format: 'yyyy',
    getRange: (startDate = new Date(), endDate = new Date(), dateFormat = 'yyyy') => eachYearOfInterval({ start: startDate, end: endDate }).map((x) => format(x, dateFormat))
  },
  {
    key: 'HOUR_OF_DAY',
    label: 'hour of day',
    format: 'p',
    getRange: () => ['12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
      '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM']
  },
  {
    key: 'DAY_OF_WEEK',
    label: 'day of week',
    format: 'EEEE',
    getRange: () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  },
  {
    key: 'MONTH_OF_YEAR',
    label: 'month of year',
    format: 'MMMM',
    getRange: () => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  }
];
