import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Container, Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format, subMonths, startOfMonth } from 'date-fns';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { useAuth } from '../hooks/use-auth';
import { GroupBys } from '../utils/group-by-date';
import { DateControls } from '../components/insights-root/date-controls';

/* Utils */
import gtm from '../lib/gtm';

import { FeedbackBars } from '../components/survey/referrals/feedback-bars';
import { RadarChart } from '../components/survey/referrals/radar-chart';
import { Trends } from '../components/survey/referrals/trends';
import { Submissions } from '../components/survey/submissions';

import { demoId } from '../utils/demo';
import demoEventsData from '../demo/rawSurveyEventData.json';

export const SurveyReferrals = () => {
  const { apiRequest, org } = useAuth();
  const [loading, setLoading] = useState(true);
  const selectedGroupBy = GroupBys[2];
  const [controller, setController] = useState({
    data: null
  });

  const [selectedDateRange, setSelectedDateRange] = useState({ start: startOfMonth(subMonths(new Date(), 11)), end: new Date() });
  const xAxisRange = org.id === demoId ? selectedGroupBy.getRange(new Date('2021-09-01'), new Date('2022-08-31')) : selectedGroupBy.getRange(selectedDateRange.start, selectedDateRange.end);
  const md = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const data = org.id === demoId ? demoEventsData : await apiRequest('/api/events', {
        eventType: 'FORM_SUBMIT',
        startDate: format(selectedDateRange.start, 'yyyy-MM-dd'),
        endDate: format(selectedDateRange.end, 'yyyy-MM-dd'),
      });

      setController({
        ...controller,
        data
      });

      setLoading(false);
    }

    gtm.push({ event: 'page_view' });
    getData();
  }, [selectedDateRange]);

  if (loading) {
    return (
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <CircularProgress
          color="primary"
          size={100}
        />

      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Survey Reports | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: md ? 1 : 2,
                flexDirection: md ? 'column' : 'row',
                textAlign: md ? 'center' : 'unset',
              }}
            >
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon />}
                to="/dashboard/surveys"
                variant="text"
              >
                Return to surveys
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="subtitle2">Themes:</Typography>

              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/referrals"
                variant="text"
              >
                Growing customer referrals
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/inactive"
                variant="text"
              >
                Why do customers stop buying?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/value"
                variant="text"
              >
                Am I satisfying my high value customers?
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                to="/dashboard/surveys/wallet"
                variant="text"
              >
                How do I increase my share of wallet?
              </Button>

            </Box>
          </Box>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: md ? '0' : 2,
                      mb: 1,
                      flexDirection: md ? 'column' : 'row',
                    }}
                  >
                    <Typography
                      sx={{ mb: 1 }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Growing customer referrals
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="subtitle2">Select Date Range:</Typography>
                    <DateControls
                      defaultStart={selectedDateRange.start}
                      defaultEnd={selectedDateRange.end}
                      showCompare={false}
                      onDateChange={(start, end = null) => {
                        setSelectedDateRange({ start, end });
                      }}
                    />
                  </Box>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                  >
                    Customer referrals are a cost effective way of acquiring new customers. The more satisfied your customers are, the more likely they are to enthusiastically recommend your business to others. In the Net Promoter Score system, customers that are very likely to recommend your business are categorized as ‘Promoters’.  The analysis steps below will help you understand how many of your customers are Promoters and how to change more of your customers into Promoters.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <FeedbackBars
                range={xAxisRange}
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    The ‘recommend to others’ question in your customer satisfaction survey provides the data for this chart. Promoters (marked in orange) are those who indicated a very high likelihood to recommend your business to others. Ideally, there should be an upward trend in the proportion of your customers who are Promoters.
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    The Net Promoter Score system segments your customers into three groups: Promoters, Passives (who don’t recommend your business) and Detractors (who make critical comments about your business to others).
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <RadarChart data={controller.data} />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    This chart compares your Passive and Detractor customers’ general expectations for service attributes (orange line) to their experience of these attributes from your business (blue area). A gap between the orange line and the blue area indicates a service attribute with poor customer satisfaction.
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    Turn Passives and Detractors into Promoters by closing the gap between their expectations and their actual experience.
                  </Typography>
                  <Typography
                    sx={{ my: 1 }}
                    variant="body2"
                    component="div"
                  >
                    1. Priority for improvement goes to attributes with high expectations and low performance.
                  </Typography>
                  <Typography
                    sx={{ my: 1 }}
                    variant="body2"
                    component="div"
                  >
                    2. Priority for improvement goes to attributes with high expectations and low performance.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Trends
                range={xAxisRange}
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    {'This chart allows you to analyse trends in your customers expectations and experience. Filter by customer experience attribute to identify any attributes for which there is a consistent and significant decline in experience compared to expectation. Any such attribute needs urgent attention. '}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    Conversely, attributes for which the gap is closing are already improving and can be deprioritized.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xl={8}
              lg={12}
              xs={12}
            >
              <Submissions
                title="3. Review survey comments for further insight"
                data={controller.data}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={12}
              xs={12}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ mb: 2 }}
                    variant="subtitle2"
                    component="div"
                  >
                    Commentary
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                  >
                    Pinpoint what should be improved
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    {'To pinpoint what needs to be improved, search for clues in the comments provided by survey respondents. '}
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    Also, look for recurring issues raised in:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your customer support communications.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                  >
                    - your social media presence.
                  </Typography>
                  <Typography
                    sx={{ my: 2 }}
                    variant="body2"
                    component="div"
                  >
                    And, ideally, talk to some customers who have had unsatisfactory experiences.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          </Grid>

        </Container>
      </Box>

    </>
  );
};
