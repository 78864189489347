import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Card, Typography, Alert, AlertTitle, Button } from '@mui/material';
import numeral from 'numeral';
import csv from 'csvtojson';
import toast from 'react-hot-toast';
import { useMounted } from '../hooks/use-mounted';
import { typologyCheck, typologyWarningCheck } from '../utils/checks';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';
import { Typologies } from '../utils/typology';

/* Summary Root Components */
import { RevenueGeneratedSummary } from '../components/summary-root/revenue-generated-summary';
import { TotalCustomersSummary } from '../components/summary-root/total-customers-summary';
import { VersantPrompt } from '../components/summary-root/versant-prompt';
import { StackedSales } from '../components/summary-root/stacked-sales';
import { CustomerActivity } from '../components/summary-root/customer-activity';
import { Performance } from '../components/summary-root/performance';
import { GenericMetricCard } from '../components/summary-root/generic-metric-card';
import { demoId } from '../utils/demo';
import demoGroups from '../demo/groups.json';
import { ImportDialog } from '../components/customer/import-dialog';
import { useDialog } from '../hooks/use-dialog';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { CustomersOverTime } from '../components/insights-root/customers-over-time';

export const SummaryRoot = () => {
  const { apiRequest, org } = useAuth();
  const mounted = useMounted();
  const [groups, setGroups] = useState(null);
  const [expand, setExpand] = useState(false);
  const [customerImportOpen, handleOpenCustomerImport, handleCloseCustomerImport] = useDialog();
  const [openImportDialog, handleOpenImportDialog, handleCloseImportDialog] = useDialog();

  const [option, setOption] = useState(Typologies[0].label);
  const [salesController, setSalesController] = useState({
    loading: true,
    value: null,

  });
  const [customerController, setCustomerController] = useState({
    loading: false,
    value: null,
    typologyConfidence: 0
  });

  const [customersToImport, setCustomersToImport] = useState([]);

  const handleImportCustomers = async () => {
    try {
      if (demoId !== org.id) {
        await apiRequest('/loyalty/import-customers', {
          customers: customersToImport
        });
      }
      handleCloseCustomerImport();
      toast.success('Customers successfully imported');
      window.location.reload();
    } catch (e) {
      toast.error('Customers failed to import');
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const text = await acceptedFiles[0].text();
    csv().fromString(text).then((json) => {
      if (json?.length) {
        const customerImport = [
          ...json.map((x) => ({
            ...x,
            ...(x.acceptsMarketing && x.acceptsMarketing?.toUpperCase() === 'TRUE') && { acceptsMarketing: true },
            loyaltyPoints: x.loyaltyPoints || 0,
          }))
        ];

        const importValid = customerImport.every((row) => {
          const emailValid = row.email && /^\S+@\S+.\S+$/.test(row.email);
          const loyaltyPointsValid = row.loyaltyPoints !== undefined && /^-?\d+\.?\d*$/.test(row.loyaltyPoints);
          return emailValid && loyaltyPointsValid;
        });
        if (importValid) {
          console.log(customerImport);
          setCustomersToImport(customerImport);
          handleOpenCustomerImport();
        } else {
          // import not valid
          toast.error('Import invalid');
        }
      } else {
        // empty file
        toast.error('Selected File has 0 entries');
      }
    });
  }, []);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    async function getGroups() {
      const groupData = org.id === demoId ? demoGroups : await apiRequest('/api/groups');
      console.log(groupData);
      if (mounted.current) {
        const allTypologies = Typologies.map((typology) => groupData.find((x) => x.name.toLowerCase() === typology.value.toLowerCase()));
        allTypologies.push(groupData.find((x) => x.name.toLowerCase() === 'all typologies'));
        setGroups(allTypologies);
      }
    }
    getGroups();
  }, [org]);
  useEffect(() => {
    async function getCustomerData() {
      try {
        const selectedTypology = groups.find((x) => (option.toLowerCase() !== 'no typology' ? (x?.name.toLowerCase() === option.toLowerCase()) : (x?.name.toLowerCase() === 'default')));
        if (selectedTypology) {
          console.log(selectedTypology.id);
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 999) + 100) } : await apiRequest('/api/customers/count', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setCustomerController({
              loading: false,
              value: value || 0,
              typologyConfidence: selectedTypology.typology_confidence
            });
          }
        } else {
          console.log('Typology group missing?', option);
        }
      } catch (e) {
        console.log(e);
      }
    }
    async function getSalesData() {
      try {
        const selectedTypology = groups.find((x) => (option.toLowerCase() !== 'no typology' ? (x?.name.toLowerCase() === option.toLowerCase()) : (x?.name.toLowerCase() === 'default')));
        if (selectedTypology) {
          const { total: value } = org.id === demoId ? { total: Math.floor((Math.random() * 99999) + 10000) } : await apiRequest('/api/sales/total', {
            group: selectedTypology.id
          });
          if (mounted.current) {
            setSalesController({
              loading: false,
              value: value || 0,
              typologyConfidence: selectedTypology.typology_confidence
            });
          }
        } else {
          console.log('Typology group missing?', option, groups);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (groups && option) {
      getSalesData().catch(console.error);
      getCustomerData().catch(console.error);
    }
  }, [org, option, groups]);

  if (!typologyCheck(org)) {
    const estimateRequired = org.total_customers > 3300 ? 1000 : (org.total_customers <= 250 ? 75 : Math.floor(org.total_customers * 0.3));

    return (
      <>
        <Helmet>
          <title>Dashboard: Summary | AudienceZen Dashboard 23w</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default' }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Alert
                severity="success"
                action={(
                  <Button
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                  >
                    {/* <input {...getInputProps()} /> */}
                    <Box onClick={handleOpenImportDialog}>Import Customers</Box>
                  </Button>
                )}
              >
                <AlertTitle>Import your customers</AlertTitle>
                Import customers placeholder text lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
              </Alert>
            </Grid>
            <Grid
              item
              xs={8}
            >
              <VersantPrompt />
            </Grid>

            <Grid
              item
              xl={4}
              md={6}
              sm={6}
              xs={12}
            >
              <Card
                variant="outlined"
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    pt: 2,
                    px: 3,
                    pb: 0,
                  }}
                >
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Total Customers With Typology
                  </Typography>
                </Box>

                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    pb: 2,
                    px: 3
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 'grow',
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h3"
                    >
                      {numeral(org.total_typologies).format('0,0')}
                    </Typography>
                  </Box>
                </Box>
              </Card>

              <Card
                sx={{ mt: 2 }}
                variant="outlined"
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    pt: 2,
                    px: 3,
                    pb: 0,
                  }}
                >
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Estimated Amount Required to exit learning phase
                  </Typography>
                </Box>

                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    pb: 2,
                    px: 3
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 'grow',
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h3"
                    >
                      {numeral(estimateRequired).format('0,0')}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>

          </Grid>
        </Box>
        <ImportDialog
          open={openImportDialog}
          onClose={handleCloseImportDialog}
          onUpload={onDrop}
        />
        <ConfirmationDialog
          message={`Are you sure you want to import these customers? ${customersToImport?.length ? customersToImport.length : 0} records found.`}
          onCancel={handleCloseCustomerImport}
          onConfirm={handleImportCustomers}
          open={customerImportOpen}
          title="Import customers?"
          variant="error"
        />
      </>
    );
  }

  if (org?.accountType === 'Business') {
    return (
      <>
        <Helmet>
          <title>Dashboard: Summary | AudienceZen Dashboard</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default' }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Alert
                severity="success"
                action={(
                  <Button
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                  >
                    {/* <input {...getInputProps()} /> */}
                    <Box onClick={handleOpenImportDialog}>Import Customers</Box>
                  </Button>
                )}
              >
                <AlertTitle>Import your customers</AlertTitle>
                Import customers placeholder text lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
              </Alert>
            </Grid>
            {
              (org && org.total_customers && ((org.total_customers / org.customer_limit) > 0.8))
              && (
              <Grid
                item
                xl={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Alert
                  severity="warning"
                  action={org.access === 'admin' ? (
                    <Button
                      sx={{ mt: 2 }}
                      color="inherit"
                      size="small"
                      component={RouterLink}
                      to="/dashboard/organization/billing"
                    >
                      UPGRADE PLAN
                    </Button>
                  ) : undefined}
                >
                  <AlertTitle>Upgrade your plan</AlertTitle>
                  {'You are at '}
                  <strong>{`${((org.total_customers / org.customer_limit) * 100).toFixed(0)}%`}</strong>
                  {' of your plan limit for total customers. Upgrade your AudienceZen plan to avoid disruption.'}
                </Alert>
              </Grid>
              )
            }
            <Grid
              item
              xl={9}
              lg={8}
              md={12}
              xs={12}
            >
              <CustomersOverTime />
            </Grid>
            <Grid
              container
              item
              xl={3}
              spacing={3}
              lg={4}
              xs={12}
            >
              <Grid
                item
                xl={12}
                md={12}
                sm={12}
                xs={12}
              >
                <TotalCustomersSummary />
              </Grid>
              <Grid
                item
                xl={12}
                md={12}
                sm={12}
                xs={12}
              >
                {/* <CustomersPerTypology groups={groups} /> */}
                <GenericMetricCard
                  option={option}
                  setOption={setOption}
                  title="CUSTOMERS PER TYPOLOGY"
                  data={numeral(customerController.value).format('0,0')}
                  typologyConfidence={customerController.typologyConfidence}
                  subtitle={option}
                  icon="Person"
                />
              </Grid>
              <Box sx={{ width: '100%', height: '100%' }} />
            </Grid>
          </Grid>
        </Box>
        <ImportDialog
          open={openImportDialog}
          onClose={handleCloseImportDialog}
          onUpload={onDrop}
        />
        <ConfirmationDialog
          message={`Are you sure you want to import these customers? ${customersToImport?.length ? customersToImport.length : 0} records found.`}
          onCancel={handleCloseCustomerImport}
          onConfirm={handleImportCustomers}
          open={customerImportOpen}
          title="Import customers?"
          variant="error"
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Summary | AudienceZen Dashboard</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Grid
          container
          spacing={3}
        >
          {
            !typologyWarningCheck(org)
            && (
            <Grid
              item
              xl={12}
              md={12}
              sm={12}
              xs={12}
            >
              <VersantPrompt lowData />
            </Grid>
            )
          }
          {
            (org && org.total_customers && ((org.total_customers / org.customer_limit) > 0.8))
             && (
             <Grid
               item
               xl={12}
               md={12}
               sm={12}
               xs={12}
             >
               <Alert
                 severity="warning"
                 action={org.access === 'admin' ? (
                   <Button
                     sx={{ mt: 2 }}
                     color="inherit"
                     size="small"
                     component={RouterLink}
                     to="/dashboard/organization/billing"
                   >
                     UPGRADE PLAN
                   </Button>
                 ) : undefined}
               >
                 <AlertTitle>Upgrade your plan</AlertTitle>
                 {'You are at '}
                 <strong>{`${((org.total_customers / org.customer_limit) * 100).toFixed(0)}%`}</strong>
                 {' of your plan limit for total customers. Upgrade your AudienceZen plan to avoid disruption.'}
               </Alert>
             </Grid>
             )
          }
          <Grid
            item
            xl={3}
            md={6}
            sm={6}
            xs={12}
          >
            <RevenueGeneratedSummary />
          </Grid>
          <Grid
            item
            xl={3}
            md={6}
            sm={6}
            xs={12}
          >
            <TotalCustomersSummary />
          </Grid>
          <Grid
            item
            xl={3}
            md={6}
            sm={6}
            xs={12}
          >
            {/* <SalesPerTypology groups={groups} /> */}
            <GenericMetricCard
              option={option}
              setOption={setOption}
              title="SALES PER TYPOLOGY"
              data={numeral(salesController.value).format('$0,0.00')}
              subtitle={option}
              icon="Cart"
            />
          </Grid>
          <Grid
            item
            xl={3}
            md={6}
            sm={6}
            xs={12}
          >
            {/* <CustomersPerTypology groups={groups} /> */}
            <GenericMetricCard
              option={option}
              setOption={setOption}
              title="CUSTOMERS PER TYPOLOGY"
              data={numeral(customerController.value).format('0,0')}
              typologyConfidence={customerController.typologyConfidence}
              subtitle={option}
              icon="Person"
            />
          </Grid>
          <Grid
            item
            lg={expand ? 12 : 9}
            md={12}
          >
            <StackedSales
              hideInfo={expand}
              expand={expand}
              setExpand={setExpand}
            />
          </Grid>
          {expand ? null
            : (
              <>
                <Grid
                  item
                  lg={3}
                  md={12}
                  xs={12}
                >
                  <CustomerActivity />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Performance />
                </Grid>
              </>
            )}
        </Grid>
      </Box>
    </>
  );
};
