import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Skeleton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody

} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

export const Email = () => {
  const { apiRequest, refreshOrgs, org } = useAuth();
  const mounted = useMounted();
  const [organizationState, setOrganizationState] = useState({ isLoading: true, data: [] });
  const [reloadApi, setReloadApi] = useState(false);
  const [email, setEmail] = useState(null);
  const [domainVerified, setDomainVerified] = useState(false);
  const [verificationToken, setVerificationToken] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: organizationState.data?.email || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email must be a valid format'),
    }),
    // onSubmit: async (values, helpers) => {
    // }
  });

  const getOrg = useCallback(async () => {
    setOrganizationState(() => ({ isLoading: true }));
    try {
      const orgData = await apiRequest('/api/organisation');
      if (mounted.current) {
        setOrganizationState(() => ({ isLoading: false, data: orgData }));
        setEmail(() => (orgData.email));
        setDomainVerified(() => (orgData.domain_verified === 1));
        setVerificationToken(() => (orgData.verification_token));
      }
    } catch (err) {
      console.error(err);
      if (mounted.current) {
        setOrganizationState(() => ({
          isLoading: false,
          error: err.message
        }));
      }
    }
  }, []);

  const createDomain = (e) => {
    e.preventDefault();
    apiRequest('/api/organisation/update-email', {
      email: formik.values.email
    }).then(() => {
      toast.success('Email Created');
      setReloadApi(!reloadApi);
    }).catch((err) => {
      toast.error('Failed to Create Email');
      console.log(err);
    });
  };

  const verifyDomain = async (e) => {
    e.preventDefault();
    apiRequest('/api/organisation/identity-exists', {
      email: formik.values.email
    }).then(async () => {
      toast.success('Verified!');
      await refreshOrgs(org.id);
      setReloadApi(!reloadApi);
    }).catch((err) => {
      toast.error('Not verified');
      console.log(err);
    });
  };

  const deleteVerifiedDomain = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getOrg();
  }, [org, reloadApi]);

  if (organizationState.isLoading) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (organizationState.error) {
    return (
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            p: 3
          }}
        >
          <ErrorOutlineIcon />
          <Typography
            color="textSecondary"
            sx={{ mt: 2 }}
            variant="body2"
          >
            {organizationState.error}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{ mt: 4 }}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            md={5}
            xs={12}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Email settings
            </Typography>
            {domainVerified ? (
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <br />
                Your emails will be sent out from
                <br />
                <b>
                  {email}
                </b>
                <br />
                <br />
                If you wish to change this, please press the delete button and verify a new email.
                <br />
                <br />
              </Typography>
            ) : (email === null ? (
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <br />
                By default, your emails will be sent out from
                <br />
                <b>
                  {organizationState.data?.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}
                  @audiencezen.com
                </b>
                <br />
                <br />
                To use your own email address, please create and verify it here.
                <br />
                <br />
              </Typography>
            ) : (
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <br />
                Your email is currently&nbsp;
                <b>
                  unverified
                </b>
                . Copy the TXT Record into your email domain&apos;s DNS records and press Verify.
                <br />
                <br />
                In the meantime, your emails will be sent out from
                <br />
                <b>
                  {organizationState.data?.name.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase()}
                  @audiencezen.com
                </b>
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
          >
            <form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <InputField
                error={Boolean(formik.touched.email
              && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                disabled={email !== null}
              />
              {email !== null && !domainVerified && (
                <Table sx={{ mt: 4 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Type
                      </TableCell>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell>
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        TXT
                      </TableCell>
                      <TableCell>
                        Blank or @
                      </TableCell>
                      <TableCell>
                        <ContentCopyIcon
                          style={{ height: '18px', width: '18px', cursor: 'pointer' }}
                          onClick={() => { navigator.clipboard.writeText(verificationToken); }}
                        />
                        &nbsp;
                        {verificationToken}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              {email !== null
                ? (
                  <Grid>
                    <Button
                      color="error"
                      disabled={formik.isSubmitting}
                      size="large"
                      sx={{ mt: 4, mr: '1em' }}
                      type="submit"
                      variant="contained"
                      onClick={deleteVerifiedDomain}
                    >
                      Delete
                    </Button>
                    {!domainVerified
                    && (
                    <Button
                      color="primary"
                      disabled={formik.isSubmitting}
                      size="large"
                      sx={{ mt: 4, mr: '1em' }}
                      type="submit"
                      variant="contained"
                      onClick={verifyDomain}
                    >
                      Verify
                    </Button>
                    )}
                  </Grid>
                ) : (
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting}
                    size="large"
                    sx={{ mt: 4 }}
                    type="submit"
                    variant="contained"
                    onClick={createDomain}
                  >
                    Create
                  </Button>
                )}
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
