import { Grid, Card, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const MetricItem = (props) => {
  const { value, caption } = props;
  MetricItem.propTypes = {
    value: PropTypes.string,
    caption: PropTypes.string,
  };
  return (
    <Card
      variant="outlined"
      sx={{
        alignItems: 'center',
        borderRadius: 1,
        display: 'flex',
        textAlign: 'center',
        height: '10em',
      }}
    >
      <Grid
        container
        direction="column"
      >
        <Typography variant="h3">{value}</Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ textTransform: 'uppercase' }}
        >
          {caption}
        </Typography>
      </Grid>
    </Card>
  );
};
