import { Grid, Button, Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import EditorToolbar, { modules, formats } from '../components/campaign/editor-toolbar';

// CSS FOR QUILL
import '../components/campaign/quillStyles.css';

export const TextEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editor, setEditor] = useState(location?.state?.html ? location.state.html : '');

  const handleChange = (html) => {
    setEditor(html);
  };
  return (
    <>
      <Helmet>
        <title>Text Editor | AudienceZen Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          py: 4
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="flex-end"
            sx={{ pb: '3em', pt: '1em' }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (location?.state?.callback) {
                  const paramsData = location?.state?.callback?.data || {};
                  navigate(location?.state?.callback?.url, {
                    state: {
                      ...paramsData,
                      html: editor,
                    }
                  });
                }
              }}
            >
              Save and Exit
            </Button>
          </Grid>
          {/* button nav bar */}

          {/* END HEADER COMPONENT */}
          <Box
            sx={{ maxHeight: '600px', overflowY: 'auto' }}
            data-text-editor="editorBox"
          >
            <EditorToolbar />
            <ReactQuill
              value={editor}
              onChange={handleChange}
              placeholder="Start customising your email template now!"
              modules={modules}
              formats={formats}
              bounds={'[data-text-editor="editorBox"]'}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
